import React, { useState, useEffect, Fragment } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs, addDoc, updateDoc, arrayUnion, serverTimestamp, deleteDoc, arrayRemove } from 'firebase/firestore';
import { getStorage, ref as storageRef, ref, getDownloadURL, listAll, uploadBytes } from 'firebase/storage';
import { useNavigate, Link } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  CheckCircleIcon,
  ClockIcon,
  BanknotesIcon,
  PlusIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  AcademicCapIcon,
  CheckBadgeIcon,
  CurrencyDollarIcon,
  EnvelopeOpenIcon,
  CursorArrowRaysIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { Timestamp } from 'firebase/firestore';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday } from 'date-fns';

import NewWorkingCapitalForm from './NewWorkingCapitalForm.js';
import customLogo from '../images/images.png';
import { LoanStatuses, StatusNextSteps } from '../constants/statuses';
import ConfirmationDialog from './ConfirmationDialog.js';

// Initialize Firestore and Storage
const db = getFirestore();
const storage = getStorage();

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  'Not started': 'text-red-700 bg-red-50 ring-red-600/20',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const navigation = [
  { name: 'Dashboard', href: '#', icon: HomeIcon },
  { name: 'Applications', href: '#', icon: DocumentDuplicateIcon },
  { name: 'Term Sheets', href: '#', icon: DocumentDuplicateIcon },
  { name: 'Calendar', href: '#', icon: CalendarIcon },
  { name: 'White Label', href: '#', icon: Cog6ToothIcon },
];

const statusTabs = [
  { name: 'All', status: null },
  { name: 'Not started', status: 'Not started' },
  { name: 'In progress', status: 'In progress' },
  { name: 'Complete', status: 'Complete' },
  { name: 'Assigned to Bank', status: 'Assigned to Bank' },
  { name: 'Term Sheet Received', status: 'Term Sheet Received' },
];

const requiredDocuments = [
  {
    DocumentID: 1,
    Name: "Personal Financial Statement",
    Description: "Upload your Personal Financial Statement.",
    RequiredDocuments: 1,
    Individual: true,
    Labels: ["Personal Financial Statement"],
    loanPurpose: ["Acquisition", "workingCapital", "workingCapital-startup"]
  },
  {
    DocumentID: 6,
    Name: "Business Tax Returns",
    Description: "Upload the last 3 years of business tax returns.",
    RequiredDocuments: 3,
    Individual: false,
    Labels: [
      "Business Tax Return - Current Year",
      "Business Tax Return - Last Year",
      "Business Tax Return - 2 Years Ago"
    ],
    loanPurpose: ["Acquisition", "workingCapital"]
  }
  // Add more document types as needed
];

const BrokerPortal = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [brokerData, setBrokerData] = useState(null);
  const [applications, setApplications] = useState([]);
  const [workingCapitalApplications, setWorkingCapitalApplications] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState('applications');
  const [selectedApplication, setSelectedApplication] = useState(null);
  const navigate = useNavigate();
  const [expandedApplicationId, setExpandedApplicationId] = useState(null);
  const [termSheets, setTermSheets] = useState({});
  const [selectedTermSheet, setSelectedTermSheet] = useState(null);
  const [bankDetails, setBankDetails] = useState({});
  const [currentStatus, setCurrentStatus] = useState(null);
  const [workingCapitalTermSheets, setWorkingCapitalTermSheets] = useState({});
  const [workingCapitalApplicationsWithTermSheets, setWorkingCapitalApplicationsWithTermSheets] = useState([]);
  const [showNewApplicationForm, setShowNewApplicationForm] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allTermSheets, setAllTermSheets] = useState([]);
  const [termSheetPreviewUrl, setTermSheetPreviewUrl] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortedApplications, setSortedApplications] = useState([]);
  const [statusFilter, setStatusFilter] = useState('All');
  // Add new state variables
  const [isBusinessNameModalOpen, setIsBusinessNameModalOpen] = useState(false);
  const [newBusinessName, setNewBusinessName] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [applicationToDelete, setApplicationToDelete] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchLogoUrl = async () => {
      if (currentUser) {
        const brokerRef = doc(db, 'brokers', currentUser.uid);
        const brokerDoc = await getDoc(brokerRef);
        if (brokerDoc.exists() && brokerDoc.data().logoUrl) {
          setLogoUrl(brokerDoc.data().logoUrl);
        }
      }
    };
    fetchLogoUrl();
  }, [currentUser]);
  
  useEffect(() => {
    const fetchAllTermSheets = async () => {
      if (!currentUser) return;

      setLoading(true);
      setError(null);
      try {
        const applicationsWithTermSheets = [...applications, ...workingCapitalApplications].filter(app => app.termSheets && app.termSheets.length > 0);
        
        console.log('Applications with term sheets:', applicationsWithTermSheets);

        if (applicationsWithTermSheets.length === 0) {
          console.log('No applications with term sheets found');
          setAllTermSheets([]);
          setLoading(false);
          return;
        }

        const allSheetsPromises = applicationsWithTermSheets.flatMap(app => 
          app.termSheets.map(async termSheetId => {
            const termSheetDoc = await getDoc(doc(db, 'termSheets', termSheetId));
            if (termSheetDoc.exists()) {
              const termSheetData = termSheetDoc.data();
              return {
                id: termSheetId,
                loanApplicationId: app.id,
                businessName: app.businessName,
                ...termSheetData
              };
            }
            return null;
          })
        );

        const allSheets = (await Promise.all(allSheetsPromises)).filter(sheet => sheet !== null);

        console.log('All term sheets with details:', allSheets);

        setAllTermSheets(allSheets);

        // Fetch bank details
        const bankIds = [...new Set(allSheets.map(sheet => sheet.bankId).filter(Boolean))];
        const bankDetailsPromises = bankIds.map(async (bankId) => {
          const bankDoc = await getDoc(doc(db, 'banks', bankId));
          if (bankDoc.exists()) {
            return [bankId, bankDoc.data()];
          }
          return null;
        });
        const bankDetailsArray = await Promise.all(bankDetailsPromises);
        const bankDetailsObject = Object.fromEntries(bankDetailsArray.filter(Boolean));
        setBankDetails(bankDetailsObject);

        if (allSheets.length > 0) {
          setSelectedTermSheet(allSheets[0]);
        }
      } catch (error) {
        console.error('Error processing term sheets:', error);
        setError('An error occurred while processing term sheets. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchAllTermSheets();
  }, [currentUser, applications, workingCapitalApplications]);

  useEffect(() => {
    const fetchBrokerData = async (userId) => {
      if (!userId) {
        console.error('User ID is undefined');
        return;
      }

      const db = getFirestore();
      try {
        const brokerDocRef = doc(db, 'brokers', userId);
        const brokerDoc = await getDoc(brokerDocRef);

        if (brokerDoc.exists()) {
          const data = brokerDoc.data();
          console.log('Broker data:', data);
          setBrokerData(data);

          if (Array.isArray(data.loanApplications) && data.loanApplications.length > 0) {
            console.log('Application IDs:', data.loanApplications);

            // Fetch all loan applications and filter client-side
            const loanAppsQuery = collection(db, 'loanApplications');
            const loanAppsSnapshot = await getDocs(loanAppsQuery);
            const loanApps = loanAppsSnapshot.docs
              .map(doc => ({ id: doc.id, ...doc.data() }))
              .filter(app => data.loanApplications.includes(app.id));

            console.log('Fetched loan applications:', loanApps);
            setApplications(loanApps);

            // Filter for working capital applications
            const workingCapitalApps = loanApps.filter(app => app.loanPurpose === 'workingCapital');
            setWorkingCapitalApplications(workingCapitalApps);
          } else {
            console.log('No applications found');
            setApplications([]);
          }
        } else {
          console.log('Broker document not found');
          navigate('/login');
        }
      } catch (error) {
        console.error('Error fetching broker data:', error);
      }
    };

    fetchBrokerData(currentUser?.uid);
  }, [currentUser, navigate]);

  useEffect(() => {
    if (brokerData?.prequalificationApplications?.length > 0) {
      fetchTermSheets(brokerData.prequalificationApplications);
    }
  }, [brokerData?.prequalificationApplications]);

  useEffect(() => {
    if (workingCapitalApplications.length > 0) {
      fetchWorkingCapitalTermSheets(workingCapitalApplications);
    }
  }, [workingCapitalApplications]);

  useEffect(() => {
    const sorted = [...workingCapitalApplications].sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt.seconds * 1000) : new Date(0);
      const dateB = b.createdAt ? new Date(b.createdAt.seconds * 1000) : new Date(0);
      return sortOrder === 'desc' ? dateB - dateA : dateA - dateB;
    });
    setSortedApplications(sorted);
  }, [workingCapitalApplications, sortOrder]);

  const fetchBrokerData = async (userId) => {
    const db = getFirestore();
    const brokerDoc = await getDoc(doc(db, 'brokers', userId));
    if (brokerDoc.exists()) {
      const data = brokerDoc.data();
      console.log('Broker data:', data);
      setBrokerData(data);
      if (data.prequalificationApplications && data.prequalificationApplications.length > 0) {
        console.log('Application IDs:', data.prequalificationApplications);
        fetchApplications(userId);
        fetchTermSheets(data.prequalificationApplications);
      } else {
        console.log('No applications found');
        setApplications([]);
        setTermSheets({});
      }
    } else {
      console.log('Broker document not found');
      navigate('/login');
    }
  };

  const fetchApplications = async (userId) => {
    const db = getFirestore();
    const brokerDoc = await getDoc(doc(db, 'brokers', userId));
    if (brokerDoc.exists()) {
      const data = brokerDoc.data();
      if (data.prequalificationApplications && data.prequalificationApplications.length > 0) {
        console.log('Application IDs:', data.prequalificationApplications);

        // Fetch all applications and filter client-side
        const applicationsQuery = collection(db, 'prequalificationApplications');
        const querySnapshot = await getDocs(applicationsQuery);
        const applicationsData = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(app => data.prequalificationApplications.includes(app.id));

        console.log('Fetched applications:', applicationsData);
        setApplications(applicationsData);
      } else {
        console.log('No applications found');
        setApplications([]);
      }
    } else {
      console.log('Broker document not found');
      navigate('/login');
    }
  };

  const fetchWorkingCapitalApplications = async (userId) => {
    const db = getFirestore();
    const userApplicationsRef = collection(db, 'users', userId, 'applications');
    const querySnapshot = await getDocs(userApplicationsRef);
    
    const workingCapitalAppIds = querySnapshot.docs
      .filter(doc => doc.data().status === 'Pre-lender')
      .map(doc => doc.data().applicationID);

    if (workingCapitalAppIds.length > 0) {
      const workingCapitalApps = [];
      for (let i = 0; i < workingCapitalAppIds.length; i += 30) {
        const chunk = workingCapitalAppIds.slice(i, i + 30);
        const appsQuery = query(
          collection(db, 'loanApplications'),
          where('__name__', 'in', chunk)
        );
        const appsSnapshot = await getDocs(appsQuery);
        workingCapitalApps.push(...appsSnapshot.docs.map(doc => {
          const data = doc.data();
          // Check if the application has a term sheet and update loanStatus if needed
          if (data.termSheets && data.termSheets.length > 0 && data.loanStatus !== "Term Sheet Received") {
            updateLoanStatus(doc.id, "Term Sheet Received");
            return { ...data, id: doc.id, loanStatus: "Term Sheet Received" };
          }
          return { ...data, id: doc.id };
        }));
      }
      setWorkingCapitalApplications(workingCapitalApps);
    }
  };

  const fetchTermSheets = async (applicationIds) => {
    if (!applicationIds || applicationIds.length === 0) {
      console.log('No application IDs to fetch term sheets');
      setTermSheets({});
      return;
    }

    const db = getFirestore();
    const termSheetsQuery = query(
      collection(db, 'prequalifiedTermSheets'),
      where('prequalificationId', 'in', applicationIds)
    );
    
    try {
      const termSheetsSnapshot = await getDocs(termSheetsQuery);
      const sheets = termSheetsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched term sheets:', sheets);
      
      const groupedSheets = sheets.reduce((acc, sheet) => {
        if (!acc[sheet.prequalificationId]) {
          acc[sheet.prequalificationId] = [];
        }
        acc[sheet.prequalificationId].push(sheet);
        return acc;
      }, {});

      console.log('Grouped term sheets:', groupedSheets);
      setTermSheets(groupedSheets);

      // Fetch bank details for each term sheet
      sheets.forEach(sheet => {
        if (sheet.bankId) {
          fetchBankDetails(sheet.bankId);
        }
      });
    } catch (err) {
      console.error('Error fetching term sheets:', err);
      setTermSheets({});
    }
  };

  const fetchBankDetails = async (bankId) => {
    try {
      const db = getFirestore();
      const bankDoc = await getDoc(doc(db, 'banks', bankId));
      if (bankDoc.exists()) {
        setBankDetails(prevDetails => ({
          ...prevDetails,
          [bankId]: bankDoc.data()
        }));
      }
    } catch (err) {
      console.error('Error fetching bank details:', err);
    }
  };

  const fetchWorkingCapitalTermSheets = async (apps) => {
    const storage = getStorage();
    const updatedApps = await Promise.all(apps.map(async (application) => {
      if (application.assignedBankIds && application.assignedBankIds.length > 0) {
        const termSheets = [];
        for (const bankId of application.assignedBankIds) {
          const termSheetsRef = storageRef(storage, `termSheets/${bankId}/${application.id}`);
          try {
            const termSheetsList = await listAll(termSheetsRef);
            const sheets = await Promise.all(termSheetsList.items.map(async (item) => {
              const url = await getDownloadURL(item);
              return { name: item.name, url };
            }));
            termSheets.push(...sheets);
          } catch (error) {
            console.error(`Error fetching term sheets for application ${application.id} and bank ${bankId}:`, error);
          }
        }
        return { ...application, termSheets };
      }
      return application;
    }));

    // Instead of updating state here, update a separate state variable
    setWorkingCapitalApplicationsWithTermSheets(updatedApps.filter(app => app.loanPurpose === 'workingCapital'));
  };

  const handleEdit = (application) => {
    setSelectedApplication(application);
    setShowNewApplicationForm(true);
  };

  const handleSave = async (formData) => {
    const db = getFirestore();
    try {
      console.log('Saving application with data:', formData);
      const applicationRef = doc(db, 'prequalificationApplications', formData.id);
      await setDoc(applicationRef, formData, { merge: true });

      const updatedApplications = applications.map(app =>
        app.id === formData.id ? formData : app
      );

      setApplications(updatedApplications);
      setEditMode(false);
      setSelectedApplication(null);
      setExpandedApplicationId(null);

      console.log('Application saved. Fetching updated data...');
      const updatedDoc = await getDoc(applicationRef);
      if (updatedDoc.exists()) {
        console.log('Updated application data:', updatedDoc.data());
      } else {
        console.log('Updated application not found');
      }
    } catch (error) {
      console.error("Error saving prequal business: ", error);
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setSelectedApplication(null);
    setExpandedApplicationId(null);
  };

  const handleNewBusiness = async () => {
    const db = getFirestore();
    try {
      const newApplication = {
        businessName: 'New Business Application',
        status: 'Not started',
        lastUpdated: new Date().toISOString(),
        brokerId: currentUser.uid,
      };

      const docRef = await addDoc(collection(db, 'prequalificationApplications'), newApplication);
      newApplication.id = docRef.id;

      const updatedBrokerData = {
        ...brokerData,
        prequalificationApplications: [...brokerData.prequalificationApplications, docRef.id],
      };

      await setDoc(doc(db, 'brokers', currentUser.uid), updatedBrokerData, { merge: true });

      setBrokerData(updatedBrokerData);
      setApplications([...applications, newApplication]);
    } catch (error) {
      console.error("Error creating new business application: ", error);
    }
  };

  // Modify the handleNewWorkingCapital function
  const handleNewWorkingCapital = () => {
    setIsBusinessNameModalOpen(true);
  };

  // New function to create the application after getting the business name
  const createNewApplication = async () => {
    if (!newBusinessName) {
      alert('Please enter a business name.');
      return;
    }

    try {
      const now = new Date();
      const newApplication = {
        businessName: newBusinessName,
        createdAt: now,
        lastUpdated: now,
        loanPurpose: "workingCapital",
        loanStatus: LoanStatuses.NEW_APPLICATION,
        userId: currentUser.uid,
        // Add these two fields to match WhiteLabel.js
        action: `call ${newBusinessName} to see what they are working on (new signup)`,
        called: false
      };

      const docRef = await addDoc(collection(db, 'loanApplications'), newApplication);
      newApplication.id = docRef.id;

      // Update the broker's document
      const brokerRef = doc(db, 'brokers', currentUser.uid);
      await updateDoc(brokerRef, {
        loanApplications: arrayUnion(docRef.id)
      });

      // Update local state
      setBrokerData(prevData => ({
        ...prevData,
        loanApplications: [...(prevData.loanApplications || []), docRef.id]
      }));
      setWorkingCapitalApplications(prevApps => [...prevApps, newApplication]);

      setSelectedApplication(newApplication);
      setShowNewApplicationForm(true);
      setIsBusinessNameModalOpen(false);
      setNewBusinessName('');
    } catch (error) {
      console.error("Error creating new working capital application: ", error);
    }
  };

  const getLoanStatus = (application) => {
    const status = application.loanStatus || 'Not started';
    const lowerStatus = status.toLowerCase();

    if (lowerStatus.startsWith('prequalified for')) {
      return "Prequalified";
    } else if (lowerStatus === 'submitted') {
      return "In progress";
    } else if (lowerStatus === 'awaiting term sheet') {
      return "Awaiting Pre-qualification";
    } else if (lowerStatus === 'rejected') {
      return "Rejected";
    }
    
    return status;
  };

  const handleSaveWorkingCapital = async (updatedFormData) => {
    try {
      const applicationRef = doc(db, 'loanApplications', updatedFormData.id);
      
      const now = serverTimestamp();
      const updateData = {
        businessName: updatedFormData.businessName || "",
        businessSummary: updatedFormData.businessSummary || "",
        creditScores: updatedFormData.creditScores || [], // Ensure it's always an array
        firstName: updatedFormData.firstName || "",
        lastName: updatedFormData.lastName || "",
        loanPurpose: "workingCapital",
        loanSize: updatedFormData.loanSize || "",
        loanStatus: updatedFormData.loanStatus,
        personalGuarantors: updatedFormData.personalGuarantors || [],
        userId: currentUser.uid,
        workingCapitalUse: updatedFormData.workingCapitalUse || "",
        lastUpdated: now,
        lastUserUpdate: now,
        updatedAt: now,
        loanApplicationId: updatedFormData.id,
        // Add the numberOfEmployees field
        numberOfEmployees: updatedFormData.numberOfEmployees || 0,
      };

      // Check if the application has a term sheet
      const appWithTermSheet = workingCapitalApplicationsWithTermSheets.find(app => app.id === updatedFormData.id);
      if (appWithTermSheet && appWithTermSheet.termSheets && appWithTermSheet.termSheets.length > 0) {
        updateData.loanStatus = "Term Sheet Received";
      }

      // If it's a new application, add createdAt
      if (!updatedFormData.createdAt) {
        updateData.createdAt = now;
      }

      // Preserve the id field
      updateData.id = updatedFormData.id;

      await setDoc(applicationRef, updateData, { merge: true });

      console.log("Application saved successfully:", updateData);

      // Update local state
      setWorkingCapitalApplications(prevApps => prevApps.map(app => 
        app.id === updatedFormData.id ? {...app, ...updateData} : app
      ));

      setShowNewApplicationForm(false);
      setSelectedApplication(null);
    } catch (error) {
      console.error("Error saving working capital application: ", error);
      throw error;
    }
  };

  const handleCloseNewApplicationForm = () => {
    setShowNewApplicationForm(false);
  };

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (file && currentUser) {
      const storageRef = ref(storage, `brokerLogos/${currentUser.uid}`);
      try {
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        setLogoUrl(url);
        await updateDoc(doc(db, 'brokers', currentUser.uid), { logoUrl: url });
        console.log('Logo uploaded successfully');
      } catch (error) {
        console.error('Error uploading logo:', error);
      }
    }
  };

  const getStatusBadgeClass = (status) => {
    switch (status.toLowerCase()) {
      case LoanStatuses.NEW_APPLICATION.toLowerCase():
        return 'bg-blue-100 text-blue-800';
      case LoanStatuses.SUBMITTED.toLowerCase():
        return 'bg-purple-100 text-purple-800'; // Changed to purple
      case LoanStatuses.PREQUALIFIED_FOR.toLowerCase():
        return 'bg-teal-100 text-teal-800';
      case LoanStatuses.DOCUMENT_CLARIFICATION.toLowerCase():
        return 'bg-green-100 text-green-800';
      case LoanStatuses.TERM_SHEET_EXTENDED.toLowerCase():
        return 'bg-yellow-100 text-yellow-800';
      case LoanStatuses.UNDERWRITING.toLowerCase():
        return 'bg-orange-100 text-orange-800';
      case LoanStatuses.FUNDED.toLowerCase():
        return 'bg-green-100 text-green-800';
      case LoanStatuses.REJECTED.toLowerCase():
        return 'bg-red-100 text-red-800';
      case 'approved':
        return 'bg-emerald-100 text-emerald-800';
      case 'in progress':
        return 'bg-purple-100 text-purple-800'; // Changed to purple
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const filteredApplications = statusFilter === 'All'
    ? [...applications, ...workingCapitalApplications]
    : [...applications, ...workingCapitalApplications].filter(app => {
        const appStatus = app.loanStatus || app.status || LoanStatuses.NEW_APPLICATION;
        if (statusFilter === 'In progress') {
          return appStatus.toLowerCase() === 'in progress' || appStatus === LoanStatuses.SUBMITTED;
        }
        return appStatus === statusFilter;
      });

  if (!brokerData) {
    return <div>Loading...</div>;
  }
  
  const formatCurrency = (amount) => {
    if (!amount) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  const getApprovalRateColor = (rate) => {
    const percentage = parseFloat(rate);
    if (isNaN(percentage)) return 'text-gray-400';
    if (percentage < 75) return 'text-red-500';
    if (percentage < 90) return 'text-yellow-500';
    return 'text-green-500';
  };

  const getProcessTimeColor = (time) => {
    const days = parseFloat(time);
    if (isNaN(days)) return 'text-gray-400';
    if (days <= 45) return 'text-green-500';
    if (days <= 75) return 'text-yellow-500';
    return 'text-red-500';
  };

  const getTotalAmountColor = (amount) => {
    const totalAmount = parseFloat(amount);
    if (isNaN(totalAmount)) return 'text-gray-400';
    if (totalAmount < 100000000) return 'text-red-500';
    if (totalAmount < 500000000) return 'text-yellow-500';
    return 'text-green-500';
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

  const fetchTermSheetPreview = async (termSheetId) => {
    try {
      const storage = getStorage();
      const termSheetRef = storageRef(storage, `termSheets/${termSheetId}`);
      const url = await getDownloadURL(termSheetRef);
      setSelectedTermSheet(prevSheet => ({
        ...prevSheet,
        url: url
      }));
    } catch (error) {
      console.error('Error fetching term sheet preview:', error);
      setSelectedTermSheet(prevSheet => ({
        ...prevSheet,
        url: null
      }));
    }
  };

  const renderWorkingCapitalTermSheets = (application) => {
    console.log('Rendering working capital term sheets for application:', application.id);
    // Use the termSheets from workingCapitalApplicationsWithTermSheets
    const appWithTermSheets = workingCapitalApplicationsWithTermSheets.find(app => app.id === application.loanApplicationId);
    const sheets = appWithTermSheets?.termSheets || [];
    console.log('Sheets for this application:', sheets);

    return (
      <div className="mt-4">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-medium text-gray-900">Term Sheets</h3>
          <p className="text-sm text-gray-500">Last update: {getLoanStatus(application)}</p>
        </div>
        {sheets.length === 0 ? (
          <div className="bg-gray-50 border border-gray-200 rounded-md p-4">
            <div className="flex items-center">
              <svg className="h-5 w-5 text-gray-400 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
              <p className="text-sm text-gray-500">No term sheets available for this application.</p>
            </div>
          </div>
        ) : (
          <ul className="divide-y divide-gray-200">
            {sheets.map((sheet, index) => (
              <li key={index} className="py-4">
                <div className="flex items-center space-x-4">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {sheet.name}
                    </p>
                    <p className="text-sm text-gray-500">
                      Uploaded: {new Date().toLocaleString()} {/* Replace with actual upload date when available */}
                    </p>
                  </div>
                  <div>
                    <a
                      href={sheet.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                    >
                      View
                    </a>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const renderApplicationButton = (application) => {
    if (application.loanPurpose === 'workingCapital') {
      return (
        <Link
          to={`/shared-upload/${application.id}`}
          className="ml-4 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
        >
          Share
        </Link>
      );
    } else {
      return (
        <button
          onClick={() => handleEdit(application.id)}
          className="ml-4 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
        >
          View details
        </button>
      );
    }
  };

  const handleSort = () => {
    setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
  };
  const handleDeleteClick = (application) => {
    setApplicationToDelete(application);
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (applicationToDelete) {
      await handleDelete(applicationToDelete);
      setIsDialogOpen(false);
      setApplicationToDelete(null);
    }
  };

  const getStatusCounts = () => {
    const counts = {
      'All': 0,
      ...Object.fromEntries(Object.values(LoanStatuses).map(status => [status, 0]))
    };

    [...applications, ...workingCapitalApplications].forEach(app => {
      const status = app.loanStatus || app.status || LoanStatuses.NEW_APPLICATION;
      if (counts.hasOwnProperty(status)) {
        counts[status]++;
      } else {
        counts[LoanStatuses.NEW_APPLICATION]++;
      }
      counts['All']++;
    });

    return counts;
  };

  const renderStatusFilter = () => {
    const statusCounts = getStatusCounts();

    return (
      <div className="bg-white shadow rounded-lg p-4 mb-6">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Filter by Status</h3>
        <div className="flex flex-wrap gap-2">
          {Object.entries(statusCounts).map(([status, count]) => (
            <button
              key={status}
              onClick={() => setStatusFilter(status)}
              className={`px-3 py-2 rounded-md text-sm font-medium ${
                statusFilter === status
                  ? 'bg-indigo-600 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {status} ({count})
            </button>
          ))}
        </div>
      </div>
    );
  };

  const renderApplicationRow = (application) => {
    const status = application.loanStatus || application.status || LoanStatuses.NEW_APPLICATION;
    const nextSteps = StatusNextSteps[status] || '';
    const loanRequest = application.loanSize ? formatCurrency(application.loanSize) : 'N/A';
    const prequalifiedAmount = application.prequalifyAmount ? formatCurrency(application.prequalifyAmount) : 'Pending';

    return (
      <tr key={application.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
          {application.businessName}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm">
          <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${getStatusBadgeClass(status)}`}>
            {status}
          </span>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {loanRequest}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {prequalifiedAmount}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {nextSteps}
        </td>
        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          <button
            onClick={() => handleEdit(application)}
            className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mr-2"
          >
            Edit
          </button>
          <button
            onClick={() => handleDeleteClick(application)}
            className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-700/10 mr-2"
          >
            Delete
          </button>
          <Link
            to={`/shared-upload/${application.id}`}
            className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 mr-2"
          >
            Share
          </Link>
          {workingCapitalApplicationsWithTermSheets.find(app => app.id === application.id)?.termSheets?.length > 0 && (
            <button
              onClick={() => handleShowTermSheet(application)}
              className="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10"
            >
              Show Term Sheet
            </button>
          )}
        </td>
      </tr>
    );
  };

  const renderApplicationsContent = () => {
    const filteredApps = statusFilter === 'All'
      ? sortedApplications
      : sortedApplications.filter(app => {
          const appStatus = app.loanStatus || app.status || LoanStatuses.NEW_APPLICATION;
          if (statusFilter === 'In progress') {
            return appStatus.toLowerCase() === 'in progress' || appStatus === LoanStatuses.SUBMITTED;
          }
          return appStatus === statusFilter;
        });

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Applications</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the applications in your account.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={handleNewWorkingCapital}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add application
            </button>
          </div>
        </div>
        
        <div className="mt-8 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-lg">
          <div className="p-4">
            <label htmlFor="status-filter" className="block text-sm font-medium text-gray-700">
              Filter by status
            </label>
            <div className="mt-2">
              <select
                id="status-filter"
                name="status-filter"
                className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="All">All</option>
                <option value="In progress">In progress</option>
                {Object.values(LoanStatuses).map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Business Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Status
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Loan Request
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Prequalified Amount
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Next Steps
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredApps.map((application) => renderApplicationRow(application))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="flex h-full flex-col">
        <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
          <div className="flex flex-shrink-0 items-center px-4">
            <img className="h-8 w-auto" src={customLogo} alt="Your Company" />
          </div>
          <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={classNames(
                  item.name.toLowerCase() === selectedTab
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                )}
                onClick={() => setSelectedTab(item.name.toLowerCase())}
              >
                <item.icon
                  className={classNames(
                    item.name.toLowerCase() === selectedTab ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                    'mr-3 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    );
  };

  const handleShowTermSheet = async (application) => {
    console.log("Handling show term sheet for application:", application);
    const appWithTermSheet = workingCapitalApplicationsWithTermSheets.find(app => app.id === application.loanApplicationId);
    
    if (appWithTermSheet && appWithTermSheet.termSheets && appWithTermSheet.termSheets.length > 0) {
      const termSheet = appWithTermSheet.termSheets[0];
      if (termSheet.url) {
        console.log("Opening term sheet URL:", termSheet.url);
        window.open(termSheet.url, '_blank');
        
        // Update the loan status if it's not already "Term Sheet Received"
        if (application.loanStatus !== "Term Sheet Received") {
          await updateLoanStatus(application.loanApplicationId, "Term Sheet Received");
        }
      } else {
        console.error("Term sheet URL not found");
        alert("Term sheet URL not available. Please contact support.");
      }
    } else {
      console.log("No term sheets found for this application");
      alert("No term sheets found for this application.");
    }
  };

  // Add a new function to update the loan status
  const updateLoanStatus = async (loanApplicationId, newStatus) => {
    try {
      const applicationRef = doc(db, 'loanApplications', loanApplicationId);
      await updateDoc(applicationRef, {
        loanStatus: newStatus,
        lastUpdated: serverTimestamp()
      });

      // Update local state
      setWorkingCapitalApplications(prevApps =>
        prevApps.map(app =>
          app.loanApplicationId === loanApplicationId ? { ...app, loanStatus: newStatus } : app
        )
      );

      console.log(`Loan status updated to ${newStatus} for application ${loanApplicationId}`);
    } catch (error) {
      console.error("Error updating loan status:", error);
    }
  };

  const stats = [
    { 
      id: 1, 
      name: 'Loan Applications', 
      stat: workingCapitalApplications.length.toString(), 
      icon: UsersIcon
    },
    { 
      id: 2, 
      name: 'Term Sheets Received', 
      stat: workingCapitalApplicationsWithTermSheets.filter(app => app.termSheets && app.termSheets.length > 0).length.toString(), 
      icon: EnvelopeOpenIcon
    },
    { 
      id: 3, 
      name: 'Closed Deals', 
      stat: workingCapitalApplications.filter(app => app.loanStatus === 'Closed').length.toString(), 
      icon: CursorArrowRaysIcon
    },
  ];

  const actions = [
    {
      title: 'New Application',
      icon: ClockIcon,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50',
      action: () => setSelectedTab('applications')
    },
    {
      title: 'View Term Sheets',
      icon: CheckBadgeIcon,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      action: () => setSelectedTab('term sheets')
    },
    {
      title: 'Schedule a Meeting',
      icon: UsersIcon,
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
      action: () => window.open('https://calendly.com/lended', '_blank')
    },
    {
      title: 'White Label',
      icon: Cog6ToothIcon,
      iconForeground: 'text-indigo-700',
      iconBackground: 'bg-indigo-50',
      action: () => setSelectedTab('white label')
    },
  ];
  const handleDelete = async (application) => {
    const db = getFirestore();
    try {
      // Delete the application from the loanApplications collection
      await deleteDoc(doc(db, 'loanApplications', application.id));
      
      // Remove the application ID from the broker's document
      const brokerRef = doc(db, 'brokers', currentUser.uid);
      await updateDoc(brokerRef, {
        loanApplications: arrayRemove(application.id)
      });
      
      // Update local state
      setApplications(prevApps => prevApps.filter(app => app.id !== application.id));
      setWorkingCapitalApplications(prevApps => prevApps.filter(app => app.id !== application.id));
      
      console.log(`Application ${application.id} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting application: ", error);
    }
  };
  const renderDashboard = () => {
    return (
      <div>
        <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3>

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {stats.map((item) => (
            <div
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md bg-indigo-500 p-3">
                  <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
                </div>
                <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
                <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                      View all<span className="sr-only"> {item.name} stats</span>
                    </a>
                  </div>
                </div>
              </dd>
            </div>
          ))}
        </dl>

        <h3 className="text-base font-semibold leading-6 text-gray-900 mt-8 mb-4">Quick Actions</h3>

        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {actions.map((action, actionIdx) => (
            <div
              key={action.title}
              className={classNames(
                actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 cursor-pointer',
              )}
              onClick={action.action}
            >
              <div>
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'inline-flex rounded-lg p-3 ring-4 ring-white',
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  {action.title}
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Quick access to {action.title.toLowerCase()} functionality.
                </p>
              </div>
              <span
                className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderCalendar = () => {
    const formatDate = (date) => {
      console.log('Formatting date:', date);
      if (!date) return null;
      if (date instanceof Date) return date;
      if (date instanceof Timestamp) return date.toDate();
      if (typeof date === 'string') {
        const parsedDate = new Date(date);
        return isNaN(parsedDate.getTime()) ? null : parsedDate;
      }
      return null;
    };

    console.log('Total applications:', workingCapitalApplications.length);
    console.log('Sample application:', workingCapitalApplications[0]);

    const days = eachDayOfInterval({
      start: startOfMonth(currentMonth),
      end: endOfMonth(currentMonth),
    }).map(date => {
      const dateStr = format(date, 'yyyy-MM-dd');
      const applicationsOnThisDay = workingCapitalApplications.filter(app => {
        const createdDate = formatDate(app.createdAt);
        return createdDate && format(createdDate, 'yyyy-MM-dd') === dateStr;
      });

      return {
        date: dateStr,
        isCurrentMonth: isSameMonth(date, currentMonth),
        isToday: isToday(date),
        events: applicationsOnThisDay.map(app => ({
          id: app.id,
          name: `Application: ${app.businessName || 'Unnamed Business'}`,
          time: formatDate(app.createdAt) ? format(formatDate(app.createdAt), 'h:mm a') : 'N/A',
          type: 'application'
        }))
      };
    });

    return (
      <div className="lg:flex lg:h-full lg:flex-col">
        <header className="flex items-center justify-between border-b border-gray-200 px-6 py-4 lg:flex-none">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            <time dateTime={format(currentMonth, 'yyyy-MM')}>
              {format(currentMonth, 'MMMM yyyy')}
            </time>
          </h1>
          <div className="flex items-center">
            <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50"
                onClick={() => setCurrentMonth(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1))}
              >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
                onClick={() => setCurrentMonth(new Date())}
              >
                Today
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50"
                onClick={() => setCurrentMonth(nextMonth => new Date(nextMonth.getFullYear(), nextMonth.getMonth() + 1, 1))}
              >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </header>
        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
            <div className="bg-white py-2">M<span className="sr-only sm:not-sr-only">on</span></div>
            <div className="bg-white py-2">T<span className="sr-only sm:not-sr-only">ue</span></div>
            <div className="bg-white py-2">W<span className="sr-only sm:not-sr-only">ed</span></div>
            <div className="bg-white py-2">T<span className="sr-only sm:not-sr-only">hu</span></div>
            <div className="bg-white py-2">F<span className="sr-only sm:not-sr-only">ri</span></div>
            <div className="bg-white py-2">S<span className="sr-only sm:not-sr-only">at</span></div>
            <div className="bg-white py-2">S<span className="sr-only sm:not-sr-only">un</span></div>
          </div>
          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
              {days.map((day) => (
                <div
                  key={day.date}
                  className={classNames(
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50 text-gray-500',
                    'relative px-3 py-2 h-32'
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      day.isToday
                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                        : undefined,
                      'ml-auto text-xs'
                    )}
                  >
                    {format(new Date(day.date), 'd')}
                  </time>
                  {day.events.length > 0 && (
                    <ol className="mt-2">
                      {day.events.slice(0, 2).map((event) => (
                        <li key={event.id} className="text-xs">
                          <a href="#" className="group flex">
                            <p className="flex-auto truncate font-medium text-blue-600 group-hover:text-indigo-600">
                              {event.name}
                            </p>
                            <time dateTime={event.time} className="ml-3 hidden flex-none text-gray-500 group-hover:text-indigo-600 xl:block">
                              {event.time}
                            </time>
                          </a>
                        </li>
                      ))}
                      {day.events.length > 2 && (
                        <li className="text-gray-500 text-xs">+ {day.events.length - 2} more</li>
                      )}
                    </ol>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBankStats = () => {
    if (!selectedTermSheet || !bankDetails[selectedTermSheet.bankId]) {
      return null;
    }
  
    const bankId = selectedTermSheet.bankId;
    const bank = bankDetails[bankId] || {};
  
    const approvalRate = bank.approvalPercentage || 'N/A';
    const approvalRateColor = getApprovalRateColor(approvalRate);
  
    const processTime = bank.processTime || 'N/A';
    const processTimeColor = getProcessTimeColor(processTime);
  
    const totalAmount = bank.totalAmount || 'N/A';
    const totalAmountColor = getTotalAmountColor(totalAmount);
  
    const stats = [
      { 
        name: 'Approval Rate', 
        stat: `${approvalRate}%`,
        icon: CheckCircleIcon,
        iconColor: approvalRateColor
      },
      { 
        name: 'Process Time', 
        stat: `${processTime} days`,
        icon: ClockIcon,
        iconColor: processTimeColor
      },
      { 
        name: 'Total Loan Amount', 
        stat: formatCurrency(totalAmount),
        subStat: '(4 years)',
        icon: CurrencyDollarIcon,
        iconColor: totalAmountColor
      },
    ];
  
    return (
      <dl className="grid grid-cols-1 gap-4 sm:grid-cols-3 mb-6">
        {stats.map((item) => (
          <div key={item.name} className="bg-white overflow-hidden shadow rounded-lg p-4 flex items-center">
            <item.icon className={`h-12 w-12 ${item.iconColor} mr-4`} />
            <div className="flex-grow text-right">
              <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
              <dd className="mt-1 text-2xl font-semibold text-gray-900">{item.stat}</dd>
              {item.subStat && (
                <dd className="mt-1 text-sm text-gray-500">{item.subStat}</dd>
              )}
            </div>
          </div>
        ))}
      </dl>
    );
  };
  

  const renderTermSheets = () => {
    console.log('Rendering term sheets. allTermSheets:', allTermSheets);
    if (loading) {
      return (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      );
    }
    if (error) {
      return (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      );
    }
    if (!allTermSheets || allTermSheets.length === 0) {
      console.log('No term sheets available');
      return (
        <div className="text-center py-10 bg-gray-50 rounded-xl">
          <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          <h3 className="mt-2 text-xl font-semibold text-gray-900">No term sheets available</h3>
          <p className="mt-1 text-sm text-gray-500">There are no term sheets available for your applications at this time.</p>
        </div>
      );
    }

    // Group term sheets by business
    const businessTermSheets = allTermSheets.reduce((acc, sheet) => {
      if (!acc[sheet.businessName]) {
        acc[sheet.businessName] = [];
      }
      acc[sheet.businessName].push(sheet);
      return acc;
    }, {});

    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Term Sheets</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Review and compare term sheets from different lenders.
            </p>
          </div>
          <a
            href="https://calendly.com/lended"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Setup Consultation
          </a>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6 p-6">
            <div className="w-full lg:w-1/4 bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="px-4 py-5 sm:px-6 bg-white border-b border-gray-200">
                <h3 className="text-lg font-medium leading-6 text-gray-900">Businesses with Term Sheets</h3>
              </div>
              <div className="border-t border-gray-200">
                <div className="h-[32rem] overflow-y-auto">
                  {Object.entries(businessTermSheets).map(([businessName, sheets]) => (
                    <div
                      key={businessName}
                      className={`p-4 cursor-pointer transition-all duration-200 ${
                        selectedTermSheet && selectedTermSheet.businessName === businessName
                          ? 'bg-blue-50 border-l-4 border-blue-500'
                          : 'hover:bg-gray-50'
                      }`}
                      onClick={() => setSelectedTermSheet(sheets[0])}
                    >
                      <div className="flex items-center justify-between">
                        <h4 className="text-lg font-semibold text-gray-900">{businessName}</h4>
                      </div>
                      <div className="mt-2 flex justify-between items-center">
                        <div className="text-sm text-gray-500">
                          <p>{sheets.length} term sheet(s)</p>
                        </div>
                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-3/4 bg-white rounded-xl shadow-md overflow-hidden">
              {selectedTermSheet ? (
                <div className="flex flex-col h-[48rem]">
                  <div className="p-6">
                    {renderBankStats()}
                  </div>
                  <div className="flex-grow overflow-hidden mx-6 mb-6">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Bank Name
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Uploaded At
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {businessTermSheets[selectedTermSheet.businessName].map((termSheet) => (
                          <tr key={termSheet.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {bankDetails[termSheet.bankId]?.bankName || 'N/A'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {termSheet.uploadedAt ? new Date(termSheet.uploadedAt.seconds * 1000).toLocaleString() : 'N/A'}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {termSheet.fileUrl ? (
                                <a
                                  href={termSheet.fileUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                  Open
                                </a>
                              ) : (
                                'Not available'
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center h-[48rem] bg-gray-50">
                  <div className="text-center">
                    <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    <h3 className="mt-2 text-lg font-medium text-gray-900">No business selected</h3>
                    <p className="mt-1 text-sm text-gray-500">Select a business to view its term sheets</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderWhiteLabel = () => {
    return (
      <div className="space-y-8">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">White Label Application</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Share this link with your clients to create applications directly linked to your account.</p>
          </div>
          <div className="mt-5">
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 bg-white">
              <div className="flex items-center justify-between">
                <span className="text-sm font-medium text-gray-900 truncate">
                  {`${window.location.origin}/broker-application/${currentUser.uid}`}
                </span>
                <div className="flex space-x-2">
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(`${window.location.origin}/broker-application/${currentUser.uid}`);
                      // Optionally, you can add a toast notification here to confirm the copy action
                    }}
                    className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
                  >
                    Copy
                  </button>
                  <Link
                    to={`/broker-application/${currentUser.uid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
                  >
                    Open
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6">
  <h3 className="text-lg font-medium leading-6 text-gray-900">Logo Upload</h3>
  <div className="mt-2 max-w-xl text-sm text-gray-500">
    <p>Upload your logo to be used in your white label application page.</p>
  </div>
  <div className="mt-5 flex items-center">
    {logoUrl && (
      <img src={logoUrl} alt="Broker Logo" className="h-12 w-auto mr-4 border border-gray-300 rounded-md" />
    )}
    <div className="flex-grow">
      <input
        type="file"
        accept="image/*"
        onChange={handleLogoUpload}
        className="hidden"
        id="logo-upload"
      />
      <label
        htmlFor="logo-upload"
        className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {logoUrl ? 'Replace Logo' : 'Upload Logo'}
      </label>
    </div>
  </div>
</div>
      </div>
    );
  };

  const handleWorkingCapitalUpdate = (updatedData) => {
    // Update the local state immediately
    setWorkingCapitalApplications(prevApps =>
      prevApps.map(app =>
        app.id === updatedData.id ? { ...app, ...updatedData } : app
      )
    );
    
    // If you're using selectedApplication state, update that too
    setSelectedApplication(updatedData);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!currentUser) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl font-semibold">Please log in to access the Broker Portal.</p>
      </div>
    );
  }

  return (
    <div>
      {/* Mobile sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                {/* Sidebar component */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                  {renderSidebar()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        {renderSidebar()}
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 lg:mx-auto lg:max-w-7xl lg:px-8">
          <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Removed search bar, notification bell, and profile dropdown */}
          </div>
        </div>

        <main className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            {selectedTab === 'dashboard' && renderDashboard()}
            {selectedTab === 'applications' && (
              <>
                {showNewApplicationForm ? (
                  <NewWorkingCapitalForm
                    initialData={selectedApplication}
                    onSave={handleSaveWorkingCapital}
                    onCancel={() => {
                      setShowNewApplicationForm(false);
                      setSelectedApplication(null);
                    }}
                    onUpdate={handleWorkingCapitalUpdate}
                  />
                ) : (
                  renderApplicationsContent()
                )}
              </>
            )}
            {selectedTab === 'term sheets' && renderTermSheets()}
            {selectedTab === 'calendar' && renderCalendar()}
            {selectedTab === 'white label' && renderWhiteLabel()}
          </div>
        </main>
      </div>

      {/* Add the modal for entering business name */}
      {isBusinessNameModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      New Working Capital Application
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Please enter the business name for the new working capital application.
                      </p>
                    </div>
                    <div className="mt-4">
                      <input
                        type="text"
                        value={newBusinessName}
                        onChange={(e) => setNewBusinessName(e.target.value)}
                        placeholder="Enter Business Name"
                        className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={createNewApplication}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Create Application
                </button>
                <button
                  type="button"
                  onClick={() => setIsBusinessNameModalOpen(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ConfirmationDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default BrokerPortal;


