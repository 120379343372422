import React, { useState, useEffect } from 'react';

function CurrencyInput({ name, label, value, onChange }) {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(formatCurrency(value));
  }, [value]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    const formattedValue = formatCurrency(rawValue);
    setInputValue(formattedValue);
    onChange({ target: { name, value: rawValue } });
  };

  return (
    <div className="flex flex-col items-center">
      <label htmlFor={name} className="block mb-2 font-semibold text-gray-700">
        {label}
      </label>
      <div className="relative">
        <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-blue-900">
          <span className="text-4xl font-bold">$</span>
        </span>
        <input
          type="text"
          name={name}
          id={name}
          value={inputValue}
          onChange={handleChange}
          className="w-full pl-12 pr-4 py-4 text-4xl font-bold text-blue-900 text-center border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          placeholder="Enter amount"
          min="250000"
          max="5000000"
        />
      </div>
      {value < 250000 && (
        <p className="mt-1 text-sm text-red-500">Minimum amount is $250,000</p>
      )}
      {value > 5000000 && (
        <p className="mt-1 text-sm text-red-500">Maximum amount is $5,000,000</p>
      )}
    </div>
  );
}

export default CurrencyInput;
