import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { ChevronUpIcon, ChevronDownIcon, XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const PrequalificationOcrReview = ({ prequalId }) => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isOcrReviewExpanded, setIsOcrReviewExpanded] = useState(true);
  const [selectedDocumentFileUrl, setSelectedDocumentFileUrl] = useState('');
  const [isFullScreenMode, setIsFullScreenMode] = useState(false);
  const [confirmedFields, setConfirmedFields] = useState({});
  const iframeRef = useRef(null);
  const [pdfObjectUrl, setPdfObjectUrl] = useState(null);
  const [lastFetchTimestamp, setLastFetchTimestamp] = useState(0);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        console.log("Fetching documents for prequalId:", prequalId);

        // Fetch OCR metadata
        const ocrMetadataRef = collection(db, 'OCRMetadata');
        const ocrQuery = query(ocrMetadataRef, where('applicationId', '==', prequalId));
        const ocrQuerySnapshot = await getDocs(ocrQuery);
        
        const ocrDocs = ocrQuerySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        console.log("Fetched OCR documents:", ocrDocs);

        // Fetch corresponding document URLs
        const prequalMetadataRef = collection(db, 'prequalificationMetadata');
        const prequalQuery = query(prequalMetadataRef, where('applicationId', '==', prequalId));
        const prequalQuerySnapshot = await getDocs(prequalQuery);

        const urlMap = {};
        prequalQuerySnapshot.docs.forEach(doc => {
          const data = doc.data();
          urlMap[data.documentId || doc.id] = data.fileUrl;
        });

        console.log("URL map:", urlMap);

        // Combine OCR data with document URLs and filter out outdated entries
        const combinedDocs = ocrDocs
          .filter(doc => urlMap[doc.documentId || doc.id])
          .map(doc => ({
            ...doc,
            fileUrl: urlMap[doc.documentId || doc.id],
          }));

        console.log("Combined documents:", combinedDocs);

        // Remove outdated OCR entries
        const outdatedDocs = ocrDocs.filter(doc => !urlMap[doc.documentId || doc.id]);
        for (const outdatedDoc of outdatedDocs) {
          await deleteDoc(doc(db, 'OCRMetadata', outdatedDoc.id));
        }

        setDocuments(combinedDocs);
        // Select the first document if available
        if (combinedDocs.length > 0) {
          console.log("Selecting first document:", combinedDocs[0]);
          handleDocumentSelect(combinedDocs[0]);
        } else {
          console.log("No documents available to select");
          setSelectedDocument(null);
          setSelectedDocumentFileUrl('');
        }
      } catch (error) {
        console.error('Error fetching documents:', error);
      }
    };

    fetchDocuments();
  }, [prequalId, lastFetchTimestamp]);

  const handleDocumentSelect = async (document) => {
    console.log("Selecting document:", document);
    
    if (!document || !document.fileUrl) {
      console.error("Invalid document or missing fileUrl:", document);
      return;
    }

    setSelectedDocument(document);
    setSelectedDocumentFileUrl(document.fileUrl);

    console.log("Updated selectedDocumentFileUrl:", document.fileUrl);

    try {
      const ocrDocRef = doc(db, 'OCRMetadata', document.id);
      const ocrDocSnap = await getDoc(ocrDocRef);
      
      if (ocrDocSnap.exists()) {
        const ocrData = ocrDocSnap.data();
        console.log("Fetched OCR data:", ocrData);
        setSelectedDocument(prev => ({
          ...prev,
          ocrResult: ocrData.ocrResult,
        }));
        setConfirmedFields(ocrData.confirmedFields || {});
      } else {
        console.log("No OCR data found for this document");
        setSelectedDocument(prev => ({
          ...prev,
          ocrResult: null,
        }));
        setConfirmedFields({});
      }
    } catch (error) {
      console.error("Error fetching OCR data:", error);
    }
  };

  const handleOcrInputChange = (e, field) => {
    if (selectedDocument && selectedDocument.ocrResult) {
      let newValue = e.target.value;
      
      if (['compensation_of_officers', 'gross_receipts_or_sales', 'ordinary_business_income'].includes(field) || field.startsWith('amount_')) {
        newValue = newValue === '' ? null : Number(newValue);
      }

      setSelectedDocument(prev => ({
        ...prev,
        ocrResult: {
          ...prev.ocrResult,
          [field]: newValue,
        }
      }));
      setConfirmedFields(prev => ({ ...prev, [field]: false }));
    }
  };

  const handleSaveOcrChanges = async () => {
    if (!selectedDocument) return;

    try {
      const docRef = doc(db, 'OCRMetadata', selectedDocument.id);
      
      await updateDoc(docRef, {
        ocrResult: selectedDocument.ocrResult,
        confirmedFields: confirmedFields,
      });

      alert('OCR changes saved successfully!');
    } catch (error) {
      console.error('Error saving OCR changes:', error);
      alert('Failed to save OCR changes. Please try again.');
    }
  };

  const handleConfirmField = async (field, status) => {
    const newConfirmedFields = { 
      ...confirmedFields, 
      [field]: status 
    };
    setConfirmedFields(newConfirmedFields);

    try {
      const docRef = doc(db, 'OCRMetadata', selectedDocument.id);
      await updateDoc(docRef, {
        confirmedFields: newConfirmedFields,
      });
    } catch (error) {
      console.error('Error saving confirmation:', error);
      setConfirmedFields(confirmedFields);
    }
  };

  const renderField = (label, field, value) => {
    const isNumeric = ['compensation_of_officers', 'gross_receipts_or_sales', 'ordinary_business_income'].includes(field) || field.startsWith('amount_');

    if (field === 'other_deductions' && Array.isArray(value)) {
      return (
        <div key={field} className="mb-4">
          <div className="flex justify-between items-center">
            <label className="block text-sm font-medium text-gray-700">{label}</label>
            <div className="ml-2 flex space-x-2">
              <button
                onClick={() => handleConfirmField('other_deductions', 'changed')}
                className={`p-2 rounded-full transition-colors duration-200 ${
                  confirmedFields['other_deductions'] === 'changed' 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-white text-blue-300 border border-blue-200 hover:bg-blue-50 hover:border-blue-300'
                }`}
                title="Confirm with changes"
              >
                {confirmedFields['other_deductions'] === 'changed' ? (
                  <CheckCircleIcon className="h-6 w-6" />
                ) : (
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <circle cx="12" cy="12" r="10" strokeWidth="2" />
                  </svg>
                )}
              </button>
              <button
                onClick={() => handleConfirmField('other_deductions', 'unchanged')}
                className={`p-2 rounded-full transition-colors duration-200 ${
                  confirmedFields['other_deductions'] === 'unchanged' 
                    ? 'bg-green-500 text-white' 
                    : 'bg-white text-green-300 border border-green-200 hover:bg-green-50 hover:border-green-300'
                }`}
                title="Confirm without changes"
              >
                {confirmedFields['other_deductions'] === 'unchanged' ? (
                  <CheckCircleIcon className="h-6 w-6" />
                ) : (
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <circle cx="12" cy="12" r="10" strokeWidth="2" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <div className="mt-1 border rounded-md p-2">
            {value.map((deduction, index) => (
              <div key={index} className="flex justify-between items-center mb-2">
                <span>{deduction.category}:</span>
                <input
                  type="number"
                  value={deduction.amount}
                  onChange={(e) => handleOtherDeductionChange(index, 'amount', e.target.value)}
                  className="w-24 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            ))}
          </div>
        </div>
      );
    }

    return (
      <div key={field} className="mb-4 flex items-center">
        <div className="flex-grow">
          <label className="block text-sm font-medium text-gray-700">{label}</label>
          <input
            type={isNumeric ? "number" : "text"}
            value={value ?? ''}
            onChange={(e) => handleOcrInputChange(e, field)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div className="ml-2 flex space-x-2">
          <button
            onClick={() => handleConfirmField(field, 'changed')}
            className={`p-2 rounded-full transition-colors duration-200 ${
              confirmedFields[field] === 'changed' 
                ? 'bg-blue-500 text-white' 
                : 'bg-white text-blue-300 border border-blue-200 hover:bg-blue-50 hover:border-blue-300'
            }`}
            title="Confirm with changes"
          >
            {confirmedFields[field] === 'changed' ? (
              <CheckCircleIcon className="h-6 w-6" />
            ) : (
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <circle cx="12" cy="12" r="10" strokeWidth="2" />
              </svg>
            )}
          </button>
          <button
            onClick={() => handleConfirmField(field, 'unchanged')}
            className={`p-2 rounded-full transition-colors duration-200 ${
              confirmedFields[field] === 'unchanged' 
                ? 'bg-green-500 text-white' 
                : 'bg-white text-green-300 border border-green-200 hover:bg-green-50 hover:border-green-300'
            }`}
            title="Confirm without changes"
          >
            {confirmedFields[field] === 'unchanged' ? (
              <CheckCircleIcon className="h-6 w-6" />
            ) : (
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <circle cx="12" cy="12" r="10" strokeWidth="2" />
              </svg>
            )}
          </button>
        </div>
      </div>
    );
  };

  const handleOtherDeductionChange = (index, key, value) => {
    if (selectedDocument && selectedDocument.ocrResult) {
      const newOtherDeductions = [...selectedDocument.ocrResult.other_deductions];
      newOtherDeductions[index] = {
        ...newOtherDeductions[index],
        [key]: key === 'amount' ? Number(value) : value
      };
      setSelectedDocument(prev => ({
        ...prev,
        ocrResult: {
          ...prev.ocrResult,
          other_deductions: newOtherDeductions
        }
      }));
      setConfirmedFields(prev => ({ ...prev, other_deductions: false }));
    }
  };

  const renderOcrReviewContent = () => (
    <div className="flex h-full">
      <div className={`${isFullScreenMode ? 'w-[35%]' : 'w-1/2'} p-6 overflow-y-auto`}>
        <h4 className="text-lg font-medium mb-4">Documents</h4>
        <ul className="space-y-2">
          {documents.map(doc => (
            <li
              key={doc.id}
              className={`cursor-pointer p-2 rounded ${selectedDocument?.id === doc.id ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
              onClick={() => handleDocumentSelect(doc)}
            >
              {doc.label || `Document ${doc.id.slice(0, 6)}`}
            </li>
          ))}
        </ul>
        {selectedDocument && selectedDocument.ocrResult && (
          <>
            <h4 className="text-lg font-medium mb-2 mt-6">Edit OCR Results</h4>
            {Object.entries(selectedDocument.ocrResult)
              .sort(([a], [b]) => a.localeCompare(b))
              .map(([field, value]) => renderField(field, field, value))}
            <button
              onClick={handleSaveOcrChanges}
              className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save Changes
            </button>
          </>
        )}
      </div>
      <div className={`${isFullScreenMode ? 'w-[65%]' : 'w-1/2'} border-l border-gray-200`}>
        {selectedDocumentFileUrl ? (
          <>
            <p>Debug: Current fileUrl: {selectedDocumentFileUrl}</p>
            <iframe
              ref={iframeRef}
              src={`${selectedDocumentFileUrl}#toolbar=0&navpanes=0&scrollbar=0&t=${Date.now()}`}
              title="Selected Document"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
              key={selectedDocumentFileUrl}
              onLoad={() => console.log("iframe loaded with URL:", selectedDocumentFileUrl)}
              onError={(e) => console.error("iframe load error:", e)}
            />
          </>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p className="text-gray-500">Select a document to view</p>
          </div>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    if (iframeRef.current && selectedDocumentFileUrl) {
      console.log("Reloading iframe with new URL:", selectedDocumentFileUrl);
      iframeRef.current.src = `${selectedDocumentFileUrl}#toolbar=0&navpanes=0&scrollbar=0&t=${Date.now()}`;
    }
  }, [selectedDocumentFileUrl]);

  useEffect(() => {
    console.log("selectedDocumentFileUrl changed:", selectedDocumentFileUrl);
  }, [selectedDocumentFileUrl]);

  console.log("Rendering PrequalificationOcrReview with state:", {
    documents,
    selectedDocument,
    selectedDocumentFileUrl,
    isOcrReviewExpanded,
    isFullScreenMode,
    confirmedFields
  });

  // Add this function to trigger a re-fetch
  const refreshDocuments = () => {
    setLastFetchTimestamp(Date.now());
  };

  return (
    <>
      <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
        <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <div>
            <h3 className="text-base font-semibold leading-7 text-gray-900">Prequalification OCR Review</h3>
            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Review and edit OCR results for prequalification documents.</p>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setIsFullScreenMode(!isFullScreenMode)}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Full Screen
            </button>
            <button
              onClick={() => setIsOcrReviewExpanded(!isOcrReviewExpanded)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              {isOcrReviewExpanded ? (
                <ChevronUpIcon className="h-6 w-6" />
              ) : (
                <ChevronDownIcon className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>
        {isOcrReviewExpanded && (
          <div className="h-[calc(100vh-200px)]">
            {renderOcrReviewContent()}
          </div>
        )}
      </div>
      {isFullScreenMode && (
        <div className="fixed inset-0 z-50 bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
            <div>
              <h3 className="text-base font-semibold leading-7 text-gray-900">Prequalification OCR Review</h3>
              <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Review and edit OCR results for prequalification documents.</p>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={handleSaveOcrChanges}
                className="
inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save Changes
              </button>
              <button
                onClick={() => setIsFullScreenMode(false)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
          </div>
          <div className="h-[calc(100vh-64px)]">
            {renderOcrReviewContent()}
          </div>
        </div>
      )}
      <button
        onClick={refreshDocuments}
        className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Refresh Documents
      </button>
    </>
  );
};

export default PrequalificationOcrReview;