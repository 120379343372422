import React, { useState, useCallback, useEffect, useRef, Fragment } from 'react';
import { ChevronLeftIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { CloudArrowUpIcon, CheckCircleIcon, ExclamationCircleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFirestore, addDoc, collection, query, where, getDoc, getDocs, setDoc, doc, updateDoc , deleteDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { storage, db } from '../../firebase';
import { getIdToken } from 'firebase/auth';
import { useDropzone } from 'react-dropzone';
import introJs from 'intro.js';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Transition, Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import ChatBot from './chat';
import LoanStructureForm from './LoanStructureForm';
import FormEditor from './FormEditor';
import axios from 'axios';

const defaultOnFormDataChange = (data) => {
  console.log('Form data changed:', data);
};
export const documentRequirements = [
  {
    "DocumentID": 1,
    "Name": "Personal Financial Statement",
    "Description": "Upload your Personal Financial Statement.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Personal Financial Statement"],
    "loanPurpose": ["Acquisition", "workingCapital", "workingCapital-startup", "Refinance"]
  },
  {
    "DocumentID": 4,
    "Name": "Personal Tax Returns",
    "Description": "Upload 3 years of personal tax returns for each guarantor.",
    "RequiredDocuments": 3,
    "Individual": true,
    "Labels": [
      "Personal Tax Return - Current Year",
      "Personal Tax Return - Last Year",
      "Personal Tax Return - 2 Years Ago"
    ],
    "loanPurpose": ["Acquisition", "workingCapital", "workingCapital-startup", "Refinance"]
  },
  {
    "DocumentID": 6,
    "Name": "Business Tax Returns",
    "Description": "Upload the last 3 years of business tax returns.",
    "RequiredDocuments": 3,
    "Individual": false,
    "Labels": [
      "Business Tax Return - Current Year",
      "Business Tax Return - Last Year",
      "Business Tax Return - 2 Years Ago"
    ],
    "loanPurpose": ["Acquisition", "workingCapital","Refinance"]
  },
  {
    "DocumentID": 7,
    "Name": "Resumes for Key Team Members",
    "Description": "Upload resumes for each key team member.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Resume"],
    "loanPurpose": ["Acquisition", "workingCapital", "workingCapital-startup", "Refinance"]
  },
  {
    "DocumentID": 8,
    "Name": "Credit Scores",
    "Description": "Enter credit scores for each guarantor.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Credit Score"],
    "loanPurpose": ["Acquisition", "workingCapital", "workingCapital-startup", "Refinance"]
  },
  {
    "DocumentID": 9,
    "Name": "Draft of LOI",
    "Description": "Upload the draft of the Letter of Intent (LOI).",
    "RequiredDocuments": 1,
    "Individual": false,
    "Labels": ["Draft of LOI"],
    "loanPurpose": ["Acquisition"]
  },
  {
    "DocumentID": 10,
    "Name": "Interim P&L",
    "Description": "Upload the interim Profit and Loss statement (PDF or Excel).",
    "RequiredDocuments": 1,
    "Individual": false,
    "Labels": ["Interim P&L"],
    "AcceptedFileTypes": ".pdf,.xlsx,.xls",
    "loanPurpose": ["Acquisition", "workingCapital", "Refinance"]
  },
  {
    "DocumentID": 11,
    "Name": "Business Tax Returns - 2",
    "Description": "Upload the last 3 years of business tax returns for the second LLC (optional).",
    "RequiredDocuments": 3,
    "Individual": false,
    "Labels": [
      "Business Tax Return - Current Year (2nd LLC)",
      "Business Tax Return - Last Year (2nd LLC)",
      "Business Tax Return - 2 Years Ago (2nd LLC)"
    ],
    "Optional": true,
    "loanPurpose": ["Acquisition"]
  },
  {
    "DocumentID": 12,
    "Name": "Interim P&L - 2",
    "Description": "Upload the interim Profit and Loss statement for the second LLC (PDF or Excel, optional).",
    "RequiredDocuments": 1,
    "Individual": false,
    "Labels": ["Interim P&L (2nd LLC)"],
    "AcceptedFileTypes": ".pdf,.xlsx,.xls",
    "Optional": true,
    "loanPurpose": ["Acquisition"]
  },
  {
    "DocumentID": 13,
    "Name": "Financial Projections",
    "Description": "Upload financial projections for the next 3 years.",
    "RequiredDocuments": 1,
    "Individual": false,
    "Labels": ["Financial Projections"],
    "AcceptedFileTypes": ".pdf,.xlsx,.xls",
    "loanPurpose": ["workingCapital-startup"]
  },
  {
    "DocumentID": 14,
    "Name": "Business Plan",
    "Description": "Upload a comprehensive business plan for your startup.",
    "RequiredDocuments": 1,
    "Individual": false,
    "Labels": ["Business Plan"],
    "AcceptedFileTypes": ".pdf,.docx,.doc",
    "loanPurpose": ["workingCapital-startup"]
  },
  {
    "DocumentID": 15,
    "Name": "Bank Statements",
    "Description": "Upload 6 months of bank statements.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Bank Statements"],
    "AcceptedFileTypes": ".pdf,.xlsx,.xls",
    "loanPurpose": ["workingCapital-nonsba"]
  },
  {
    "DocumentID": 17,
    "Name": "Voided Check",
    "Description": "Upload a scanned copy of a voided check.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Voided Check"],
    "AcceptedFileTypes": ".pdf,.jpg,.png",
    "loanPurpose": ["workingCapital-nonsba"]
  },
  {
    "DocumentID": 18,
    "Name": "ID",
    "Description": "Upload a copy of your government-issued ID.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["ID"],
    "AcceptedFileTypes": ".pdf,.jpg,.png",
    "loanPurpose": ["workingCapital-nonsba"]
  },
  {
    "DocumentID": 20,
    "Name": "Debt Schedule",
    "Description": "Upload a detailed debt schedule for the business.",
    "RequiredDocuments": 1,
    "Individual": false,
    "Labels": ["Debt Schedule"],
    "AcceptedFileTypes": ".pdf,.xlsx,.xls",
    "loanPurpose": ["Refinance"]
  },
];

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  'Not started': 'text-red-700 bg-red-50 ring-red-600/20',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


const DocumentUploader = ({ onFormDataChange = defaultOnFormDataChange, onReviewSubmit }) => {
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [personalGuarantors, setPersonalGuarantors] = useState([]);
  const [loanApplicationId, setLoanApplicationId] = useState(null);
  const [isPersonalExpanded, setIsPersonalExpanded] = useState(true);
  const [isBusinessExpanded, setIsBusinessExpanded] = useState(true);
  const [isLoanApplicationExpanded, setIsLoanApplicationExpanded] = useState(true);
  const [isAddOwnerModalOpen, setIsAddOwnerModalOpen] = useState(false);
  const [newOwnerFirstName, setNewOwnerFirstName] = useState('');
  const [newOwnerLastName, setNewOwnerLastName] = useState('');
  const addOwnerButtonRef = useRef(null);
  const [tutorialStarted, setTutorialStarted] = useState(false);
  const introRef = useRef(null);
  const [formData, setFormData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [businessName, setBusinessName] = useState('');
  const [creditScores, setCreditScores] = useState({});
  const [showSecondLLC, setShowSecondLLC] = useState(false);
  const [organizedFiles, setOrganizedFiles] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showCategorizedFiles, setShowCategorizedFiles] = useState(false);
  const [categorizedFiles, setCategorizedFiles] = useState({});
  const [isCategorizing, setIsCategorizing] = useState(false);
  const [isReviewExpanded, setIsReviewExpanded] = useState(true);
  const [loanPurpose, setLoanPurpose] = useState('Acquisition');

  const filteredDocumentRequirements = documentRequirements.filter(
    (doc) => {
      if (loanPurpose === 'workingCapital-startup') {
        return doc.loanPurpose.includes('workingCapital') || 
               doc.Name === "Financial Projections" || 
               doc.Name === "Business Plan";
      }
      if (loanPurpose === 'workingCapital-nonsba') {
        return doc.loanPurpose.includes('workingCapital-nonsba');
      }
      return doc.loanPurpose.includes(loanPurpose);
    }
  );

  const personalDocuments = filteredDocumentRequirements.filter(doc => 
    doc.Individual || (loanPurpose === 'workingCapital-nonsba' && doc.Name === "Loan Application")
  );
  const businessDocuments = filteredDocumentRequirements.filter(doc => {
    if (loanPurpose === 'workingCapital-nonsba') {
      return !doc.Individual;
    }
    if (loanPurpose === 'workingCapital-startup') {
      return !doc.Individual && (doc.Name === "Financial Projections" || doc.Name === "Business Plan");
    }
    if (loanPurpose === 'workingCapital') {
      return !doc.Individual && !doc.Optional && 
             (doc.Name !== "Financial Projections" || doc.Name === "Business Bank Statements");
    }
    return !doc.Individual && !doc.Optional;
  });
  const secondLLCDocuments = filteredDocumentRequirements.filter(doc => !doc.Individual && doc.Optional);

  const getDocumentType = (documentId) => {
    const document = filteredDocumentRequirements.find((doc) => doc.DocumentID === documentId);
    return document.Individual ? 'personal' : 'business';
  };

  const handleAuthError = () => {
    alert('Your session has expired. Please log in again.');
    navigate('/login');
  };

  const handleFileChange = async (event, guarantorId, label) => {
    try {
      const uploadedFile = event.target.files[0];
      if (!uploadedFile) {
        console.warn('No file provided to handleFileChange');
        return;
      }

      console.log('File selected:', uploadedFile.name);

      const document = filteredDocumentRequirements.find(doc => doc.Labels.includes(label));
      if (!document) {
        console.warn(`No matching document requirement found for label: ${label}`);
        return;
      }

      const fileExtension = uploadedFile.name.split('.').pop().toLowerCase();
      const allowedExtensions = document.AcceptedFileTypes ? document.AcceptedFileTypes.split(',').map(ext => ext.replace('.', '')) : ['pdf'];
      
      if (!allowedExtensions.includes(fileExtension)) {
        alert(`Invalid file type. Please upload a ${allowedExtensions.join(' or ')} file.`);
        return;
      }

      const contentType = fileExtension === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

      const storageRef = ref(storage, `documents/${currentUser.uid}/${loanApplicationId}/${guarantorId || 'business'}/${document.Name}/${label}/${uploadedFile.name}`);
      console.log('Storage reference path:', storageRef.fullPath);

      const existingDocument = getUploadedDocument(document.Name, guarantorId, label);
      if (existingDocument) {
        const oldStorageRef = ref(storage, existingDocument.fileUrl);
        await deleteObject(oldStorageRef);
        console.log('Deleted existing document:', existingDocument.fileUrl);
      }

      await uploadBytes(storageRef, uploadedFile, { contentType: contentType });
      console.log('File uploaded to Firebase Storage');

      const fileUrl = await getDownloadURL(storageRef);
      console.log('File URL:', fileUrl);

      const documentMetadata = {
        fileName: uploadedFile.name,
        fileUrl,
        uploadedAt: new Date().toISOString(),
        userId: currentUser.uid,
        documentName: document.Name,
        loanApplicationId: loanApplicationId,
        ownerId: guarantorId || null,
        label,
      };
      console.log('Document metadata:', JSON.stringify(documentMetadata, null, 2));

      const documentRef = await addDoc(collection(db, 'documentMetadata'), documentMetadata);
      documentMetadata.documentId = documentRef.id;
      console.log('Document metadata added to Firestore with ID:', documentRef.id);

      setUploadedDocuments((prevDocs) => {
        const updatedDocs = prevDocs.filter(
          (doc) =>
            !(doc.documentName === document.Name && doc.ownerId === guarantorId && doc.label === label)
        );
        return [...updatedDocs, documentMetadata];
      });

      const loanRef = doc(db, 'loanApplications', loanApplicationId);
      await updateDoc(loanRef, { lastUserUpdate: new Date() });
      console.log('Loan application updated with last user update');

      // Prepare the data to be sent
      const requestBody = {
        fileUrl: fileUrl,
        metadata: documentMetadata,
      };
      console.log('Request body to be sent:', JSON.stringify(requestBody, null, 2));

      const idToken = await getIdToken(currentUser);
      console.log('ID token obtained for authentication');

      const response = await fetch('https://sba-30d9855b23ef.herokuapp.com/process-document', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      console.log('Response status:', response.status);
      console.log('Response status text:', response.statusText);

      if (response.ok) {
        console.log(`Document uploaded and processed successfully for guarantorId: ${guarantorId}, label: ${label}`);
        const responseData = await response.json();
        console.log('Response data:', JSON.stringify(responseData, null, 2));
      } else {
        console.error(`Error processing document for guarantorId: ${guarantorId}, label: ${label}`);
        const errorData = await response.text();
        console.error('Error response:', errorData);
      }

      event.target.value = null;

      // Update the UI to reflect the new upload
      setFormData(prevData => ({
        ...prevData,
        [document.Name]: {
          ...prevData[document.Name],
          [label]: documentMetadata
        }
      }));
      console.log('UI updated with new document metadata');

    } catch (error) {
      console.error("Error in handleFileChange:", error);
      if (error.code === 'permission-denied') {
        handleAuthError();
      }
    }
  };

  const categorizeFiles = async (files) => {
    console.log('Starting file categorization...');
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
        console.log(`Appending file: ${file.name}`);
      });

      const metadata = JSON.stringify({
        userId: currentUser.uid,
        timestamp: new Date().toISOString(),
      });
      formData.append('metadata', metadata);
      console.log('Metadata appended:', metadata);

      const idToken = await getIdToken(currentUser);
      console.log('Got ID token, sending request to server...');

      // Use a timeout to prevent the request from hanging indefinitely
      const timeoutDuration = 30000; // 30 seconds
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeoutDuration);

      const response = await axios.post('https://sba-30d9855b23ef.herokuapp.com/categorize-files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${idToken}`,
        },
        signal: controller.signal,
      });

      clearTimeout(timeoutId);

      console.log('Server response:', response.data);

      if (!response.data.categorized_files || response.data.categorized_files.length === 0) {
        console.warn('No files were categorized by the backend');
        return [];
      }

      return response.data.categorized_files.map(result => {
        console.log('Processing result:', result);
        const matchedFile = files.find(f => 
          f.name === result.filename ||
          f.path === result.filename ||
          f.webkitRelativePath === result.filename
        );
        
        console.log('Matched file:', matchedFile);
        
        if (!matchedFile) {
          console.warn(`No matching file found for ${result.filename}`);
          return null;
        }

        return {
          file: matchedFile,
          category: {
            Name: result.category.Name,
            Labels: [result.category.Label]
          }
        };
      }).filter(Boolean);
    } catch (error) {
      console.error('Error categorizing files:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Response data:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error setting up request:', error.message);
        }
      } else if (error.name === 'AbortError') {
        console.error('Request timed out');
      } else {
        console.error('Unexpected error:', error);
      }
      throw error;
    }
  };

  const categorizeFilesLocally = (files) => {
    console.log('Categorizing files locally...');
    return files.map(file => {
      const fileName = file.name.toLowerCase();
      let category = 'Uncategorized';
      let label = 'Uncategorized';
  
      if (fileName.includes('tax') && (fileName.includes('return') || fileName.includes('1040'))) {
        category = 'Personal Tax Returns';
        if (fileName.includes('2023') || fileName.includes('current')) {
          label = 'Personal Tax Return - Current Year';
        } else if (fileName.includes('2022') || fileName.includes('last')) {
          label = 'Personal Tax Return - Last Year';
        } else {
          label = 'Personal Tax Return - 2 Years Ago';
        }
      } else if (fileName.includes('resume') || fileName.includes('cv')) {
        category = 'Resumes for Key Team Members';
        label = 'Resume';
      } else if (fileName.includes('credit') || fileName.includes('score')) {
        category = 'Credit Scores';
        label = 'Credit Score';
      } else if (fileName.includes('financial') && fileName.includes('statement')) {
        category = 'Personal Financial Statement';
        label = 'Personal Financial Statement';
      } else if (fileName.includes('drivers') || fileName.includes('licence')) {
        category = 'Identification Documents';
        label = 'Driver\'s License';
      }
  
      return {
        file,
        category: {
          Name: category,
          Labels: [label]
        }
      };
    });
  };

  const organizeFiles = (categorizedResults) => {
    const newOrganizedFiles = { ...organizedFiles };
  
    categorizedResults.forEach(({ file, category }) => {
      const categoryName = category.Name || 'Uncategorized';
      const label = category.Labels && category.Labels.length > 0 ? category.Labels[0] : 'Uncategorized';
  
      if (!newOrganizedFiles[categoryName]) {
        newOrganizedFiles[categoryName] = {};
      }
      if (!newOrganizedFiles[categoryName][label]) {
        newOrganizedFiles[categoryName][label] = [];
      }
      newOrganizedFiles[categoryName][label].push({ file });
    });
  
    return newOrganizedFiles;
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setIsLoading(true);
    setIsCategorizing(true);
    try {
      let categorizedResults;
      try {
        categorizedResults = await categorizeFiles(acceptedFiles);
      } catch (error) {
        console.warn('Server categorization failed, falling back to local categorization', error);
        categorizedResults = categorizeFilesLocally(acceptedFiles);
      }
      const newOrganizedFiles = organizeFiles(categorizedResults);
      setOrganizedFiles(newOrganizedFiles);
  
      // Upload and associate files
      for (const [category, labels] of Object.entries(newOrganizedFiles)) {
        for (const [label, files] of Object.entries(labels)) {
          for (const fileObj of files) {
            const file = fileObj.file;
            console.log(`Processing file: ${file.name} for category: ${category}, label: ${label}`);
            const documentRequirement = filteredDocumentRequirements.find(
              req => req.Name === category && req.Labels.includes(label)
            );
  
            if (documentRequirement) {
              const guarantorId = documentRequirement.Individual ? personalGuarantors[0]?.firstName + ' ' + personalGuarantors[0]?.lastName : null;
              await handleFileChange({ target: { files: [file] } }, guarantorId, label);
            } else {
              console.warn(`No matching document requirement found for ${category} - ${label}`);
            }
          }
        }
      }
  
      // Set categorized files and show popup after all processing is complete
      setCategorizedFiles(newOrganizedFiles);
      setShowCategorizedFiles(true);
    } catch (error) {
      console.error('Error in onDrop:', error);
      alert('An error occurred while processing the files. Please try again.');
    } finally {
      setIsLoading(false);
      setIsCategorizing(false);
    }
  }, [categorizeFiles, organizeFiles, filteredDocumentRequirements, personalGuarantors, handleFileChange]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  // Fetch Loan Application ID hook
  useEffect(() => {
    let isMounted = true;

    const fetchLoanApplicationId = async (userId) => {
      try {
        const q = query(collection(db, 'loanApplications'), where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const loanApplicationDoc = querySnapshot.docs[0];
          if (isMounted) {
            setLoanApplicationId(loanApplicationDoc.id);
            setBusinessName(loanApplicationDoc.data().businessName || '');
            setLoanPurpose(loanApplicationDoc.data().loanPurpose || 'Acquisition');
          }
        }
      } catch (error) {
        console.error('Error fetching loan application:', error);
        if (error.code === 'permission-denied') {
          handleAuthError();
        }
      }
    };

    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (isMounted) {
          setCurrentUser(user);
        }
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);

          if (!userDoc.exists()) {
            await setDoc(userDocRef, { hasSeenTutorial: false });
          } else if (!userDoc.data().hasOwnProperty('hasSeenTutorial')) {
            await updateDoc(userDocRef, { hasSeenTutorial: false });
          }

          fetchLoanApplicationId(user.uid);
          //&& !userDoc.data().hasSeenTutorial
          if (userDoc.exists() && !userDoc.data().hasSeenTutorial) {
            if (addOwnerButtonRef.current && !tutorialStarted) {
              const intro = introJs();
              intro.setOptions({
                steps: [
                  {
                    element: '#documentUploader',
                    intro: 'This is where you can upload the required documents for your loan application.',
                  },
                  {
                    element: '#preCheckSection',
                    intro: 'The pre-check section is the first step for obtaining an SBA loan, whether you have found a business or not. This is where you will upload files for each guarantor',
                  },
                  {
                    element: addOwnerButtonRef.current,
                    intro: 'Click here to add additional personal guarantors to your loan application (that is anyone who will have over 20% ownership)',
                  },
                  {
                    element: '#reviewButton',
                    intro: 'Click here to upload and edit specific documents.',
                  },
                  {
                    element: '#loanapplicationsection',
                    intro: 'After you have found a business you are looking to purchase, you will fill out a short loan application here',
                  },
                  {
                    element: '#termsheetsection',
                    intro: 'Once you haveve uploaded your business files, we will begin contacting lenders. Some banks may provide term sheets within a few hours, while others may take a couple of days',
                  },
                  {
                    element: '#statusarea',
                    intro: 'This area will keep track of what step you are on and let you know what you need to do',
                  },
                  {
                    element: '#requireddocuments',
                    intro: 'The required documents section will show you what files you are missing on your current step',
                  },
                  {
                    element: '#contactussection',
                    intro: 'If you have any questions at any point feel free to contact us here!',
                  },
                ],
              });
              intro.start();
              setTutorialStarted(true);
              await updateDoc(userDocRef, { hasSeenTutorial: true });
            }
          }
        } catch (error) {
          console.error('Error fetching user document:', error);
          if (error.code === 'permission-denied') {
            handleAuthError();
          }
        }
      } else {
        if (isMounted) {
          setCurrentUser(null);
        }
        handleAuthError();
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [navigate]);

  // Fetch Uploaded Documents and Personal Guarantors hook
  useEffect(() => {
    let isMounted = true;

    const fetchUploadedDocuments = async (userId, loanApplicationId) => {
      try {
        const q = query(
          collection(db, 'documentMetadata'),
          where('userId', '==', userId),
          where('loanApplicationId', '==', loanApplicationId)
        );
        const querySnapshot = await getDocs(q);
        const documents = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        if (isMounted) {
          setUploadedDocuments(documents);
        }
      } catch (error) {
        console.error('Error fetching uploaded documents:', error);
        if (error.code === 'permission-denied') {
          handleAuthError();
        }
      }
    };

    const fetchPersonalGuarantors = async (loanApplicationId) => {
      try {
        const loanApplicationDoc = await getDoc(doc(db, 'loanApplications', loanApplicationId));
        if (loanApplicationDoc.exists()) {
          const loanApplicationData = loanApplicationDoc.data();
          const guarantors = loanApplicationData.personalGuarantors || [];
          if (isMounted) {
            setPersonalGuarantors(guarantors);
          }
        }
      } catch (error) {
        console.error('Error fetching personal guarantors:', error);
        if (error.code === 'permission-denied') {
          handleAuthError();
        }
      }
    };

    if (currentUser && loanApplicationId) {
      fetchUploadedDocuments(currentUser.uid, loanApplicationId);
      fetchPersonalGuarantors(loanApplicationId);
    }

    return () => {
      isMounted = false;
    };
  }, [currentUser, loanApplicationId, navigate]);

  // Fetch Form Data hook
  useEffect(() => {
    let isMounted = true;

    const fetchFormData = async () => {
      if (currentUser) {
        try {
          const db = getFirestore();
          const formQuery = query(collection(db, 'loanApplications'), where('userId', '==', currentUser.uid));
          const querySnapshot = await getDocs(formQuery);
          if (!querySnapshot.empty) {
            const formDoc = querySnapshot.docs[0];
            if (isMounted) {
              setFormData({
                ...formDoc.data(),
              });
            }
          } else {
            if (isMounted) {
              setFormData({
                name: 'Loan Application',
                loanapplicationstatus: 'Not started',
                href: '#',
              });
            }
          }
        } catch (error) {
          console.error('Error fetching form data:', error);
          if (isMounted) {
            setError('Error fetching form data');
          }
        }
      }
    };

    fetchFormData();

    return () => {
      isMounted = false;
    };
  }, [currentUser]);

  // Check Form Status hook
  useEffect(() => {
    const checkFormStatus = (formData) => {
      if (!formData) return 'Not started';

      const fieldsToCheck = [
        'businessAge',
        'businessState',
        'businessSummary',
        'loanPurpose',
        'loanSize',
      ];

      let isComplete = true;
      let isEmpty = true;

      for (let field of fieldsToCheck) {
        const fieldValue = field.split('.').reduce((obj, key) => obj?.[key], formData);
        if (!fieldValue) {
          isComplete = false;
        } else {
          isEmpty = false;
        }
      }

      if (isComplete) return 'Complete';
      if (isEmpty) return 'Not started';
      return 'In progress';
    };

    if (formData) {
      const updatedStatus = checkFormStatus(formData);
      if (formData.loanapplicationstatus !== updatedStatus) {
        setFormData((prevData) => ({
          ...prevData,
          loanapplicationstatus: updatedStatus,
        }));
      }

      onFormDataChange({
        firstName: formData.firstName,
        dealInfo: formData,
      });
    }
  }, [formData, onFormDataChange]);

  const handleAddOwner = async () => {
    if (newOwnerFirstName && newOwnerLastName) {
      const newOwner = {
        firstName: newOwnerFirstName,
        lastName: newOwnerLastName,
      };

      try {
        const loanApplicationRef = doc(db, 'loanApplications', loanApplicationId);
        await updateDoc(loanApplicationRef, {
          personalGuarantors: [...personalGuarantors, newOwner],
        });

        setPersonalGuarantors([...personalGuarantors, newOwner]);
        setNewOwnerFirstName('');
        setNewOwnerLastName('');
        setIsAddOwnerModalOpen(false);
      } catch (error) {
        console.error('Error adding owner:', error);
        if (error.code === 'permission-denied') {
          handleAuthError();
        }
      }
    }
  };

  const handleEdit = () => {
    if (formData.loanPurpose === 'Acquisition' && !formData.loanSize) {
      navigate('/loanform');
    } else {
      setEditMode(true);
    }
  };

  const handleSave = async () => {
    if (currentUser) {
      try {
        const db = getFirestore();
        const formQuery = query(collection(db, 'loanApplications'), where('userId', '==', currentUser.uid));
        const querySnapshot = await getDocs(formQuery);
        if (!querySnapshot.empty) {
          const formDoc = querySnapshot.docs[0];
          // Ensure workingCapitalUse is included in the saved data only for relevant loan purposes
          const dataToSave = {
            ...formData,
            workingCapitalUse: formData.loanPurpose === 'workingCapital' ? formData.workingCapitalUse : null,
            lastUserUpdate: new Date()
          };
          await updateDoc(formDoc.ref, dataToSave);
          setEditMode(false);
          console.log('Form data saved successfully');
        } else {
          console.error('No loan application document found for the current user');
        }
      } catch (error) {
        console.error('Error saving form data:', error);
        if (error.code === 'permission-denied') {
          handleAuthError();
        }
      }
    } else {
      console.error('No current user found');
    }
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      const [parent, child, grandchild] = name.split('.');
      if (grandchild) {
        return {
          ...prevData,
          [parent]: {
            ...prevData[parent],
            [child]: {
              ...prevData[parent]?.[child],
              [grandchild]: value
            }
          }
        };
      } else if (child) {
        return {
          ...prevData,
          [parent]: {
            ...prevData[parent],
            [child]: value
          }
        };
      } else {
        return {
          ...prevData,
          [name]: value,
          // Explicitly handle workingCapitalUse
          ...(name === 'loanPurpose' && (value === 'workingCapital' || value === 'workingCapital-startup') 
            ? { workingCapitalUse: prevData.workingCapitalUse || '' } 
            : {}),
        };
      }
    });
  };

  const handleCapTableChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedCapTable = [...prevData.capTable];
      updatedCapTable[index] = {
        ...updatedCapTable[index],
        [field]: value,
      };
      return {
        ...prevData,
        capTable: updatedCapTable,
      };
    });
  };

  const handleAddCapTableRow = () => {
    setFormData((prevData) => ({
      ...prevData,
      capTable: [...prevData.capTable, { name: '', percentage: '' }],
    }));
  };

  const handleDeleteCapTableRow = (index) => {
    setFormData((prevData) => {
      const updatedCapTable = prevData.capTable.filter((_, i) => i !== index);
      return {
        ...prevData,
        capTable: updatedCapTable,
      };
    });
  };

  const handleCreditScoreChange = (guarantorId, score) => {
    setCreditScores(prevScores => ({
      ...prevScores,
      [guarantorId]: score
    }));
  };

  const handleCreditScoreInput = (guarantorId, value) => {
    const numericValue = value.replace(/[^0-9]/g, '').slice(0, 3);
    handleCreditScoreChange(guarantorId, numericValue);
  };

  const saveCreditScores = async () => {
    if (currentUser && loanApplicationId) {
      try {
        const loanApplicationRef = doc(db, 'loanApplications', loanApplicationId);
        await updateDoc(loanApplicationRef, { creditScores });
        setFormData(prevData => ({
          ...prevData,
          creditScores
        }));
        console.log('Credit scores saved successfully');
      } catch (error) {
        console.error('Error saving credit scores:', error);
        if (error.code === 'permission-denied') {
          handleAuthError();
        }
      }
    }
  };

  const loadCreditScores = async () => {
    if (loanApplicationId) {
      try {
        const loanApplicationRef = doc(db, 'loanApplications', loanApplicationId);
        const loanApplicationSnap = await getDoc(loanApplicationRef);
        if (loanApplicationSnap.exists()) {
          const data = loanApplicationSnap.data();
          if (data.creditScores) {
            setCreditScores(data.creditScores);
          }
        }
      } catch (error) {
        console.error('Error loading credit scores:', error);
        if (error.code === 'permission-denied') {
          handleAuthError();
        }
      }
    }
  };

  useEffect(() => {
    loadCreditScores();
  }, [loanApplicationId]);

  const toggleSecondLLC = () => {
    setShowSecondLLC(!showSecondLLC);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!formData) {
    return <div>No form data available</div>;
  }

  const handleReviewClick = (document) => {
    if (document.Name === "Credit Scores") {
      setSelectedDocument({
        ...document,
        isCredit: true
      });
    } else {
      setSelectedDocument(document);
    }
  };

  const handleBackClick = () => {
    setSelectedDocument(null);
  };

  const getUploadedDocument = (documentName, guarantorId, label) => {
    return uploadedDocuments.find(
      (doc) =>
        doc.documentName === documentName &&
        doc.ownerId === guarantorId &&
        doc.label === label
    );
  };

  const isDocumentUploaded = (documentName, guarantorId, label) => {
    const isUploaded = uploadedDocuments.some(
      (doc) =>
        doc.documentName === documentName &&
        doc.ownerId === guarantorId &&
        doc.label === label
    );
    console.log(`Checking if document is uploaded for documentId: ${documentName}, guarantorId: ${guarantorId}, label: ${label}. Result: ${isUploaded}`);
    return isUploaded;
  };

  const isDocumentSetComplete = (documentId) => {
    const document = filteredDocumentRequirements.find((doc) => doc.DocumentID === documentId);
    
    if (!document) return false;

    if (document.Name === "Credit Scores") {
      return isCreditScoresComplete();
    }

    // Check if the document is required for the current loan purpose
    if (!document.loanPurpose.includes(loanPurpose)) {
      return true; // Document is not required for this loan purpose
    }

    if (document.Individual) {
      return personalGuarantors.every((guarantor) => {
        return document.Labels.every((label) => {
          return uploadedDocuments.some(
            (doc) =>
              doc.documentName === document.Name &&
              doc.ownerId === guarantor.firstName + ' ' + guarantor.lastName &&
              doc.label === label
          );
        });
      });
    } else {
      // For business documents
      if (loanPurpose === 'workingCapital-startup') {
        // For startup working capital, we need to check Financial Projections and Business Plan
        if (document.Name === "Financial Projections" || document.Name === "Business Plan") {
          return document.Labels.every((label) => {
            return uploadedDocuments.some(
              (doc) =>
                doc.documentName === document.Name &&
                doc.ownerId === null &&
                doc.label === label
            );
          });
        }
        // Other business documents are not required for startup working capital
        return true;
      }
      
      // For other loan purposes
      return document.Labels.every((label) => {
        if (document.Optional && !showSecondLLC) {
          return true;
        }
        return uploadedDocuments.some(
          (doc) =>
            doc.documentName === document.Name &&
            doc.ownerId === null &&
            doc.label === label
        );
      });
    }
  };

  const isCreditScoresComplete = () => {
    return personalGuarantors.every(guarantor => 
      formData.creditScores && formData.creditScores[guarantor.firstName + ' ' + guarantor.lastName] !== undefined
    );
  };

  const isLoanApplicationComplete = (formData) => {
    const requiredFields = [
      'loanSize',
      'businessState',
      'capTable',
      'businessSummary'
    ];

    if (formData.loanPurpose === 'Acquisition') {
      requiredFields.push('loanStructure', 'businessAge');
    } else if (formData.loanPurpose === 'workingCapital') {
      requiredFields.push('workingCapitalUse', 'businessAge');
    }
    // For 'workingCapital-startup', we don't add 'workingCapitalUse' or 'businessAge'

    return requiredFields.every(field => {
      if (field === 'capTable') {
        return formData[field] && formData[field].length > 0 && 
               formData[field].reduce((sum, owner) => sum + (parseFloat(owner.percentage) || 0), 0) === 100;
      }
      return formData[field] && formData[field] !== '';
    });
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    {currentUser ? (
      <>
     
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Please Complete the Required Steps For a Term Sheet
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Once they are completed and uploaded, we will begin reaching out to lenders.
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
           
            {selectedDocument ? (
              
              <div className="sm:px-6 sm:py-5">
                <button
                  type="button"
                  onClick={handleBackClick}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
                >
                  <ChevronLeftIcon className="-ml-1 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Back
                </button>
                <div className="mt-4">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    {selectedDocument.Name}
                  </h4>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {selectedDocument.Description}
                  </p>
                  {selectedDocument.Name === "Personal Financial Statement" && (
                    <p className="mt-2 text-sm text-blue-600">
                      <a href="https://www.sba.gov/sites/default/files/2022-08/SBA%20Form%20413%20%287a-504-SBG-WOSB-8a%29_8.9.2022-508.pdf" target="_blank" rel="noopener noreferrer" className="font-medium hover:underline">
                        PFS Form 413
                      </a>
                    </p>
                  )}
                  {selectedDocument.isCredit ? (
                    <div className="mt-6">
                      {personalGuarantors.map((guarantor) => {
                        const guarantorId = `${guarantor.firstName} ${guarantor.lastName}`;
                        return (
                          <div key={guarantorId} className="mb-6">
                            <label htmlFor={`credit-score-${guarantorId}`} className="block text-sm font-medium text-gray-700 mb-2">
                              {guarantorId}
                            </label>
                            <div className="relative">
                              <input
                                type="text"
                                id={`credit-score-${guarantorId}`}
                                value={creditScores[guarantorId] || ''}
                                onChange={(e) => handleCreditScoreInput(guarantorId, e.target.value)}
                                className="block w-24 h-12 px-3 py-2 text-center text-lg font-semibold bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                placeholder="---"
                                maxLength={3}
                              />
                              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                {creditScores[guarantorId] && creditScores[guarantorId].length === 3 ? (
                                  <CheckCircleIcon className="h-5 w-5 text-green-500" aria-hidden="true" />
                                ) : (
                                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <button
                        onClick={saveCreditScores}
                        className="mt-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-150 ease-in-out"
                      >
                        Save Credit Scores
                      </button>
                    </div>
                  ) : selectedDocument.Individual ? (
                    personalGuarantors.map((guarantor) => (
                      <div key={guarantor.firstName + ' ' + guarantor.lastName} className="mt-6">
                        <h5 className="text-lg leading-6 font-medium text-gray-900">
                          {guarantor.firstName + ' ' + guarantor.lastName}
                        </h5>
                        <div className="mt-4">
                          {selectedDocument.Labels.map((label, index) => (
                            <div key={index} className="mb-4">
                              <label htmlFor={`file-upload-${guarantor.firstName + ' ' + guarantor.lastName}-${index}`} className="block text-sm font-medium text-gray-700">
                                {label}
                              </label>
                              <div className="mt-1">
                                {getUploadedDocument(selectedDocument.Name, guarantor.firstName + ' ' + guarantor.lastName, label) && (
                                  <div className="mb-2">
                                    <a
                                      href={getUploadedDocument(selectedDocument.Name, guarantor.firstName + ' ' + guarantor.lastName, label).fileUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-blue-600 hover:text-blue-500"
                                    >
                                      {getUploadedDocument(selectedDocument.Name, guarantor.firstName + ' ' + guarantor.lastName, label).fileName}
                                    </a>
                                  </div>
                                )}
                                <div className="flex items-center">
                                  <input
                                    id={`file-upload-${guarantor.firstName + ' ' + guarantor.lastName}-${index}`}
                                    name={`file-upload-${guarantor.firstName + ' ' + guarantor.lastName}-${index}`}
                                    type="file"
                                    accept={selectedDocument.AcceptedFileTypes || "application/pdf"}
                                    className="sr-only"
                                    onChange={(event) => handleFileChange(event, guarantor.firstName + ' ' + guarantor.lastName, label)}
                                  />
                                  <label
                                    htmlFor={`file-upload-${guarantor.firstName + ' ' + guarantor.lastName}-${index}`}
                                    className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                  >
                                    <span>{getUploadedDocument(selectedDocument.Name, guarantor.firstName + ' ' + guarantor.lastName, label) ? 'Change File' : 'Upload File'}</span>
                                    <span className="sr-only"> for {label}</span>
                                  </label>
                                  {isDocumentUploaded(selectedDocument.Name, guarantor.firstName + ' ' + guarantor.lastName, label) ? (
                                    <CheckCircleIcon className="h-5 w-5 text-green-500 ml-2" aria-hidden="true" />
                                  ) : (
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500 ml-2" aria-hidden="true" />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mt-4">
                      {selectedDocument.Labels.map((label, index) => (
                        <div key={index} className="mb-4">
                          <label htmlFor={`file-upload-${index}`} className="block text-sm font-medium text-gray-700">
                            {label}
                          </label>
                          <div className="mt-1">
                            {getUploadedDocument(selectedDocument.Name, null, label) && (
                              <div className="mb-2">
                                <a
                                  href={getUploadedDocument(selectedDocument.Name, null, label).fileUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-blue-600 hover:text-blue-500"
                                >
                                  {getUploadedDocument(selectedDocument.Name, null, label).fileName}
                                </a>
                              </div>
                            )}
                            <div className="flex items-center">
                              <input
                                id={`file-upload-${index}`}
                                name={`file-upload-${index}`}
                                type="file"
                                accept={selectedDocument.AcceptedFileTypes || "application/pdf"}
                                className="sr-only"
                                onChange={(event) => handleFileChange(event, null, label)}
                              />
                              <label
                                htmlFor={`file-upload-${index}`}
                                className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                              >
                                <span>{getUploadedDocument(selectedDocument.Name, null, label) ? 'Change File' : 'Upload File'}</span>
                                <span className="sr-only"> for {label}</span>
                              </label>
                              {isDocumentUploaded(selectedDocument.Name, null, label) ? (
                                <CheckCircleIcon className="h-5 w-5 text-green-500 ml-2" aria-hidden="true" />
                              ) : (
                                <ExclamationCircleIcon className="h-5 w-5 text-red-500 ml-2" aria-hidden="true" />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div  className="sm:px-6 sm:py-5">
                {loanPurpose !== 'workingCapital-nonsba' && (
                  <div {...getRootProps()} className={`dropzone p-10 border-2 border-dashed rounded-2xl text-center mb-8 transition-all duration-300 ${
            isDragActive ? 'border-blue-400 bg-blue-50' : 'border-gray-200 hover:border-blue-400 hover:bg-gray-50'
          }`}>
            <input {...getInputProps()} directory="" webkitdirectory="" type="file" />
            <div className="flex flex-col items-center justify-center space-y-4">
              {isCategorizing ? (
                <div className="flex items-center space-x-3">
                  <div className="inline-block animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
                  <p className="text-sm font-medium text-gray-600">Processing files...</p>
                </div>
              ) : isDragActive ? (
                <div className="flex flex-col items-center">
                  <CloudArrowUpIcon className="h-12 w-12 text-blue-500 mb-2" />
                  <p className="text-lg font-medium text-blue-600">Drop files here</p>
                </div>
              ) : (
                <>
                  <div className="bg-gray-100 rounded-full p-4">
                    <CloudArrowUpIcon className="h-10 w-10 text-blue-500" />
                  </div>
                  <p className="text-lg font-medium text-gray-700">
                    Drag & drop files or folders here
                  </p>
                  <p className="text-sm text-gray-500">
                    or <span className="text-blue-500 cursor-pointer hover:underline" onClick={(e) => {
                      e.stopPropagation();
                      document.querySelector('.dropzone input').click();
                    }}>browse</span> to upload
                  </p>
                </>
              )}
            </div>
          </div>
                )}
                
                <div  className="mt-4">
                <div id = 'preCheckSection' className="bg-white shadow overflow-hidden sm:rounded-lg shadow-md mb-4 border border-gray-300">
                  <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                    <div className="flex items-center">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        1. Required Files
                      </h3>
                      {personalDocuments.every((doc) => isDocumentSetComplete(doc.DocumentID)) ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          Complete
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 ml-2">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          Incomplete
                        </span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <ul className="flex space-x-4">
                        {personalGuarantors.map((guarantor, index) => (
                          <li key={index} className="text-sm text-gray-900">
                            {guarantor.firstName} {guarantor.lastName}
                          </li>
                        ))}
                      </ul>
                      <div className="relative group" ref={addOwnerButtonRef}>
                        <button
                          type="button"
                          onClick={() => setIsAddOwnerModalOpen(true)}
                          className="inline-flex items-center justify-center w-10 h-10 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                          <PlusIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <button
                        type="button"
                        onClick={() => setIsPersonalExpanded(!isPersonalExpanded)}
                        className="ml-2 inline-flex items-center justify-center w-10 h-10 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-6 w-6 transition-transform ${isPersonalExpanded ? 'transform rotate-180' : ''}`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {isPersonalExpanded && (
                    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                      <dl className="sm:divide-y sm:divide-gray-200">
                        {personalDocuments.map((document) => (
                          <div key={document.DocumentID} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">{document.Name}</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-between items-center">
                              <div className="flex items-center">
                                {isDocumentSetComplete(document.DocumentID) ? (
                                  <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" aria-hidden="true" />
                                ) : (
                                  <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" aria-hidden="true" />
                                )}
                              </div>
                              <div className="flex items-center">
                                {document.formLink && (
                                  <a
                                    href={document.formLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-600 hover:text-blue-500 mr-4"
                                  >
                                    Download Form
                                  </a>
                                )}
                                <button
                                  type="button"
                                  onClick={() => handleReviewClick(document)}
                                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                  Review
                                </button>
                              </div>
                            </dd>
                            {document.Name === "Personal Financial Statement" && (
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                                <a 
                                  href="" 
                                  target="_blank" 
                                  rel="noopener noreferrer" 
                                  className="text-blue-600 hover:text-blue-800 hover:underline"
                                >
                                  Download Personal Financial Statement Form (SBA Form 413)
                                </a>
                              </dd>
                            )}
                          </div>
                        ))}
                      </dl>
                    </div>
                  )}
                </div>
                {loanPurpose !== 'workingCapital-nonsba' && (
                <div id="loanapplicationsection" className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg shadow-md mb-4 border border-gray-300">
                    <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                     <div className="flex items-center">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">2. Loan Application</h3>
                      {isLoanApplicationComplete(formData) ? (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          Complete
                        </span>
                      ) : (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 ml-2">
                          <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                            <circle cx={4} cy={4} r={3} />
                          </svg>
                          Incomplete
                        </span>
                      )}
                    </div>
                     <button
                        type="button"
                        onClick={() => setIsLoanApplicationExpanded(!isLoanApplicationExpanded)}
                        className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-6 w-6 transition-transform ${isLoanApplicationExpanded ? 'transform rotate-180' : ''}`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                    </div>
                    {isLoanApplicationExpanded && (
  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
    <div className="bg-white rounded-lg shadow-md p-6">
      {!editMode ? (
        <ul role="list" className="divide-y divide-gray-100">
          <li className="flex items-center justify-between gap-x-6 py-5">
            <div className="min-w-0">
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">
                  <strong>Loan Application</strong>
                </p>
                <p
                  className={classNames(
                    isLoanApplicationComplete(formData) ? statuses['Complete'] : statuses['In progress'],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                  )}
                >
                  {isLoanApplicationComplete(formData) ? 'Complete' : 'In progress'}
                </p>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <button
                onClick={handleEdit}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                View application
              </button>
              <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                        >
                          Edit<span className="sr-only">, Loan Application</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                        >
                          Move<span className="sr-only">, Loan Application</span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900'
                          )}
                        >
                          Delete<span className="sr-only">, Loan Application</span>
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        </ul>
      ) : (
        <FormEditor
          formData={formData}
          handleChange={handleChange}
          handleSave={handleSave}
          handleCancel={handleCancel}
          handleCapTableChange={handleCapTableChange}
          handleAddCapTableRow={handleAddCapTableRow}
          handleDeleteCapTableRow={handleDeleteCapTableRow}
          loanPurpose={loanPurpose}  // Add this line
        />
      )}
    </div>
  </div>
)}
                  </div>
)}
                {loanPurpose !== 'workingCapital-nonsba' && (
                  <div id="termsheetsection" className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg shadow-md mb-4 border border-gray-300">
                    <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                    <div className="flex items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      3. Business Documents
                    </h3>
                    {businessDocuments.every((doc) => isDocumentSetComplete(doc.DocumentID)) ? (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
                        <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx={4} cy={4} r={3} />
                        </svg>
                        Complete
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 ml-2">
                        <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx={4} cy={4} r={3} />
                        </svg>
                        Incomplete
                      </span>
                    )}
                  </div>
                      <button
                        type="button"
                        onClick={() => setIsBusinessExpanded(!isBusinessExpanded)}
                        className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`h-6 w-6 transition-transform ${isBusinessExpanded ? 'transform rotate-180' : ''}`}
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </button>
                    </div>
                    {isBusinessExpanded && (
                      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          {businessDocuments.map((document) => (
                            <div key={document.DocumentID} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                {document.Name}
                                {document.Optional && <span className="ml-2 text-xs text-gray-400">(Optional)</span>}
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-between items-center">
                                <div className="flex items-center">
                                  {isDocumentSetComplete(document.DocumentID) ? (
                                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" aria-hidden="true" />
                                  ) : (
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" aria-hidden="true" />
                                  )}
                                </div>
                                <button
                                  type="button"
                                  onClick={() => handleReviewClick(document)}
                                  className="ml-4 inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                  Review
                                </button>
                              </dd>
                            </div>
                          ))}
                          {showSecondLLC && secondLLCDocuments.map((document) => (
                            <div key={document.DocumentID} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                              <dt className="text-sm font-medium text-gray-500">
                                {document.Name}
                                <span className="ml-2 text-xs text-gray-400">(Optional)</span>
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex justify-between items-center">
                                <div className="flex items-center">
                                  {isDocumentSetComplete(document.DocumentID) ? (
                                    <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" aria-hidden="true" />
                                  ) : (
                                    <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" aria-hidden="true" />
                                  )}
                                </div>
                                <button
                                  type="button"
                                  onClick={() => handleReviewClick(document)}
                                  className="ml-4 inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                  Review
                                </button>
                              </dd>
                            </div>
                          ))}
                        </dl>
                        <div className="mt-4">
                          <button
                            type="button"
                            onClick={toggleSecondLLC}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            {showSecondLLC ? "Hide Second LLC" : "Add Second LLC"}
                          </button>
                        </div>
                      </div>
                    )}
</div>
                )}
                {loanPurpose !== 'workingCapital-nonsba' && (

<div id="reviewSubmitSection" className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg shadow-md mb-4 border border-gray-300">
  <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
    <div className="flex items-center">
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        4. Review and Submit Application
      </h3>
      {/* You can add a status indicator here if needed */}
    </div>
    <button
      type="button"
      onClick={() => setIsReviewExpanded(!isReviewExpanded)}
      className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`h-6 w-6 transition-transform ${isReviewExpanded ? 'transform rotate-180' : ''}`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19 9l-7 7-7-7"
        />
      </svg>
    </button>
  </div>
  {isReviewExpanded && (
    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
      <div className="flex justify-between items-center">
        <p className="text-sm text-gray-500">
          Review your application details and submit when you're ready.
        </p>
        <button
          type="button"
          onClick={onReviewSubmit}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Review and Submit Application
        </button>
      </div>
    </div>
  )}
</div>
                )}
</div>
</div>
            )}
</div>
        </>
      ) : (
        <div className="px-4 py-5 sm:px-6">
          <p className="text-sm text-gray-500">Please log in to upload documents.</p>
</div>
      )}
      {isAddOwnerModalOpen && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
</div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      Add Owner
                    </h3>
                    <div className="mt-2">
                      <div>
                        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                          First Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            value={newOwnerFirstName}
                            onChange={(e) => setNewOwnerFirstName(e.target.value)}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
</div>
</div>
                      <div className="mt-2">
                        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                          Last Name
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            value={newOwnerLastName}
                            onChange={(e) => setNewOwnerLastName(e.target.value)}
                            className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
</div>
</div>
</div>
</div>
</div>
</div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleAddOwner}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setIsAddOwnerModalOpen(false)}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
</div>
</div>
</div>
</div>
      )}
      <Transition.Root show={showCategorizedFiles} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setShowCategorizedFiles}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setShowCategorizedFiles(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
</div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Categorized Files
                      </Dialog.Title>
                      <div className="mt-2 max-h-96 overflow-y-auto">
                        {Object.keys(categorizedFiles).length === 0 ? (
                          <p>No files were categorized.</p>
                        ) : (
                          Object.entries(categorizedFiles).map(([category, labels]) => (
                            <div key={category} className="mb-4">
                              <h4 className="font-medium text-gray-900">{category}</h4>
                              {Object.entries(labels).map(([label, files]) => (
                                <div key={label} className="ml-4">
                                  <h5 className="text-sm font-medium text-gray-700">{label}</h5>
                                  <ul className="list-disc list-inside">
                                    {files.map((fileInfo, index) => (
                                      <li key={index} className="text-sm text-gray-600">{fileInfo.file.name}</li>
                                    ))}
                                  </ul>
</div>
                              ))}
</div>
                          ))
                        )}
</div>
</div>
</div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                      onClick={() => setShowCategorizedFiles(false)}
                    >
                      Close
                    </button>
</div>
                </Dialog.Panel>
              </Transition.Child>
</div>
</div>
        </Dialog>
      </Transition.Root>
</div>
  );
};

export const addOwnerButtonRef = DocumentUploader.addOwnerButtonRef;
export default DocumentUploader;












