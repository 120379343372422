import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import LightModeLoanCard from '../admin/LightModeLoanCard';
import { useNavigate } from 'react-router-dom';

const PrequalificationTab = ({ bankId }) => {
  const [prequalifications, setPrequalifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const onCardClick = (prequalId) => {
    navigate(`/bank-portal/prequalification/${prequalId}`, { state: { bankId } });
  };

  useEffect(() => {
    const fetchPrequalifications = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching prequalifications for bankId:", bankId);
        // Step 1: Fetch bank assignments for prequalifications
        const assignmentsRef = collection(db, 'bankAssignments');
        const assignmentsQuery = query(
          assignmentsRef,
          where('bankId', '==', bankId),
          where('currentStage.name', '==', 'prequalification')
        );
        const assignmentsSnapshot = await getDocs(assignmentsQuery);
        console.log("Assignments snapshot size:", assignmentsSnapshot.size);

        // Step 2: Fetch corresponding prequalification applications
        const prequalPromises = assignmentsSnapshot.docs.map(async (assignmentDoc) => {
          const assignmentData = assignmentDoc.data();
          console.log("Assignment data:", JSON.stringify(assignmentData, null, 2));
          
          const prequalId = assignmentData.prequalificationId || assignmentData.applicationId;
          if (!prequalId) {
            console.warn("Missing prequalificationId or applicationId for assignment:", assignmentDoc.id);
            return null;
          }

          try {
            const prequalRef = doc(db, 'prequalificationApplications', prequalId);
            const prequalDoc = await getDoc(prequalRef);

            if (prequalDoc.exists()) {
              const prequalData = prequalDoc.data();
              console.log("Prequalification data:", JSON.stringify(prequalData, null, 2));
              return {
                id: prequalDoc.id,
                ...prequalData,
                assignedAt: assignmentData.assignedAt,
                currentStage: assignmentData.currentStage
              };
            } else {
              console.warn("Prequalification document does not exist:", prequalId);
              return null;
            }
          } catch (err) {
            console.error("Error fetching prequalification:", prequalId, err);
            return null;
          }
        });

        const fetchedPrequalifications = (await Promise.all(prequalPromises)).filter(prequal => prequal !== null);
        console.log("Fetched prequalifications:", JSON.stringify(fetchedPrequalifications, null, 2));
        setPrequalifications(fetchedPrequalifications);
      } catch (err) {
        console.error("Error fetching prequalifications:", err);
        setError("Failed to load prequalifications. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    if (bankId) {
      fetchPrequalifications();
    } else {
      console.warn("No bankId provided to PrequalificationTab");
    }
  }, [bankId]);

  if (isLoading) {
    return <div>Loading prequalifications...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Prequalifications</h2>
      {prequalifications.length === 0 ? (
        <p>No prequalifications found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {prequalifications.map((prequal) => (
            <LightModeLoanCard
              key={prequal.id}
              id={prequal.id}
              name={prequal.businessName}
              loanSize={prequal.loanSize}
              projectedSalePrice={prequal.projectedSalePrice}
              loanPurpose={prequal.businessDescription}
              businessState={prequal.businessState}
              businessAge={prequal.businessAge}
              submittedAt={prequal.lastUpdated ? new Date(prequal.lastUpdated).toLocaleDateString() : 'N/A'}
              ebitda={prequal.ebitda}
              onClick={() => onCardClick(prequal.id)}
              isPrequalification={true}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PrequalificationTab;