import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

export function LoanCalculator() {
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [lenderMonthlyPayment, setLenderMonthlyPayment] = useState(0);
  const [lenderTotalInterest, setLenderTotalInterest] = useState(0);
  const [averageMonthlyPayment, setAverageMonthlyPayment] = useState(0);
  const [averageTotalInterest, setAverageTotalInterest] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);

  const averageInterestRate = 11;
  const lenderInterestRate = 9.75;
  const loanTerm = 10; // Fixed 10-year term

  useEffect(() => {
    calculateLoan();
  }, [loanAmount]);

  const calculateLoan = () => {
    const calculatePayment = (rate) => {
      const monthlyRate = rate / 100 / 12;
      const numberOfPayments = loanTerm * 12;
      return (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    };

    const lenderPayment = calculatePayment(lenderInterestRate);
    const averagePayment = calculatePayment(averageInterestRate);

    const lenderTotalPaid = lenderPayment * loanTerm * 12;
    const averageTotalPaid = averagePayment * loanTerm * 12;

    setLenderMonthlyPayment(lenderPayment);
    setLenderTotalInterest(lenderTotalPaid - loanAmount);
    setAverageMonthlyPayment(averagePayment);
    setAverageTotalInterest(averageTotalPaid - loanAmount);
    setTotalSavings(averageTotalPaid - lenderTotalPaid);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(value);
  };

  return (
    <div className="bg-white rounded-3xl shadow-2xl overflow-hidden max-w-5xl mx-auto font-sans">
      <div className="border-b border-gray-200 pb-4">
        <div className="flex flex-col items-center justify-center pt-6">
          <h3 className="text-xl font-semibold leading-6 text-gray-900">SBA Loan Calculator</h3>
          <p className="mt-1 text-sm text-gray-500">Compare rates and see potential savings</p>
        </div>
      </div>
      <div className="p-6">
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-600 mb-1">Loan Amount: {formatCurrency(loanAmount)}</label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="range"
              min="250000"
              max="5000000"
              step="10000"
              value={loanAmount}
              onChange={(e) => setLoanAmount(Number(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
            <div className="flex justify-between text-xs text-gray-600 mt-1">
              <span>$250k</span>
              <span>$5M</span>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 rounded-2xl p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-white rounded-xl p-4 shadow-sm">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Lended Offer</h3>
              <div className="space-y-2">
                <div>
                  <p className="text-xs text-gray-600">Interest Rate</p>
                  <p className="text-2xl font-bold text-blue-600">{lenderInterestRate}%</p>
                </div>
                <div>
                  <p className="text-xs text-gray-600">Monthly Payment</p>
                  <p className="text-2xl font-bold text-blue-600">{formatCurrency(lenderMonthlyPayment)}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-600">Total Interest</p>
                  <p className="text-2xl font-bold text-blue-600">{formatCurrency(lenderTotalInterest)}</p>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-xl p-4 shadow-sm">
              <h3 className="text-lg font-semibold text-gray-900 mb-3">Average Market Rate</h3>
              <div className="space-y-2">
                <div>
                  <p className="text-xs text-gray-600">Interest Rate</p>
                  <p className="text-2xl font-bold text-gray-600">{averageInterestRate}%</p>
                </div>
                <div>
                  <p className="text-xs text-gray-600">Monthly Payment</p>
                  <p className="text-2xl font-bold text-gray-600">{formatCurrency(averageMonthlyPayment)}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-600">Total Interest</p>
                  <p className="text-2xl font-bold text-gray-600">{formatCurrency(averageTotalInterest)}</p>
                </div>
              </div>
            </div>
            <div className="bg-green-50 rounded-xl p-4 shadow-sm flex flex-col justify-center items-center">
              <h3 className="text-lg font-semibold text-gray-900 mb-2">Your Savings</h3>
              <p className="text-3xl font-bold text-green-600">{formatCurrency(totalSavings)}</p>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <Link
              to="/loanform"
              className="rounded-md bg-indigo-50 px-4 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 transition duration-300 ease-in-out"
            >
              Get A Term Sheet in 24 Hours
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoanCalculator;
