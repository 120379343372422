import React, { useState, useEffect } from 'react';
import { CloudArrowUpIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CheckIcon, CurrencyDollarIcon, BanknotesIcon } from '@heroicons/react/20/solid';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFirestore, addDoc, collection, query as firestoreQuery, where, getDoc, getDocs, setDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { storage, db } from '../../firebase';
import { getIdToken } from 'firebase/auth';

const prequalificationDocuments = [
  {
    "DocumentID": 1,
    "Name": "Personal Financial Statement",
    "Description": "Upload your Personal Financial Statement (SBA Form 413). This document helps lenders understand your net worth and financial capacity.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Personal Financial Statement"],
    "FormLink": "https://www.sba.gov/sites/default/files/2022-08/SBA%20Form%20413%20%287a-504-SBG-WOSB-8a%29_8.9.2022-508.pdf"
  },
  {
    "DocumentID": 4,
    "Name": "Personal Tax Returns",
    "Description": "Upload 3 years of personal tax returns. This helps verify your income history and financial stability.",
    "RequiredDocuments": 3,
    "Individual": true,
    "Labels": [
      "Personal Tax Return - Current Year",
      "Personal Tax Return - Last Year",
      "Personal Tax Return - 2 Years Ago"
    ]
  },
  {
    "DocumentID": 7,
    "Name": "Resume",
    "Description": "Upload your professional resume showing relevant business experience. Lenders want to see your management and industry experience.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Resume"]
  },
  {
    "DocumentID": 8,
    "Name": "Credit Report",
    "Description": "Upload a recent copy of your credit report. This helps verify your creditworthiness and history of managing debt responsibly.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Credit Report"],
    "AcceptedFileTypes": ".pdf,.jpg,.jpeg,.png"
  },
  {
    "DocumentID": 15,
    "Name": "Bank Statements",
    "Description": "Upload 3 months of personal bank statements. This helps verify your liquid assets and financial resources.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Bank Statements"],
    "AcceptedFileTypes": ".pdf,.xlsx,.xls"
  }
];

const Prequalification = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [jobPlan, setJobPlan] = useState('');
  const [investors, setInvestors] = useState('');
  const [investorAmount, setInvestorAmount] = useState('');
  const [ficoScore, setFicoScore] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [loanApplicationId, setLoanApplicationId] = useState(null);
  const [expandedDocuments, setExpandedDocuments] = useState({});
  const [selectedGuarantor, setSelectedGuarantor] = useState(null);
  const [guarantors, setGuarantors] = useState([]);
  const [isJobPlanOpen, setIsJobPlanOpen] = useState(false);
  const [isInvestorsOpen, setIsInvestorsOpen] = useState(false);
  const [isGuarantorOpen, setIsGuarantorOpen] = useState(false);
  const [prequalifiedAmount, setPrequalifiedAmount] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setCurrentUser(user);
      if (user) {
        // Fetch loan application ID
        const q = firestoreQuery(collection(db, 'loanApplications'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const loanAppId = querySnapshot.docs[0].id;
          setLoanApplicationId(loanAppId);
          fetchGuarantors(loanAppId);
          fetchPrequalifiedAmount(loanAppId);
        }
        fetchUploadedDocuments(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchGuarantors = async (loanAppId) => {
    try {
      const loanAppRef = doc(db, 'loanApplications', loanAppId);
      const loanAppDoc = await getDoc(loanAppRef);
      
      if (loanAppDoc.exists()) {
        const loanAppData = loanAppDoc.data();
        const personalGuarantors = loanAppData.personalGuarantors || [];
        
        const guarantorsWithStatus = await Promise.all(personalGuarantors.map(async (guarantor, index) => {
          const filesComplete = await checkGuarantorFilesComplete(loanAppId, guarantor);
          return {
            ...guarantor,
            id: index,
            name: `${guarantor.firstName} ${guarantor.lastName}`,
            filesComplete
          };
        }));
        
        setGuarantors(guarantorsWithStatus);
        if (guarantorsWithStatus.length > 0) {
          setSelectedGuarantor(guarantorsWithStatus[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching guarantors:', error);
    }
  };

  const checkGuarantorFilesComplete = async (loanAppId, guarantor) => {
    // This is a placeholder. You should implement the actual logic to check if all required files are uploaded for this guarantor.
    // For now, we'll return a random boolean.
    return Math.random() < 0.5;
  };

  const fetchUploadedDocuments = async (userId) => {
    try {
      // Get user's full name
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();
      const guarantorId = `${userData.firstName} ${userData.lastName}`;

      // Query documentMetadata with both userId and ownerId (guarantorId)
      const q = firestoreQuery(
        collection(db, 'documentMetadata'),
        where('userId', '==', userId)
      );
      
      const querySnapshot = await getDocs(q);
      setUploadedDocuments(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const handleFileUpload = async (event, documentId, label) => {
    if (!event.target.files[0]) return;
    
    setIsLoading(true);
    try {
      const file = event.target.files[0];
      const document = prequalificationDocuments.find(doc => doc.DocumentID === documentId);
      
      // Get user's full name to match documentTracker.js guarantorId format
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();
      const guarantorId = `${userData.firstName} ${userData.lastName}`;

      // Create storage path matching documentTracker.js exactly
      const storageRef = ref(storage, 
        `documents/${currentUser.uid}/${loanApplicationId}/${guarantorId}/${document.Name}/${label}/${file.name}`
      );

      // Check for existing document using same logic as documentTracker
      const existingDoc = uploadedDocuments.find(
        doc => doc.documentName === document.Name && 
              doc.ownerId === guarantorId && 
              doc.label === label
      );

      // Delete existing file if it exists
      if (existingDoc) {
        const oldStorageRef = ref(storage, existingDoc.fileUrl);
        try {
          await deleteObject(oldStorageRef);
        } catch (error) {
          console.log('No old file to delete or error deleting:', error);
        }
      }

      // Upload new file
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Create metadata exactly matching documentTracker.js
      const documentMetadata = {
        fileName: file.name,
        fileUrl: downloadURL,
        uploadedAt: new Date().toISOString(),
        userId: currentUser.uid,
        documentName: document.Name,
        loanApplicationId: loanApplicationId,
        ownerId: guarantorId,  // Use guarantorId instead of userId
        label,
        status: 'uploaded'
      };

      // Save to documentMetadata collection (primary source of truth)
      const docRef = await addDoc(collection(db, 'documentMetadata'), documentMetadata);
      documentMetadata.documentId = docRef.id;

      // Update loan application last modified timestamp
      if (loanApplicationId) {
        const loanRef = doc(db, 'loanApplications', loanApplicationId);
        await updateDoc(loanRef, { 
          lastUserUpdate: new Date(),
          status: 'in_progress' 
        });
      }

      // Update local state
      setUploadedDocuments(prev => {
        const updatedDocs = prev.filter(
          doc => !(doc.documentName === document.Name && 
                  doc.ownerId === guarantorId && 
                  doc.label === label)
        );
        return [...updatedDocs, documentMetadata];
      });

      // Process document with backend service
      const idToken = await getIdToken(currentUser);
      const response = await fetch('https://sba-30d9855b23ef.herokuapp.com/process-document', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileUrl: downloadURL,
          metadata: documentMetadata,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to process document');
      }

      // Show success message
      alert('Document uploaded successfully!');

    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const isDocumentUploaded = (documentId, label) => {
    const document = prequalificationDocuments.find(doc => doc.DocumentID === documentId);
    return uploadedDocuments.some(
      doc => 
        doc.documentName === document.Name && 
        doc.label === label
    );
  };

  const toggleDocumentExpansion = (documentId) => {
    setExpandedDocuments(prev => ({
      ...prev,
      [documentId]: !prev[documentId]
    }));
  };

  const isDocumentComplete = (document) => {
    return document.Labels.every(label => isDocumentUploaded(document.DocumentID, label));
  };

  const closeAllDropdowns = () => {
    setIsJobPlanOpen(false);
    setIsInvestorsOpen(false);
    setIsGuarantorOpen(false);
  };

  const formatCurrencyPrequal = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  const getGuarantorName = () => {
    if (selectedGuarantor) {
      return `${selectedGuarantor.firstName} ${selectedGuarantor.lastName}'s`;
    }
    return 'your';
  };

  const fetchPrequalifiedAmount = async (loanAppId) => {
    try {
      const loanAppRef = doc(db, 'loanApplications', loanAppId);
      const loanAppDoc = await getDoc(loanAppRef);
      
      if (loanAppDoc.exists()) {
        const loanAppData = loanAppDoc.data();
        if (loanAppData.prequalifiedAmount) {
          setPrequalifiedAmount(loanAppData.prequalifiedAmount);
        }
      }
    } catch (error) {
      console.error('Error fetching prequalified amount:', error);
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-white rounded-lg shadow-lg p-8 mb-8">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mb-4">
            Get Prequalified for Your SBA Loan
          </h2>
          <p className="text-xl text-gray-500 leading-8">
            Unlock the advantages of prequalification and take a significant step towards your business acquisition journey.
          </p>
        </div>

        <div className="bg-indigo-50 border border-indigo-100 rounded-xl p-6 mb-8">
          <h3 className="text-lg font-semibold text-indigo-800 mb-4">
            Benefits of Getting Prequalified:
          </h3>
          <ul className="space-y-3">
            {[
              { title: "Credibility with Business Brokers", description: "Show you're a serious buyer with verified financial backing" },
              { title: "Know Your Budget", description: "Understand exactly what size business you can afford to pursue" },
              { title: "Faster Closing", description: "Much of your paperwork will be pre-processed, speeding up the final loan process" },
              { title: "Better Negotiating Position", description: "Sellers prefer buyers with verified financial capability" },
              { title: "Save Time", description: "Focus only on businesses within your qualified purchase range" }
            ].map((benefit, index) => (
              <li key={index} className="flex items-start">
                <CheckIcon className="h-6 w-6 text-indigo-600 flex-shrink-0 mr-3" />
                <div>
                  <span className="font-medium text-gray-900">{benefit.title}:</span>
                  <span className="ml-1 text-gray-600">{benefit.description}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Conditional rendering for prequalified amount card */}
        {prequalifiedAmount && (
          <div className="bg-green-50 border border-green-200 rounded-lg p-4 mb-8">
            <div className="flex items-center">
              <BanknotesIcon className="h-6 w-6 text-green-500 mr-2" />
              <h3 className="text-lg font-semibold text-green-700">Expected Prequalification Amount</h3>
            </div>
            <div className="mt-2">
              <p className="text-2xl font-bold text-green-800">
                {formatCurrencyPrequal(prequalifiedAmount)}
              </p>
              <p className="text-sm text-green-600">
                Based on our analysis of {getGuarantorName()} financial documents
              </p>
            </div>
          </div>
        )}

        <div className="mb-6">
          <label htmlFor="guarantor-select" className="block text-sm font-medium leading-6 text-gray-900">
            Select Guarantor
          </label>
          <div className="relative mt-2">
            <button
              type="button"
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
              onClick={() => {
                closeAllDropdowns();
                setIsGuarantorOpen(!isGuarantorOpen);
              }}
              aria-haspopup="listbox"
              aria-expanded={isGuarantorOpen}
              aria-labelledby="listbox-label-guarantor"
            >
              <span className="flex items-center">
                <span
                  className={classNames(
                    'inline-block h-2 w-2 flex-shrink-0 rounded-full mr-3',
                    selectedGuarantor?.filesComplete ? 'bg-green-400' : 'bg-gray-200'
                  )}
                  aria-hidden="true"
                />
                <span className="block truncate">{selectedGuarantor?.name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </button>

            {isGuarantorOpen && (
              <ul
                className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                tabIndex={-1}
                role="listbox"
                aria-labelledby="listbox-label-guarantor"
                aria-activedescendant="listbox-option-guarantor"
              >
                {guarantors.map((guarantor) => (
                  <li
                    key={guarantor.id}
                    className={classNames(
                      'text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9',
                      selectedGuarantor?.id === guarantor.id ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )}
                    id={`listbox-option-${guarantor.id}`}
                    role="option"
                    onClick={() => {
                      setSelectedGuarantor(guarantor);
                      setIsGuarantorOpen(false);
                    }}
                  >
                    <div className="flex items-center">
                      <span
                        className={classNames(
                          'inline-block h-2 w-2 flex-shrink-0 rounded-full mr-3',
                          guarantor.filesComplete ? 'bg-green-400' : 'bg-gray-200'
                        )}
                        aria-hidden="true"
                      />
                      <span className={classNames('font-normal block truncate', selectedGuarantor?.id === guarantor.id ? 'font-semibold' : 'font-normal')}>
                        {guarantor.name}
                      </span>
                    </div>

                    {selectedGuarantor?.id === guarantor.id && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          selectedGuarantor?.id === guarantor.id ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="space-y-6 mb-8">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Are you planning on keeping your job or working full time on the business you acquire?
            </label>
            <div className="relative mt-2">
              <button
                type="button"
                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                onClick={() => {
                  closeAllDropdowns();
                  setIsJobPlanOpen(!isJobPlanOpen);
                }}
                aria-haspopup="listbox"
                aria-expanded={isJobPlanOpen}
                aria-labelledby="listbox-label-jobPlan"
              >
                <span className="block truncate">{jobPlan || "Select an option"}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </button>

              {isJobPlanOpen && (
                <ul
                  className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  tabIndex={-1}
                  role="listbox"
                  aria-labelledby="listbox-label-jobPlan"
                  aria-activedescendant="listbox-option-jobPlan"
                >
                  {["Keeping current job", "Working full time on acquired business", "Undecided"].map((option) => (
                    <li
                      key={option}
                      className={`text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 ${
                        jobPlan === option ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      }`}
                      id={`listbox-option-jobPlan-${option}`}
                      role="option"
                      onClick={() => {
                        setJobPlan(option);
                        setIsJobPlanOpen(false);
                      }}
                    >
                      <span className={`font-normal block truncate ${jobPlan === option ? 'font-semibold' : 'font-normal'}`}>
                        {option}
                      </span>
                      {jobPlan === option && (
                        <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${jobPlan === option ? 'text-white' : 'text-indigo-600'}`}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Are you bringing on investors?
            </label>
            <div className="relative mt-2">
              <button
                type="button"
                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
                onClick={() => {
                  closeAllDropdowns();
                  setIsInvestorsOpen(!isInvestorsOpen);
                }}
                aria-haspopup="listbox"
                aria-expanded={isInvestorsOpen}
                aria-labelledby="listbox-label-investors"
              >
                <span className="block truncate">{investors ? (investors === 'yes' ? 'Yes' : 'No') : "Select an option"}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </button>

              {isInvestorsOpen && (
                <ul
                  className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  tabIndex={-1}
                  role="listbox"
                  aria-labelledby="listbox-label-investors"
                  aria-activedescendant="listbox-option-investors"
                >
                  {["Yes", "No"].map((option) => (
                    <li
                      key={option}
                      className={`text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9 ${
                        investors === option.toLowerCase() ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      }`}
                      id={`listbox-option-investors-${option}`}
                      role="option"
                      onClick={() => {
                        setInvestors(option.toLowerCase());
                        setIsInvestorsOpen(false);
                        if (option.toLowerCase() === 'no') setInvestorAmount('');
                      }}
                    >
                      <span className={`font-normal block truncate ${investors === option.toLowerCase() ? 'font-semibold' : 'font-normal'}`}>
                        {option}
                      </span>
                      {investors === option.toLowerCase() && (
                        <span className={`absolute inset-y-0 right-0 flex items-center pr-4 ${investors === option.toLowerCase() ? 'text-white' : 'text-indigo-600'}`}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {investors === 'yes' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                How much will they invest?
              </label>
              <input
                type="text"
                value={investorAmount}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  setInvestorAmount(value ? `$${Number(value).toLocaleString()}` : '');
                }}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                placeholder="$0"
              />
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Estimated FICO score (This is not included in credit report)
            </label>
            <input
              type="text"
              value={ficoScore}
              onChange={(e) => setFicoScore(e.target.value.replace(/\D/g, '').slice(0, 3))}
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              placeholder="Enter FICO score"
              maxLength="3"
            />
          </div>
        </div>

        <div className="space-y-4">
          {prequalificationDocuments.map((document) => {
            const isComplete = isDocumentComplete(document);
            return (
              <div key={document.DocumentID} className="border rounded-lg">
                <button
                  className="w-full flex justify-between items-center p-4 focus:outline-none"
                  onClick={() => toggleDocumentExpansion(document.DocumentID)}
                >
                  <h2 className="text-lg font-semibold text-gray-900">{document.Name}</h2>
                  <div className="flex items-center space-x-2">
                    <span className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${
                      isComplete 
                        ? "bg-green-50 text-green-700 ring-green-600/20" 
                        : "bg-red-50 text-red-700 ring-red-600/10"
                    }`}>
                      {isComplete ? "Complete" : "Incomplete"}
                    </span>
                    {expandedDocuments[document.DocumentID] ? (
                      <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </div>
                </button>
                {expandedDocuments[document.DocumentID] && (
                  <div className="p-4 border-t">
                    <p className="text-sm text-gray-600 mb-4">{document.Description}</p>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {document.Labels.map((label) => {
                        const isUploaded = isDocumentUploaded(document.DocumentID, label);
                        return (
                          <div key={label} className="col-span-1">
                            <label htmlFor={`file-${document.DocumentID}-${label}`} className="block text-sm font-medium text-gray-700 mb-2">
                              {label}
                            </label>
                            <div className="flex items-center justify-center w-full">
                              <label
                                htmlFor={`file-${document.DocumentID}-${label}`}
                                className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                              >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                  {isUploaded ? (
                                    <CheckCircleIcon className="w-8 h-8 mb-2 text-green-500" />
                                  ) : (
                                    <CloudArrowUpIcon className="w-8 h-8 mb-2 text-gray-400" />
                                  )}
                                  <p className="mb-2 text-sm text-gray-500">
                                    <span className="font-semibold">{isUploaded ? 'Replace file' : 'Upload file'}</span>
                                  </p>
                                  <p className="text-xs text-gray-500">
                                    {isUploaded ? 'File uploaded' : `PDF${document.AcceptedFileTypes ? ', ' + document.AcceptedFileTypes.replace(/\./g, '').toUpperCase() : ''}`}
                                  </p>
                                </div>
                                <input
                                  id={`file-${document.DocumentID}-${label}`}
                                  type="file"
                                  className="hidden"
                                  onChange={(e) => handleFileUpload(e, document.DocumentID, label)}
                                  accept={document.AcceptedFileTypes || ".pdf,.doc,.docx"}
                                />
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {document.FormLink && (
                      <div className="mt-4">
                        <a
                          href={document.FormLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                        >
                          Download Form Template
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Prequalification;

