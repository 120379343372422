import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet, pdf, Svg, Rect, G } from '@react-pdf/renderer';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import logoImage from '../images/images.png'; // Adjust this import path as needed

const GRAPH_COLORS = ['#008081', '#6593F5', '#3FE0D0'];
const safeNumber = (value) => {
  const num = parseFloat(value);
  return isNaN(num) || !isFinite(num) ? 0 : num;
};
const styles = StyleSheet.create({
  page: {
    padding: 40,
    backgroundColor: '#FFFFFF',
    fontFamily: 'Helvetica',
  },
  section: {
    marginBottom: 30,
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  gridItem: {
    width: '48%',
    marginBottom: 20,
    backgroundColor: '#F5F5F7',
    borderRadius: 12,
    padding: 16,
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  businessSummarySection: {
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderRadius: 5,
  },
  businessSummaryText: {
    fontSize: 12,
    color: '#333',
    lineHeight: 1.5,
  },
  gridTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 12,
    color: '#1D1D1F',
  },
  gridContent: {
    marginBottom: 8,
  },
  label: {
    fontSize: 12,
    color: '#86868B',
    marginBottom: 2,
  },
  value: {
    fontSize: 16,
    color: '#1D1D1F',
    fontWeight: '500',
  },
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#000',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  subheading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#333',
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    color: '#555',
  },
  boldText: {
    fontWeight: 'bold',
    color: '#000',
  },
  graphRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  graphItem: {
    width: '20%',
    marginBottom: 20,
    alignItems: 'center',
  },
  graphTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#333',
    textAlign: 'center',
  },
  loanSummarySection: {
    marginBottom: 30,
    padding: 20,
  },
  loanSummaryHeading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#000',
    textAlign: 'center',
  },
  loanSummaryGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -10,
  },
  loanSummaryGridItem: {
    width: '50%',
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  loanSummaryCard: {
    borderWidth: 1,
    borderColor: '#E0E0E0',
  },
  loanSummaryCardTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  loanSummaryInfoRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },
  loanSummaryLabel: {
    fontSize: 12,
    padding: 8,
    width: '50%',
    borderRightWidth: 1,
    borderRightColor: '#E0E0E0',
  },
  loanSummaryValue: {
    fontSize: 12,
    padding: 8,
    width: '50%',
  },
  loanSummaryHighlightValue: {
    fontWeight: 'bold',
  },
  notesContainer: {
    marginTop: 20,
    borderTopWidth: 1,
    borderTopColor: '#E0E0E0',
    paddingTop: 15,
  },
  notesLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 5,
  },
  notesValue: {
    fontSize: 14,
    color: '#555',
  },

  label: {
    fontSize: 12,
    color: '#666',
    marginBottom: 2,
    width: '40%',
  },
  value: {
    fontSize: 14,
    color: '#333',
    width: '60%',
    paddingLeft: 20,
  },
  notesContainer: {
    marginTop: 20,
    width: '70%',
    alignSelf: 'center',
  },
  tableContainer: {
    marginTop: 15,
    borderWidth: 1,
    borderColor: '#E0E0E0',
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#F5F5F5',
    padding: 10,
  },
  tableHeaderCell: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'center',
    color: '#333',
  },
  tableRow: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#E0E0E0',
    padding: 10,
  },
  tableRowEven: {
    backgroundColor: '#F9F9F9',
  },
  tableRowOdd: {
    backgroundColor: '#FFFFFF',
  },
  tableCell: {
    flex: 1,
    fontSize: 11,
    textAlign: 'center',
    color: '#555',
  },
  columnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    width: '48%',
  },
  financialItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  totalItem: {
    borderTopWidth: 1,
    borderTopColor: '#333',
    marginTop: 15,
    paddingTop: 8,
  },
  capTableContainer: {
    marginTop: 15,
    borderWidth: 1,
    borderColor: '#E0E0E0',
  },
  capTableHeader: {
    flexDirection: 'row',
    backgroundColor: '#F5F5F5',
    padding: 10,
  },
  capTableHeaderCell: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333',
  },
  capTableRow: {
    flexDirection: 'row',
    borderTopWidth: 1,
    borderTopColor: '#E0E0E0',
    padding: 10,
  },
  capTableCell: {
    flex: 1,
    fontSize: 12,
    color: '#555',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  // Credit matrix styles
  creditMatrixTable: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#DDD',
    marginBottom: 20,
  },
  creditMatrixRow: {
    flexDirection: 'row',
  },
  creditMatrixColPoints: {
    width: '10%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#DDD',
  },
  creditMatrixCol: {
    width: '18%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#DDD',
  },
  creditMatrixCell: {
    margin: 5,
    fontSize: 9,
    textAlign: 'center',
  },
  creditMatrixCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    paddingBottom: 10,
    borderBottomWidth: 2,
    borderBottomColor: '#008081', // Using the teal color from your palette
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#008081',
  },
  logo: {
    width: 100,
    height: 50,
    objectFit: 'contain',
  },
  highlightedCell: {
    backgroundColor: '#E0F7FA',
  },
   creditMatrix: {
    marginTop: 20,
    marginBottom: 20,
  },
  creditMatrixTable: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#DDD',
  },
  creditMatrixRow: {
    flexDirection: 'row',
  },
  creditMatrixHeaderRow: {
    flexDirection: 'row',
    backgroundColor: '#F5F5F5',
  },
  creditMatrixColPoints: {
    width: '10%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#DDD',
  },
  creditMatrixCol: {
    width: '18%',
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#DDD',
  },
  creditMatrixCell: {
    padding: 5,
    fontSize: 8,
    textAlign: 'center',
  },
  creditMatrixHeaderCell: {
    padding: 5,
    fontSize: 9,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  creditMatrixHighlightedCell: {
    backgroundColor: '#E0F7FA',
  },

   ownerSection: {
    marginBottom: 20,
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 10,
  },
  ownerName: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  financialColumns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  netWorth: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 10,
  },
  creditScoreSection: {
    marginBottom: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
  },
  creditScoreContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  creditScoreLabel: {
    fontSize: 14,
    color: '#333',
  },
  creditScoreValue: {
    fontSize: 24,
    fontWeight: 'bold',
    marginRight: 10,
  },
});

const getAverageDSCR = (financialAnalysis) => {
  const dscrValues = Object.values(financialAnalysis).map((data) => parseFloat(data.dscr) || 0);
  const totalDSCR = dscrValues.reduce((sum, value) => sum + value, 0);
  return dscrValues.length > 0 ? totalDSCR / dscrValues.length : 0;
};

const getDSCRPoints = (dscr) => {
  if (dscr >= 1.75) return 20;
  if (dscr >= 1.5) return 16;
  if (dscr >= 1.35) return 12;
  if (dscr >= 1.25) return 8;
  if (dscr >= 1.15) return 4;
  return 0;
};

const calculateCollateralPoints = (realEstateAssets, loanSize, realEstateAppraisal) => {
  const totalCollateral = realEstateAssets + realEstateAppraisal;
  const collateralPercentage = (totalCollateral / loanSize) * 100;
  if (collateralPercentage >= 100) return 20;
  if (collateralPercentage >= 75) return 16;
  if (collateralPercentage >= 50) return 12;
  if (collateralPercentage >= 25) return 8;
  if (collateralPercentage > 0) return 4;
  return 0;
};

const calculateEquityPoints = (cashOnHand, savingsAccounts, stocksAndBonds, loanSize, monthlyPayment) => {
  const totalAssets = cashOnHand + savingsAccounts + stocksAndBonds;
  const sixMonthsPayments = monthlyPayment * 6;
  if (totalAssets >= 0.20 * loanSize + sixMonthsPayments) return 20;
  if (totalAssets >= 0.15 * loanSize + sixMonthsPayments) return 16;
  if (totalAssets >= 0.10 * loanSize + sixMonthsPayments) return 12;
  if (totalAssets >= 0.05 * loanSize + sixMonthsPayments) return 8;
  if (totalAssets >= 0.01 * loanSize) return 4;
  return 0;
};

const fetchPersonalFinancialStatement = async (loanId) => {
  try {
    const documentsRef = collection(db, 'documentMetadata');
    const q = query(documentsRef, where('loanApplicationId', '==', loanId), where('documentName', '==', 'Personal Financial Statement'));
    const querySnapshot = await getDocs(q);
    const documentsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const personalFinancialStatements = [];

    for (const doc of documentsData) {
      const ocrMetadataRef = collection(db, 'OCRMetadata');
      const ocrQuery = query(ocrMetadataRef, where('documentId', '==', doc.id));
      const ocrQuerySnapshot = await getDocs(ocrQuery);
      const ocrMetadata = ocrQuerySnapshot.docs.map((ocrDoc) => ({
        id: ocrDoc.id,
        ocrResult: ocrDoc.data().ocrResult,
      }));
      
      personalFinancialStatements.push({
        ...doc,
        ocrMetadata,
        ownerId: ocrMetadata[0]?.ocrResult?.ownerId || 'Unknown',
      });
    }

    return personalFinancialStatements;
  } catch (error) {
    console.error('Error fetching personal financial statements:', error);
    return [];
  }
};

const fetchBusinessIncomeDocument = async (loanId) => {
  try {
    const documentsRef = collection(db, 'documentMetadata');
    const q = query(documentsRef, where('loanApplicationId', '==', loanId), where('documentName', '==', 'Business Income'));
    const querySnapshot = await getDocs(q);
    const documentsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    if (documentsData.length > 0) {
      const businessIncomeDoc = documentsData[0];
      const ocrMetadataRef = collection(db, 'OCRMetadata');
      const ocrQuery = query(ocrMetadataRef, where('documentId', '==', businessIncomeDoc.id));
      const ocrQuerySnapshot = await getDocs(ocrQuery);
      const ocrMetadata = ocrQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ocrResult: doc.data().ocrResult,
      }));
      return { ...businessIncomeDoc, ocrMetadata };
    }
    return null;
  } catch (error) {
    console.error('Error fetching business income document:', error);
    return null;
  }
};



const SummaryDocument = ({ loan, personalFinancialStatements, businessIncomeDoc }) => {
  // Use loan.personalFinancialStatement instead of personalFinancialStatementDoc
  const personalFinancialStatement = loan.personalFinancialStatement || {};
  const businessIncome = businessIncomeDoc?.ocrMetadata?.[0]?.ocrResult || {};

  const renderAssetsAndLiabilitiesSection = () => renderAssetsAndLiabilities();

  const realEstateAssets = parseFloat(personalFinancialStatement?.Assets?.RealEstate || 0);
  const realEstateAppraisal = parseFloat(loan.realEstateAppraisal || 0);
  const loanSize = parseFloat(loan.loanSize || 0);

  const financialAnalysis = loan.financialAnalysis || {};
  const averageDSCR = getAverageDSCR(financialAnalysis);
  const dscrPoints = getDSCRPoints(averageDSCR);

  const collateralPoints = calculateCollateralPoints(realEstateAssets, loanSize, realEstateAppraisal);
  const cashOnHand = parseFloat(personalFinancialStatement?.Assets?.CashOnHandAndInBanks || 0);
  const savingsAccounts = parseFloat(personalFinancialStatement?.Assets?.SavingsAccounts || 0);
  const stocksAndBonds = parseFloat(personalFinancialStatement?.Assets?.StocksAndBonds || 0);
  const monthlyPayment = parseFloat(loan.calculatedMonthlyPayment || 0);
  const equityPoints = calculateEquityPoints(cashOnHand, savingsAccounts, stocksAndBonds, loanSize, monthlyPayment);

  const getHighestCreditScore = (creditScores) => {
    if (!creditScores || typeof creditScores !== 'object') return 0;
    return Math.max(...Object.values(creditScores).map(score => parseInt(score, 10)));
  };

  const highestCreditScore = getHighestCreditScore(loan.creditScores);
  const creditScorePoints = getCreditScorePoints(highestCreditScore);

  const getDSCRData = () => {
    const labels = ['2 Years Ago', 'Last Year', 'Current'];
    const data = labels.map((label) => {
      const year = label === 'Current' ? 'Current Year' : label;
      return safeNumber(financialAnalysis[`Business Tax Return - ${year}`]?.dscr);
    });
    return { labels, data };
  };
  
  
  const getNetIncomeData = () => {
    const labels = ['2 Years Ago', 'Last Year', 'Current'];
    const data = labels.map((label) => {
      const year = label === 'Current' ? 'Current Year' : label;
      return safeNumber(financialAnalysis[`Business Tax Return - ${year}`]?.noi);
    });
    return { labels, data };
  };
  
  const getOrdinaryBusinessIncomeData = () => {
    const labels = ['2 Years Ago', 'Last Year', 'Current'];
    const data = labels.map((label) => {
      const year = label === 'Current' ? 'Current Year' : label;
      return safeNumber(financialAnalysis[`Business Tax Return - ${year}`]?.ordinaryBusinessIncome);
    });
    return { labels, data };
  };

  const getOrdinaryBusinessIncome = () => (
    <>
      <Text style={styles.text}>
        <Text style={styles.boldText}>Current Year:</Text> {formatCurrency(loan.financialAnalysis['Business Tax Return - Current Year']?.ordinaryBusinessIncome)}
      </Text>
      <Text style={styles.text}>
        <Text style={styles.boldText}>Last Year:</Text> {formatCurrency(loan.financialAnalysis['Business Tax Return - Last Year']?.ordinaryBusinessIncome)}
      </Text>
      <Text style={styles.text}>
        <Text style={styles.boldText}>Two Years Ago:</Text> {formatCurrency(loan.financialAnalysis['Business Tax Return - 2 Years Ago']?.ordinaryBusinessIncome)}
      </Text>
    </>
  );

  const getNetIncome = (financialAnalysis) => (
    Object.entries(financialAnalysis).map(([label, values]) => (
      <Text key={label} style={styles.text}>
        <Text style={styles.boldText}>{label}:</Text> {formatCurrency(values.noi)}
      </Text>
    ))
  );

  const getDSCR = (financialAnalysis) => (
    Object.entries(financialAnalysis).map(([label, values]) => (
      <Text key={label} style={styles.text}>
        <Text style={styles.boldText}>{label}:</Text> {values.dscr !== null ? parseFloat(values.dscr).toFixed(2) : 'N/A'}
      </Text>
    ))
  );

  const renderCreditScore = () => (
    <View style={styles.creditScoreSection}>
      <Text style={styles.subheading}>Credit Scores</Text>
      {Object.entries(loan.creditScores || {}).map(([name, score]) => (
        <View key={name} style={styles.creditScoreContainer}>
          <Text style={styles.creditScoreLabel}>{name}: </Text>
          <Text style={styles.creditScoreValue}>{score}</Text>
          <Text style={styles.creditScoreLabel}> ({getCreditScoreLabel(parseInt(score, 10))})</Text>
        </View>
      ))}
    </View>
  );

  const renderCreditEvaluationMatrix = () => {
    console.log("Highest Credit Score:", highestCreditScore);
    console.log("Credit Score Points:", creditScorePoints);
    console.log("Resume Score:", loan.ResumeScore);
    console.log("DSCR Points:", dscrPoints);
    console.log("Collateral Points:", collateralPoints);
    console.log("Equity Points:", equityPoints);

    const getHighlightStyle = (category, points) => {
      switch (category) {
        case 'repayment':
          return dscrPoints === points ? styles.creditMatrixHighlightedCell : null;
        case 'collateral':
          return collateralPoints === points ? styles.creditMatrixHighlightedCell : null;
        case 'management':
          return parseInt(loan.ResumeScore) === points ? styles.creditMatrixHighlightedCell : null;
        case 'credit':
          return creditScorePoints === points ? styles.creditMatrixHighlightedCell : null;
        case 'equity':
          return equityPoints === points ? styles.creditMatrixHighlightedCell : null;
        default:
          return null;
      }
    };

    return (
      <View style={styles.creditMatrix}>
        <Text style={styles.heading}>Credit Evaluation Matrix</Text>
        <View style={styles.creditMatrixTable}>
          <View style={styles.creditMatrixHeaderRow}>
            <View style={styles.creditMatrixColPoints}><Text style={styles.creditMatrixHeaderCell}>Points</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixHeaderCell}>Repayment Ability</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixHeaderCell}>Collateral</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixHeaderCell}>Management</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixHeaderCell}>Personal Credit</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixHeaderCell}>Equity</Text></View>
          </View>
          {[20, 16, 12, 8, 4].map(points => (
            <View key={points} style={styles.creditMatrixRow}>
              <View style={styles.creditMatrixColPoints}><Text style={styles.creditMatrixCell}>{points}</Text></View>
              <View style={[styles.creditMatrixCol, getHighlightStyle('repayment', points)]}>
                <Text style={styles.creditMatrixCell}>{getCriteriaText('repayment', points)}</Text>
              </View>
              <View style={[styles.creditMatrixCol, getHighlightStyle('collateral', points)]}>
                <Text style={styles.creditMatrixCell}>{getCriteriaText('collateral', points)}</Text>
              </View>
              <View style={[styles.creditMatrixCol, getHighlightStyle('management', points)]}>
                <Text style={styles.creditMatrixCell}>{getCriteriaText('management', points)}</Text>
              </View>
              <View style={[styles.creditMatrixCol, getHighlightStyle('credit', points)]}>
                <Text style={styles.creditMatrixCell}>{getCriteriaText('credit', points)}</Text>
              </View>
              <View style={[styles.creditMatrixCol, getHighlightStyle('equity', points)]}>
                <Text style={styles.creditMatrixCell}>{getCriteriaText('equity', points)}</Text>
              </View>
            </View>
          ))}
          <View style={styles.creditMatrixRow}>
            <View style={styles.creditMatrixColPoints}><Text style={styles.creditMatrixCell}>Total</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixCell}>{dscrPoints}</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixCell}>{collateralPoints}</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixCell}>{loan.ResumeScore}</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixCell}>{creditScorePoints}</Text></View>
            <View style={styles.creditMatrixCol}><Text style={styles.creditMatrixCell}>{equityPoints}</Text></View>
          </View>
        </View>
      </View>
    );
  };
  
  const Header = () => (
    <View style={styles.header}>
      <Text style={styles.headerText}>Loan Summary Report</Text>
      <Image 
        style={styles.logo} 
        src={logoImage} // Adjust this path if necessary
      />
    </View>
  );

  const renderFinancialMetrics = () => (
    <View style={styles.section}>
      <Text style={styles.heading}>Financial Metrics</Text>
      <View style={styles.graphRow}>
        <View style={styles.graphItem}>
          <Text style={styles.graphTitle}>DSCR</Text>
          {renderBarGraph(getDSCRData().data, getDSCRData().labels, 180, 100, GRAPH_COLORS[0])}
        </View>
        <View style={styles.graphItem}>
          <Text style={styles.graphTitle}>Net Income</Text>
          {renderBarGraph(getNetIncomeData().data, getNetIncomeData().labels, 180, 100, GRAPH_COLORS[1])}
        </View>
        <View style={styles.graphItem}>
          <Text style={styles.graphTitle}>OBI</Text>
          {renderBarGraph(getOrdinaryBusinessIncomeData().data, getOrdinaryBusinessIncomeData().labels, 180, 100, GRAPH_COLORS[2])}
        </View>
      </View>
    </View>
  );
  
  // Update the renderBarGraph function to accept a specific color
  const renderBarGraph = (data, labels, width, height, color) => (
    <Svg width={width} height={height + 20}>
      {data.map((value, index) => {
        const safeValue = safeNumber(value);
        const maxValue = Math.max(...data.map(safeNumber));
        const barHeight = maxValue === 0 ? 0 : (safeValue / maxValue) * height;
        return (
          <Rect
            key={index}
            x={index * (width / data.length) + 10}
            y={height - barHeight}
            width={width / data.length - 20}
            height={barHeight}
            fill={color}
          />
        );
      })}
      <G>
        {labels.map((label, index) => (
          <Text
            key={index}
            x={index * (width / labels.length) + (width / labels.length) / 2}
            y={height + 10}
            textAnchor="middle"
            style={{ fontSize: 8 }}
          >
            {label}
          </Text>
        ))}
      </G>
    </Svg>
  );
  
  const formatCurrency = (value) => {
    const safeValue = safeNumber(value);
    return `$${safeValue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };
  
  const renderLoanSummary = (loan) => {
    const InfoCard = ({ title, items }) => (
      <View style={styles.loanSummaryCard}>
        <Text style={styles.loanSummaryCardTitle}>{title}</Text>
        {items.map((item, index) => (
          <View key={index} style={styles.loanSummaryInfoRow}>
            <Text style={styles.loanSummaryLabel}>{item.label}</Text>
            <Text style={[styles.loanSummaryValue, item.highlight && styles.loanSummaryHighlightValue]}>
              {item.value}
            </Text>
          </View>
        ))}
      </View>
    );
  
    return (
      <View style={styles.loanSummarySection}>
        <Text style={styles.loanSummaryHeading}>Loan Summary</Text>
        <View style={styles.loanSummaryGrid}>
          <View style={styles.loanSummaryGridItem}>
            <InfoCard
              title="Business Overview"
              items={[
                { label: "Business Age", value: `${loan.businessAge}` },
                { label: "Business State", value: loan.businessState },
                { label: "Revenue", value: formatCurrency(loan.revenue), highlight: true },
                { label: "EBITDA", value: formatCurrency(loan.ebitda) },
              ]}
            />
          </View>
          <View style={styles.loanSummaryGridItem}>
            <InfoCard
              title="Loan Details"
              items={[
                { label: "Loan Purpose", value: loan.loanPurpose },
                { label: "Loan Size", value: formatCurrency(loan.loanSize), highlight: true },
                { label: "Purchase Type", value: loan.purchaseType },
                { label: "Real Estate Appraisal", value: formatCurrency(loan.realEstateAppraisal) },
              ]}
            />
          </View>
        </View>
        
        {/* New section for Business Summary */}
        <View style={styles.businessSummarySection}>
          <Text style={styles.subheading}>Business Summary</Text>
          <Text style={styles.businessSummaryText}>{loan.businessSummary}</Text>
        </View>
      </View>
    );
  };
  
  const renderGridItem = (title, items) => (
    <View style={styles.gridItem}>
      <Text style={styles.gridTitle}>{title}</Text>
      {items.map((item, index) => (
        <View key={index} style={styles.gridContent}>
          <Text style={styles.label}>{item.label}</Text>
          <Text style={styles.value}>{item.value}</Text>
        </View>
      ))}
    </View>
  );
  
  
  const renderLoanSummaryItem = (label, value) => (
    <View style={styles.loanSummaryItem}>
      <Text style={styles.loanSummaryLabel}>{label}</Text>
      <Text style={styles.loanSummaryValue}>{value}</Text>
    </View>
  );
  


  const renderFinancialDetails = () => (
    <View style={styles.section}>
      <Text style={styles.heading}>Financial Details</Text>
      <View style={styles.tableContainer}>
        <View style={styles.tableHeader}>
          <Text style={styles.tableHeaderCell}>Year</Text>
          <Text style={styles.tableHeaderCell}>OBI</Text>
          <Text style={styles.tableHeaderCell}>NOI</Text>
          <Text style={styles.tableHeaderCell}>DSCR</Text>
        </View>
        {[
          'Business Tax Return - 2 Years Ago',
          'Business Tax Return - Last Year',
          'Business Tax Return - Current Year'
        ].map((year, index) => {
          const values = financialAnalysis[year] || {};
          return (
            <View key={year} style={[styles.tableRow, index % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd]}>
              <Text style={styles.tableCell}>{year}</Text>
              <Text style={styles.tableCell}>{formatCurrency(values.ordinaryBusinessIncome)}</Text>
              <Text style={styles.tableCell}>{formatCurrency(values.noi)}</Text>
              <Text style={styles.tableCell}>{values.dscr !== null ? parseFloat(values.dscr).toFixed(2) : 'N/A'}</Text>
            </View>
          );
        })}
      </View>
    </View>
  );
  

  const renderAssetsAndLiabilities = () => {
    const personalFinancialStatements = loan.personalFinancialStatements || {};

    return (
      <View style={styles.section}>
        <Text style={styles.heading}>Assets and Liabilities</Text>
        {Object.entries(personalFinancialStatements).map(([ownerId, statement], index) => (
          <View key={ownerId} style={styles.ownerSection}>
            <Text style={styles.ownerName}>Owner ID: {ownerId}</Text>
            <View style={styles.columnContainer}>
              <View style={styles.column}>
                <Text style={styles.subheading}>Assets</Text>
                {renderFinancialItem("Cash on Hand", statement.Assets.CashOnHandAndInBanks)}
                {renderFinancialItem("Savings Accounts", statement.Assets.SavingsAccounts)}
                {renderFinancialItem("Stocks and Bonds", statement.Assets.StocksAndBonds)}
                {renderFinancialItem("Real Estate", statement.Assets.RealEstate)}
                {renderFinancialItem("Automobiles", statement.Assets.Automobiles)}
                {renderFinancialItem("Other Assets", statement.Assets.OtherAssets)}
                {renderFinancialItem("Total Assets", calculateTotalAssets(statement.Assets), true)}
              </View>
              <View style={styles.column}>
                <Text style={styles.subheading}>Liabilities</Text>
                {renderFinancialItem("Accounts Payable", statement.Liabilities.AccountsPayable)}
                {renderFinancialItem("Notes Payable", statement.Liabilities.NotesPayable)}
                {renderFinancialItem("Auto Installment", statement.Liabilities.AutoInstallment)}
                {renderFinancialItem("Other Installment", statement.Liabilities.OtherInstallment)}
                {renderFinancialItem("Life Insurance Loans", statement.Liabilities.LifeInsuranceLoans)}
                {renderFinancialItem("Mortgages", statement.Liabilities.Mortgages)}
                {renderFinancialItem("Unpaid Taxes", statement.Liabilities.UnpaidTaxes)}
                {renderFinancialItem("Other Liabilities", statement.Liabilities.OtherLiabilities)}
                {renderFinancialItem("Total Liabilities", calculateTotalLiabilities(statement.Liabilities), true)}
              </View>
            </View>
            <Text style={styles.netWorth}>Net Worth: {formatCurrency(calculateNetWorth(statement))}</Text>
          </View>
        ))}
      </View>
    );
  };
  
  const calculateTotalAssets = (assets) => {
    return Object.values(assets).reduce((sum, value) => sum + safeNumber(value), 0);
  };

  const calculateTotalLiabilities = (liabilities) => {
    return Object.values(liabilities).reduce((sum, value) => sum + safeNumber(value), 0);
  };

  const calculateNetWorth = (statement) => {
    const totalAssets = calculateTotalAssets(statement.Assets);
    const totalLiabilities = calculateTotalLiabilities(statement.Liabilities);
    return totalAssets - totalLiabilities;
  };
  
  const renderFinancialItem = (label, value, isTotal = false) => (
    <View style={[styles.financialItem, isTotal && styles.totalItem]}>
      <Text style={[styles.label, isTotal && styles.boldText]}>{label}</Text>
      <Text style={[styles.value, isTotal && styles.boldText]}>{formatCurrency(value)}</Text>
    </View>
  );
  
  const renderCapTable = () => (
    <View style={styles.section}>
      <Text style={styles.heading}>Cap Table</Text>
      <View style={styles.capTableContainer}>
        <View style={styles.capTableHeader}>
          <Text style={styles.capTableHeaderCell}>Name</Text>
          <Text style={styles.capTableHeaderCell}>Percentage</Text>
        </View>
        {loan.capTable.map((entry, index) => (
          <View key={index} style={styles.capTableRow}>
            <Text style={styles.capTableCell}>{entry.name}</Text>
            <Text style={styles.capTableCell}>{entry.percentage}%</Text>
          </View>
        ))}
      </View>
    </View>
  );
  
  

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {Header()}
        {renderCreditEvaluationMatrix()}
        {renderFinancialMetrics()}
        {renderLoanSummary(loan)}
        {renderCreditScore()}
        {renderFinancialDetails()}
        {renderAssetsAndLiabilities()}
        {renderCapTable()}
      </Page>
    </Document>
  );
};

const getCriteriaText = (category, points) => {
  const criteria = {
    repayment: {
      20: 'DSCR: 1.75 3 most current years, 1.75 interim, 1.75 pro forma.',
      16: 'DSCR: 1.5 2 most current years, 1.5 interim, 1.5 pro forma.',
      12: 'DSCR: 1.35 most current year, 1.35 interim. 1.35 pro forma.',
      8: 'DSCR: 1.25 current year, 1.25 interim (minimum 6 months), 1.25 pro forma.',
      4: 'Projected DSCR of 1.15, start-up or existing business with no historical cash flow numbers.'
    },
    collateral: {
      20: 'Commercial/Residential real estate equal to 100% of the loan amount.',
      16: 'Commercial/Residential real estate equal to 75-99% of the loan amount.',
      12: 'Commercial/Residential real estate equal to 50-74% of the loan amount.',
      8: 'Commercial/Residential real estate equal to 25-49% of the loan amount.',
      4: 'Commercial/Residential real estate equal to 0-24% of the loan amount.'
    },
    management: {
      20: '3 or more years experience successfully managing the borrowing business or a similar business.',
      16: '1 to 3 years experience successfully managing the borrowing business or a similar business.',
      12: 'At least 3 years successful management experience with a small business.',
      8: 'At least 1 to 3 years of management experience with a small business.',
      4: 'No industry or management experience.'
    },
    credit: {
      20: 'FICO Score of 725 to 850.',
      16: 'FICO Score of 700 to 724.',
      12: 'FICO Score of 650 to 699.',
      8: 'FICO Score of 600 to 649.',
      4: 'FICO Score of 570 to 599.'
    },
    equity: {
      20: 'Enough liquid assets for 20% cash injection and 6 months of SBA payments.',
      16: 'Enough liquid assets for 15-19% cash injection and 6 months of SBA payments.',
      12: 'Enough liquid assets for 10-14% cash injection and 6 months of SBA payments.',
      8: 'Enough liquid assets for 5-9% cash injection and 6 months of SBA Payments',
      4: 'Enough liquid assets for less than 5% and not enough for 6 months of payments.'
    }
  };
  return criteria[category][points] || '';
};

const getCreditScorePoints = (creditScore) => {
  creditScore = parseInt(creditScore, 10);
  if (creditScore >= 725) return 20;
  if (creditScore >= 700) return 16;
  if (creditScore >= 650) return 12;
  if (creditScore >= 600) return 8;
  if (creditScore >= 570) return 4;
  return 0;
};

const getCreditScoreLabel = (score) => {
  if (score >= 800) return 'Exceptional';
  if (score >= 740) return 'Very Good';
  if (score >= 670) return 'Good';
  if (score >= 580) return 'Fair';
  return 'Poor';
};

export const generateSummaryPDF = async (loan) => {
  const businessIncomeDoc = await fetchBusinessIncomeDocument(loan.id) || {};

  const SummaryDocumentComponent = () => (
    <SummaryDocument
      loan={loan}å
      businessIncomeDoc={businessIncomeDoc}
    />
  );

  try {
    const blob = await pdf(<SummaryDocumentComponent />).toBlob();
    return blob;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};
 