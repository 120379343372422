import React from 'react';

const BusinessSummary = ({ name, label, value, onChange }) => {
  return (
    <div className="mb-6">
      <label htmlFor={name} className="block mb-2 font-semibold text-gray-700">
        {label}
      </label>
      <div className="mb-4 p-4 bg-gray-100 rounded-lg">
        <p className="mb-2 text-gray-600 font-medium">Include the following:</p>
        <ul className="list-disc list-inside text-gray-600">
          <li>What does the business do?</li>
          <li>Who are the customers?</li>
          <li>What role did the seller play in the business?</li>
        </ul>
      </div>
      <textarea
        name={name}
        id={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        rows="4"
        className="w-full px-4 py-2 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        placeholder={label}
      ></textarea>
    </div>
  );
};

export default BusinessSummary;