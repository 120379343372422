import React, { useState, useEffect, useRef, Fragment } from 'react';
import { getFirestore, collection, query, where, getDocs, onSnapshot, doc, getDoc, updateDoc, addDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { DocumentIcon, EnvelopeIcon, PhoneIcon, ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import logo from '../images/images.png';
import { Menu, Transition } from '@headlessui/react';
import max from "../images/images.png";
import CongratulatoryPopup from './CongratulatoryPopup';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { generatePrequalificationLetterPDF } from './PrequalificationLetterPDF';
import { calculatePrequalifiedAmount } from './prequalificationLetter';
import { db } from '../../firebase';
const statuses = {
  'Pending Documents': 'text-yellow-700 bg-yellow-50 ring-yellow-600/20',
  'Documents Uploaded': 'text-green-700 bg-green-50 ring-green-600/20',
  'Under Review': 'text-blue-700 bg-blue-50 ring-blue-600/10',
  'Approved': 'text-green-700 bg-green-50 ring-green-600/20',
  'Declined': 'text-red-700 bg-red-50 ring-red-600/10',
};
const documentRequirements = [
  {
    "DocumentID": 1,
    "Name": "Personal Financial Statement",
    "Description": "Upload your Personal Financial Statement.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Personal Financial Statement"]
  },
  {
    "DocumentID": 4,
    "Name": "Personal Tax Returns",
    "Description": "Upload 3 years of personal tax returns for each guarantor.",
    "RequiredDocuments": 3,
    "Individual": true,
    "Labels": [
      "Personal Tax Return - Current Year",
      "Personal Tax Return - Last Year",
      "Personal Tax Return - 2 Years Ago"
    ]
  },

  {
    "DocumentID": 6,
    "Name": "Business Tax Returns",
    "Description": "Upload the last 3 years of business tax returns.",
    "RequiredDocuments": 3,
    "Individual": false,
    "Labels": [
      "Business Tax Return - Current Year",
      "Business Tax Return - Last Year",
      "Business Tax Return - 2 Years Ago"
    ]
  },
  {
    "DocumentID": 7,
    "Name": "Resumes for Key Team Members",
    "Description": "Upload resumes for each key team member.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Resume"]
  },
  {
    "DocumentID": 8,
    "Name": "Credit Score",
    "Description": "Upload credit score for each guarantor.",
    "RequiredDocuments": 1,
    "Individual": true,
    "Labels": ["Credit Score"]
  },
  {
    "DocumentID": 9,
    "Name": "Draft of LOI",
    "Description": "Upload the draft of Letter of Intent (LOI).",
    "RequiredDocuments": 1,
    "Individual": false,
    "Labels": ["Draft of LOI"]
  }
];
const clients = [
  {
    id: 1,
    name: 'Tuple',
    imageUrl: 'https://tailwindui.com/img/logos/48x48/tuple.svg',
    lastInvoice: { date: 'December 13, 2022', dateTime: '2022-12-13', amount: '$2,000.00', status: 'Overdue' },
  },
  {
    id: 2,
    name: 'SavvyCal',
    imageUrl: 'https://tailwindui.com/img/logos/48x48/savvycal.svg',
    lastInvoice: { date: 'January 22, 2023', dateTime: '2023-01-22', amount: '$14,000.00', status: 'Paid' },
  },
  {
    id: 3,
    name: 'Reform',
    imageUrl: 'https://tailwindui.com/img/logos/48x48/reform.svg',
    lastInvoice: { date: 'January 23, 2023', dateTime: '2023-01-23', amount: '$7,600.00', status: 'Paid' },
  },
]
const people = [
  {
    name: 'Max Krakow',
    title: 'CEO, Lended',
    role: 'Application Manager',
    email: 'max@sbacapital.ai',
    telephone: '+1-914-319-7160',
    imageUrl: max,
  },
  // More people...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ChatBot = ({ firstName, toggleChatbotExpanded, isChatbotExpanded, dealInfo }) => {
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [messageQueue, setMessageQueue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef(null);
  const webSocketRef = useRef(null);
  const herokuAppUrl = 'https://sba-30d9855b23ef.herokuapp.com';
  const webSocketUrl = `wss://${herokuAppUrl.split('://')[1]}/ws/chat`;
  const [currentUser, setCurrentUser] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const textareaRef = useRef(null);
  const [isChatActive, setIsChatActive] = useState(false);
  const [hasChatMessages, setHasChatMessages] = useState(false);
  const lastMessageRef = useRef(null);
  const [formData, setFormData] = useState(null);
  const [personalGuarantors, setPersonalGuarantors] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
const [businessDocumentsUploaded, setBusinessDocumentsUploaded] = useState(false);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
const [containerHeight, setContainerHeight] = useState(535);
const contentRef = useRef(null);
const [termSheets, setTermSheets] = useState([]);
const [loanApplicationId, setLoanApplicationId] = useState(null);
const [showCongratulationsPopup, setShowCongratulationsPopup] = useState(false);
const [prequalLetterUrl, setPrequalLetterUrl] = useState(null);
const [prequalLetterError, setPrequalLetterError] = useState(null);
const [loanId, setLoanId] = useState(null);
const [pfsUploaded, setPfsUploaded] = useState(false);
const [pfsOcrComplete, setPfsOcrComplete] = useState(false);
const [isGeneratingLetter, setIsGeneratingLetter] = useState(false);
const [emailSent, setEmailSent] = useState(false); // Add a flag to track if the email has been sent

const preCheckComplete = personalGuarantors.every((guarantor) =>
documentRequirements
  .filter((doc) => doc.Individual)
  .every((doc) =>
    doc.Labels.every((label) =>
      uploadedDocuments.some(
        (uploadedDoc) =>
          uploadedDoc.documentName === doc.Name &&
          uploadedDoc.ownerId === guarantor.firstName + ' ' + guarantor.lastName &&
          uploadedDoc.label === label
      )
    )
  )
);
const isBusinessDocumentsComplete = () => {
  return isDocumentComplete('Business Tax Returns') && isDocumentComplete('Draft of LOI');
};

const formComplete = formData?.loanapplicationstatus === 'Complete';
  const checkFormSubmission = async (userId) => {
    try {
      const db = getFirestore();
      const formQuery = query(collection(db, 'loanApplications'), where('userId', '==', userId));
      const formSnapshot = await getDocs(formQuery);
  
      if (!formSnapshot.empty) {
        const formDoc = formSnapshot.docs[0];
        const formData = formDoc.data();
        return formData.submitted || false;
      }
  
      return false;
    } catch (error) {
      console.error('Error checking form submission:', error);
      return false;
    }
  };
  
  const checkBusinessDocumentsUpload = async (userId) => {
    try {
      const db = getFirestore();
      const businessDocsQuery = query(
        collection(db, 'businessDocuments'),
        where('userId', '==', userId)
      );
      const businessDocsSnapshot = await getDocs(businessDocsQuery);
  
      return !businessDocsSnapshot.empty;
    } catch (error) {
      console.error('Error checking business documents upload:', error);
      return false;
    }
  };
  const getStatus = () => {
    const isNonSBA = formData?.loanPurpose === 'workingCapital-nonsba';
    const preCheckComplete = isPreCheckComplete();
    const loanAppComplete = isLoanApplicationComplete(formData);
    const businessDocsComplete = isBusinessDocumentsComplete();
    const termSheetReceived = termSheets.length > 0;
  
    if (isNonSBA) {
      const nonSBADocsComplete = ['Bank Statements', 'Credit Score', 'Voided Check', 'ID'].every(
        (documentName) => isDocumentComplete(documentName)
      );
  
      if (!nonSBADocsComplete || !loanAppComplete) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 ml-2">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            Application In Progress
          </span>
        );
      }
  
      if (nonSBADocsComplete && loanAppComplete) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 ml-2">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-blue-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            Submitted for Review
          </span>
        );
      }
    } else {
      if (!preCheckComplete) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800 ml-2">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            Pending Pre-Check Documents
          </span>
        );
      }
  
      if (preCheckComplete && !loanAppComplete) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 ml-2">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-blue-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            Loan Application In Progress
          </span>
        );
      }
  
      if (loanAppComplete && !businessDocsComplete) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 ml-2">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-purple-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            Pending Business Documents
          </span>
        );
      }
  
      if (businessDocsComplete && !termSheetReceived) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800 ml-2">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            Waiting for Term Sheet
          </span>
        );
      }
  
      if (termSheetReceived) {
        return (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 ml-2">
            <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
              <circle cx={4} cy={4} r={3} />
            </svg>
            Term Sheet Received
          </span>
        );
      }
    }
  
    return (
      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800 ml-2">
        <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-gray-400" fill="currentColor" viewBox="0 0 8 8">
          <circle cx={4} cy={4} r={3} />
        </svg>
        Unknown Status
      </span>
    );
  };
  
  const getNextStep = () => {
    const isNonSBA = formData?.loanPurpose === 'workingCapital-nonsba';
    const preCheckComplete = isPreCheckComplete();
    const loanAppComplete = isLoanApplicationComplete(formData);
    const businessDocsComplete = isBusinessDocumentsComplete();
    const termSheetReceived = termSheets.length > 0;
  
    if (isNonSBA) {
      const nonSBADocuments = [
        'Bank Statements',
        'Voided Check',
        'ID',
        'Loan Application'
      ];

      const nonSBADocsComplete = nonSBADocuments.every(docName => isDocumentComplete(docName));
  
      if (!nonSBADocsComplete || !loanAppComplete) {
        return 'Complete Application and Upload Documents';
      }
  
      if (nonSBADocsComplete && loanAppComplete) {
        return 'Wait for Bank Review';
      }
    } else {
      if (!preCheckComplete) {
        return 'Complete Pre-Check Documents';
      }
  
      if (preCheckComplete && !loanAppComplete) {
        return 'Complete the Loan Application';
      }
  
      if (loanAppComplete && !businessDocsComplete) {
        return 'Upload Business Documents';
      }
  
      if (businessDocsComplete && !termSheetReceived) {
        return 'Wait for Term Sheets';
      }
  
      if (termSheetReceived) {
        return 'Review Term Sheet';
      }
    }
  
    return 'Unknown Step';
  };

  const getRequiredDocumentsSection = () => {
    const isNonSBA = formData?.loanPurpose === 'workingCapital-nonsba';
    const preCheckComplete = isPreCheckComplete();
    const loanAppComplete = isLoanApplicationComplete(formData);

    if (isNonSBA) {
      const nonSBADocuments = [
        'Bank Statements',
        'Voided Check',
        'ID',
        'Loan Application'
      ];

      return (
        <>
          <h3 className="text-lg font-bold mb-2">Required Documents for Non-SBA Working Capital</h3>
          <ul className="space-y-2">
            {nonSBADocuments.map((documentName) => (
              <li key={documentName} className="flex items-center">
                {isDocumentComplete(documentName) ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    Complete
                  </span>
                ) : (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    Incomplete
                  </span>
                )}
                <span className="ml-2 text-gray-700">{documentName}</span>
              </li>
            ))}
          </ul>
        </>
      );
    } else if (!preCheckComplete) {
      return (
        <>
          <h3 className="text-lg font-bold mb-2">Required Documents for Pre-Check</h3>
          <ul className="space-y-2">
            {['Personal Financial Statement', 'Personal Tax Returns', 'Resumes for Key Team Members', 'Credit Score'].map((documentName) => (
              <li key={documentName} className="flex items-center">
                {isDocumentComplete(documentName) ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    Complete
                  </span>
                ) : (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    Incomplete
                  </span>
                )}
                <span className="ml-2 text-gray-700">{documentName}</span>
              </li>
            ))}
          </ul>
        </>
      );
    } else if (!loanAppComplete) {
      return (
        <>
          <h3 className="text-lg font-bold mb-2">Required Step</h3>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={loanAppComplete}
              className="form-checkbox h-5 w-5 text-blue-600"
              readOnly
            />
            <span className="ml-2 text-gray-700">Complete the Loan Application</span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <h3 className="text-lg font-bold mb-2">Required Documents for Term Sheet</h3>
          <ul className="space-y-2">
            {['Business Tax Returns', 'Draft of LOI'].map((documentName) => (
              <li key={documentName} className="flex items-center">
                {isDocumentComplete(documentName) ? (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    Complete
                  </span>
                ) : (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    <svg className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400" fill="currentColor" viewBox="0 0 8 8">
                      <circle cx={4} cy={4} r={3} />
                    </svg>
                    Incomplete
                  </span>
                )}
                <span className="ml-2 text-gray-700">{documentName}</span>
              </li>
            ))}
          </ul>
        </>
      );
    }
  };

 

  const isDocumentComplete = (documentName, guarantorId = null) => {
    if (formData?.loanPurpose === 'workingCapital-nonsba') {
      // For non-SBA working capital, check if any document with this name exists
      return uploadedDocuments.some(doc => doc.documentName === documentName);
    }

    const document = documentRequirements.find(doc => doc.Name === documentName);
    
    if (!document) {
      console.warn(`Document not found: ${documentName}`);
      return false;
    }

    if (documentName === 'Credit Score') {
      return personalGuarantors.every(guarantor => {
        const guarantorFullName = `${guarantor.firstName} ${guarantor.lastName}`;
        return formData?.creditScores && formData.creditScores[guarantorFullName] !== undefined;
      });
    }
    
    if (document.Individual) {
      if (guarantorId) {
        // Check for a specific guarantor
        return document.Labels.every(label =>
          uploadedDocuments.some(
            (doc) =>
              doc.documentName === documentName &&
              doc.ownerId === guarantorId &&
              doc.label === label
          )
        );
      } else {
        // Check for all guarantors
        return personalGuarantors.every(guarantor => {
          const guarantorFullName = `${guarantor.firstName} ${guarantor.lastName}`;
          return document.Labels.every(label =>
            uploadedDocuments.some(doc =>
              doc.documentName === documentName &&
              doc.ownerId === guarantorFullName &&
              doc.label === label
            )
          );
        });
      }
    } else {
      // For non-individual (business) documents
      return document.Labels.every(label =>
        uploadedDocuments.some(doc =>
          doc.documentName === documentName &&
          doc.ownerId === null &&
          doc.label === label
        )
      );
    }
  };
  const isDocumentSetComplete = (documentId) => {
    const document = documentRequirements.find((doc) => doc.DocumentID === documentId);
    
    if (document.Name === "Credit Scores") {
      return isDocumentComplete("Credit Scores");
    }

    if (document.Individual) {
      return personalGuarantors.every((guarantor) => {
        return document.Labels.every((label) => {
          return uploadedDocuments.some(
            (doc) =>
              doc.documentName === document.Name &&
              doc.ownerId === guarantor.firstName + ' ' + guarantor.lastName &&
              doc.label === label
          );
        });
      });
    } else {
      return document.Labels.every((label) => {
        return uploadedDocuments.some(
          (doc) =>
            doc.documentName === document.Name &&
            doc.ownerId === null &&
            doc.label === label
        );
      });
    }
  };
  const isPreCheckComplete = () => {
    return ['Personal Financial Statement', 'Personal Tax Returns', 'Resumes for Key Team Members', 'Credit Score'].every(
      (documentName) => isDocumentComplete(documentName)
    );
  };
  const isDocumentUploaded = (documentName, guarantorId, label) => {
    if (documentName === 'Credit Score') {
      return !!formData?.creditScores?.[guarantorId];
    }
    return uploadedDocuments.some(
      (doc) =>
        doc.documentName === documentName &&
        doc.ownerId === guarantorId &&
        doc.label === label
    );
  };

  const checkRequiredDocuments = () => {
    const requiredDocuments = [
      'Bank Statements',
      'Voided Check',
      'ID',
      'Loan Application'
    ];

    const allDocumentsComplete = requiredDocuments.every(docName => isDocumentComplete(docName));
    console.log('All required documents complete:', allDocumentsComplete);
    return allDocumentsComplete;
  };

  const getDocumentLinks = async () => {
    const storage = getStorage();
    const documentLinks = {};

    for (const doc of uploadedDocuments) {
      const storageRef = ref(storage, `NonSBALoanForm/${doc.fileName}`);
      const url = await getDownloadURL(storageRef);
      documentLinks[doc.documentName] = url;
    }

    return documentLinks;
  };

  const sendEmailWithDocuments = async () => {
    try {
      console.log('Preparing to send email with documents...');
      const documentLinks = await getDocumentLinks();
      const emailRef = collection(db, 'email');
      const emailData = {
        to: 'max@lended.ai',
        from: 'max@sbacapital.ai',
        replyTo: 'max@sbacapital.ai',
        subject: 'Required Documents Uploaded',
        templateName: 'DocumentUpload',
        message: {
          subject: 'New Loan Application',
          firstName: 'Jesse',
          loanDetails: `The required documents have been uploaded. Please review the attached files.
                        <br><br>
                        <strong>Applicant Name:</strong> ${formData.firstName} ${formData.lastName}<br>
                        <strong>Loan Amount:</strong> $${Number(formData.loanSize).toLocaleString()}<br>
                        <strong>Credit Score:</strong> ${formData.creditScore}<br>
                        <strong>Email:</strong> ${formData.email}<br>
                        <strong>Phone Number:</strong> ${formData.number}<br>
                        <br>
                        <ul>
                          ${Object.entries(documentLinks).map(([docName, url]) => `<li>${docName}: <a href="${url}">${url}</a></li>`).join('')}
                        </ul>`,
          html: `<p>Hey Jesse,</p>
                 <p>We got a new loan application. The required documents have been uploaded. Please review the attached files:</p>
                 <ul>
                   ${Object.entries(documentLinks).map(([docName, url]) => `<li>${docName}: <a href="${url}">${url}</a></li>`).join('')}
                 </ul>
                 <p><strong>Applicant Name:</strong> ${formData.firstName} ${formData.lastName}</p>
                 <p><strong>Loan Amount:</strong> $${Number(formData.loanSize).toLocaleString()}</p>
                 <p><strong>Credit Score:</strong> ${formData.creditScore}</p>
                 <p><strong>Email:</strong> ${formData.email}</p>
                 <p><strong>Phone Number:</strong> ${formData.number}</p>
                 <p>Best,</p>
                 <p>Lended</p>`
        }
      };

      const emailDoc = await addDoc(emailRef, emailData);
      console.log('Email document created with ID:', emailDoc.id);
      localStorage.setItem('emailSent', 'true'); // Set the flag in local storage
    } catch (error) {
      console.error('Error creating email document:', error);
    }
  };

  useEffect(() => {
    const emailSent = localStorage.getItem('emailSent') === 'true'; // Check the flag in local storage
    if (checkRequiredDocuments() && !emailSent) {
      sendEmailWithDocuments();
    }
  }, [uploadedDocuments]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const fetchFormData = async () => {
      if (currentUser) {
        try {
          const db = getFirestore();
          const formQuery = query(collection(db, 'loanApplications'), where('userId', '==', currentUser.uid));
          const querySnapshot = await getDocs(formQuery);
          if (!querySnapshot.empty) {
            const formDoc = querySnapshot.docs[0];
            setFormData({
              ...formDoc.data(),
            });
          } else {
            setFormData({
              name: 'Loan Application',
              loanapplicationstatus: 'Not started',
              href: '#',
            });
          }
        } catch (error) {
          console.error('Error fetching form data:', error);
          setError('Error fetching form data');
        }
      }
      setLoading(false);
    };

    fetchFormData();
  }, [currentUser]);
  useEffect(() => {
    const checkFormStatus = (formData) => {
      if (!formData) return 'Not started';
  
      const fieldsToCheck = [
        'businessAge',
        'businessState',
        'email',
        'firstName',
        'lastName',
        'industry.code',
        'industry.description',
        'loanPurpose',
        'loanSize',
        
      ];
  
      let isComplete = true;
      let isEmpty = true;
  
      for (let field of fieldsToCheck) {
        const fieldValue = field.split('.').reduce((obj, key) => obj?.[key], formData);
        if (!fieldValue) {
          isComplete = false;
        } else {
          isEmpty = false;
        }
      }
  
      if (isComplete) return 'Complete';
      if (isEmpty) return 'Not started';
      return 'In progress';
    };
  
  if (formData) {
      const updatedStatus = checkFormStatus(formData);
      if (formData.loanapplicationstatus !== updatedStatus) {
        setFormData((prevData) => ({
          ...prevData,
          loanapplicationstatus: updatedStatus,
        }));
      }
    }
  }, [formData]);

  useEffect(() => {
    const fetchUploadedDocuments = async () => {
      if (currentUser) {
        const db = getFirestore();
        const formQuery = query(collection(db, 'loanApplications'), where('userId', '==', currentUser.uid));
        const formSnapshot = await getDocs(formQuery);
  
        if (!formSnapshot.empty) {
          const formDoc = formSnapshot.docs[0];
          const formData = formDoc.data();
  
          // Fetch the personalGuarantors data
          const personalGuarantorsData = formData.personalGuarantors || [];
          setPersonalGuarantors(personalGuarantorsData);
  
          setFormData(formData);
  
          const businessDocumentsUploaded = await checkBusinessDocumentsUpload(currentUser.uid);
          const formSubmitted = formData.loanapplicationstatus === 'Complete';
  
          setFormSubmitted(formSubmitted);
          setBusinessDocumentsUploaded(businessDocumentsUploaded);

          const documentMetadataQuery = query(
            collection(db, 'documentMetadata'),
            where('userId', '==', currentUser.uid)
          );
          
          const unsubscribeDocuments = onSnapshot(documentMetadataQuery, async (snapshot) => {
            const documentPromises = snapshot.docs.map(async (doc) => {
              const documentData = doc.data();
              const ocrMetadataQuery = query(
                collection(db, 'OCRMetadata'),
                where('userId', '==', currentUser.uid),
                where('documentId', '==', doc.id)
              );
              const ocrMetadataSnapshot = await getDocs(ocrMetadataQuery);
              const ocrData = ocrMetadataSnapshot.docs[0]?.data();
              return {
                id: doc.id,
                ...documentData,
                ocrResult: ocrData?.ocrResult || '',
              };
            });
            const documents = await Promise.all(documentPromises);
            setUploadedDocuments(documents);
          });
  
          return () => {
            unsubscribeDocuments();
          };
        }
      }
    };
  
    fetchUploadedDocuments();
  }, [currentUser]);

  useEffect(() => {
    // Establish WebSocket connection when the component mounts
    const socket = new WebSocket(webSocketUrl);
    webSocketRef.current = socket;

    socket.onopen = () => {
      console.log("WebSocket Connected");
    };

    socket.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    socket.onmessage = (event) => {
      const messageData = JSON.parse(event.data);
      if (messageData.response) {
        console.log("Received message from WebSocket:", messageData.response);
        setMessageQueue((prevQueue) => [...prevQueue, messageData.response]);
      }
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      // Close WebSocket connection when the component unmounts
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (messageQueue.length > 0) {
      const nextMessage = messageQueue[0];
      console.log("Processing next message from queue:", nextMessage);
      setChatHistory((currentMessages) => {
        const lastMessage = currentMessages[currentMessages.length - 1];
        if (lastMessage && lastMessage.role === 'assistant') {
          const combinedMessage = lastMessage.content + '' + nextMessage;
          return [
            ...currentMessages.slice(0, -1),
            { role: 'assistant', content: combinedMessage }
          ];
        } else {
          return [
            ...currentMessages,
            { role: 'assistant', content: nextMessage }
          ];
        }
      });

      setMessageQueue((prevQueue) => prevQueue.slice(1));
      setIsLoading(false);
    }
  }, [messageQueue]);

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever the chat history or message queue changes
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory, messageQueue]);

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        const contentHeight = contentRef.current.getBoundingClientRect().height;
        setContainerHeight(contentHeight); // Add 30 pixels to the content height
      }
    };

    updateHeight(); // Initial height update

    // Set up a ResizeObserver to watch for content size changes
    const resizeObserver = new ResizeObserver(updateHeight);
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (contentRef.current) {
        resizeObserver.unobserve(contentRef.current);
      }
    };
  }, [uploadedDocuments, formData, personalGuarantors]); // Re-run when these dependencies change

  useEffect(() => {
    const fetchLoanApplicationData = async () => {
      if (currentUser) {
        try {
          const db = getFirestore();
          const loanApplicationQuery = query(collection(db, 'loanApplications'), where('userId', '==', currentUser.uid));
          const querySnapshot = await getDocs(loanApplicationQuery);
          
          if (!querySnapshot.empty) {
            const loanApplicationDoc = querySnapshot.docs[0];
            setLoanId(loanApplicationDoc.id);
            setFormData(loanApplicationDoc.data());
          }
        } catch (error) {
          console.error('Error fetching loan application data:', error);
        }
      }
    };

    fetchLoanApplicationData();
  }, [currentUser]);

  useEffect(() => {
    const fetchTermSheets = async () => {
      if (currentUser && loanApplicationId) {
        try {
          const db = getFirestore();
          const termSheetsQuery = query(collection(db, 'termSheets'), where('loanApplicationId', '==', loanApplicationId));
          const termSheetsSnapshot = await getDocs(termSheetsQuery);
          
          const sheets = termSheetsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          
          setTermSheets(sheets);
        } catch (err) {
          console.error('Error fetching term sheets:', err);
        }
      }
    };

    fetchTermSheets();
  }, [currentUser, loanApplicationId]);

  useEffect(() => {
    const preCheckComplete = isPreCheckComplete();
    const loanAppComplete = isLoanApplicationComplete(formData);
    const businessDocsComplete = isBusinessDocumentsComplete();
    const termSheetReceived = termSheets.length > 0;

    if (preCheckComplete && loanAppComplete && businessDocsComplete && !termSheetReceived && !showCongratulationsPopup) {
      setShowCongratulationsPopup(true);
    }
  }, [formData, uploadedDocuments, termSheets]);

  useEffect(() => {
    const checkPrequalLetter = async () => {
      if (loanId) {
        const db = getFirestore();
        const loanDocRef = doc(db, 'loanApplications', loanId);
        const loanDocSnap = await getDoc(loanDocRef);
        
        if (loanDocSnap.exists()) {
          const loanData = loanDocSnap.data();
          if (loanData.prequalLetterUrl) {
            setPrequalLetterUrl(loanData.prequalLetterUrl);
          }
        }
      }
    };

    checkPrequalLetter();
  }, [loanId]);

  useEffect(() => {
    const checkPfsStatus = () => {
      const pfsDocument = uploadedDocuments.find(doc => doc.documentName === 'Personal Financial Statement');
      if (pfsDocument) {
        setPfsUploaded(true);
        setPfsOcrComplete(!!pfsDocument.ocrResult);
      } else {
        setPfsUploaded(false);
        setPfsOcrComplete(false);
      }
    };

    checkPfsStatus();
  }, [uploadedDocuments]);

  useEffect(() => {
    const checkPfsUploadAndGenerateLetter = async () => {
      if (loanId) {
        const db = getFirestore();
        const ocrMetadataRef = collection(db, 'OCRMetadata');
        const q = query(ocrMetadataRef, 
          where('loanApplicationId', '==', loanId),
          where('documentName', '==', 'Personal Financial Statement')
        );
        
        const querySnapshot = await getDocs(q);
        const isPfsUploaded = !querySnapshot.empty;
        setPfsUploaded(isPfsUploaded);

        if (isPfsUploaded && !prequalLetterUrl && !isGeneratingLetter) {
          generateAndUploadPrequalLetter();
        }
      }
    };

    checkPfsUploadAndGenerateLetter();
  }, [loanId, prequalLetterUrl, isGeneratingLetter]);

  const generateAndUploadPrequalLetter = async () => {
    setIsGeneratingLetter(true);
    setPrequalLetterError(null);

    try {
      const db = getFirestore();
      const loanDocRef = doc(db, 'loanApplications', loanId);
      const loanDocSnap = await getDoc(loanDocRef);
      
      if (!loanDocSnap.exists()) {
        throw new Error('Loan application not found');
      }
      
      const loanData = loanDocSnap.data();
      const customerName = `${loanData.firstName} ${loanData.lastName}`;

      const prequalifiedAmount = await calculatePrequalifiedAmount(loanId);

      if (!prequalifiedAmount || prequalifiedAmount <= 0) {
        throw new Error('Unable to calculate a valid prequalified amount');
      }

      const letterBlob = await generatePrequalificationLetterPDF(customerName, prequalifiedAmount);
      
      const storage = getStorage();
      const letterRef = ref(storage, `prequalificationLetters/${loanId}.pdf`);
      
      await uploadBytes(letterRef, letterBlob);
      
      const url = await getDownloadURL(letterRef);
      
      setPrequalLetterUrl(url);

      await updateDoc(loanDocRef, {
        prequalifiedAmount: prequalifiedAmount,
        prequalLetterUrl: url
      });

    } catch (error) {
      console.error('Error generating or uploading prequalification letter:', error);
      setPrequalLetterError('Failed to generate letter. Please try again later.');
    } finally {
      setIsGeneratingLetter(false);
    }
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleSendMessage = (message, documentName) => {
    const inputMessage = message || userInput.trim();
    if (inputMessage !== '') {
      let userMessage;
      let displayedUserMessage;

      if (documentName) {
        userMessage = {
          role: 'user',
          content: `Here is the ${documentName} of this applicant - document:\n\n${inputMessage}\n\nPlease provide pointers for this applicant for their ${documentName} based on the following deal information:\n\nIndustry Code: ${formData?.industry?.code}\nDeal Information: ${JSON.stringify(formData, null, 2)}\n\nLet me know if anything is missing or looks bad specific to the ${formData?.industry?.code} industry.`,
        };
        displayedUserMessage = {
          role: 'user',
          content: `What are some pointers on my ${documentName}?`,
        };
      } else {
        userMessage = {
          role: 'user',
          content: inputMessage,
        };
        displayedUserMessage = {
          role: 'user',
          content: inputMessage,
        };
      }

      setChatHistory((prevHistory) => [...prevHistory, displayedUserMessage]);
      setUserInput('');
      setIsLoading(true);
      setHasChatMessages(true);

      if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
        webSocketRef.current.send(JSON.stringify({ message: userMessage.content }));
      }

      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
      setIsChatActive(true);
    }
  };

  const isLoanApplicationComplete = (formData) => {
    if (!formData) return false;

    const requiredFields = [
      'businessAge',
      'businessState',
      'businessSummary',
      'loanSize',
      'purchaseType',
      'capTable',
      'loanStructure'
    ];

    return requiredFields.every(field => {
      if (field === 'capTable') {
        return formData[field] && formData[field].length > 0 && 
               formData[field].reduce((sum, owner) => sum + (parseFloat(owner.percentage) || 0), 0) === 100;
      }
      
      return !!formData[field];
    });
  };

  return (
    <div id="fulltask">
   <div className="rounded-lg overflow-hidden">
        <div
          className="flex flex-col"
          style={{ height: `${containerHeight}px` }}
        >
          <div
            className="flex-1 overflow-y-auto"
            style={{ background: 'linear-gradient(to bottom, #1E40AF, #3B82F6, white 0%)' }}
          >
            <div className="mt-4 px-4 pb-4" ref={contentRef}>
              <div id="statusarea" className="bg-white overflow-hidden rounded-xl shadow-md mb-4 border border-gray-300">
                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Status</dt>
                    <dd className="flex items-start gap-x-2">
                      <div>{getStatus()}</div>
                    </dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-3">
                    <dt className="text-gray-500">Next Step</dt>
                    <dd className="font-medium text-gray-900">{getNextStep()}</dd>
                  </div>
                </dl>
              </div>

              <div id="requireddocuments" className="bg-white p-4 rounded-lg shadow-md mb-4 border border-gray-300">
        {getRequiredDocumentsSection()}
      </div>

              {/* Prequalification Letter Section */}
              {formData?.loanPurpose !== 'workingCapital-nonsba' && prequalLetterUrl && (
                <div className="bg-white overflow-hidden rounded-xl shadow-md mb-4 border border-gray-300">
                  <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500 flex items-center">
                        <DocumentIcon className="h-5 w-5 text-gray-400 mr-2" />
                        Prequalification Letter
                      </dt>
                      <dd className="flex items-center">
                        <a
                          href={prequalLetterUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800 font-medium"
                        >
                          View Letter
                        </a>
                      </dd>
                    </div>
                  </dl>
                </div>
              )}

              <div id="contactussection">
                <ul role="list" className="space-y-4">
                  {people.map((person) => (
                    <li
                      key={person.email}
                      className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow-md mb-4 border border-gray-300"
                    >
                      <div className="flex w-full items-center justify-between space-x-6 p-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium text-gray-900">
                              {person.name}
                            </h3>
                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              {person.role}
                            </span>
                          </div>
                          <p className="mt-1 truncate text-sm text-gray-500">{person.title}</p>
                        </div>
                        <img
                          className="h-10 w-10 flex-shrink-0"
                          src={person.imageUrl}
                          alt=""
                        />
                      </div>
                      <div>
                        <div className="-mt-px flex divide-x divide-gray-200">
                          <div className="flex w-0 flex-1">
                            <a
                              href={`mailto:${person.email}`}
                              className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                              <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              Email
                            </a>
                          </div>
                          <div className="-ml-px flex w-0 flex-1">
                            <a
                              href={`tel:${person.telephone}`}
                              className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            >
                              <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              {person.telephone}
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
      {showCongratulationsPopup && (
        <CongratulatoryPopup onClose={() => setShowCongratulationsPopup(false)} />
      )}
    </div>
  );
};
export default ChatBot;