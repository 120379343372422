import React, { useState, useEffect, useRef } from 'react';
import { DollarSign, Percent } from 'lucide-react';

const LoanStructure = ({ formData, setFormData }) => {
  const [inputType, setInputType] = useState('percentage');
  const [percentageInputs, setPercentageInputs] = useState({});
  const [dollarInputs, setDollarInputs] = useState({});
  const totalAmount = parseFloat(formData.loanSize) || 0;
  const inputRefs = useRef({});

  useEffect(() => {
    const initialPercentageInputs = {};
    const initialDollarInputs = {};
    Object.entries(formData.loanStructure || {}).forEach(([key, value]) => {
      if (key === 'equityInjection') {
        Object.entries(value).forEach(([subKey, subValue]) => {
          const fieldName = `${key}.${subKey}`;
          initialPercentageInputs[fieldName] = subValue;
          initialDollarInputs[fieldName] = formatDollarAmount(((parseFloat(subValue) || 0) / 100 * totalAmount).toFixed(2));
        });
      } else {
        initialPercentageInputs[key] = value;
        initialDollarInputs[key] = formatDollarAmount(((parseFloat(value) || 0) / 100 * totalAmount).toFixed(2));
      }
    });
    setPercentageInputs(initialPercentageInputs);
    setDollarInputs(initialDollarInputs);
  }, [formData.loanStructure, totalAmount]);

  const formatDollarAmount = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleInputChange = (e, field) => {
    const value = e.target.value.replace(/,/g, '');
    const cursorPosition = e.target.selectionStart;

    if (e.key === 'Enter') {
      e.preventDefault();
      return;
    }

    if (inputType === 'percentage') {
      setPercentageInputs(prev => ({ ...prev, [field]: value }));
      const percentage = parseFloat(value) || 0;
      setDollarInputs(prev => ({ ...prev, [field]: formatDollarAmount(Math.round(percentage / 100 * totalAmount)) }));
    } else {
      const dollars = parseInt(value) || 0;
      setDollarInputs(prev => ({ ...prev, [field]: formatDollarAmount(dollars) }));
      const percentage = totalAmount > 0 ? ((dollars / totalAmount) * 100).toFixed(2) : '0';
      setPercentageInputs(prev => ({ ...prev, [field]: percentage }));
    }

    setTimeout(() => {
      const input = inputRefs.current[field];
      if (input) {
        input.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  };

  const saveToFirebase = () => {
    const newLoanStructure = Object.entries(percentageInputs).reduce((acc, [key, value]) => {
      if (key.includes('.')) {
        const [main, sub] = key.split('.');
        if (!acc[main]) acc[main] = {};
        acc[main][sub] = value === '' ? '' : Math.round(parseFloat(value)).toString();
      } else {
        acc[key] = value === '' ? '' : Math.round(parseFloat(value)).toString();
      }
      return acc;
    }, {});

    setFormData(prev => ({
      ...prev,
      loanStructure: newLoanStructure
    }));
  };

  const renderInput = (label, field) => (
    <div key={field} className="mb-2">
      <label className="block text-xs font-medium text-gray-600 mb-1">{label}</label>
      <div className="relative">
        {inputType === 'dollar' && (
          <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-gray-400 text-xs">
            $
          </span>
        )}
        <input
          ref={el => inputRefs.current[field] = el}
          type="text"
          value={inputType === 'percentage' ? percentageInputs[field] || '' : dollarInputs[field] || ''}
          onChange={(e) => handleInputChange(e, field)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onBlur={saveToFirebase}
          className={`w-full px-2 py-1 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 ${
            inputType === 'dollar' ? 'pl-6' : ''
          }`}
          placeholder={`Enter ${inputType === 'percentage' ? '%' : 'amount'}`}
        />
        {inputType === 'percentage' && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400 text-xs">
            %
          </span>
        )}
      </div>
    </div>
  );

  const totalPercentage = () => {
    return Object.values(percentageInputs).reduce((sum, value) => sum + (parseInt(value) || 0), 0);
  };

  const isCloseToHundred = (value) => {
    return Math.abs(value - 100) < 0.01;
  };

  const handleInputTypeChange = (newType) => (e) => {
    e.preventDefault();
    setInputType(newType);
  };

  return (
    <div className="bg-white rounded-lg shadow p-3 w-full max-w-sm mx-auto">
      <h3 className="text-base font-semibold text-gray-800 mb-2">Expected Loan Structure</h3>
      
      <div className="flex justify-center mb-3">
        <div className="inline-flex rounded-md bg-gray-100 p-0.5">
          <button
            onClick={handleInputTypeChange('percentage')}
            className={`flex items-center space-x-1 px-2 py-1 rounded-md text-xs font-medium transition-all duration-200 ${
              inputType === 'percentage' ? 'bg-white shadow text-blue-600' : 'text-gray-600 hover:bg-gray-200'
            }`}
          >
            <Percent size={10} />
            <span>Percentage</span>
          </button>
          <button
            onClick={handleInputTypeChange('dollar')}
            className={`flex items-center space-x-1 px-2 py-1 rounded-md text-xs font-medium transition-all duration-200 ${
              inputType === 'dollar' ? 'bg-white shadow text-blue-600' : 'text-gray-600 hover:bg-gray-200'
            }`}
          >
            <DollarSign size={10} />
            <span>Dollar</span>
          </button>
        </div>
      </div>

      <div className="space-y-2">
        {renderInput('SBA 7a Loan', 'sba7aLoan')}
        {renderInput('Conventional Loan', 'conventionalLoan')}
        {renderInput('Non-Full Standby Sellers Note', 'sellersNote')}
        {renderInput('Personal Equity Injection', 'equityInjection.personal')}
        {renderInput('Full Standby Sellers Note', 'equityInjection.fullStandby')}
      </div>

      <div className="flex items-center justify-between pt-2 border-t border-gray-200 mt-3">
        <span className="text-gray-600 text-xs font-medium">Total</span>
        <span className="text-blue-600 font-semibold text-sm">
          {inputType === 'percentage'
            ? `${totalPercentage()}%`
            : `$${formatDollarAmount(Object.values(dollarInputs).reduce((sum, value) => sum + (parseInt(value.replace(/,/g, '')) || 0), 0))}`}
        </span>
      </div>

      {!isCloseToHundred(totalPercentage()) && (
        <p className="text-red-500 mt-1 text-xs">The total must add up to 100% or the full loan amount.</p>
      )}
      {(parseInt(percentageInputs['equityInjection.personal']) || 0) + (parseInt(percentageInputs['equityInjection.fullStandby']) || 0) < 10 && (
        <p className="text-red-500 mt-1 text-xs">The combined Equity Injection must be at least 10%.</p>
      )}
    </div>
  );
};

export default LoanStructure;