import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { createClient } from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { format } from 'date-fns';

const client = createClient({
    space: 'a5ya9ztddrl3',
    environment: 'master',
    accessToken: 'epYb_XLyiqSZqwzfgP7hQJ9cGh_aLtD_u0OTOxxjfWU'
});

function BlogPost() {
  const [post, setPost] = useState(null);
  const [otherPosts, setOtherPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await client.getEntry(id);
        setPost(response);

        // Fetch all blog posts
        const allPosts = await client.getEntries({
          content_type: 'blogPage',
          order: '-sys.createdAt', // Sort by creation date, newest first
          limit: 5, // Limit to 5 posts, adjust as needed
        });

        // Filter out the current post
        setOtherPosts(allPosts.items.filter(item => item.sys.id !== id));

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-16 h-16 border-4 border-blue-500 border-solid rounded-full animate-spin border-t-transparent"></div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="max-w-2xl mx-auto px-4 py-16 text-center">
        <h2 className="text-3xl font-bold text-gray-900 mb-4">Post not found</h2>
        <p className="text-lg text-gray-600 mb-8">The blog post you're looking for doesn't exist or has been removed.</p>
        <Link to="/blog" className="inline-block bg-blue-600 text-white py-2 px-4 rounded-full hover:bg-blue-700 transition-colors duration-300">
          Back to Blog
        </Link>
      </div>
    );
  }

  const formattedDate = format(new Date(post.sys.createdAt), 'MMMM d, yyyy');

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <h1 className="text-4xl font-bold mt-12 mb-6">{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="text-3xl font-semibold mt-10 mb-4">{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className="text-2xl font-medium mt-8 mb-3">{children}</h3>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className="mb-6 leading-relaxed">{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul className="list-disc pl-6 mb-6">{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol className="list-decimal pl-6 mb-6">{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li className="mb-2">{children}</li>,
    },
    renderMark: {
      [MARKS.BOLD]: text => <strong className="font-semibold">{text}</strong>,
    },
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-16 flex">
      {/* Main Content */}
      <div className="w-3/4 pr-8">
        <Link to="/blog" className="inline-block mb-8 text-blue-600 hover:text-blue-800 transition-colors duration-300">
          &larr; Back to Blog
        </Link>
        <article className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-8">
            <header className="mb-8">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">{post.fields.title}</h1>
              <time className="text-sm text-gray-600" dateTime={post.sys.createdAt}>{formattedDate}</time>
              <div className="mt-4 flex flex-wrap gap-2">
                {post.fields.categoryTags?.map((tag, index) => (
                  <span key={index} className="px-3 py-1 bg-blue-100 text-blue-800 text-sm rounded-full">
                    {tag}
                  </span>
                ))}
              </div>
            </header>
            <div className="prose prose-lg max-w-none">
              {documentToReactComponents(post.fields.body, options)}
            </div>
          </div>
        </article>
      </div>

      {/* Other Articles */}
      <div className="w-1/4">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Other Articles</h2>
        <div className="space-y-4">
          {otherPosts.map((post) => (
            <Link 
              key={post.sys.id} 
              to={`/blog/${post.sys.id}`} 
              className="block p-4 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              <h3 className="font-semibold text-blue-600 hover:text-blue-800 mb-2">{post.fields.title}</h3>
              <p className="text-sm text-gray-600 line-clamp-2">
                {post.fields.body?.content[0]?.content[0]?.value || 'No content available'}
              </p>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
