import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, updateDoc, doc, setDoc, Timestamp, arrayUnion, getDoc, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

const LoanOfficerAssignment = ({ loanId, handleAssignBank, isPrequalification }) => {
  const [banks, setBanks] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState('');
  const [dropboxLink, setDropboxLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [assignedBanks, setAssignedBanks] = useState([]);
  const [matchedBanks, setMatchedBanks] = useState([]);
  const [loanData, setLoanData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    const fetchBanksAndLoanData = async () => {
      try {
        setIsLoading(true);
        // Fetch banks
        const banksQuery = query(collection(db, 'banks'));
        const banksSnapshot = await getDocs(banksQuery);
        const banksData = banksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched banks:', banksData);
        setBanks(banksData);

        // Fetch loan data
        const loanRef = doc(db, 'prequalificationApplications', loanId);
        const loanDoc = await getDoc(loanRef);
        if (loanDoc.exists()) {
          const loanData = loanDoc.data();
          console.log('Fetched loan data:', loanData);
          setLoanData(loanData);
        } else {
          console.error('Loan document does not exist');
        }

        // Fetch assigned banks
        const assignmentRef = doc(db, 'bankAssignments', loanId);
        const assignmentDoc = await getDoc(assignmentRef);
        if (assignmentDoc.exists()) {
          setAssignedBanks(assignmentDoc.data().assignedBanks || []);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Failed to fetch data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBanksAndLoanData();
  }, [loanId]);

  useEffect(() => {
    if (banks.length > 0 && loanData) {
      console.log('Matching banks...');
      const projectedSalePrice = parseFloat(loanData.projectedSalePrice);
      const matched = banks.filter(bank => {
        const bankStates = Array.isArray(bank.state) ? bank.state : [bank.state];
        const stateMatch = bankStates.includes(loanData.businessState) || bankStates.includes('All States') || bankStates.length === 50;
        
        let loanAmountMatch = true;
        if (bank.optimalLoanAmount && bank.optimalLoanAmount.min && bank.optimalLoanAmount.max) {
          loanAmountMatch = projectedSalePrice >= bank.optimalLoanAmount.min && 
                            projectedSalePrice <= bank.optimalLoanAmount.max;
        }

        console.log(`Bank: ${bank.bankName}`);
        console.log(`State Match: ${stateMatch}, Bank States: ${bankStates.join(', ')}, Business State: ${loanData.businessState}`);
        console.log(`Loan Amount Match: ${loanAmountMatch}`);
        console.log(`Projected Sale Price: ${projectedSalePrice}, Bank Range: ${bank.optimalLoanAmount?.min || 'N/A'} - ${bank.optimalLoanAmount?.max || 'N/A'}`);
        
        return stateMatch && loanAmountMatch;
      });
      console.log('Matched banks:', matched);
      setMatchedBanks(matched);
    }
  }, [banks, loanData]);

  const handleSelection = async () => {
    if (!selectedBankId || !dropboxLink) {
      alert('Please select a bank and provide a Dropbox link.');
      return;
    }

    setIsLoading(true);

    try {
      console.log("Starting bank assignment process...");
      console.log("Loan ID:", loanId);
      console.log("Selected Bank ID:", selectedBankId);
      console.log("Is Prequalification:", isPrequalification);
      console.log("Dropbox Link:", dropboxLink);

      // Call the handleAssignBank function from the parent component
      await handleAssignBank(selectedBankId, dropboxLink);
      console.log("handleAssignBank completed successfully");

      // Update local state
      setAssignedBanks(prevBanks => [...prevBanks, { bankId: selectedBankId, dropboxLink }]);

      alert('Bank assigned and Dropbox link added successfully!');
      setDropboxLink('');
      setSelectedBankId('');
    } catch (error) {
      console.error('Error assigning bank or adding Dropbox link:', error);
      alert(`An error occurred: ${error.message}. Please check the console for more details.`);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleExpand = () => setIsExpanded(!isExpanded);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <div>
          <h3 className="text-base font-semibold leading-7 text-gray-900">Matched Banks</h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Assign banks and add Dropbox links.</p>
        </div>
        <button
          onClick={toggleExpand}
          className="text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          {isExpanded ? (
            <ChevronUpIcon className="h-6 w-6" />
          ) : (
            <ChevronDownIcon className="h-6 w-6" />
          )}
        </button>
      </div>
      {isExpanded && (
        <div className="px-6 py-4">
          {matchedBanks.length === 0 ? (
            <p>No matched banks found. Please check the loan data and bank criteria.</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {matchedBanks.map((bank) => (
                <div key={bank.id} className="border rounded-lg p-4 shadow-md">
                  <div className="flex justify-between items-center mb-3">
                    <h4 className="text-lg font-semibold text-gray-800 truncate">{bank.bankName}</h4>
                    <button
                      onClick={() => setSelectedBankId(bank.id)}
                      className={`px-3 py-1 rounded-full text-xs font-medium transition-colors duration-300 ${
                        selectedBankId === bank.id
                          ? 'bg-blue-600 text-white'
                          : 'bg-gray-200 text-gray-700 hover:bg-blue-500 hover:text-white'
                      }`}
                    >
                      {selectedBankId === bank.id ? 'Selected' : 'Select'}
                    </button>
                  </div>
                  <div className="grid grid-cols-2 gap-3 text-sm mb-3">
                    <div>
                      <p className="text-gray-500">Loan Range</p>
                      <p className="font-bold text-gray-800">
                        ${bank.optimalLoanAmount?.min?.toLocaleString()} - ${bank.optimalLoanAmount?.max?.toLocaleString()}
                      </p>
                    </div>
                    <div>
                      <p className="text-gray-500">Approval Rate</p>
                      <p className="font-bold text-gray-800">{bank.approvalPercentage}%</p>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-3 text-sm mb-3">
                    <div>
                      <p className="text-gray-500">Avg. Interest Rate</p>
                      <p className="font-bold text-gray-800">{((bank.usualLoanRateMin + bank.usualLoanRateMax) / 2).toFixed(2)}%</p>
                    </div>
                    <div>
                      <p className="text-gray-500">States</p>
                      <p className="font-bold text-gray-800">{bank.state.length === 50 ? 'All States' : bank.state.join(', ')}</p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center pt-3 border-t border-gray-200 text-xs">
                    <div>
                      <p className="text-gray-500">Min Credit Score</p>
                      <p className="font-bold text-gray-800">{bank.creditScore || 'N/A'}</p>
                    </div>
                    <div>
                      <p className="text-gray-500">Min DSCR</p>
                      <p className="font-bold text-gray-800">{bank.debtServiceRequirement || 'N/A'}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="mt-4">
            <label htmlFor="dropboxLink" className="block text-sm font-medium text-gray-700">
              Dropbox Link
            </label>
            <input
              type="text"
              id="dropboxLink"
              name="dropboxLink"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              value={dropboxLink}
              onChange={(e) => setDropboxLink(e.target.value)}
              placeholder="https://www.dropbox.com/..."
            />

            <div className="mt-4">
              <h3 className="text-sm font-medium text-gray-700">Assigned Banks:</h3>
              <ul className="mt-2 list-disc list-inside">
                {assignedBanks.map((assignment, index) => (
                  <li key={index}>
                    {banks.find(bank => bank.id === assignment.bankId)?.bankName || 'Unknown Bank'} - 
                    <a href={assignment.dropboxLink} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                      Dropbox Link
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <button
              type="button"
              onClick={handleSelection}
              disabled={isLoading}
              className={`mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${isLoading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {isLoading ? 'Assigning...' : 'Assign Bank and Add Dropbox Link'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanOfficerAssignment;