import React, { useState, useEffect } from 'react';
import { createClient } from 'contentful';

const client = createClient({
    space: 'a5ya9ztddrl3',
    environment: 'master',
    accessToken: 'epYb_XLyiqSZqwzfgP7hQJ9cGh_aLtD_u0OTOxxjfWU'
});

function Blog() {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        client.getEntries({
            content_type: 'blogPage',
        })
            .then((response) => {
                console.log('Contentful response:', response);
                setBlogPosts(response.items);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching blog posts:', error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (blogPosts.length === 0) {
        return <div>No blog posts found</div>;
    }

    return (
        <div className="bg-gray-50 min-h-screen py-16 px-4 sm:px-6 lg:px-8">
            <div className="max-w-6xl mx-auto">
                <h1 className="text-4xl font-bold mb-16 text-center text-gray-900">
                    Blog Posts
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {blogPosts.map((post) => (
                        <div key={post.sys.id} className="bg-white rounded-lg shadow-sm overflow-hidden transition-shadow duration-300 hover:shadow-md">
                            <div className="p-6">
                                <h2 className="text-xl font-semibold mb-3 text-gray-900 line-clamp-2">{post.fields.title}</h2>
                                <p className="text-gray-600 mb-4 text-sm line-clamp-3">
                                    {post.fields.body?.content[0]?.content[0]?.value || 'No content available'}
                                </p>
                                <div className="flex justify-between items-center">
                                    <a href={`/blog/${post.sys.id}`} 
                                       className="text-blue-600 hover:text-blue-800 font-medium text-sm">
                                        Read more
                                    </a>
                                    <span className="text-xs text-gray-400">
                                        {new Date(post.sys.createdAt).toLocaleDateString()}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Blog;
