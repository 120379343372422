import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { storage, db, auth } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, getStorage, uploadBytes } from 'firebase/storage';
import { doc, updateDoc, getDoc, addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { getIdToken } from 'firebase/auth';
import { CheckCircleIcon, EnvelopeIcon, PhoneIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Disclosure, Menu } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import profileImage from '../images/images.png';
import logo from '../images/logo.png';
import { LoanStatuses } from '../constants/statuses';
import { EllipsisHorizontalIcon, DocumentIcon } from '@heroicons/react/20/solid';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { CheckIcon } from '@heroicons/react/20/solid';

const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
};

const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
  { name: 'Reports', href: '#', current: false },
];

const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const requiredDocuments = [
  {
    DocumentID: 6,
    Name: "Business Tax Returns",
    Description: "Upload the last 3 years of business tax returns.",
    RequiredDocuments: 3,
    Individual: false,
    Labels: [
      "Business Tax Return - Current Year",
      "Business Tax Return - Last Year",
      "Business Tax Return - 2 Years Ago"
    ],
    loanPurpose: ["Acquisition", "workingCapital"]
  },
  {
    DocumentID: 10,
    Name: "Interim P&L",
    Description: "Upload the interim Profit and Loss statement (PDF or Excel).",
    RequiredDocuments: 1,
    Individual: false,
    Labels: ["Interim P&L"],
    AcceptedFileTypes: ".pdf,.xlsx,.xls",
    loanPurpose: ["Acquisition", "workingCapital"]
  },
  {
    DocumentID: 4,
    Name: "Personal Tax Returns",
    Description: "Upload the current year's personal tax return for each guarantor.",
    RequiredDocuments: 1,
    Individual: true,
    Labels: ["Personal Tax Return - Current Year"],
    loanPurpose: ["Acquisition", "workingCapital", "workingCapital-startup"]
  },
  {
    DocumentID: 15,
    Name: "Bank Statements",
    Description: "Upload 3 months of bank statements.",
    RequiredDocuments: 1,
    Individual: false,
    Labels: ["Bank Statement - Most Recent","Bank Statement - 2 Months Ago", "Bank Statement - 3 Months Ago"],
    AcceptedFileTypes: ".pdf,.xlsx,.xls",
    loanPurpose: ["workingCapital-nonsba"]
  },
  {
    DocumentID: 16,
    Name: "Debt Schedule",
    Description: "Upload your company's debt schedule.",
    RequiredDocuments: 1,
    Individual: false,
    Labels: ["Debt Schedule"],
    AcceptedFileTypes: ".pdf,.xlsx,.xls",
    loanPurpose: ["Acquisition", "workingCapital", "workingCapital-startup"]
  },
];

const tabs = [
  { name: 'Questions', current: true },
  { name: 'Documents', current: false, locked: true }
]

const SharedUploadPage = () => {
  const { loanId } = useParams();
  const [uploading, setUploading] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [numberOfOwners, setNumberOfOwners] = useState('');
  const [personalGuarantors, setPersonalGuarantors] = useState([]);
  const [creditScores, setCreditScores] = useState([]);
  const [businessName, setBusinessName] = useState('');
  const [yearsInBusiness, setYearsInBusiness] = useState('0');
  const [workingCapitalUse, setWorkingCapitalUse] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [step, setStep] = useState(0);
  const [numberOfEmployees, setNumberOfEmployees] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [processingStatus, setProcessingStatus] = useState({});
  const [currentTab, setCurrentTab] = useState('Questions');
  const [currentTaxReturn, setCurrentTaxReturn] = useState(0);
  const navigate = useNavigate();
  const [showUploadInterface, setShowUploadInterface] = useState(false);
  const [prequalifyAmount, setPrequalifyAmount] = useState(null);
  const [emails, setEmails] = useState([]);
  const [loanStatus, setLoanStatus] = useState('');

  const people = [
    {
      name: 'Tom Cook',
      title: 'Loan Officer',
      role: 'Member',
      email: 'tom@example.com',
      telephone: '+1 (555) 123-4567',
      imageUrl: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  ];

  const getStatusStyle = (isUploaded) => {
    if (isUploaded) {
      return 'text-green-700 bg-green-50 ring-green-600/20';
    }
    return 'text-gray-600 bg-gray-50 ring-gray-500/10';
  };

  useEffect(() => {
    const fetchLoanApplicationData = async () => {
      if (loanId) {
        setIsLoading(true);
        try {
          const loanDoc = await getDoc(doc(db, 'loanApplications', loanId));
          if (loanDoc.exists()) {
            const loanData = loanDoc.data();
            
            // Fetch uploaded documents for this specific loan application
            const uploadedDocsQuery = query(
              collection(db, 'documentMetadata'),
              where('loanApplicationId', '==', loanId)
            );
            const uploadedDocsSnapshot = await getDocs(uploadedDocsQuery);
            const uploadedDocsData = {};
            uploadedDocsSnapshot.forEach((doc) => {
              const data = doc.data();
              const key = `${data.documentName}-${data.label}${data.guarantorId ? `-${data.guarantorId}` : ''}`;
              uploadedDocsData[key] = {
                url: data.fileUrl,
                name: data.fileName,
                uploadedAt: data.uploadedAt,
                metadataId: doc.id
              };
            });

            setUploadedFiles(uploadedDocsData);
            setBusinessName(loanData.businessName || '');
            setYearsInBusiness(loanData.yearsInBusiness || '0');
            setWorkingCapitalUse(loanData.workingCapitalUse || '');
            setLoanAmount(loanData.loanSize || '');
            setPersonalGuarantors(loanData.personalGuarantors || []);
            setCreditScores(loanData.creditScores || []);
            setNumberOfEmployees(loanData.numberOfEmployees || '');
            setEmails(loanData.emails || []);
            setLoanStatus(loanData.loanStatus || '');

            if (loanData.businessName && loanData.yearsInBusiness !== undefined &&
                loanData.workingCapitalUse && loanData.loanSize) {
              setShowDocumentUpload(true);
            }
          }
        } catch (error) {
          console.error("Error fetching loan application data:", error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchLoanApplicationData();
  }, [loanId]);

  useEffect(() => {
    const fetchPrequalifyAmount = async () => {
      if (loanId) {
        const loanRef = doc(db, 'loanApplications', loanId);
        const loanSnap = await getDoc(loanRef);
        if (loanSnap.exists()) {
          setPrequalifyAmount(loanSnap.data().prequalifyAmount);
        }
      }
    };
    
    fetchPrequalifyAmount();
  }, [loanId]);

  const renderForm = () => {
    switch (step) {
      case 0:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="numberOfOwners" className="block text-sm font-medium text-gray-700">
                How many owners have more than 20% ownership?
              </label>
              <input
                type="number"
                id="numberOfOwners"
                value={numberOfOwners}
                onChange={(e) => {
                  const value = e.target.value;
                  const num = parseInt(value) || 0;
                  if (num >= 0 && num <= 100) {
                    setNumberOfOwners(value);
                    setPersonalGuarantors(num ? Array(num).fill().map(() => ({ firstName: '', lastName: '' })) : []);
                    setCreditScores(num ? Array(num).fill('') : []);
                    setEmails(num ? Array(num).fill('') : []);
                  }
                }}
                required
                min="0"
                max="100"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        );
      case 1:
        return (
          <div className="space-y-6">
            {personalGuarantors.map((guarantor, index) => (
              <div key={index} className="border-b pb-4">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Personal Guarantor {index + 1}</h3>
                <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label htmlFor={`firstName-${index}`} className="block text-sm font-medium text-gray-700">
                      First name
                    </label>
                    <input
                      type="text"
                      id={`firstName-${index}`}
                      value={guarantor.firstName}
                      onChange={(e) => {
                        const newGuarantors = [...personalGuarantors];
                        newGuarantors[index].firstName = e.target.value;
                        setPersonalGuarantors(newGuarantors);
                      }}
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label htmlFor={`lastName-${index}`} className="block text-sm font-medium text-gray-700">
                      Last name
                    </label>
                    <input
                      type="text"
                      id={`lastName-${index}`}
                      value={guarantor.lastName}
                      onChange={(e) => {
                        const newGuarantors = [...personalGuarantors];
                        newGuarantors[index].lastName = e.target.value;
                        setPersonalGuarantors(newGuarantors);
                      }}
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label htmlFor={`email-${index}`} className="block text-sm font-medium text-gray-700">
                      Email
                    </label>
                    <input
                      type="email"
                      id={`email-${index}`}
                      value={emails[index]}
                      onChange={(e) => {
                        const newEmails = [...emails];
                        newEmails[index] = e.target.value;
                        setEmails(newEmails);
                      }}
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label htmlFor={`creditScore-${index}`} className="block text-sm font-medium text-gray-700">
                      Credit Score
                    </label>
                    <input
                      type="number"
                      id={`creditScore-${index}`}
                      value={creditScores[index]}
                      onChange={(e) => {
                        const newCreditScores = [...creditScores];
                        newCreditScores[index] = e.target.value;
                        setCreditScores(newCreditScores);
                      }}
                      required
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      case 2:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">Business Name</label>
              <input
                type="text"
                id="businessName"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="yearsInBusiness" className="block text-sm font-medium text-gray-700">
                How many years have you been in business?
              </label>
              <input
                type="number"
                id="yearsInBusiness"
                value={yearsInBusiness}
                onChange={(e) => setYearsInBusiness(e.target.value)}
                required
                min="0"
                max="100"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="workingCapitalUse" className="block text-sm font-medium text-gray-700">Working Capital Use</label>
              <textarea
                id="workingCapitalUse"
                value={workingCapitalUse}
                onChange={(e) => setWorkingCapitalUse(e.target.value)}
                required
                rows={3}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="loanAmount" className="block text-sm font-medium text-gray-700">
                Loan Amount (Min: $50,000 - Max: $5,000,000)
              </label>
              <div className="relative mt-1">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  id="loanAmount"
                  value={loanAmount}
                  onChange={(e) => {
                    // Remove any non-numeric characters except decimal point
                    const value = e.target.value.replace(/[^\d.]/g, '');
                    setLoanAmount(value);
                  }}
                  onBlur={(e) => {
                    // Validate on blur
                    const value = parseFloat(e.target.value);
                    if (isNaN(value) || value < 50000) {
                      setLoanAmount('50000');
                    } else if (value > 5000000) {
                      setLoanAmount('5000000');
                    }
                  }}
                  className="block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="50,000"
                />
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="numberOfEmployees" className="block text-sm font-medium text-gray-700">Number of Employees</label>
              <input
                type="number"
                id="numberOfEmployees"
                value={numberOfEmployees}
                onChange={(e) => setNumberOfEmployees(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loanRef = doc(db, 'loanApplications', loanId);
    await updateDoc(loanRef, {
      firstName: personalGuarantors[0]?.firstName || '',
      lastName: personalGuarantors[0]?.lastName || '',
      personalGuarantors,
      creditScores,
      emails,
      businessName,
      yearsInBusiness,
      workingCapitalUse,
      loanSize: loanAmount,
      numberOfEmployees,
      lastUserUpdate: new Date(),
      loanStatus: LoanStatuses.NEW_APPLICATION,
      loanStructure: {
        sba7aLoan: "100",
        additionalNotes: ""
      }
    });
    setShowDocumentUpload(true);
  };

  const handleFileUpload = async (e, docName, label, guarantor = null) => {
    const file = e.target.files[0];
    if (file) {
      const guarantorId = guarantor ? `${guarantor.firstName} ${guarantor.lastName}` : null;
      const fileKey = `${docName}-${label}${guarantorId ? `-${guarantorId}` : ''}`;
      setProcessingStatus(prevStatus => ({
        ...prevStatus,
        [fileKey]: 'processing'
      }));
  
      try {
        const storage = getStorage();
        // Use 'anonymous' as userId if not authenticated
        const userId = auth.currentUser?.uid || 'anonymous';
        const storageRef = ref(storage, `documents/${userId}/${loanId}/${docName}/${label}${guarantorId ? `/${guarantorId}` : ''}/${file.name}`);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
  
        const fileMetadata = {
          documentName: docName,
          label: label,
          fileName: file.name,
          fileUrl: downloadURL,
          uploadedAt: new Date().toISOString(),
          loanApplicationId: loanId,
          userId: userId,
          businessName: businessName,
          uploadedBy: 'borrower',
          guarantorId: guarantorId,
        };
  
        const metadataRef = collection(db, 'documentMetadata');
        const docRef = await addDoc(metadataRef, fileMetadata);
        fileMetadata.documentId = docRef.id;
  
        // Process the document
        try {
          let requestBody = {
            fileUrl: downloadURL,
            metadata: fileMetadata,
          };
  
          // Only add authorization header if user is authenticated
          let headers = {
            'Content-Type': 'application/json'
          };
  
          if (auth.currentUser) {
            const idToken = await getIdToken(auth.currentUser);
            headers['Authorization'] = `Bearer ${idToken}`;
          }
  
          const response = await fetch('https://sba-30d9855b23ef.herokuapp.com/process-document', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestBody),
          });
  
          if (!response.ok) {
            throw new Error('Failed to process document');
          }
  
          const processingResult = await response.json();
          console.log('Document processing result:', processingResult);
        } catch (processError) {
          console.error("Error processing document:", processError);
          // Continue with the upload even if processing fails
        }
  
        // Update the loan application document
        const loanRef = doc(db, 'loanApplications', loanId);
        
        // First update for the specific document
        await updateDoc(loanRef, {
          [`uploadedDocuments.${docName}`]: true,
          lastUpdated: new Date().toISOString(),
        });
  
        // Check if all documents are uploaded
        const allDocsUploaded = requiredDocuments.every(doc => 
          doc.Labels.every(label => 
            doc.Individual 
              ? personalGuarantors.every(guarantor => 
                  uploadedFiles[`${doc.Name}-${label}-${guarantor.firstName} ${guarantor.lastName}`]
                )
              : uploadedFiles[`${doc.Name}-${label}`]
          )
        );
  
        // If all documents are uploaded, update the status
        if (allDocsUploaded) {
          await updateDoc(loanRef, {
            loanStatus: LoanStatuses.SUBMITTED,
            allDocumentsUploaded: true,
            lastUpdated: new Date().toISOString()
          });
        }
  
        setUploadedFiles(prevFiles => ({
          ...prevFiles,
          [fileKey]: { name: file.name, url: downloadURL }
        }));
  
        setProcessingStatus(prevStatus => ({
          ...prevStatus,
          [fileKey]: 'complete'
        }));
  
        console.log('File uploaded successfully');
      } catch (error) {
        console.error("Error uploading file:", error);
        setProcessingStatus(prevStatus => ({
          ...prevStatus,
          [fileKey]: 'error'
        }));
        alert('Error uploading or processing file. Please try again.');
      }
    }
  };

  const renderDocumentCard = (doc, label, documentKey) => {
    const isUploaded = uploadedFiles[documentKey];
    const isMultipleDoc = doc.Labels.length > 1;
    
    const renderUploadStatus = () => (
      <div className="flex items-center gap-2">
        {isUploaded ? (
          <>
            <DocumentIcon className="h-5 w-5 text-green-500" />
            <span className="text-sm font-medium text-green-600">
              {uploadedFiles[documentKey].name}
            </span>
          </>
        ) : (
          <>
            <DocumentIcon className="h-5 w-5 text-gray-400" />
            <span className="text-sm font-medium text-gray-500">
              No file uploaded
            </span>
          </>
        )}
      </div>
    );

    const renderDottedUploadBox = () => (
      <div 
        onDragOver={handleDragOver}
        onDrop={(e) => handleDrop(e, doc.Name, label)}
        className={classNames(
          'border-2 border-dashed rounded-lg p-12 flex flex-col items-center justify-center w-full',
          isUploaded ? 'border-green-300 bg-green-50' : 'border-gray-300 hover:border-gray-400'
        )}
      >
        <input
          type="file"
          id={documentKey}
          onChange={(e) => handleFileUpload(e, doc.Name, label)}
          className="sr-only"
        />
        <label
          htmlFor={documentKey}
          className="cursor-pointer text-center w-full"
        >
          {isUploaded ? (
            <>
              <DocumentIcon className="mx-auto h-12 w-12 text-green-500" />
              <span className="mt-2 block text-sm font-medium text-green-600">
                {uploadedFiles[documentKey].name}
              </span>
              <span className="mt-1 block text-sm text-green-500">
                Click to replace
              </span>
            </>
          ) : (
            <>
              <DocumentIcon className="mx-auto h-12 w-12 text-gray-400" />
              <span className="mt-2 block text-sm font-medium text-gray-900">
                Drop your file here, or click to upload
              </span>
              <span className="mt-1 block text-sm text-gray-500">
                PDF, DOC up to 10MB
              </span>
            </>
          )}
        </label>
      </div>
    );

    if (isMultipleDoc) {
      return (
        <Disclosure as="div" className="mb-4">
          {({ open }) => (
            <div className="overflow-hidden rounded-xl border border-gray-200">
              <Disclosure.Button className="flex w-full items-center justify-between bg-gray-50 px-6 py-4">
                <div className="flex items-center gap-x-4">
                  <div className="h-12 w-12 flex-none rounded-lg bg-white ring-1 ring-gray-900/10 flex items-center justify-center">
                    <DocumentIcon className="h-6 w-6 text-gray-400" />
                  </div>
                  <div>
                    <div className="text-sm font-medium text-gray-900">{label}</div>
                    <div className="mt-1">
                      {renderUploadStatus()}
                    </div>
                  </div>
                </div>
                <ChevronUpIcon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-5 w-5 text-gray-500`}
                />
              </Disclosure.Button>
              
              <Disclosure.Panel className="px-6 py-4">
                {renderDottedUploadBox()}
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      );
    }
    
    // Single document with collapsible section
    return (
      <Disclosure as="div" className="mb-6">
        {({ open }) => (
          <div className="overflow-hidden rounded-xl border border-gray-200">
            <Disclosure.Button className="flex w-full items-center justify-between bg-gray-50 px-6 py-4">
              <div className="flex items-center gap-x-4">
                <div className="h-12 w-12 flex-none rounded-lg bg-white ring-1 ring-gray-900/10 flex items-center justify-center">
                  <DocumentIcon className="h-6 w-6 text-gray-400" />
                </div>
                <div>
                  <div className="text-sm font-medium text-gray-900">{doc.Name}</div>
                  <div className="mt-1">
                    {renderUploadStatus()}
                  </div>
                </div>
              </div>
              <ChevronUpIcon
                className={`${
                  open ? 'rotate-180 transform' : ''
                } h-5 w-5 text-gray-500`}
              />
            </Disclosure.Button>
            
            <Disclosure.Panel className="px-6 py-4">
              {renderDottedUploadBox()}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    );
  };
  const handleEditAnswers = () => {
    // Set number of owners based on existing guarantors length
    setNumberOfOwners(personalGuarantors.length.toString());
    setShowDocumentUpload(false);
    setCurrentTab('Questions');
    // Optionally keep user on last step or reset to first step
    // setStep(0); // Remove or comment this line to stay on last step
  };
  const renderApplicationSummary = () => {
    const primeRate = 8.5;
    const rateSpread = 2.75;
    const totalRate = primeRate + rateSpread;
    const questionsNotSubmitted = !showDocumentUpload && loanStatus !== LoanStatuses.NEW_APPLICATION;

    return (
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="p-6">
          <div className="flex justify-between items-start mb-6">
            <div>
              <h2 className="text-2xl font-semibold text-gray-900">
                {businessName || 'New Application'}
              </h2>
              <p className="text-sm text-gray-500 mt-1">Estimated Terms</p>
            </div>
            <img 
              src={profileImage} 
              alt="Company Logo" 
              className="h-8 w-auto object-contain"
            />
          </div>

          <div className="grid grid-cols-2 gap-x-8 gap-y-6">
            <div>
              <dt className="text-sm text-gray-500">Loan Type</dt>
              <dd className="mt-1 text-sm font-medium text-gray-900">SBA 7(a) Term Loan</dd>
            </div>

            <div>
              <dt className="text-sm text-gray-500">Loan Amount</dt>
              <dd className="mt-1 text-lg font-semibold text-blue-600">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0
                }).format(loanAmount || 0)}
              </dd>
            </div>

            {!questionsNotSubmitted && (
              <>
                <div>
                  <dt className="text-sm text-gray-500">Time to Receive</dt>
                  <dd className="mt-1 text-sm font-medium text-green-600">2-3 Weeks</dd>
                </div>

                <div>
                  <dt className="text-sm text-gray-500">Est. Monthly Payment</dt>
                  <dd className="mt-1 text-sm font-medium text-gray-900">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD'
                    }).format(loanAmount * 0.015 || 0)}
                  </dd>
                </div>

                <div>
                  <dt className="text-sm text-gray-500">Repayment Term</dt>
                  <dd className="mt-1 text-sm font-medium text-gray-900">10 years</dd>
                </div>

                <div>
                  <dt className="text-sm text-gray-500">Rate</dt>
                  <dd className="mt-1 text-sm font-medium text-gray-900">
                    Prime + {rateSpread}% ({totalRate}%)
                  </dd>
                </div>
              </>
            )}
          </div>

          {!questionsNotSubmitted && (
            <div className="mt-8 text-xs text-gray-500 leading-5">
              Interest rates, loan term and monthly payments will be determined based on an analysis and review of information submitted to lenders and other available information. All loan information is presented without warranty, and the estimated terms are not binding in any way.
            </div>
          )}
        </div>
      </div>
    );
  };
  
  const renderDocumentStepIndicator = () => {
    const allDocumentsUploaded = requiredDocuments.every(doc => 
      doc.Labels.every(label => uploadedFiles[`${doc.Name}-${label}`])
    );

    if (allDocumentsUploaded) {
      return (
        <nav aria-label="Progress" className="mb-8">
          <div className="text-center py-6">
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              {loanStatus === LoanStatuses.DOCUMENT_CLARIFICATION ? (
                'Document Clarification'
              ) : prequalifyAmount ? (
                <>You've been prequalified for {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  maximumFractionDigits: 0
                }).format(prequalifyAmount)}</>
              ) : (
                'Application Submitted'
              )}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {loanStatus === LoanStatuses.DOCUMENT_CLARIFICATION ? 
                'Please check your email for questions' : 
                prequalifyAmount ? 
                  'Underwriting will start in the next few days' : 
                  'Updates will be sent in 24-48 hours'
              }
            </p>
          </div>
        </nav>
      );
    }

    return (
      <nav aria-label="Progress" className="mb-8">
        <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {requiredDocuments.map((doc) => {
            const isUploaded = doc.Labels.every(label => 
              uploadedFiles[`${doc.Name}-${label}`]
            );
            
            return (
              <li key={doc.Name} className="md:flex-1">
                <div className={classNames(
                  'flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4',
                  isUploaded ? 'border-green-500' : 'border-gray-200'
                )}>
                  <span className={classNames(
                    'text-sm font-medium',
                    isUploaded ? 'text-green-500' : 'text-gray-500'
                  )}>
                    {doc.Name}
                  </span>
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    );
  };

  const renderDocumentUpload = () => {
    const getDocumentDescription = (docName) => {
      const descriptions = {
        'Business Tax Returns': 'Upload the last 3 years of business tax returns.',
        'Personal Financial Statement': 'Upload Personal Financial Statement for each guarantor.',
        'Bank Statements': 'Upload the last 3 months of business bank statements.',
        'Interim P&L': 'Upload your most recent interim profit and loss statement.',
        'Personal Tax Returns': 'Upload your personal tax return from 2023.',
        'Debt Schedule': 'Upload a detailed schedule of all business debt.',
      };
      return descriptions[docName] || '';
    };

    const renderDocumentStepIndicator = () => {
      const allDocumentsUploaded = requiredDocuments.every(doc => 
        doc.Labels.every(label => uploadedFiles[`${doc.Name}-${label}`])
      );

      if (allDocumentsUploaded) {
        return (
          <nav aria-label="Progress" className="mb-8">
            <div className="text-center py-6">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                {loanStatus === LoanStatuses.DOCUMENT_CLARIFICATION ? (
                  'Document Clarification'
                ) : prequalifyAmount ? (
                  <>You've been prequalified for {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0
                  }).format(prequalifyAmount)}</>
                ) : (
                  'Application Submitted'
                )}
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                {loanStatus === LoanStatuses.DOCUMENT_CLARIFICATION ? 
                  'Please check your email for questions' : 
                  prequalifyAmount ? 
                    'Underwriting will start in the next few days' : 
                    'Updates will be sent in 24-48 hours'
                }
              </p>
            </div>
          </nav>
        );
      }

      return (
        <nav aria-label="Progress" className="mb-8">
          <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
            {requiredDocuments.map((doc) => {
              const isUploaded = doc.Labels.every(label => 
                uploadedFiles[`${doc.Name}-${label}`]
              );
              
              return (
                <li key={doc.Name} className="md:flex-1">
                  <div className={classNames(
                    'flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4',
                    isUploaded ? 'border-green-500' : 'border-gray-200'
                  )}>
                    <span className={classNames(
                      'text-sm font-medium',
                      isUploaded ? 'text-green-500' : 'text-gray-500'
                    )}>
                      {doc.Name}
                    </span>
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      );
    };

    return (
      <div className="space-y-8">
        {renderDocumentStepIndicator()}
        
        {requiredDocuments.map((doc) => (
          <div key={doc.Name} className="bg-white">
            <div className="mb-4">
              <h3 className="text-lg font-medium text-gray-900">{doc.Name}</h3>
              <p className="mt-1 text-sm text-gray-500">
                {getDocumentDescription(doc.Name)}
              </p>
            </div>
            
            {doc.Labels.length > 1 ? (
              <div className="space-y-4">
                {doc.Labels.map((label) => {
                  const documentKey = `${doc.Name}-${label}`;
                  return renderDocumentCard(doc, label, documentKey);
                })}
              </div>
            ) : (
              renderDocumentCard(doc, doc.Labels[0], `${doc.Name}-${doc.Labels[0]}`)
            )}
          </div>
        ))}
      </div>
    );
  };

  const allDocumentsUploaded = requiredDocuments.every(doc => 
    doc.Labels.every(label => 
      doc.Individual 
        ? personalGuarantors.every(guarantor => 
            uploadedFiles[`${doc.Name}-${label}-${guarantor.firstName} ${guarantor.lastName}`]
          )
        : uploadedFiles[`${doc.Name}-${label}`]
    )
  );

  const person = {
    name: 'Max Krakow',
    role: 'Application Manager',
    title: 'CEO, Lended',
    email: 'max@lended.ai',
    telephone: '+1 (914) 319-7160',
    imageUrl: profileImage,
  };

  const renderTabs = () => {
    const allDocumentsUploaded = requiredDocuments.every(doc => 
      doc.Labels.every(label => uploadedFiles[`${doc.Name}-${label}`])
    );

    return (
      <div className="border-b border-gray-200 mb-6">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setCurrentTab('Questions')}
            className={classNames(
              currentTab === 'Questions'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm flex items-center'
            )}
          >
            Questions
            {showDocumentUpload && (
              <CheckIcon className="ml-2 h-4 w-4 text-green-500" aria-hidden="true" />
            )}
          </button>
          <button
            onClick={() => showDocumentUpload && setCurrentTab('Documents')}
            className={classNames(
              currentTab === 'Documents'
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500',
              'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm flex items-center',
              !showDocumentUpload && 'opacity-50 cursor-not-allowed'
            )}
          >
            Documents
            {allDocumentsUploaded && (
              <CheckIcon className="ml-2 h-4 w-4 text-green-500" aria-hidden="true" />
            )}
          </button>
        </nav>
      </div>
    );
  };

  const renderStepIndicator = () => (
    <nav aria-label="Progress" className="mb-8">
      <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
        {[
          'Ownership Information',
          'Personal Guarantors',
          'Business Information',
          'Loan Details',
          'Employee Count'
        ].map((stepName, index) => (
          <li key={stepName} className="md:flex-1">
            <div className={classNames(
              'flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4',
              step === index 
                ? 'border-indigo-600' 
                : step > index 
                  ? 'border-green-500'
                  : 'border-gray-200'
            )}>
              <span className={classNames(
                'text-sm font-medium',
                step === index 
                  ? 'text-indigo-600' 
                  : step > index 
                    ? 'text-green-500'
                    : 'text-gray-500'
              )}>
                Step {index + 1}
              </span>
              <span className="text-sm">{stepName}</span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );

  const renderMainContent = () => {
    return (
      <div className="min-h-full">
        <div className="bg-indigo-600 pb-32">
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">
                Loan Application
              </h1>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
              {/* Left column */}
              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                <section aria-labelledby="section-1-title">
                  <div className="overflow-hidden rounded-lg bg-white shadow">
                    <div className="p-6">
                      {renderTabs()}

                      {currentTab === 'Questions' ? (
                        renderQuestionsContent()
                      ) : (
                        renderDocumentUpload()
                      )}
                    </div>
                  </div>
                </section>
              </div>

              {/* Right column */}
              <div className="grid grid-cols-1 gap-4">
                <section aria-labelledby="section-2-title">
                  {renderApplicationSummary()}
                  {renderContactCard()}
                </section>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  };

  const renderQuestionsContent = () => {
    if (showDocumentUpload) {
      return (
        <div className="text-center py-12">
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <svg 
              className="h-6 w-6 text-green-600" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M5 13l4 4L19 7" 
              />
            </svg>
          </div>
          <h3 className="mt-4 text-lg font-medium text-gray-900">Questions Completed</h3>
          <p className="mt-2 text-sm text-gray-500">
            You have completed all the required questions. You can now proceed to upload your documents.
          </p>
          <div className="mt-6 flex justify-center space-x-4">
            <button
              onClick={() => setCurrentTab('Documents')}
              className="inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Go to Document Upload
              <svg 
                className="ml-2 -mr-0.5 h-4 w-4" 
                fill="none" 
                viewBox="0 0 24 24" 
                strokeWidth={2} 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  d="M8.25 4.5l7.5 7.5-7.5 7.5" 
                />
              </svg>
            </button>
            <button
              onClick={handleEditAnswers}
              className="inline-flex items-center rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Edit Answers
              <svg
                className="ml-2 -mr-0.5 h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>
            </button>
          </div>
        </div>
      );
    }

    return (
      <>
        {/* Progress Steps */}
        <div className="mb-8">
          <div className="flex justify-between">
            {[
              'Ownership Information',
              'Personal Guarantors',
              'Business Information',
              'Loan Details',
              'Employee Count'
            ].map((stepName, index) => (
              <div key={stepName} className="flex flex-col items-center">
                <div className={`h-2 w-full ${index <= step ? 'bg-green-500' : 'bg-gray-200'}`} />
                <div className="mt-2">
                  <span className={`text-sm ${step === index ? 'text-indigo-600 font-medium' : 'text-gray-500'}`}>
                    Step {index + 1}
                  </span>
                  <p className="text-sm text-gray-500">{stepName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Questions Form */}
        <form onSubmit={handleSubmit} className="space-y-6">
          {renderForm()}
          <div className="flex justify-between pt-6">
            {step > 0 && (
              <button
                type="button"
                onClick={handlePrevious}
                className="rounded-md bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Previous
              </button>
            )}
            {step < 4 ? (
              <button
                type="button"
                onClick={handleNext}
                className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              >
                Next
              </button>
            ) : (
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
              >
                Continue to Document Upload
              </button>
            )}
          </div>
        </form>
      </>
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e, docName, label) => {
    e.preventDefault();
    e.stopPropagation();
    
    const files = e.dataTransfer.files;
    if (files.length) {
      // Use the existing handleFileUpload function with the dropped file
      const event = { target: { files: [files[0]] } };
      await handleFileUpload(event, docName, label);
    }
  };

  const renderContactCard = () => {
    const person = {
      name: 'Max Krakow',
      title: 'CEO, Lended',
      role: 'Application Manager',
      email: 'max@lended.ai',
      telephone: '+1 (914) 319-7160',
      imageUrl: profileImage,
    };

    return (
      <div className="mt-4 col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="truncate text-sm font-medium text-gray-900">{person.name}</h3>
              <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                {person.role}
              </span>
            </div>
            <p className="mt-1 truncate text-sm text-gray-500">{person.title}</p>
          </div>
          <img 
            className="h-10 w-auto flex-shrink-0 object-contain" 
            src={person.imageUrl} 
            alt="" 
          />
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="flex w-0 flex-1">
              <a
                href={`mailto:${person.email}`}
                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              >
                <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                Email
              </a>
            </div>
            <div className="-ml-px flex w-0 flex-1">
              <a
                href={`tel:${person.telephone}`}
                className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
              >
                <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                Call
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-full">
      <div className="bg-indigo-600 pb-32">
        <header className="py-10">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-white">
              Loan Application
            </h1>
          </div>
        </header>
      </div>

      <main className="-mt-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            {/* Left column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              <section aria-labelledby="section-1-title">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    {renderTabs()}

                    {currentTab === 'Questions' ? (
                      renderQuestionsContent()
                    ) : (
                      renderDocumentUpload()
                    )}
                  </div>
                </div>
              </section>
            </div>

            {/* Right column */}
            <div className="grid grid-cols-1 gap-4">
              <section aria-labelledby="section-2-title">
                {renderApplicationSummary()}
                {renderContactCard()}
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default SharedUploadPage;


