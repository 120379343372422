import React, { useState, useCallback, useEffect } from 'react';
import { db } from '../../firebase';
import FinancialMetricsDisplayCustomer from './FinancialMetricsDisplayCustomer';
import { getAuth } from 'firebase/auth';
import { collection, query, where, doc, getDoc, updateDoc, setDoc, onSnapshot, getFirestore, getDocs, orderBy, limit, addDoc, serverTimestamp } from 'firebase/firestore';
import { motion, AnimatePresence } from 'framer-motion';

const ApplicationView = ({ loanId }) => {
  const [loanData, setLoanData] = useState(null);
  const [aiQuestions, setAiQuestions] = useState([
    {
      id: 1,
      question: "Can you provide more details about the recent increase in your business's operating expenses?",
      answered: false
    }
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [analysisQuestions, setAnalysisQuestions] = useState([]);
  const [questionAnswers, setQuestionAnswers] = useState({});
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [taxReturnsUploaded, setTaxReturnsUploaded] = useState({
    year1: false,
    year2: false,
    year3: false
  });
  const [analysisStarted, setAnalysisStarted] = useState(false);
  const [questionsLoaded, setQuestionsLoaded] = useState(false);
  const [ocrData, setOcrData] = useState({});
  const [loanSize, setLoanSize] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [personalFinancialData, setPersonalFinancialData] = useState([]);
  const [activeGuarantorIndex, setActiveGuarantorIndex] = useState(0);
  const [personalFinancialQuestions, setPersonalFinancialQuestions] = useState([]);
  const [personalQuestionAnswers, setPersonalQuestionAnswers] = useState({});
  const [personalCurrentQuestionIndex, setPersonalCurrentQuestionIndex] = useState(0);
  const [personalFinancialDocumentsUploaded, setPersonalFinancialDocumentsUploaded] = useState(false);
  const [personalFinancialQuestionsCreated, setPersonalFinancialQuestionsCreated] = useState(false);
  const [savedAnswers, setSavedAnswers] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [taxReturnsProcessed, setTaxReturnsProcessed] = useState(false);
  const [documentsSubmitted, setDocumentsSubmitted] = useState(false);
  const [financialSummary, setFinancialSummary] = useState(null);
  const [applicationStatus, setApplicationStatus] = useState('answering_questions');
  const [hasSavedAnswers, setHasSavedAnswers] = useState(false);
  const [hasTermSheets, setHasTermSheets] = useState(false);

  const auth = getAuth();

  const nextQuestion = () => {
    if (currentQuestionIndex < analysisQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const prevQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const checkForTermSheets = async () => {
    try {
      const db = getFirestore();
      const termSheetsQuery = query(collection(db, 'termSheets'), where('loanApplicationId', '==', loanId));
      const termSheetsSnapshot = await getDocs(termSheetsQuery);
      setHasTermSheets(!termSheetsSnapshot.empty);
    } catch (error) {
      console.error('Error checking for term sheets:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!loanId) {
        setError("No loan ID provided");
        setLoading(false);
        return;
      }

      try {
        // Fetch loan application data
        const loanDocRef = doc(db, 'loanApplications', loanId);
        const loanDocSnap = await getDoc(loanDocRef);
        
        if (loanDocSnap.exists()) {
          const loanData = loanDocSnap.data();
          setLoanData(loanData);
          setLoanSize(loanData.loanSize);
        } else {
          setError("Loan application not found");
          setLoading(false);
          return;
        }

        // Fetch OCR data
        const ocrMetadataRef = collection(db, 'OCRMetadata');
        const q = query(ocrMetadataRef, where('loanApplicationId', '==', loanId));
        const querySnapshot = await getDocs(q);
        
        const processedData = {};
        let personalFinancialOCRs = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.documentName === "Business Tax Returns") {
            const year = getYearFromLabel(data.label);
            processedData[year] = {
              ordinaryBusinessIncome: parseFloat(data.ocrResult?.ordinary_business_income) || 0,
              grossRevenue: parseFloat(data.ocrResult?.gross_receipts_or_sales) || 0,
              officerCompensation: parseFloat(data.ocrResult?.compensation_of_officers) || 0,
              interest: parseFloat(data.ocrResult?.interest) || 0,
              depreciation: parseFloat(data.ocrResult?.depreciation) || 0,
              otherDeductions: data.ocrResult?.other_deductions || {},
              year: year,
            };
          } else if (data.documentName === "Personal Financial Statement") {
            personalFinancialOCRs.push(data.ocrResult);
          }
        });

        setOcrData(processedData);
        setPersonalFinancialData(personalFinancialOCRs);
        setLoading(false);
        setDataLoaded(true);

        // Check if tax returns have been processed
        const taxReturnsProcessed = Object.keys(processedData).length >= 3; // Assuming we need 3 years of tax returns
        setTaxReturnsProcessed(taxReturnsProcessed);

        // Check if any documents have been submitted
        const hasSubmittedDocuments = querySnapshot.size > 0;
        setDocumentsSubmitted(hasSubmittedDocuments);

        // Check for term sheets
        await checkForTermSheets();
      } catch (error) {
        console.error('Error fetching data:', error);
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchData();
  }, [loanId]);

  useEffect(() => {
    if (loanId) {
      const loanDocRef = doc(db, 'loanApplications', loanId);
      const unsubscribe = onSnapshot(loanDocRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setLoanData(data);
          setApplicationStatus(data.status || 'answering_questions');
        }
      });

      return () => unsubscribe();
    }
  }, [loanId]);

  useEffect(() => {
    if (dataLoaded) {
      loadQuestions();
      loadPersonalFinancialQuestions();
      fetchSavedAnswers();
    }
  }, [dataLoaded]);

  useEffect(() => {
    if (dataLoaded && personalFinancialDocumentsUploaded && !personalFinancialQuestionsCreated) {
      createPersonalFinancialQuestions();
    }
  }, [dataLoaded, personalFinancialDocumentsUploaded, personalFinancialQuestionsCreated]);

  useEffect(() => {
    if (loanData && loanData.loanPurpose !== 'workingCapital' && loanData.financialAnalysis && !questionsLoaded) {
      analyzeFinancials();
    }
  }, [loanData, questionsLoaded]);

  const getYearFromLabel = (label) => {
    const yearLabels = {
      'Business Tax Return - Current Year': '2023',
      'Business Tax Return - Last Year': '2022',
      'Business Tax Return - 2 Years Ago': '2021'
    };
    return yearLabels[label] || label;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  };

  const renderPersonalFinancialInfo = () => {
    if (personalFinancialData.length === 0 || !loanData || !loanData.personalGuarantors) {
      return <div>No personal financial data available.</div>;
    }

    const renderFinancialItem = (label, value, isTotal = false) => (
      <div className={`flex justify-between items-center py-2 ${isTotal ? 'font-medium' : ''}`}>
        <span className="text-gray-500 text-sm">{label}</span>
        <span className={`${isTotal ? 'text-base text-blue-600' : 'text-sm'}`}>{formatCurrency(value || 0)}</span>
      </div>
    );

    const renderGuarantorData = (guarantorData) => {
      const { FullName, Assets, Liabilities } = guarantorData;

      const calculateTotal = (obj) => Object.entries(obj).reduce((sum, [key, value]) => {
        if (!['total', 'totalliabilities', 'networth', 'monthlypayments', 'monthlypaymentauto', 'monthlypaymentother'].includes(key.toLowerCase())) {
          return sum + (parseFloat(value) || 0);
        }
        return sum;
      }, 0);

      const totalAssets = calculateTotal(Assets);
      const totalLiabilities = calculateTotal(Liabilities);
      const netWorth = totalAssets - totalLiabilities;

      return (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <h5 className="text-sm font-medium text-gray-500 uppercase tracking-wider">Assets</h5>
              <div className="space-y-2">
                {Object.entries(Assets).map(([key, value]) => (
                  !['total', 'totalliabilities', 'networth'].includes(key.toLowerCase()) && 
                  renderFinancialItem(key.replace(/([A-Z])/g, ' $1').trim(), value)
                ))}
              </div>
              <div className="pt-2 border-t border-gray-100">
                {renderFinancialItem("Total Assets", totalAssets, true)}
              </div>
            </div>
            <div className="space-y-4">
              <h5 className="text-sm font-medium text-gray-500 uppercase tracking-wider">Liabilities</h5>
              <div className="space-y-2">
                {Object.entries(Liabilities).map(([key, value]) => {
                  const lowerKey = key.toLowerCase();
                  if (!['total', 'totalliabilities', 'networth'].includes(lowerKey)) {
                    if (['monthlypayments', 'monthlypaymentauto', 'monthlypaymentother'].includes(lowerKey)) {
                      return renderFinancialItem(key.replace(/([A-Z])/g, ' $1').trim() + ' (Monthly)', value);
                    }
                    return renderFinancialItem(key.replace(/([A-Z])/g, ' $1').trim(), value);
                  }
                  return null;
                })}
              </div>
              <div className="pt-2 border-t border-gray-100">
                {renderFinancialItem("Total Liabilities", totalLiabilities, true)}
              </div>
            </div>
          </div>
          <div className="mt-6 pt-4 border-t border-gray-200">
            {renderFinancialItem("Net Worth", netWorth, true)}
          </div>
        </>
      );
    };

    return (
      <div className="mt-6 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Personal Finances</h3>
          
          {/* Tabs for multiple guarantors */}
          <div className="mb-4 border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {loanData.personalGuarantors.map((guarantor, index) => (
                <button
                  key={index}
                  onClick={() => setActiveGuarantorIndex(index)}
                  className={`${
                    index === activeGuarantorIndex
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                >
                  {`${guarantor.firstName} ${guarantor.lastName}`}
                </button>
              ))}
            </nav>
          </div>

          {/* Render active guarantor's data */}
          {personalFinancialData[activeGuarantorIndex] && renderGuarantorData(personalFinancialData[activeGuarantorIndex])}
        </div>
      </div>
    );
  };

  const loadQuestions = async () => {
    const storedQuestions = localStorage.getItem(`questions_${loanId}`);
    if (storedQuestions) {
      const parsedQuestions = JSON.parse(storedQuestions);
      setAnalysisQuestions(parsedQuestions);
      const initialAnswers = parsedQuestions.reduce((acc, q) => {
        acc[q.id] = q.answer || '';
        return acc;
      }, {});
      setQuestionAnswers(initialAnswers);
      setQuestionsLoaded(true);
    } else {
      await fetchQuestionsFromFirestore();
    }
  };

  const fetchQuestionsFromFirestore = async () => {
    const db = getFirestore();
    const financialAnalysesRef = collection(db, 'FinancialAnalyses');
    const q = query(
      financialAnalysesRef,
      where('prequalId', '==', loanId)
    );

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const sortedDocs = querySnapshot.docs.sort((a, b) => b.data().createdAt.toMillis() - a.data().createdAt.toMillis());
        const doc = sortedDocs[0];
        const data = doc.data();
        if (data.questions && Array.isArray(data.questions)) {
          const formattedQuestions = data.questions.map((q, index) => ({
            id: index,
            question: q.question,
            answer: q.response || ''
          }));
          setAnalysisQuestions(formattedQuestions);
          const initialAnswers = formattedQuestions.reduce((acc, q) => {
            acc[q.id] = q.answer;
            return acc;
          }, {});
          setQuestionAnswers(initialAnswers);
          localStorage.setItem(`questions_${loanId}`, JSON.stringify(formattedQuestions));
          setQuestionsLoaded(true);
        } else {
          console.error('Unexpected data format in Firestore:', data);
          setAnalysisQuestions([]);
        }
      } else {
        console.log('No questions found. Initiating financial analysis...');
        await analyzeFinancials();
      }
    } catch (error) {
      console.error('Error fetching questions from Firestore:', error);
      setAnalysisQuestions([]);
    }
  };

  const fetchSavedAnswers = async () => {
    try {
      const db = getFirestore();
      const savedAnswersRef = doc(db, 'SavedAnswers', loanId);
      const savedAnswersDoc = await getDoc(savedAnswersRef);
  
      if (savedAnswersDoc.exists()) {
        const data = savedAnswersDoc.data();
        setSavedAnswers(data.questions);
        setHasSavedAnswers(true);
        
        // Populate the answers in the state
        const businessAnswers = {};
        const personalAnswers = {};
        
        data.questions.forEach((q, index) => {
          if (q.type === 'business') {
            businessAnswers[index] = q.response;
          } else {
            personalAnswers[index] = q.response;
          }
        });
        
        setQuestionAnswers(businessAnswers);
        setPersonalQuestionAnswers(personalAnswers);
      } else {
        console.log('No saved answers found');
        setHasSavedAnswers(false);
      }
    } catch (error) {
      console.error('Error fetching saved answers:', error);
      setHasSavedAnswers(false);
    }
  };

  const analyzeFinancials = async () => {
    setIsAnalyzing(true);
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.error('No user logged in');
      setIsAnalyzing(false);
      return;
    }

    if (!loanSize || !loanData) {
      console.error('Loan data not available');
      setIsAnalyzing(false);
      return;
    }

    // Check if financialAnalysis exists for business acquisitions
    if (loanData.loanPurpose !== 'workingCapital' && !loanData.financialAnalysis) {
      console.log('Waiting for financial analysis data...');
      setIsAnalyzing(false);
      return;
    }

    try {
      const token = await user.getIdToken();

      let requestBody;
      if (loanData.loanPurpose === 'workingCapital') {
        requestBody = {
          loanId: loanId,
          loanPurpose: loanData.loanPurpose,
          businessDescription: loanData.businessDescription,
          financialData: ocrData,
          workingCapitalUse: loanData.workingCapitalUse,
          loanSize: Number(loanSize)
        };
      } else {
        requestBody = {
          prequalId: loanId,
          loanPurpose: loanData.loanPurpose,
          businessDescription: loanData.businessSummary,
          financialData: ocrData,
          financialAnalysis: loanData.financialAnalysis, // Include financialAnalysis for business acquisitions
          loanSize: Number(loanSize),
          loanStructure: loanData.loanStructure,
          businessAge: loanData.businessAge, // "7"
          businessState: loanData.businessState, // "CA"
        };
      }

      console.log('Analyze Financials Request body:', JSON.stringify(requestBody));

      const response = await fetch('https://sba-30d9855b23ef.herokuapp.com/analyze-financials', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // After analysis, fetch the newly created questions
      await fetchQuestionsFromFirestore();
    } catch (error) {
      console.error('Error analyzing financials:', error);
      setAnalysisQuestions([]);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleAnswerChange = (id, answer) => {
    setQuestionAnswers(prev => {
      const newAnswers = { ...prev, [id]: answer };
      // Update local storage with new answers
      const updatedQuestions = analysisQuestions.map(q => ({
        ...q,
        answer: newAnswers[q.id] || ''
      }));
      localStorage.setItem(`questions_${loanId}`, JSON.stringify(updatedQuestions));
      return newAnswers;
    });
  };

  const handlePersonalAnswerChange = (index, answer) => {
    setPersonalQuestionAnswers(prev => ({
      ...prev,
      [index]: answer
    }));
  };

  const submitAllAnswers = async () => {
    console.log('Submitting all answers:');
    console.log('Business Financial Answers:', questionAnswers);
    console.log('Personal Financial Answers:', personalQuestionAnswers);
    
    try {
      const db = getFirestore();
      const auth = getAuth();
      
      if (!auth.currentUser) {
        throw new Error('No authenticated user found');
      }
  
      // Combine business and personal questions with answers
      const businessQuestions = analysisQuestions.map((q, index) => ({
        question: q.question,
        response: questionAnswers[index] || '',
        type: 'business'
      }));
  
      const personalQuestions = personalFinancialQuestions.map((q, index) => ({
        question: q.question,
        response: personalQuestionAnswers[index] || '',
        type: 'personal'
      }));
  
      const allQuestions = [...businessQuestions, ...personalQuestions];
  
      // Create or update document in SavedAnswers collection
      const savedAnswersRef = doc(db, 'SavedAnswers', loanId);
      const existingSavedAnswers = savedAnswers || [];
      const updatedQuestions = [...existingSavedAnswers];

      allQuestions.forEach((newQuestion) => {
        const existingIndex = updatedQuestions.findIndex(q => q.question === newQuestion.question);
        if (existingIndex !== -1) {
          updatedQuestions[existingIndex] = newQuestion;
        } else {
          updatedQuestions.push(newQuestion);
        }
      });

      const dataToSave = {
        prequalId: loanId,
        questions: updatedQuestions,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        userId: auth.currentUser.uid
      };
  
      console.log('Attempting to save data:', dataToSave);
  
      await setDoc(savedAnswersRef, dataToSave, { merge: true });
  
      setHasSavedAnswers(true);
      
      // Update application status
      const newStatus = 'under_review';
      setApplicationStatus(newStatus);

      // Update the loan application status in Firestore
      const loanDocRef = doc(db, 'loanApplications', loanId);
      await updateDoc(loanDocRef, {
        status: newStatus,
        submittedAt: serverTimestamp()
      });

      console.log('All answers saved successfully');
      setShowNotification(true);
    } catch (error) {
      console.error('Error saving answers:', error);
      if (error.code === 'permission-denied') {
        console.error('Permission denied. Please check Firestore security rules.');
      }
    }
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const renderQuestionSection = (questions, currentIndex, answers, handleAnswerChange, prevQuestion, nextQuestion) => {
    if (questions.length === 0) {
      return (
        <div className="text-center text-gray-500">
          <p>No questions available.</p>
        </div>
      );
    }
    
    const currentQuestion = questions[currentIndex];
    const savedAnswer = savedAnswers ? savedAnswers.find(q => q.question === currentQuestion.question) : null;
    
    return (
      <div>
        <div className="mb-4 flex flex-col">
          <span className="text-sm text-gray-500 mt-1">
            Question {currentIndex + 1} of {questions.length}
          </span>
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-lg shadow-md border border-gray-200 overflow-hidden"
          >
            <div className="bg-gray-50 px-4 py-3 border-b border-gray-200">
              <span className="text-sm font-medium text-gray-800">
                {questions[currentIndex].question}
              </span>
            </div>
            <div className="p-4">
              <textarea
                className="w-full p-2 border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out"
                rows="3"
                value={answers[currentIndex] || (savedAnswer ? savedAnswer.response : '') || ''}
                onChange={(e) => handleAnswerChange(currentIndex, e.target.value)}
                placeholder="Type your response here..."
              ></textarea>
            </div>
          </motion.div>
        </AnimatePresence>
        <div className="mt-6 flex justify-between items-center">
        <button
          onClick={prevQuestion}
          className="flex items-center px-4 py-2 bg-white text-blue-600 rounded-md border border-blue-600 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
          disabled={currentIndex === 0}
        >
          <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
          Previous
        </button>
        <button
          onClick={nextQuestion}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
          disabled={currentIndex === questions.length - 1}
        >
          Next
          <svg className="w-5 h-5 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
    </div>
  );
};

  const renderProgressIndicator = () => {
    const steps = [
      { key: 'answering_questions', label: 'Answer Questions', icon: 'M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z' },
      { key: 'under_review', label: 'Under Review', icon: 'M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z' },
      { key: 'approved', label: 'Approved', icon: 'M5 13l4 4L19 7' }
    ];

    let currentStatus;
    if (hasTermSheets) {
      currentStatus = 'approved';
    } else if (hasSavedAnswers) {
      currentStatus = 'under_review';
    } else {
      currentStatus = 'answering_questions';
    }

    const currentStepIndex = steps.findIndex(s => s.key === currentStatus);

    return (
      <div className="mb-6 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex flex-col mb-4">
            <h3 className="text-lg font-medium text-gray-900">Application Progress</h3>
          </div>
          <div className="relative">
            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
              <div style={{ width: `${(currentStepIndex + 1) / steps.length * 100}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"></div>
            </div>
            <div className="flex justify-between">
              {steps.map((step, index) => (
                <div key={step.key} className="flex flex-col items-center">
                  <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                    index <= currentStepIndex ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-400'
                  }`}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={step.icon} />
                    </svg>
                  </div>
                  <div className={`text-xs mt-2 text-center ${
                    index <= currentStepIndex ? 'text-blue-600 font-medium' : 'text-gray-500'
                  }`}>
                    {step.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-4 text-sm text-gray-600">
            {currentStatus === 'answering_questions' && 'Once you submit answers to all questions, your application will be sent for review.'}
            {currentStatus === 'under_review' && 'Your application has been submitted and is now under review. Term sheets will be available in the term sheet tab.'}
            {currentStatus === 'approved' && 'Congratulations! Your application has a term sheet available.'}
          </div>
        </div>
      </div>
    );
  };

  const renderCommandCenter = () => {
    if (!taxReturnsProcessed) {
      return (
        <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200 relative">
          <div className="px-6 py-8 sm:px-8 sm:py-10 bg-gradient-to-r from-blue-50 to-indigo-50">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-2xl font-bold text-gray-900 flex items-center">
                <svg className="w-8 h-8 mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
                AI Insights
              </h3>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                Coming Soon
              </span>
            </div>
            <p className="text-sm text-gray-600 ml-11">Powered by advanced analytics</p>
          </div>
          <div className="px-6 py-8 sm:px-8 sm:py-10 text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Waiting for Tax Returns</h3>
            <p className="mt-1 text-sm text-gray-500">
              We're currently processing your tax returns. AI insights will be available once the processing is complete.
            </p>
          </div>
        </div>
      );
    }

    const getQuestionCategory = (question) => {
      if (question.category) return question.category;
      if (question.question.toLowerCase().includes('tax')) return 'Business Tax Returns';
      if (question.question.toLowerCase().includes('personal') || question.question.toLowerCase().includes('individual')) return 'Personal Financial Statement';
      return 'Business Financials';
    };
    return (
      <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200 relative">
        {/* AI Insights Header */}
        <div className="px-6 py-8 sm:px-8 sm:py-10 bg-gradient-to-r from-blue-50 to-indigo-50">
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-2xl font-bold text-gray-900 flex items-center">
              <svg className="w-8 h-8 mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
              </svg>
              AI Insights
            </h3>
            <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
              Beta
            </span>
          </div>
          <p className="text-sm text-gray-600 ml-11">Powered by advanced analytics</p>
        </div>
              {/* Business Financial Questions Section */}
      <div className="px-6 py-6 sm:px-8 sm:py-8">
        <h4 className="text-lg font-semibold text-gray-700 mb-4">Business Financial Questions</h4>
        {renderQuestionSection(analysisQuestions, currentQuestionIndex, questionAnswers, handleAnswerChange, prevQuestion, nextQuestion)}
      </div>
      {/* Personal Financial Questions Section */}
      <div className="px-6 py-6 sm:px-8 sm:py-8 border-t border-gray-200">
        <h4 className="text-lg font-semibold text-gray-700 mb-4">Personal Financial Questions</h4>
        {renderQuestionSection(personalFinancialQuestions, personalCurrentQuestionIndex, personalQuestionAnswers, handlePersonalAnswerChange, prevPersonalQuestion, nextPersonalQuestion)}
      </div>

      {/* Submit Button */}
      <div className="px-6 py-6 sm:px-8 sm:py-8 border-t border-gray-200">
        <button
          onClick={submitAllAnswers}
          className="w-full px-4 py-3 bg-green-500 text-white text-sm font-medium rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5 shadow-md hover:shadow-lg"
        >
          <span className="flex items-center justify-center">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
            Submit All Responses
          </span>
        </button>
      </div>
    </div>
  );
};

        {/* Business Financial Questions Section */}
        <div className="px-6 py-6 sm:px-8 sm:py-8">
          <h4 className="text-lg font-semibold text-gray-700 mb-4">Business Financial Questions</h4>
          {renderQuestionSection(analysisQuestions, currentQuestionIndex, questionAnswers, handleAnswerChange, prevQuestion, nextQuestion)}
        </div>
  
  const renderCapTable = () => {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Cap Table</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Percentage</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {loanData.capTable.map((owner, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{owner.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">{owner.percentage}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderLoanStructure = () => {
    const loanStructure = loanData.loanStructure || {};

    const categories = [
      { label: "SBA 7(a) Loan", key: "sba7aLoan", color: "bg-blue-500" },
      { label: "Equity Injection", key: "equityInjection", color: "bg-yellow-500" },
      { label: "Seller's Note", key: "sellersNote", color: "bg-green-500" },
    ];

    const totalPercentage = categories.reduce((sum, category) => {
      const value = category.key === "equityInjection"
        ? parseFloat(loanStructure.equityInjection?.personal) || 0
        : parseFloat(loanStructure[category.key]) || 0;
      return sum + value;
    }, 0);

    const totalAmount = loanData.loanSize || 0;

    const renderBar = (category) => {
      let percentage = 0;
      let amount = 0;

      if (category.key === "equityInjection") {
        percentage = parseFloat(loanStructure.equityInjection?.personal) || 0;
      } else {
        percentage = parseFloat(loanStructure[category.key]) || 0;
      }

      amount = (totalAmount * percentage / 100) || 0;

      if (percentage === 0) return null;

      const barWidth = (percentage / totalPercentage) * 100;

      return (
        <div 
          key={category.key}
          style={{ width: `${barWidth}%` }} 
          className={`h-full ${category.color} relative group`}
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-xs font-medium text-white">{percentage.toFixed(1)}%</span>
          </div>
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
            {category.label}: {formatCurrency(amount)}
          </div>
        </div>
      );
    };

    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden mb-6">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Loan Structure</h3>
          <div className="h-10 flex rounded-lg overflow-hidden">
            {categories.map(renderBar)}
          </div>
          <div className="mt-4 flex flex-wrap justify-between text-xs text-gray-500">
            {categories.map(category => {
              let percentage = 0;
              let amount = 0;

              if (category.key === "equityInjection") {
                percentage = parseFloat(loanStructure.equityInjection?.personal) || 0;
              } else {
                percentage = parseFloat(loanStructure[category.key]) || 0;
              }

              amount = (totalAmount * percentage / 100) || 0;

              if (percentage === 0) return null;

              return (
                <div key={category.key} className="flex items-center mb-2 mr-4">
                  <div className={`w-3 h-3 ${category.color} rounded-full mr-1`}></div>
                  <span>{category.label}: {percentage.toFixed(1)}% ({formatCurrency(amount)})</span>
                </div>
              );
            })}
          </div>
          <div className="mt-4 text-sm font-medium text-gray-700">
            Total Amount: {formatCurrency(totalAmount)}
          </div>
        </div>
      </div>
    );
  };

  const loadPersonalFinancialQuestions = async () => {
    console.log("Loading personal financial questions...");
    const db = getFirestore();
    const personalFinancialAnalysesRef = collection(db, 'PersonalFinancialAnalyses');
    const q = query(
      personalFinancialAnalysesRef,
      where('prequalId', '==', loanId),
      limit(1)
    );

    try {
      const querySnapshot = await getDocs(q);
      console.log("Query snapshot:", querySnapshot);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        console.log("Personal financial data:", data);
        if (data.questions && Array.isArray(data.questions)) {
          console.log("Setting personal financial questions:", data.questions);
          setPersonalFinancialQuestions(data.questions);
          const initialAnswers = data.questions.reduce((acc, q, index) => {
            acc[index] = q.response || '';
            return acc;
          }, {});
          setPersonalQuestionAnswers(initialAnswers);
        } else {
          console.log("No questions array found in the data");
          setPersonalFinancialQuestions([]);
        }
      } else {
        console.log("No matching documents found");
        setPersonalFinancialQuestions([]);
      }
    } catch (error) {
      console.error('Error fetching personal financial questions:', error);
      setPersonalFinancialQuestions([]);
    }
  };

  const nextPersonalQuestion = () => {
    if (personalCurrentQuestionIndex < personalFinancialQuestions.length - 1) {
      setPersonalCurrentQuestionIndex(personalCurrentQuestionIndex + 1);
    }
  };

  const prevPersonalQuestion = () => {
    if (personalCurrentQuestionIndex > 0) {
      setPersonalCurrentQuestionIndex(personalCurrentQuestionIndex - 1);
    }
  };

  const createPersonalFinancialQuestions = async () => {
    if (!loanData || !personalFinancialData || personalFinancialData.length === 0) {
      console.error('Loan data or personal financial data not available');
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
        console.error('No user logged in');
        return;
      }

      // Check if questions already exist
      const db = getFirestore();
      const personalFinancialAnalysesRef = collection(db, 'PersonalFinancialAnalyses');
      const q = query(
        personalFinancialAnalysesRef,
        where('prequalId', '==', loanId),
        limit(1)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log('Personal financial questions already exist');
        setPersonalFinancialQuestionsCreated(true);
        return;
      }

      const token = await user.getIdToken();

      let requestBody;
      if (loanData.loanPurpose === 'workingCapital') {
        requestBody = {
          loanId: loanId,
          loanPurpose: loanData.loanPurpose,
          personalFinancialData: personalFinancialData,
          workingCapitalUse: loanData.workingCapitalUse,
          loanSize: loanData.loanSize
        };
      } else {
        requestBody = {
          prequalId: loanId,
          loanPurpose: loanData.loanPurpose,
          personalFinancialData: personalFinancialData,
          loanSize: loanData.loanSize,
          loanStructure: loanData.loanStructure
        };
      }

      console.log('Request body:', JSON.stringify(requestBody));

      const response = await fetch('https://sba-30d9855b23ef.herokuapp.com/analyze-personal-financials', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      // Save the generated questions to Firestore
      await addDoc(personalFinancialAnalysesRef, {
        prequalId: loanId,
        questions: result.questions,
        createdAt: serverTimestamp()
      });

      setPersonalFinancialQuestionsCreated(true);
      // Load the newly created questions
      await loadPersonalFinancialQuestions();
    } catch (error) {
      console.error('Error creating personal financial questions:', error);
    }
  };
  const NotificationModal = () => (
    <AnimatePresence>
      {showNotification && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={closeNotification}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="bg-white rounded-lg p-6 max-w-sm mx-auto relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={closeNotification}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h3 className="text-lg font-medium text-gray-900 mb-2">Success!</h3>
            <p className="text-gray-600">Your answers have been submitted successfully.</p>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
  const checkPersonalFinancialDocuments = () => {
    // This function should be called whenever a new document is uploaded
    // It should check if all required personal financial documents are uploaded
    // For this example, we'll assume all documents are uploaded when personalFinancialData is not empty
    if (personalFinancialData && personalFinancialData.length > 0) {
      setPersonalFinancialDocumentsUploaded(true);
    }
  };
  const handleFinancialDataCalculated = useCallback((newFinancialSummary) => {
    setFinancialSummary(newFinancialSummary);
  }, []);

  useEffect(() => {
    if (financialSummary && loanId) {
      const saveFinancialData = async () => {
        try {
          const loanDocRef = doc(db, 'loanApplications', loanId);
          const financialAnalysis = financialSummary.dscr.reduce((acc, item) => {
            acc[`Business Tax Return - ${item.year}`] = {
              dscr: item.value,
              sde: financialSummary.sde.find(sde => sde.year === item.year)?.value || 0,
              noi: financialSummary.noi.find(noi => noi.year === item.year)?.value || 0,
              addBacks: financialSummary.addBacks.map(addBack => ({
                name: addBack.name,
                value: addBack.values.find(v => v.year === item.year)?.value || 0
              }))
            };
            return acc;
          }, {});

          await updateDoc(loanDocRef, {
            financialAnalysis: financialAnalysis
          });
          console.log('Financial data saved to loan application');
        } catch (error) {
          console.error('Error saving financial data to loan application:', error);
        }
      };

      saveFinancialData();
    }
  }, [financialSummary, loanId]);

  useEffect(() => {
    if (loanId) {
      const loanDocRef = doc(db, 'loanApplications', loanId);
      const unsubscribe = onSnapshot(loanDocRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setLoanData(data);
          setApplicationStatus(data.status || 'answering_questions');
        }
      });

      return () => unsubscribe();
    }
  }, [loanId]);

  useEffect(() => {
    checkPersonalFinancialDocuments();
  }, [personalFinancialData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!loanData) {
    return <div>No loan application found</div>;
  }

  if (!documentsSubmitted) {
    return (
      <div className="px-4 py-5 sm:p-6">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200">
          <div className="px-6 py-8 sm:px-8 sm:py-10 bg-gradient-to-r from-blue-50 to-indigo-50">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-2xl font-bold text-gray-900 flex items-center">
                <svg className="w-8 h-8 mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
                Application View
              </h3>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                Pending
              </span>
            </div>
            <p className="text-sm text-gray-600 ml-11">Waiting for document submission</p>
          </div>
          <div className="px-6 py-8 sm:px-8 sm:py-10 text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No Documents Submitted</h3>
            <p className="mt-1 text-sm text-gray-500">
              Please submit your tax returns and other required documents to proceed with the application.
            </p>
            <div className="mt-6">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Upload Documents
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!taxReturnsProcessed) {
    return (
      <div className="px-4 py-5 sm:p-6">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200">
          <div className="px-6 py-8 sm:px-8 sm:py-10 bg-gradient-to-r from-blue-50 to-indigo-50">
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-2xl font-bold text-gray-900 flex items-center">
                <svg className="w-8 h-8 mr-3 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                </svg>
                Application View
              </h3>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
                Processing
              </span>
            </div>
            <p className="text-sm text-gray-600 ml-11">Powered by advanced analytics</p>
          </div>
          <div className="px-6 py-8 sm:px-8 sm:py-10 text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Processing Tax Returns</h3>
            <p className="mt-1 text-sm text-gray-500">
              We're currently processing your tax returns. The full application view will be available once the processing is complete.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 py-5 sm:p-6">
      {renderProgressIndicator()}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          {renderLoanStructure()}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Financial Information</h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-6">
              <FinancialMetricsDisplayCustomer 
                loanId={loanId}
                onFinancialDataCalculated={handleFinancialDataCalculated}
              />
              {renderPersonalFinancialInfo()}
            </div>
          </div>
          {renderCapTable()}
        </div>
        <div className="lg:col-span-1">
          {renderCommandCenter()}
        </div>
      </div>
      <NotificationModal />
    </div>
  );
};

export default ApplicationView;
