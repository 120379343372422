import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, doc, updateDoc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { db, auth } from '../../firebase';
import Select from 'react-select';
import NaicsSearch from '../loanForm/NaicsSearch';
import { motion, AnimatePresence } from 'framer-motion';

const BankForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [applicationData, setApplicationData] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [accountCreated, setAccountCreated] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [docRef, setDocRef] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);

  const formFields = [
    { name: 'bankName', type: 'text', label: 'Bank Name', component: 'input', required: true },
    { name: 'number', type: 'tel', label: 'Phone Number', component: 'input', required: true },
    { name: 'email', type: 'email', label: 'Email address', component: 'input', required: true },
    { name: 'loanOfficerName', type: 'text', label: 'Loan Officer Name', component: 'input', required: true },
    { name: 'usualLoanRate', type: 'group', label: 'Usual Loan Rate Range', component: 'group', fields: [
      { name: 'usualLoanRateMin', type: 'select', label: 'Minimum Rate', component: 'select', options: [
        { value: 0, label: 'Prime' },
        { value: 0.25, label: 'Prime + 0.25' },
        { value: 0.5, label: 'Prime + 0.5' },
        { value: 0.75, label: 'Prime + 0.75' },
        { value: 1, label: 'Prime + 1' },
        { value: 1.25, label: 'Prime + 1.25' },
        { value: 1.5, label: 'Prime + 1.5' },
        { value: 1.75, label: 'Prime + 1.75' },
        { value: 2, label: 'Prime + 2' },
        { value: 2.25, label: 'Prime + 2.25' },
        { value: 2.5, label: 'Prime + 2.5' },
        { value: 2.75, label: 'Prime + 2.75' },
        { value: 3, label: 'Prime + 3' }
      ], required: true },
      { name: 'usualLoanRateMax', type: 'select', label: 'Maximum Rate', component: 'select', options: [
        { value: 0, label: 'Prime' },
        { value: 0.25, label: 'Prime + 0.25' },
        { value: 0.5, label: 'Prime + 0.5' },
        { value: 0.75, label: 'Prime + 0.75' },
        { value: 1, label: 'Prime + 1' },
        { value: 1.25, label: 'Prime + 1.25' },
        { value: 1.5, label: 'Prime + 1.5' },
        { value: 1.75, label: 'Prime + 1.75' },
        { value: 2, label: 'Prime + 2' },
        { value: 2.25, label: 'Prime + 2.25' },
        { value: 2.5, label: 'Prime + 2.5' },
        { value: 2.75, label: 'Prime + 2.75' },
        { value: 3, label: 'Prime + 3' }
      ], required: true },
    ]},
    { name: 'pariPassu', type: 'select', label: 'Does your bank do pari passu?', component: 'select', options: [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' }
    ], required: true },
    { name: 'state', type: 'multiselect', label: 'States Served', component: 'multiselect', options: [
      { value: 'All States', label: 'All States' },
      { value: 'Northeast', label: 'Northeast' },
      { value: 'Midwest', label: 'Midwest' },
      { value: 'South', label: 'South' },
      { value: 'West', label: 'West' },
      { value: 'AL', label: 'Alabama' },
      { value: 'AK', label: 'Alaska' },
      { value: 'AZ', label: 'Arizona' },
      { value: 'AR', label: 'Arkansas' },
      { value: 'CA', label: 'California' },
      { value: 'CO', label: 'Colorado' },
      { value: 'CT', label: 'Connecticut' },
      { value: 'DE', label: 'Delaware' },
      { value: 'FL', label: 'Florida' },
      { value: 'GA', label: 'Georgia' },
      { value: 'HI', label: 'Hawaii' },
      { value: 'ID', label: 'Idaho' },
      { value: 'IL', label: 'Illinois' },
      { value: 'IN', label: 'Indiana' },
      { value: 'IA', label: 'Iowa' },
      { value: 'KS', label: 'Kansas' },
      { value: 'KY', label: 'Kentucky' },
      { value: 'LA', label: 'Louisiana' },
      { value: 'ME', label: 'Maine' },
      { value: 'MD', label: 'Maryland' },
      { value: 'MA', label: 'Massachusetts' },
      { value: 'MI', label: 'Michigan' },
      { value: 'MN', label: 'Minnesota' },
      { value: 'MS', label: 'Mississippi' },
      { value: 'MO', label: 'Missouri' },
      { value: 'MT', label: 'Montana' },
      { value: 'NE', label: 'Nebraska' },
      { value: 'NV', label: 'Nevada' },
      { value: 'NH', label: 'New Hampshire' },
      { value: 'NJ', label: 'New Jersey' },
      { value: 'NM', label: 'New Mexico' },
      { value: 'NY', label: 'New York' },
      { value: 'NC', label: 'North Carolina' },
      { value: 'ND', label: 'North Dakota' },
      { value: 'OH', label: 'Ohio' },
      { value: 'OK', label: 'Oklahoma' },
      { value: 'OR', label: 'Oregon' },
      { value: 'PA', label: 'Pennsylvania' },
      { value: 'RI', label: 'Rhode Island' },
      { value: 'SC', label: 'South Carolina' },
      { value: 'SD', label: 'South Dakota' },
      { value: 'TN', label: 'Tennessee' },
      { value: 'TX', label: 'Texas' },
      { value: 'UT', label: 'Utah' },
      { value: 'VT', label: 'Vermont' },
      { value: 'VA', label: 'Virginia' },
      { value: 'WA', label: 'Washington' },
      { value: 'WV', label: 'West Virginia' },
      { value: 'WI', label: 'Wisconsin' },
      { value: 'WY', label: 'Wyoming' }
    ], required: true },
    { name: 'industriesNotServed', type: 'naics', label: 'Industries Not Served', component: 'naics', required: false, multiple: true },
    { name: 'specializedIndustries', type: 'naics', label: 'Specialized Industries', component: 'naics', required: false, multiple: true },
    { name: 'optimalLoanAmountMin', type: 'select', label: 'Minimum Loan Amount', component: 'select', options: [
      { value: 100000, label: '$100,000' },
      { value: 250000, label: '$250,000' },
      { value: 500000, label: '$500,000' },
      { value: 1000000, label: '$1,000,000' },
      { value: 2000000, label: '$2,000,000' }
    ], required: true },
    { name: 'optimalLoanAmountMax', type: 'select', label: 'Maximum Loan Amount', component: 'select', options: [
      { value: 1000000, label: '$1,000,000' },
      { value: 2000000, label: '$2,000,000' },
      { value: 3000000, label: '$3,000,000' },
      { value: 5000000, label: '$5,000,000' },
    ], required: true },
    { name: 'debtServiceRequirement', type: 'select', label: 'Debt Service Requirement', component: 'select', options: [
      { value: 1.15, label: '1.15' },
      { value: 1.2, label: '1.2' },
      { value: 1.25, label: '1.25' },
      { value: 1.3, label: '1.3' },
      { value: 1.35, label: '1.35' }
    ], required: true },
    { name: 'processTime', type: 'select', label: 'Process Time (days)', component: 'select', options: [
      { value: 30, label: '30 days' },
      { value: 45, label: '45 days' },
      { value: 60, label: '60 days' },
      { value: 90, label: '90 days' },
      { value: 120, label: '120 days' }
    ], required: true },
    { name: 'approvalPercentage', type: 'select', label: 'Approval Percentage', component: 'select', options: [
      { value: 75, label: '75%' },
      { value: 80, label: '80%' },
      { value: 85, label: '85%' },
      { value: 90, label: '90%' },
      { value: 95, label: '95%' },
      { value: 99, label: '99%' }
    ], required: true },
    { name: 'creditScore', type: 'select', label: 'Minimum Credit Score', component: 'select', options: [
      { value: 550, label: '550+' },
      { value: 575, label: '575+' },
      { value: 600, label: '600+' },
      { value: 625, label: '625+' },
      { value: 640, label: '640+' },
      { value: 650, label: '650+' },
      { value: 660, label: '660+' },
      { value: 675, label: '675+' },
      { value: 700, label: '700+' }
    ], required: true },
    { name: 'assetCashflowBased', type: 'select', label: 'Asset/Cashflow Based', component: 'select', options: [
      { value: 'Asset', label: 'Asset' },
      { value: 'Cashflow', label: 'Cashflow' },
      { value: 'Both', label: 'Both' }
    ], required: true },
    { name: 'industryExperienceNeeded', type: 'select', label: 'Industry Experience Needed', component: 'select', options: [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' },
      { value: 'Transferable', label: 'Transferable' }
    ], required: true },
    { name: 'termSheetTime', type: 'select', label: 'Once all necessary documents are uploaded how long does it take for a term sheet  ', component: 'select', options: [
      { value: 1, label: '1 hour' },
      { value: 4, label: '4 hours' },
      { value: 24, label: '24 hours' },
      { value: 48, label: '48 hours' },
      { value: 72, label: '48 hours' },
      { value: 96, label: '96 hours+' }
    ], required: true },
    { name: 'neededItemsForTermSheet', type: 'multiselect', label: 'Needed Items for Term Sheet', component: 'multiselect', options: [
      { value: 'Personal Tax Returns', label: 'Personal Tax Returns' },
      { value: 'LOI', label: 'LOI' },
      { value: 'Business Tax Returns', label: 'Business Tax Returns' },
      { value: 'Credit Score', label: 'Credit Score' },
      { value: 'Personal Financial Statement', label: 'Personal Financial Statement' },
      { value: 'Interim P&L', label: 'Interim P&L' },
      { value: 'Interim Balance Sheet', label: 'Interim Balance Sheet' }
    ], required: true },
    { name: 'referralPercentage', type: 'select', label: 'Referral Percentage', component: 'select', options: [
      { value: 0.5, label: '0.5%' },
      { value: 1, label: '1%' },
      { value: 1.5, label: '1.5%' },
      { value: 2, label: '2%' },
      { value: 2.5, label: '2.5%' },
      { value: 3, label: '3%' }
    ], required: true },
    
  ];

  const handleNext = async () => {
    const currentQuestion = formFields[currentStep];

    if (currentQuestion.required && !formData[currentQuestion.name]) {
      alert(`Please fill in the required field: ${currentQuestion.label}`);
      return;
    }

    if (currentStep < formFields.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(formFields.length); // Move to the account creation step
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleExit = () => {
    navigate('/'); // Adjust this path as needed
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    console.log('handleCreateAccount called');
    setIsLoading(true);
    setError(null);
    try {
      console.log('Creating user account...');
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User account created:', userCredential.user.uid);

      console.log('Creating bank document...');
      const bankData = {
        ...formData,
        userId: userCredential.user.uid,
        email: email
      };

      // Handle data transformations here (similar to what was in handleSubmit)
      // Handle comma-separated strings for industries
      ['industriesNotServed', 'specializedIndustries'].forEach(field => {
        if (typeof bankData[field] === 'string') {
          bankData[field] = bankData[field].split(',').map(item => item.trim());
        }
      });

      // Handle "All States" selection
      if (bankData.state && bankData.state.includes('All')) {
        bankData.state = formFields.find(field => field.name === 'state').options
          .filter(option => option.value !== 'All')
          .map(option => option.value);
      }

      // Ensure numeric fields are saved as numbers
      ['referralPercentage', 'debtServiceRequirement', 'approvalPercentage', 'optimalLoanAmountMin', 'optimalLoanAmountMax'].forEach(field => {
        if (typeof bankData[field] === 'string') {
          bankData[field] = parseFloat(bankData[field]);
        }
      });

      // Handle nested usualLoanRate field
      if (bankData.usualLoanRateMin && bankData.usualLoanRateMax) {
        bankData.usualLoanRate = {
          min: Number(bankData.usualLoanRateMin),
          max: Number(bankData.usualLoanRateMax)
        };
        delete bankData.usualLoanRateMin;
        delete bankData.usualLoanRateMax;
      }

      // Handle neededItemsForTermSheet and customNeededItems
      if (bankData.neededItemsForTermSheet) {
        if (bankData.customNeededItems) {
          const customItems = bankData.customNeededItems.split(',').map(item => item.trim());
          bankData.neededItemsForTermSheet = [...bankData.neededItemsForTermSheet, ...customItems];
        }
        delete bankData.customNeededItems;
      }

      // Create a new document with a generated id.
      const newBankRef = doc(collection(db, 'banks'));
      await setDoc(newBankRef, bankData);
      console.log('Bank document created with ID:', newBankRef.id);

      setAccountCreated(true);
      console.log('Account creation process completed');

      // Redirect to the bank portal or dashboard
      navigate('/bank-portal');
    } catch (error) {
      console.error('Error creating account:', error);
      setError(`Error creating account: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const resendVerificationEmail = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        alert('Verification email resent. Please check your inbox and spam folder.');
      } else {
        setError('No user is currently signed in. Please try creating your account again.');
      }
    } catch (error) {
      console.error('Error resending verification email:', error);
      setError(`Error resending verification email: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const checkEmailVerification = async () => {
    try {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        setEmailVerified(true);
        navigate('/bank-portal'); // Redirect to bank portal
      } else {
        alert('Please verify your email before proceeding.');
      }
    } catch (error) {
      console.error('Error checking email verification:', error);
      alert('Error verifying email. Please try again.');
    }
  };

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handleChange = (event) => {
    let name, value;
    if (event.target && event.target.name) {
      ({ name, value } = event.target);
    } else if (event.name !== undefined) {
      ({ name, value } = event);
    } else {
      console.error('Unexpected event structure:', event);
      return;
    }

    if (name === 'number') {
      value = formatPhoneNumber(value);
    }

    setFormData((prevData) => {
      if (name === 'industriesNotServed' || name === 'specializedIndustries') {
        const existingValues = prevData[name] || [];
        if (existingValues.some(item => item.code === value.code)) {
          return {
            ...prevData,
            [name]: existingValues.filter(item => item.code !== value.code)
          };
        } else {
          return {
            ...prevData,
            [name]: [...existingValues, value]
          };
        }
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const renderProgressBar = () => {
    const progress = ((currentStep + 1) / formFields.length) * 100;
    return (
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-6">
        <div 
          className="bg-[#31c3fb] h-2.5 rounded-full transition-all duration-300 ease-in-out" 
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    );
  };

  const renderQuestion = () => {
    const currentField = formFields[currentStep];

    switch (currentField.component) {
      case 'input':
      case 'textarea':
        return (
          <div className="space-y-2">
            <label htmlFor={currentField.name} className="block text-sm font-medium text-gray-700">
              {currentField.label}
            </label>
            {currentField.component === 'input' ? (
              <input
                type={currentField.type}
                id={currentField.name}
                name={currentField.name}
                value={formData[currentField.name] || ''}
                onChange={handleChange}
                className="w-full px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#31c3fb] focus:border-[#31c3fb]"
                required={currentField.required}
                maxLength={currentField.name === 'number' ? 14 : undefined}
              />
            ) : (
              <textarea
                id={currentField.name}
                name={currentField.name}
                value={formData[currentField.name] || ''}
                onChange={handleChange}
                className="w-full px-3 py-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-[#31c3fb] focus:border-[#31c3fb]"
                required={currentField.required}
              ></textarea>
            )}
          </div>
        );
      case 'select':
        return (
          <div className="space-y-2">
            <div className="flex items-center">
              <label htmlFor={currentField.name} className="block text-sm font-medium text-gray-700">
                {currentField.label}
              </label>
              {currentField.name === 'approvalPercentage' && (
                <div className="relative ml-2">
                  <button
                    type="button"
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                  >
                    <InfoIcon />
                  </button>
                  <AnimatePresence>
                    {showTooltip && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        transition={{ duration: 0.2 }}
                        className="absolute left-full ml-2 top-0 z-10"
                      >
                        <div className="bg-gray-900 text-white text-sm rounded-lg py-2 px-4 max-w-sm w-72">
                          This percentage represents the proportion of deals that close once they enter the underwriting process.
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              )}
            </div>
            <Select
              name={currentField.name}
              options={currentField.options}
              value={currentField.options.find(option => option.value === formData[currentField.name]) || null}
              onChange={(selectedOption) => handleChange({ target: { name: currentField.name, value: selectedOption.value } })}
              className="w-full"
              styles={customStyles}
            />
          </div>
        );
      case 'multiselect':
        if (currentField.name === 'state') {
          return (
            <div className="space-y-2">
              <label htmlFor={currentField.name} className="block text-sm font-medium text-gray-700">
                {currentField.label}
              </label>
              <StateSelector
                value={formData[currentField.name] || []}
                onChange={(value) => handleChange({ target: { name: currentField.name, value } })}
              />
            </div>
          );
        }
        return (
          <div className="space-y-2">
            <label htmlFor={currentField.name} className="block text-sm font-medium text-gray-700">
              {currentField.label}
            </label>
            <Select
              isMulti={currentField.component === 'multiselect'}
              name={currentField.name}
              options={currentField.options}
              value={
                currentField.component === 'multiselect'
                  ? currentField.options.filter(option => formData[currentField.name]?.includes(option.value))
                  : currentField.options.find(option => option.value === formData[currentField.name]) || null
              }
              onChange={(selectedOption) => {
                if (currentField.component === 'multiselect') {
                  handleChange({ name: currentField.name, value: selectedOption.map(option => option.value) });
                } else {
                  handleChange({ name: currentField.name, value: selectedOption ? selectedOption.value : null });
                }
              }}
              className="w-full"
              styles={customStyles}
            />
            {currentField.name === 'neededItemsForTermSheet' && (
              <div className="mt-2">
                <label htmlFor="customNeededItems" className="block text-sm font-medium text-gray-700">
                  Other Items (comma-separated)
                </label>
                <input
                  type="text"
                  id="customNeededItems"
                  name="customNeededItems"
                  value={formData.customNeededItems || ''}
                  onChange={(e) => handleChange({ target: { name: 'customNeededItems', value: e.target.value } })}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-[#31c3fb] focus:border-[#31c3fb] sm:text-sm"
                />
              </div>
            )}
          </div>
        );
      case 'naics':
        return (
          <div className="space-y-2">
            <label htmlFor={currentField.name} className="block text-sm font-medium text-gray-700">
              {currentField.label}
            </label>
            <NaicsSearch
              name={currentField.name}
              label={currentField.label}
              value={formData[currentField.name] || []}
              onChange={handleChange}
            />
            {formData[currentField.name] && formData[currentField.name].length > 0 && (
              <div className="mt-2">
                <p className="font-medium">Selected Industries:</p>
                <ul className="list-disc pl-5">
                  {formData[currentField.name].map((item, index) => (
                    <li key={index} className="text-sm">
                      {item.code} - {item.description}
                      <button
                        type="button"
                        onClick={() => handleChange({ name: currentField.name, value: item })}
                        className="ml-2 text-red-600 hover:text-red-800"
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        );
      case 'group':
        return (
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              {currentField.label}
            </label>
            {currentField.fields.map((field, index) => (
              <div key={index} className="space-y-2">
                <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                  {field.label}
                </label>
                <Select
                  name={field.name}
                  options={field.options}
                  value={field.options.find(option => option.value === formData[field.name]) || null}
                  onChange={(selectedOption) => handleChange({ target: { name: field.name, value: selectedOption.value } })}
                  className="w-full"
                  styles={customStyles}
                />
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  if (accountCreated && !emailVerified) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Verify Your Email</h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please check your email and verify your account. Once verified, click the button below.
          </p>
          {error && <p className="mt-2 text-center text-sm text-red-600">{error}</p>}
          <button
            onClick={checkEmailVerification}
            disabled={isLoading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#31c3fb] hover:bg-[#56c0ff] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#31c3fb] disabled:opacity-50"
          >
            {isLoading ? 'Checking...' : 'I\'ve verified my email'}
          </button>
          <button
            onClick={resendVerificationEmail}
            disabled={isLoading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
          >
            {isLoading ? 'Sending...' : 'Resend Verification Email'}
          </button>
        </div>
      </div>
    );
  }

  if (currentStep === formFields.length) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Create Your Account</h2>
          <form onSubmit={handleCreateAccount} className="mt-8 space-y-6">
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">Email address</label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-[#31c3fb] focus:border-[#31c3fb] focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">Password</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#31c3fb] focus:border-[#31c3fb] focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="confirm-password" className="sr-only">Confirm Password</label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  autoComplete="new-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-[#31c3fb] focus:border-[#31c3fb] focus:z-10 sm:text-sm"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>

            {error && <p className="mt-2 text-center text-sm text-red-600">{error}</p>}

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#31c3fb] hover:bg-[#56c0ff] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#31c3fb] disabled:opacity-50"
              >
                {isLoading ? 'Creating Account...' : 'Create Account'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <button
        type="button"
        onClick={handleExit}
        className="absolute top-4 left-4 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#31c3fb]"
      >
        Exit
      </button>
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Add Your Bank</h2>
        </div>
        <div className="bg-white p-8 rounded-xl shadow-lg">
          {renderProgressBar()}
          <form onSubmit={(e) => { e.preventDefault(); handleNext(); }} className="space-y-6">
            {renderQuestion()}
            <div className="flex justify-between pt-4">
              <button
                type="button"
                onClick={handleBack}
                className={`px-4 py-2 text-sm font-medium rounded-md ${
                  currentStep === 0
                    ? 'text-gray-400 bg-gray-200 cursor-not-allowed'
                    : 'text-white bg-[#31c3fb] hover:bg-[#56c0ff] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#31c3fb]'
                }`}
                disabled={currentStep === 0}
              >
                Back
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-[#31c3fb] rounded-md hover:bg-[#56c0ff] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#31c3fb]"
              >
                {currentStep < formFields.length - 1 ? 'Next' : 'Submit'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
  </svg>
);

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'white',
    borderColor: state.isFocused ? '#3b82f6' : '#e5e7eb',
    boxShadow: state.isFocused ? '0 0 0 3px rgba(59, 130, 246, 0.5)' : 'none',
    borderRadius: '0.375rem',
    '&:hover': {
      borderColor: '#3b82f6',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#3b82f6' : state.isFocused ? '#e5e7eb' : 'white',
    color: state.isSelected ? 'white' : '#374151',
    '&:active': {
      backgroundColor: '#3b82f6',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#e5e7eb',
    borderRadius: '0.25rem',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#374151',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#6b7280',
    '&:hover': {
      backgroundColor: '#3b82f6',
      color: 'white',
    },
  }),
};

const StateSelector = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const regions = [
    { value: 'All States', label: 'All States' },
    { value: 'Northeast', label: 'Northeast' },
    { value: 'Midwest', label: 'Midwest' },
    { value: 'South', label: 'South' },
    { value: 'West', label: 'West' }
  ];

  const statesByRegion = {
    'Northeast': ['CT', 'ME', 'NH', 'RI', 'VT', 'NJ', 'NY', 'PA'],
    'Midwest': ['IL', 'IN', 'MI', 'OH', 'WI', 'IA', 'KS', 'MN', 'MO', 'NE', 'ND', 'SD'],
    'South': ['DE', 'FL', 'GA', 'MD', 'NC', 'SC', 'VA', 'WV', 'AL', 'KY', 'MS', 'TN', 'AR', 'LA', 'OK', 'TX'],
    'West': ['AZ', 'CO', 'ID', 'MT', 'NV', 'NM', 'UT', 'WY', 'AK', 'CA', 'HI', 'OR', 'WA']
  };

  const allStates = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
  ];

  const allStateValues = allStates.map(state => state.value);

  const stateOptions = [...regions, ...allStates];

  const expandSelection = (selection) => {
    let expandedSelection = [];
    selection.forEach(item => {
      if (item === 'All States') {
        expandedSelection = allStateValues;
      } else if (statesByRegion[item]) {
        expandedSelection = [...expandedSelection, ...statesByRegion[item]];
      } else {
        expandedSelection.push(item);
      }
    });
    return [...new Set(expandedSelection)]; // Remove duplicates
  };

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    const expandedValues = expandSelection(selectedValues);
    onChange(expandedValues);
  };

  const getCurrentSelection = () => {
    if (value.length === allStateValues.length) {
      return [{ value: 'All States', label: 'All States' }];
    }
    
    const selectedRegions = Object.entries(statesByRegion)
      .filter(([_, states]) => states.every(state => value.includes(state)))
      .map(([region, _]) => ({ value: region, label: region }));

    const selectedStates = allStates.filter(state => value.includes(state.value));

    return [...selectedRegions, ...selectedStates];
  };

  const selectedOptions = getCurrentSelection();

  return (
    <div className="space-y-2">
      <Select
        isMulti
        name="states"
        options={stateOptions}
        styles={customStyles}
        value={selectedOptions}
        onChange={handleChange}
        placeholder="Select states or regions..."
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="bg-gray-100 p-4 rounded-lg mt-2"
          >
            <h4 className="text-sm font-semibold mb-2">Selected States:</h4>
            <div className="flex flex-wrap gap-2">
              {value.map((state) => (
                <motion.span
                  key={state}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  className="bg-white px-2 py-1 rounded-full text-xs font-medium text-gray-700 shadow-sm"
                >
                  {state}
                </motion.span>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default BankForm;






