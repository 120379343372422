import React, { useState } from 'react';
import naicsData from './naics_codes.json'; // Assuming you have a JSON file named naics-data.json

function NaicsSearch({ name, label, value, onChange }) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClick = (code, description) => {
    onChange({ target: { name, value: { code, description } } });
    setSearchTerm('');
  };

  const filteredCodes = naicsData.filter(
    (item) =>
      item.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <label htmlFor={name} className="block mb-2 font-semibold text-gray-700">
        {label}
      </label>
      <input
        type="text"
        id={`${name}-search`}
        value={searchTerm}
        onChange={handleSearch}
        className="w-full px-4 py-2 mb-2 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        placeholder="Search by NAICS code or description"
      />
      <ul className="max-h-40 overflow-y-auto">
        {filteredCodes.map((item) => (
          <li
            key={item.code}
            onClick={() => handleClick(item.code, item.description)}
            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
          >
            {item.code} - {item.description}
          </li>
        ))}
      </ul>
      {value && (
        <p className="mt-2">
          Selected: {value.code} - {value.description}
        </p>
      )}
    </div>
  );
}

export default NaicsSearch;