import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, updateDoc, doc, setDoc, serverTimestamp, getDoc, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const LoanOfficerAssignment = ({ loanId, handleAssignBank, isPrequalification }) => {
  const [banks, setBanks] = useState([]);
  const [selectedBankIds, setSelectedBankIds] = useState([]);
  const [dropboxLink, setDropboxLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const q = query(collection(db, 'banks'));
        const querySnapshot = await getDocs(q);
        const banksData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setBanks(banksData);
      } catch (error) {
        console.error("Error fetching banks:", error);
        alert("Failed to fetch banks. Please try again.");
      }
    };

    fetchBanks();
  }, []);

  const handleSelection = async () => {
    if (selectedBankIds.length === 0) {
      alert('Please select at least one bank before assigning.');
      return;
    }

    if (!dropboxLink) {
      alert('Please provide a Dropbox link.');
      return;
    }

    setIsLoading(true);

    try {
      console.log("Starting bank assignment process...");
      console.log("Loan ID:", loanId);
      console.log("Selected Bank IDs:", selectedBankIds);
      console.log("Is Prequalification:", isPrequalification);
      console.log("Dropbox Link:", dropboxLink);

      // Create a new bank assignment document for each selected bank
      for (const bankId of selectedBankIds) {
        const assignmentData = {
          bankId: bankId,
          applicationId: loanId,
          dropboxLink: dropboxLink,
          assignedAt: serverTimestamp(),
          currentStage: {
            name: isPrequalification ? 'prequalification' : 'underReview',
            enteredAt: serverTimestamp()
          },
          stageHistory: [{
            name: isPrequalification ? 'prequalification' : 'underReview',
            enteredAt: new Date().toISOString(),
            exitedAt: null
          }]
        };
        
        await addDoc(collection(db, 'bankAssignments'), assignmentData);
        console.log(`Bank assignment document created for bank ${bankId}`);
      }

      // Update the loan application document
      const collectionName = isPrequalification ? 'prequalificationApplications' : 'loanApplications';
      const applicationRef = doc(db, collectionName, loanId);
      
      const docSnap = await getDoc(applicationRef);
      if (docSnap.exists()) {
        const existingData = docSnap.data();
        const updatedBankIds = [...new Set([...(existingData.assignedBankIds || []), ...selectedBankIds])];
        
        const applicationData = {
          assignedBankIds: updatedBankIds,
          dropboxLink: dropboxLink,
          lastUpdated: serverTimestamp()
        };

        await updateDoc(applicationRef, applicationData);
        console.log(`${isPrequalification ? 'Prequalification' : 'Loan'} application updated successfully`);
      } else {
        console.log(`${isPrequalification ? 'Prequalification' : 'Loan'} application document does not exist`);
      }

      // Call the handleAssignBank function from the parent component
      await handleAssignBank(selectedBankIds);
      console.log("handleAssignBank completed successfully");

      alert('Banks assigned and Dropbox link added successfully!');
      setDropboxLink('');
      setSelectedBankIds([]);
    } catch (error) {
      console.error('Error assigning banks or adding Dropbox link:', error);
      alert(`An error occurred: ${error.message}. Please check the console for more details.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-4">
      <label htmlFor="bank" className="block text-sm font-medium text-gray-700">
        Select Bank
      </label>
      <select
        id="bank"
        name="bank"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        value={selectedBankIds.join(',')}
        onChange={(e) => setSelectedBankIds(e.target.value.split(','))}
      >
        <option value="">Select a Bank</option>
        {banks.map((bank) => (
          <option key={bank.id} value={bank.id}>
            {bank.bankName}
          </option>
        ))}
      </select>

      <label htmlFor="dropboxLink" className="block mt-4 text-sm font-medium text-gray-700">
        Dropbox Link
      </label>
      <input
        type="text"
        id="dropboxLink"
        name="dropboxLink"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        value={dropboxLink}
        onChange={(e) => setDropboxLink(e.target.value)}
        placeholder="https://www.dropbox.com/..."
      />

      <button
        type="button"
        onClick={handleSelection}
        disabled={isLoading}
        className={`mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${isLoading ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        {isLoading ? 'Assigning...' : 'Assign Banks and Add Dropbox Link'}
      </button>
    </div>
  );
};

export default LoanOfficerAssignment;
