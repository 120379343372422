import React, { useState } from 'react';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword, multiFactor, TotpMultiFactorGenerator, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import QRCode from 'qrcode.react';

function AuthFormApplication({ formData }) {
  const [email, setEmail] = useState(formData.email || '');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState(formData.firstName || '');
  const [lastName, setLastName] = useState(formData.lastName || '');
  const [loading, setLoading] = useState(false);
  const [enrollTOTP, setEnrollTOTP] = useState(false);
  const [totpSecret, setTotpSecret] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const navigate = useNavigate();

  const handleEmailSignUp = async () => {
    if (email && password && firstName && lastName && termsAccepted) {
      try {
        setLoading(true);

        // Create user with email and password
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        console.log('User signed up:', user);

        // Send email verification
        await sendEmailVerification(user);
        alert('A verification email has been sent to your email address. Please verify your email to complete the sign-up process.');
        setEmailSent(true);

        // Create a user profile in 'users' node with 'Pre-lender' as the initial status
        const userProfileRef = doc(db, 'users', user.uid);
        await setDoc(userProfileRef, {
          email: user.email,
          firstName,
          lastName,
          status: 'Pre-lender', // Set the status to 'Pre-lender'
        });

        console.log('User profile created');

        setLoading(false);
      } catch (error) {
        console.log('Error signing up:', error);
        setLoading(false);

        if (error.code === 'auth/email-already-in-use') {
          alert('The email address is already in use. Please use a different email.');
        } else {
          alert('Error signing up. Please try again.');
        }
      }
    } else {
      alert('Please enter all required fields and agree to the terms of service.');
    }
  };

  // Function to handle email verification status check
  const checkEmailVerification = async () => {
    try {
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        console.log('Email verified');
        setEmailVerified(true);
        if (enrollTOTP) {
          // Generate a TOTP secret
          const multiFactorSession = await multiFactor(auth.currentUser).getSession();
          const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
          setTotpSecret(totpSecret);
        } else {
          // Link user to loan application by email
          await linkUserToLoanApplication(auth.currentUser);
          setTimeout(() => {
            setLoading(false);
            navigate('/portal', { state: { retryCount: 0 } });
          }, 2000);
        }
      } else {
        alert('Please verify your email before proceeding.');
      }
    } catch (error) {
      console.log('Error checking email verification:', error);
      alert('Error verifying email. Please try again.');
    }
  };

  const linkUserToLoanApplication = async (user) => {
    try {
      const formDataQuery = query(collection(db, 'loanApplications'), where('email', '==', email));
      const formDataSnapshot = await getDocs(formDataQuery);

      if (!formDataSnapshot.empty) {
        formDataSnapshot.forEach(async (formDataDoc) => {
          // Update the form data with the user's UID and set the status to 'Pre-lender'
          const applicationID = formDataDoc.id;
          await updateDoc(doc(db, 'loanApplications', applicationID), {
            userId: user.uid,
            status: 'Pre-lender', // Ensure the status is set to 'Pre-lender' in the loan applications
          });

          // Create a reference to this application under the 'applications' node within the 'users' node
          const userApplicationsRef = doc(db, 'users', user.uid, 'applications', applicationID);
          await setDoc(userApplicationsRef, {
            applicationID,
            status: 'Pre-lender', // Set the status to 'Pre-lender' for the application reference
          });

          console.log('Loan application and user profile updated with user UID and status set to Pre-lender');
        });
      }
    } catch (error) {
      console.log('Error linking user to loan application:', error);
    }
  };

  const handleTOTPEnrollment = async () => {
    try {
      // Finalize the enrollment
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(
        totpSecret,
        verificationCode
      );
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'TOTP');
      console.log('TOTP enrollment successful');

      // Link user to loan application by email
      await linkUserToLoanApplication(auth.currentUser);

      // Delay the navigation to the portal to allow data to be fully updated
      setTimeout(() => {
        setLoading(false);
        navigate('/portal', { state: { retryCount: 0 } });
      }, 2000); // Adjust the delay as needed
    } catch (error) {
      console.log('Error enrolling TOTP:', error);
      alert('Invalid verification code. Please try again.');
    }
  };

  return (
    <div>
      {!emailSent ? (
        <div className="space-y-6">
          <div className="rounded-md shadow-sm space-y-4">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <label htmlFor="firstName" className="sr-only">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="First Name"
                />
              </div>
              <div className="w-1/2">
                <label htmlFor="lastName" className="sr-only">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
            <div className="flex items-center">
              <input
                id="enrollTOTP"
                name="enrollTOTP"
                type="checkbox"
                checked={enrollTOTP}
                onChange={(e) => setEnrollTOTP(e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="enrollTOTP" className="ml-2 block text-sm text-gray-900">
                Enable Two-Factor Authentication using Google Authenticator (TOTP)
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="termsAccepted"
                name="termsAccepted"
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="termsAccepted" className="ml-2 block text-sm text-gray-900">
                I agree to the <button type="button" onClick={() => setShowTerms(true)} className="text-blue-600 underline">terms of service</button>
              </label>
            </div>
          </div>

          <div>
            <button
              type="button"
              onClick={handleEmailSignUp}
              disabled={loading}
              className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
                loading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
              }`}
            >
              {loading ? 'Creating account...' : 'Create an account'}
            </button>
          </div>
        </div>
      ) : !emailVerified ? (
        <div className="space-y-6">
          <h3 className="text-center text-xl font-bold tracking-tight text-gray-900">
            Check your email to verify your account.
          </h3>
          <p className="text-center text-sm text-gray-600">
            After verifying your email, click the button below to complete the registration process.
          </p>
          <div>
            <button
              type="button"
              onClick={checkEmailVerification}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Once you have verified your email click here
            </button>
          </div>
        </div>
      ) : totpSecret ? (
        <div>
          <h3 className="mt-6 text-center text-xl font-bold tracking-tight text-gray-900">
            Two-Factor Authentication Setup
          </h3>
          <div className="mt-4">
            <ul className="list-disc list-inside text-sm text-gray-500">
              <li>Download the Google Authenticator app on your phone</li>
              <li>Click the + in the bottom right corner and scan the QR code below with your authenticator app or enter the secret key manually.</li>
              <li>Input the verification code displayed in the app</li>
            </ul>
            <div className="mt-4 flex justify-center">
              <QRCode value={totpSecret.generateQrCodeUrl(email, 'Your App Name')} />
            </div>
            <div className="mt-4">
              <label htmlFor="secretKey" className="block text-sm font-medium text-gray-700">
                Secret Key
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="secretKey"
                  value={totpSecret.secretKey}
                  readOnly
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                />
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">
                Verification Code
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="verificationCode"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Enter the code from your authenticator app"
                />
              </div>
            </div>
            <div className="mt-6">
              <button
                type="button"
                onClick={handleTOTPEnrollment}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Verify and Enroll
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          <h3 className="text-center text-xl font-bold tracking-tight text-gray-900">
            Registration Complete
          </h3>
          <p className="text-center text-sm text-gray-600">
            Your account has been successfully verified and set up. You can now access the portal.
          </p>
          <div>
            <button
              type="button"
              onClick={() => navigate('/portal', { state: { retryCount: 0 } })}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Go to Portal
            </button>
          </div>
        </div>
      )}
      {showTerms && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Terms of Service</h2>
            <p className="text-sm text-gray-600">
              We may receive referral compensation for matching you with a bank.
            </p>
            <div className="mt-4 flex justify-end">
              <button
                type="button"
                onClick={() => setShowTerms(false)}
                className="bg-blue-600 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AuthFormApplication;
