import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const calculateMonthlyPayment = (loanAmount, annualInterestRate, loanTermYears) => {
  const monthlyInterestRate = annualInterestRate / 12 / 100;
  const numberOfPayments = loanTermYears * 12;
  const monthlyPayment = loanAmount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) / (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
  return monthlyPayment;
};

export const calculatePrequalifiedAmount = async (loanId) => {
  console.log('Calculating prequalified amount for loanId:', loanId);
  const db = getFirestore();
  const ocrMetadataRef = collection(db, 'OCRMetadata');
  const q = query(ocrMetadataRef, where('loanApplicationId', '==', loanId));

  try {
    const querySnapshot = await getDocs(q);
    console.log('Number of documents found:', querySnapshot.size);

    let totalLiquidAssets = 0;
    let personalFinancialOCRs = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      console.log('Document data:', data);
      if (data.documentName === "Personal Financial Statement") {
        console.log('Found Personal Financial Statement');
        personalFinancialOCRs.push(data.ocrResult);
      }
    });

    console.log('Number of Personal Financial Statements:', personalFinancialOCRs.length);

    // Process personal financial data for all guarantors
    personalFinancialOCRs.forEach((data, index) => {
      console.log(`Processing Personal Financial Statement ${index + 1}:`, data);
      const { Assets } = data;
      
      // Calculate liquid assets for each guarantor
      const cashOnHandAndInBanks = parseFloat(Assets.CashOnHandAndInBanks) || 0;
      const stocksAndBonds = parseFloat(Assets.StocksAndBonds) || 0;
      
      console.log('Cash on Hand and in Banks:', cashOnHandAndInBanks);
      console.log('Stocks and Bonds:', stocksAndBonds);

      // Add this guarantor's liquid assets to the total
      totalLiquidAssets += cashOnHandAndInBanks + stocksAndBonds;
    });

    console.log('Total Combined Liquid Assets:', totalLiquidAssets);

    // Loan terms
    const annualInterestRate = 10.5;
    const loanTermYears = 10;

    // Binary search to find the maximum loan amount
    let left = 0;
    let right = totalLiquidAssets * 10; // Start with a high upper bound
    let prequalifiedAmount = 0;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const monthlyPayment = calculateMonthlyPayment(mid, annualInterestRate, loanTermYears);
      const sixMonthsPayments = monthlyPayment * 6;
      const equityInjection = mid * 0.1;

      // Check if total liquid assets can cover 6 months of payments and 10% equity injection
      if (totalLiquidAssets >= sixMonthsPayments + equityInjection) {
        prequalifiedAmount = mid;
        left = mid + 1;
      } else {
        right = mid - 1;
      }
    }

    console.log('Prequalified Amount:', prequalifiedAmount);
    return Math.round(prequalifiedAmount); // Round to nearest whole number
  } catch (error) {
    console.error('Error calculating prequalified amount:', error);
    throw error;
  }
};
