// StateSelect.js
import React, { useState, useRef, useEffect } from 'react';

const states = [
  'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
  'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
  'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
  'West Virginia', 'Wisconsin', 'Wyoming'
];

function StateSelect({ name, label, value, onChange }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleClick = (state) => {
    onChange({ target: { name, value: state } });
    setSearchTerm('');
    setIsOpen(false);
  };

  const filteredStates = states.filter((state) =>
    state.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div ref={dropdownRef} className="relative">
      <div className="flex items-center mb-2">
        <label htmlFor={name} className="block font-semibold text-gray-700">
          {label}
        </label>
     
      </div>
     
      <input
        type="text"
        id={`${name}-search`}
        value={searchTerm}
        onChange={handleSearch}
        onFocus={() => setIsOpen(true)}
        className="w-full px-4 py-2 mb-2 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        placeholder="Search for a state"
      />
      {isOpen && (
        <ul className="absolute z-10 w-64 max-h-48 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg">
          {filteredStates.map((state) => (
            <li
              key={state}
              onClick={() => handleClick(state)}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100"
            >
              {state}
            </li>
          ))}
        </ul>
      )}
      {value && <p className="mt-2">Selected: {value}</p>}
    </div>
  );
}

export default StateSelect;