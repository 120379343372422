import React, { useState, useRef, useEffect } from 'react';
import { Percent, HelpCircle } from 'lucide-react';

const LoanStructureForm = ({ formData, handleChange }) => {
  const [localFormData, setLocalFormData] = useState(formData.loanStructure || {});
  const inputRefs = useRef({});

  useEffect(() => {
    setLocalFormData(formData.loanStructure || {});
  }, [formData.loanStructure]);

  const handleInputChange = (e, field) => {
    const value = e.target.value.replace(/,/g, '');

    setLocalFormData(prevData => {
      const newData = { ...prevData };
      if (field.includes('.')) {
        const [main, sub] = field.split('.');
        newData[main] = { ...newData[main], [sub]: value };
      } else {
        newData[field] = value;
      }
      return newData;
    });
  };

  const handleInputBlur = () => {
    handleChange({
      target: {
        name: 'loanStructure',
        value: localFormData
      }
    });
  };

  const getFieldValue = (field) => {
    if (field.includes('.')) {
      const [main, sub] = field.split('.');
      return localFormData[main]?.[sub] || '';
    }
    return localFormData[field] || '';
  };

  const renderInput = (label, field) => {
    const value = getFieldValue(field);

    return (
      <div key={field} className="mb-4">
        <label htmlFor={field} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            ref={el => inputRefs.current[field] = el}
            type="text"
            id={field}
            value={value}
            onChange={(e) => handleInputChange(e, field)}
            onBlur={handleInputBlur}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Enter percentage"
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <span className="text-gray-500 sm:text-sm">%</span>
          </div>
        </div>
      </div>
    );
  };

  const totalPercentage = () => {
    return Object.entries(localFormData).reduce((sum, [key, value]) => {
      if (key === 'equityInjection') {
        return sum + (parseFloat(value.personal) || 0) + (parseFloat(value.fullStandby) || 0);
      }
      return sum + (parseFloat(value) || 0);
    }, 0);
  };

  const isCloseToHundred = (value) => {
    return Math.abs(value - 100) < 0.01;
  };

  return (
    <div className="col-span-2 mt-8">
      <div className="bg-white p-6 rounded-md shadow">
        <h3 className="text-xl font-semibold text-gray-900 mb-4 flex items-center">
          Expected Loan Structure
          <HelpCircle className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" title="Breakdown of the loan structure" />
        </h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h4 className="text-lg font-medium text-gray-700 mb-4">Equity Injection</h4>
            {renderInput('Personal', 'equityInjection.personal')}
            {renderInput('Full Standby', 'equityInjection.fullStandby')}
          </div>
          <div>
            {renderInput('Conventional Loan', 'conventionalLoan')}
            {renderInput('SBA 7a Loan', 'sba7aLoan')}
            {renderInput("Seller's Note", 'sellersNote')}
          </div>
        </div>

        <div className="flex items-center justify-between pt-6 border-t border-gray-200 mt-8">
          <span className="text-lg font-medium text-gray-700">Total</span>
          <span className="text-2xl font-bold text-blue-600">
            {`${totalPercentage().toFixed(2)}%`}
          </span>
        </div>

        {!isCloseToHundred(totalPercentage()) && (
          <p className="text-red-500 mt-4 text-sm">The total must add up to 100% or the full loan amount.</p>
        )}
        {(parseFloat(localFormData.equityInjection?.personal) || 0) + (parseFloat(localFormData.equityInjection?.fullStandby) || 0) < 10 && (
          <p className="text-red-500 mt-2 text-sm">The combined Equity Injection must be at least 10%.</p>
        )}
      </div>
    </div>
  );
};

export default LoanStructureForm;