import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, doc, setDoc, addDoc } from 'firebase/firestore';
import AuthForm from './AuthFormApplication';
import StateSelect from './StateSelect';
import NaicsSearch from './NaicsSearch';
import CapTable from './CapTable';
import CurrencyInput from './CurrencyInput';
import SelectQuestion from './SelectQuestion';
import FinancialInfo from './financialinfo';
import LoanStructure from './LoanStructure'; // Import the new component
import RealEstateAppraisalInput from './RealEstateAppraisalInput'; // Ensure the correct path
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import BusinessSummary from './BusinessSummary'; // Import the new component// Import the new component

const ProgressBar = ({ progress }) => {
  const segments = [
    { width: '10%', color: 'bg-blue-200' },
    { width: '10%', color: 'bg-blue-300' },
    { width: '10%', color: 'bg-blue-400' },
    { width: '10%', color: 'bg-blue-500' },
    { width: '10%', color: 'bg-blue-600' },
    { width: '10%', color: 'bg-blue-700' },
    { width: '10%', color: 'bg-blue-800' },
    { width: '10%', color: 'bg-blue-900' },
    { width: '10%', color: 'bg-blue-1000' },
    { width: '10%', color: 'bg-blue-1100' },
  ];

  return (
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div>
          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-600 bg-blue-200">
            Form Progress
          </span>
        </div>
        <div className="text-right">
          <span className="text-xs font-semibold inline-block text-blue-600">
            {progress}%
          </span>
        </div>
      </div>
      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
        {segments.map((segment, index) => (
          <div
            key={index}
            style={{ width: progress >= (index + 1) * 10 ? segment.width : '0%' }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
              progress >= (index + 1) * 10 ? segment.color : 'bg-gray-300'
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

function LoanApplicationForm() {
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [formData, setFormData] = useState({
    loanSize: '',
    loanPurpose: 'Acquisition',
    purchaseType: '',
    businessState: '',
    businessAge: '',
    industry: '',
    capTable: [],
    pg: 0,
    firstName: '',
    lastName: '',
    email: '',
    number: '',
    ebitda: '',
    revenue: '',
    businessName:'',
    additionalNotes: '',
    userId: null,
    status: "form",
    realEstateAppraisal: '0', 
    personalGuarantors: [],
    loanStructure: {
      sba7aLoan: '',
      sellersNote: '',
      equityInjection: {
        personal: '',
        fullStandby: ''
      }
    }, // New field for loan structure
    businessSummary: '' // New field for business summary
  });

  const [progress, setProgress] = useState(0);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [confirmRepresentative, setConfirmRepresentative] = useState(false);

  const questions = [
    { name: 'personalInfo', type: 'personalInfo', label: 'Personal Information', component: 'personalInfo', required: false },
    { name: 'loanSize', type: 'currency', label: 'What is the total purchase price for the business?', component: 'currency', required: true },
    { name: 'financialInfo', type: 'financialInfo', label: 'Financial Information', component: 'financialInfo', required: true },
    // Removed loanPurpose question
    { name: 'purchaseType', type: 'select', label: 'What type of purchase is it?', component: 'select', options: ['Stock', 'Asset', 'Unsure'], required: true },
    { name: 'businessState', type: 'select', label: 'What state is the business in?', component: 'stateSelect', required: true },
    { name: 'businessAge', type: 'select', label: 'How long has the company been in business?', component: 'select', options: ['Startup', '0-2 Years', '2+ Years'], required: true },
    { name: 'capTable', type: 'capTable', label: 'Cap Table of New Company', component: 'capTable', required: true },
    { name: 'realEstateAppraisal', type: 'currency', label: 'If there is real estate in the deal, what is its appraisal?', component: 'realEstateAppraisal', required: false}, // New question for real estate appraisal
    { name: 'loanStructure', type: 'loanStructure', label: 'Expected Loan Structure', component: 'loanStructure', required: true }, // New question for loan structure// New question for business summary
    { name: 'businessSummary', type: 'businessSummary', label: 'Can you provide a short summary of the business you are buying?', component: 'BusinessSummary', required: true }, // New question for business summary
    { name: 'additionalNotes', type: 'textarea', label: 'Additional Notes', component: 'textarea', required: false }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Check for missing fields in the general questions array
    const missingGeneralFields = questions
      .filter((question) => question.required && !formData[question.name] && question.component !== 'financialInfo')
      .map((question) => question.label);
  
    // Check for missing Financial Information fields
  
    // Define an array of required personal information fields with their labels
    const requiredPersonalInfoFields = [
      { name: 'firstName', label: 'First Name' },
      { name: 'lastName', label: 'Last Name' },
      { name: 'email', label: 'Email Address' },
      { name: 'number', label: 'Phone Number' },
    ];
  
    // Check for missing personal information fields
    const missingPersonalInfoFields = requiredPersonalInfoFields
      .filter((field) => !formData[field.name])
      .map((field) => field.label);
  
    // Combine missing fields from all checks
    const allMissingFields = missingGeneralFields.concat(missingPersonalInfoFields);
  
    // Alert the user if there are any missing fields
    if (allMissingFields.length > 0) {
      alert(`Please fill in the following required fields: ${allMissingFields.join(', ')}`);
      return;
    }
  
    // Extract personal guarantors from the captable
    const personalGuarantors = extractPersonalGuarantors();
  
    await saveFormDataToFirestore(true, personalGuarantors); // Save form data to Firestore and mark as submitted
  };
  
  const extractPersonalGuarantors = () => {
    const capTable = formData.capTable || [];
    const personalGuarantors = capTable
      .filter((owner) => parseFloat(owner.percentage) > 20)
      .map((owner) => ({
        firstName: owner.name.split(' ')[0],
        lastName: owner.name.split(' ')[1] || '',
      }));
    return personalGuarantors;
  };
  
  const saveFormDataToFirestore = async (isSubmitting, personalGuarantors) => {
    try {
      let applicationRef;
      const validFormData = {
        ...formData,
        personalGuarantors,
        lastUserUpdate: new Date(), // Rename this line to update the lastUserUpdate field
      };
  
      // Filter out undefined values from validFormData
      Object.entries(validFormData).forEach(([key, value]) => {
        if (value === undefined) {
          delete validFormData[key];
        }
      });
  
      if (formData.applicationId) {
        // If an application ID exists, update the existing document
        applicationRef = doc(db, 'loanApplications', formData.applicationId);
        await setDoc(applicationRef, {
          ...validFormData,
          updatedAt: new Date(),
          status: isSubmitting ? 'submitted' : 'in progress',
        }, { merge: true });
      } else {
        // If no application ID exists, create a new document
        applicationRef = await addDoc(collection(db, 'loanApplications'), {
          ...validFormData,
          userId: null,
          createdAt: new Date(),
          updatedAt: new Date(),
          status: isSubmitting ? 'submitted' : 'in progress',
        });
        // Store the generated application ID in the formData state
        setFormData((prevFormData) => ({
          ...prevFormData,
          applicationId: applicationRef.id,
        }));
      }
  
      console.log('Form data saved to Firestore with ID:', applicationRef.id);
      if (isSubmitting) {
        setFormSubmitted(true);
      }
    } catch (error) {
      console.error('Error saving form data to Firestore:', error);
      throw error;
    }
  };
const handleNext = async () => {
  const currentQuestion = questions[currentStep];

  if (currentQuestion.required) {
    if (currentQuestion.component === 'personalInfo') {
      if (
        !formData.firstName ||
        !formData.lastName ||
        !formData.email ||
        !formData.number
      ) {
        setError('Please fill in all required personal information fields.');
        return;
      }
    } else if (currentQuestion.component === 'capTable') {
      const capTable = formData[currentQuestion.name];
      const isCapTableValid = capTable.every(
        (owner) => owner.name && owner.percentage && parseFloat(owner.percentage) > 0
      );
      const totalOwnership = capTable.reduce(
        (sum, owner) => sum + parseFloat(owner.percentage),
        0
      );
      if (!isCapTableValid || totalOwnership !== 100) {
        setError('Please fill in all owner names and ensure the total ownership equals 100%. This can always be edited later');
        return;
      }
    } else if (currentQuestion.component === 'financialInfo') {
      if (!formData.ebitda || !formData.revenue) {
        setError('Please fill in the EBITDA and Revenue fields.');
        return;
      }
    } else if (!formData[currentQuestion.name]) {
      setError(`Please fill in the required field: ${currentQuestion.label}`);
      return;
    }
  }

  setError('');
  if (currentStep < questions.length - 1) {
    try {
      // Update the formData state with the latest values
      setFormData((prevFormData) => ({
        ...prevFormData,
        [currentQuestion.name]: formData[currentQuestion.name],
      }));

      // Wait for the state update to complete
      await new Promise((resolve) => {
        setFormData((prevFormData) => {
          resolve();
          return prevFormData;
        });
      });

      // Save the updated form data to Firestore
      await saveFormDataToFirestore(false);

      // Move to the next step
      setCurrentStep((prevStep) => prevStep + 1);
    } catch (error) {
      setError('There was a problem saving your application. Please try again.');
    }
  }
};
const handleBack = async () => {
  if (currentStep > 0) {
    try {
      // Save the current form data before moving back
      await saveFormDataToFirestore(false);

      // Move to the previous step
      setCurrentStep((prevStep) => prevStep - 1);
    } catch (error) {
      setError('There was a problem saving your application. Please try again.');
    }
  }
};

  const renderQuestion = (question) => {
    switch (question.component) {
      case 'input':
        return (
          <div className="mb-6 relative">
          <div className="flex items-center">
            <label htmlFor={question.name} className="block mb-2 font-semibold text-gray-700">
              {question.label}
            </label>
            <div className="relative mb-2 ml-1 cursor-pointer group">
              <span className="inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-500 rounded-full">
                i
              </span>
              <div className="absolute z-10 hidden w-64 px-4 py-2 mt-2 ml-8 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-lg group-hover:block left-0 top-0">
                If you are acquiring a company please provide the name of the company you plan on acquiring.
              </div>
            </div>
          </div>
        
            <input
              type={question.type}
              name={question.name}
              id={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              className="w-full px-4 py-2 text-gray-700 border border-blue-900 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder={question.label}
            />
          </div>
        );
      case 'select':
          return (
            <SelectQuestion
              key={question.name}
              name={question.name}
              label={question.label}
              value={formData[question.name]}
              options={question.options}
              onChange={handleChange}
            />
        );
      case 'currency':
        return (
          <div className="mb-6">
            <CurrencyInput
              name={question.name}
              label={question.label}
              value={formData[question.name]}
              onChange={handleChange}
            />
          </div>
        );
      case 'realEstateAppraisal':
          return (
          <div className="mb-6">
            <RealEstateAppraisalInput
              name={question.name}
              label={question.label}
              value={formData[question.name]}
              onChange={handleChange}
            />
          </div>
        );
      case 'stateSelect':
        return (
          <div className="mb-6">
            <StateSelect
              name={question.name}
              label={question.label}
              value={formData[question.name]}
              onChange={handleChange}
            />
          </div>
        );
      case 'naicsSearch':
        return (
          <div className="mb-6">
            <NaicsSearch
              name={question.name}
              label={question.label}
              value={formData[question.name]}
              onChange={handleChange}
            />
          </div>
        );
      case 'financialInfo':
          return <FinancialInfo formData={formData} handleChange={handleChange} />;
      case 'capTable':
        return (
          <div className="mb-6">
            <CapTable
              name={question.name}
              label={question.label}
              value={formData[question.name]}
              onChange={(value, pg) => {
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [question.name]: value,
                  pg: pg,
                }));
              }}
            />
          </div>
        );
      case 'loanStructure':
        return (
          <div className="mb-6">
            <LoanStructure
              formData={formData}
              setFormData={setFormData}
            />
          </div>
        );
      case 'BusinessSummary':
        return (
          <div className="mb-6">
            <BusinessSummary
              name={question.name}
              label={question.label}
              value={formData[question.name]}
              onChange={(value) => setFormData((prevFormData) => ({
                ...prevFormData,
                [question.name]: value,
              }))}
            />
          </div>
        );
      case 'personalInfo':
        return (
          <div className="mb-6">
            <h3 className="mb-4 font-semibold text-gray-800">Personal Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-gray-700">
                  First Name
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="First Name"
                />
              </div>
              <div>
                <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Email Address"
              />
            </div>
            <div className="mt-4">
              <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <PhoneInput
                country={'us'}
                value={formData.number}
                onChange={(phone) => setFormData((prevFormData) => ({ ...prevFormData, number: phone }))}
                inputClass="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        );
      case 'textarea':
        return (
          <div className="mb-6">
            <label htmlFor={question.name} className="block mb-2 font-semibold text-gray-700">
              {question.label}
            </label>
            <textarea
              name={question.name}
              id={question.name}
              value={formData[question.name]}
              onChange={handleChange}
              rows="4"
              className="w-full px-4 py-2 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
              placeholder={question.label}
            ></textarea>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const calculateProgress = () => {
      const totalFields = questions.length - 1; // Exclude the additional notes step
      const completedFields = questions.slice(0, currentStep).filter((question) => {
        if (question.component === 'personalInfo') {
          return formData.firstName && formData.lastName && formData.email && formData.number;
        }
        return formData[question.name];
      }).length;
  
      // Check if all form fields up to the current step are empty
      const allFieldsEmpty = questions.slice(0, currentStep).every((question) => {
        if (question.component === 'personalInfo') {
          return !formData.firstName && !formData.lastName && !formData.email && !formData.number;
        }
        return !formData[question.name];
      });
  
      return allFieldsEmpty ? 0 : Math.round((completedFields / totalFields) * 100);
    };
  
    setProgress(calculateProgress());
  }, [formData, currentStep, questions]);

  useEffect(() => {
    if (formSubmitted) {
      setProgress(100);
    }
  }, [formSubmitted]);

  const handleAuthFormSubmit = (e) => {
    e.preventDefault();
    if (!confirmRepresentative) {
      alert('Please check the confirmation box to proceed.');
      return;
    }

    // Check if the email in the AuthForm matches the email in the loan application form
    if (formData.email !== e.target.email.value) {
      setEmailError('Please use the same email address you entered in the loan application form.');
      return;
    }

    // Proceed with form submission logic or account creation
  };

  return (
    <div className="gradient-background flex items-center justify-center">
      <div className="polygon polygon-1"></div>
      <div className="polygon polygon-2"></div>
      <div className="polygon polygon-3"></div>
      <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-lg relative z-10 form-container mx-4 sm:mx-auto">
        <ProgressBar progress={progress} />
        <h2 className="text-3xl font-bold mt-8 mb-8 text-center text-blue-900">Loan Application</h2>
        {error && <p className="text-red-500 text-center mb-6">{error}</p>}
  
        {formSubmitted ? (
          <div>
            <p className="text-lg font-semibold mb-4">
              Thank you for submitting your loan application. In order to access your loan portal and speak with our AI loan specialist, please create an account.
            </p>
            <form onSubmit={handleAuthFormSubmit}>
              <AuthForm formData={formData} />
              {emailError && <p className="text-red-500 mb-4">{emailError}</p>}

             
            </form>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {renderQuestion(questions[currentStep])}
            <div className="flex justify-between mt-8">
              <button
                key={`back-${currentStep}`}
                type="button"
                onClick={handleBack}
                className={`px-6 py-2 font-semibold rounded-lg ${
                  currentStep === 0 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-blue-900 text-white hover:bg-blue-600'
                }`}
                disabled={currentStep === 0}
              >
                Back
              </button>
              {currentStep === questions.length - 1 ? (
                <button
                  key={`submit-${currentStep}`}
                  type="submit"
                  className="px-6 py-2 font-semibold text-white bg-blue-900 rounded-lg hover:bg-blue-600"
                >
                  Submit Form
                </button>
              ) : (
                <button
                  key={`next-${currentStep}`}
                  type="button"
                  onClick={handleNext}
                  className="px-6 py-2 font-semibold text-white bg-blue-900 rounded-lg hover:bg-blue-600"
                >
                  Next
                </button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default LoanApplicationForm;

