import React, { useEffect, useState } from 'react';
import { Tab } from '@headlessui/react';
import clsx from 'clsx';

import backgroundImage from '../images/background-features.jpg';
import screenshotExpenses from '../images/screenshots/screenshot4.png';
import screenshotPayroll from '../images/screenshots/form.png';
import screenshotReporting from '../images/screenshots/screenshot7.png';
import screenshotVatReturns from '../images/screenshots/screenshot6.png';

const features = [
  {
    title: 'Pre-Check',
    description:
      "The first step in the process is creating an account and uploading the required personal documents to get prequalified by our AI. Once that has been done, whenever you find the business you're looking to move forward with we'll be able to get you financing for it.",
    image: screenshotExpenses,
  },
  {
    title: 'Loan Application',
    description:
      "Easily fill out our streamlined loan application form, designed to capture all the necessary information to see if you are a good fit for an SBA loan.",
    image: screenshotPayroll,
  },
  {
    title: 'Lender Matching',
    description:
      "Our intelligent algorithms analyze your application and documents to match you with the most suitable lenders based on your specific requirements and eligibility. We strive to find you the best possible loan options.",
    image: screenshotVatReturns,
  },
  {
    title: 'Term Sheet',
    description:
      "Once all business documents, personal documents, and the loan application have been submitted, we will provide you with term sheets within 24 hours.",
    image: screenshotReporting,
  },
];

function Container({ className, children }) {
  return <div className={`mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ${className}`}>{children}</div>;
}

export function PrimaryFeatures() {
  const [tabOrientation, setTabOrientation] = useState('horizontal');

  useEffect(() => {
    const lgMediaQuery = window.matchMedia('(min-width: 1024px)');

    const onMediaQueryChange = ({ matches }) => {
      setTabOrientation(matches ? 'vertical' : 'horizontal');
    };

    onMediaQueryChange(lgMediaQuery);
    lgMediaQuery.addListener(onMediaQueryChange);

    return () => {
      lgMediaQuery.removeListener(onMediaQueryChange);
    };
  }, []);

  return (
    <section
      id="#process"
      aria-label="Features for running your books"
      className="relative overflow-hidden bg-gradient-to-r from-blue-700 to-blue-300 pb-28 pt-20 sm:py-32"
    >
      <Container className="relative">
        <div className="max-w-2xl mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl text-center tracking-tight text-white sm:text-4xl md:text-5xl">
            Everything you need to secure your SBA loan.
          </h2>
          <p className="mt-0 text-lg tracking-tight text-blue-100"></p>
        </div>
        <Tab.Group
          as="div"
          className="mt-4 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-12 lg:grid-cols-12 lg:pt-0"
          vertical={tabOrientation === 'vertical'}
        >
          {({ selectedIndex }) => (
            <>
              <div className="flex flex-col items-center -mx-4 overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
                <Tab.List className="relative z-10 grid grid-cols-2 gap-2 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
                  {features.map((feature, featureIndex) => (
                    <div
                      key={feature.title}
                      className={clsx(
                        'group relative rounded-full p-3 lg:rounded-l-xl lg:rounded-r-none lg:p-6',
                        selectedIndex === featureIndex
                          ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10'
                          : 'hover:bg-white/10 lg:hover:bg-white/5'
                      )}
                    >
                      <h3>
                        <Tab
                          className={clsx(
                            'font-display font-bold text-sm focus:outline-none md:text-base',
                            selectedIndex === featureIndex
                              ? 'text-blue-900 lg:text-white'
                              : 'text-blue-100 hover:text-white lg:text-white'
                          )}
                        >
                          <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none" />
                          {featureIndex < 2 ? feature.mobileTitle || feature.title : feature.title}
                        </Tab>
                      </h3>
                      <p
                        className={clsx(
                          'mt-2 hidden text-sm lg:block',
                          selectedIndex === featureIndex
                            ? 'text-white'
                            : 'text-blue-100 group-hover:text-white'
                        )}
                      >
                        {feature.description}
                      </p>
                    </div>
                  ))}
                </Tab.List>
              </div>
              <Tab.Panels className="lg:col-span-7">
                {features.map((feature) => (
                  <Tab.Panel key={feature.title} className="mt-0 lg:mt-0 lg:w-[67.8125rem]">
                    <div className="relative sm:px-6 lg:hidden">
                      <p className="relative mx-auto max-w-2xl text-base text-white sm:text-center mb-4">
                        {feature.description}
                      </p>
                    </div>
                    <div className="flex flex-col items-center mb-4">
                      <img
                        className="w-full rounded-xl"
                        src={feature.image}
                        alt=""
                        sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem"
                      />
                    </div>
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </>
          )}
        </Tab.Group>
      </Container>
    </section>
  );
}

function App() {
  return (
    <div>
      <PrimaryFeatures />
    </div>
  );
}

export default App;
