import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { documentRequirements } from './documentTracker';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';

const DealRoom = () => {
  const [organizedFiles, setOrganizedFiles] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));

  const categorizeFiles = async (files) => {
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file);
      });

      // Add metadata
      const metadata = JSON.stringify({
        userId: 'user_id_here', // Replace with actual user ID
        timestamp: new Date().toISOString(),
      });
      formData.append('metadata', metadata);

      const response = await axios.post('https://sba-30d9855b23ef.herokuapp.com/categorize-files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${authToken}`,
        },
      });

      console.log('Backend response:', response.data);

      return response.data.categorized_files.map(result => {
        console.log('Trying to match file:', result.filename);
        console.log('Available files:', files.map(f => ({ name: f.name, path: f.path, webkitRelativePath: f.webkitRelativePath })));
        
        const matchedFile = files.find(f => 
          f.name === result.filename ||
          f.path === result.filename ||
          f.webkitRelativePath === result.filename
        );
        
        console.log('Matched file:', matchedFile);
        
        return {
          file: matchedFile,
          category: {
            Name: result.category.Name,
            Labels: [result.category.Label]
          }
        };
      });
    } catch (error) {
      console.error('Error categorizing files:', error);
      return [];
    }
  };

  const organizeFiles = (categorizedResults) => {
    const newOrganizedFiles = { ...organizedFiles };

    categorizedResults.forEach(({ file, category }) => {
      if (!category) return;

      const categoryName = category.Name || 'Uncategorized';
      const label = category.Labels && category.Labels.length > 0 ? category.Labels[0] : 'Uncategorized';

      if (!newOrganizedFiles[categoryName]) {
        newOrganizedFiles[categoryName] = {};
      }
      if (!newOrganizedFiles[categoryName][label]) {
        newOrganizedFiles[categoryName][label] = [];
      }
      newOrganizedFiles[categoryName][label].push({ file });
    });

    return newOrganizedFiles;
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setIsLoading(true);
    const categorizedResults = await categorizeFiles(acceptedFiles);
    const newOrganizedFiles = organizeFiles(categorizedResults);
    setOrganizedFiles(newOrganizedFiles);
    setIsLoading(false);
  }, [categorizeFiles, organizeFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
    onDrop,
    noClick: true,
    noKeyboard: true
  });

  return (
    <div className="deal-room bg-gray-100 min-h-screen p-8">
      <h2 className="text-3xl font-bold mb-8 text-gray-900">Deal Room</h2>
      <div 
        {...getRootProps()} 
        className={`dropzone p-8 border-2 border-dashed rounded-xl text-center mb-8 transition-all duration-300 ${
          isDragActive ? 'border-blue-400 bg-blue-50' : 'border-gray-300 bg-white'
        }`}
      >
        <input {...getInputProps()} directory="" webkitdirectory="" type="file" />
        {isDragActive ? (
          <p className="text-lg text-blue-600">Drop the files or folders here ...</p>
        ) : (
          <div>
            <p className="text-lg text-gray-600 mb-4">Drag 'n' drop files or folders here</p>
            <button 
              onClick={() => document.querySelector('.dropzone input').click()}
              className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-300"
            >
              Select Files or Folders
            </button>
          </div>
        )}
      </div>
      {isLoading && <p className="text-lg text-gray-600 mb-8">Categorizing files...</p>}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {documentRequirements.map((category) => (
          <div key={category.DocumentID} className="bg-white rounded-xl shadow-md overflow-hidden">
            <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
              <h3 className="text-xl font-semibold text-gray-900">{category.Name}</h3>
            </div>
            <div className="px-6 py-4">
              <p className="text-sm text-gray-600 mb-4">{category.Description}</p>
              {category.Labels.map((label, index) => {
                const files = organizedFiles[category.Name]?.[label] || [];
                return (
                  <div key={index} className="mb-4">
                    <h4 className="text-md font-medium text-gray-800 mb-2">{label}</h4>
                    {files.length > 0 ? (
                      <ul className="list-disc pl-5">
                        {files.map((fileInfo, fileIndex) => (
                          <li key={fileIndex} className="text-sm text-gray-600">
                            {fileInfo.file.name} ({(fileInfo.file.size / 1024 / 1024).toFixed(2)} MB)
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-sm text-gray-500 italic">No file uploaded yet</p>
                    )}
                    <div className="mt-2 flex items-center">
                      {files.length > 0 ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500 mr-2" />
                      ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500 mr-2" />
                      )}
                      <span className="text-sm text-gray-600">
                        {files.length > 0 ? 'File uploaded' : 'File required'}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealRoom;