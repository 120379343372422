import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hero from './components/landingPage/hero';
import Entryform from './components/loanForm/Entryform';
import Login from './components/login';
import Cards from './components/landingPage/cardsStats';
import { Bottom } from './components/landingPage/CTA';
import Animation2 from './components/landingPage/animationOld';
import Portal from './components/customerPortal/portal';
import PrimaryFeatures from './components/landingPage/Features';
import { Header } from './components/landingPage/header';
import Footer from './components/landingPage/footer';
import SecondaryFeatures from './components/landingPage/secondary';
import StatSection from './components/landingPage/stats';
import AdminDashboard from './components/admin/AdminDashboard';
import AdminSignUp from './components/admin/adminsignup';
import AdminLogin from './components/admin/adminlogin';
import ForgotPassword from './components/forgotpassword';
import LoanDetails from './components/admin/loandetails';
import FormEditor from './components/customerPortal/fullApplication';
import LoanApplicationForm from './components/customerPortal/loanform';
import AuthFormSearcher from './components/loanForm/AuthFormSearcher';
import AuthFormApplication from './components/loanForm/AuthFormApplication';
import BankForm from './components/bank/bankform';
import BankPortal from './components/bank/BankPortal';
import BankLoanDetails from './components/bank/bankloandetails';
import BrokerPortal from './components/Seller/brokerPortal';
import BrokerSignup from './components/Seller/brokerSignup';
import PrequalificationDetails from './components/admin/prequalificationDetails';
import BankPrequalificationDetails from './components/bank/BankPrequalificationDetails';
import LoanCalculator from './components/landingPage/LoanCalculator';
import AuthFormWorking from './components/loanForm/AuthFormWorking';
import Blog from './components/blog/Blog'; // Add this import
import BlogPost from './components/blog/BlogPost';
import AuthFormRefinance from './components/loanForm/AuthFormRefinance'; // Add this import
import SharedUploadPage from './components/admin/SharedUploadPage';
import WhiteLabelApplicationPage from './components/Seller/WhiteLabel';
import EmailVerificationUpload from './components/admin/EmailVerificationUpload';
import { EligibilityMeeting } from './components/landingPage/EligibilityMeeting';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <Hero />
            <PrimaryFeatures />
            <SecondaryFeatures/>
            <StatSection/>
            <section className="mt-20 mb-20">
              <Bottom />
            </section>
            <Footer />
          </>
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/searchersignup" element={<AuthFormSearcher />} />
        <Route path="/workingcapitalsignup" element={<AuthFormWorking />} />
        <Route path="/signup" element={<AuthFormApplication />} />
        <Route path="/refinancesignup" element={<AuthFormRefinance />} /> // Add this new route
        <Route path="/portal" element={<Portal />} />
        <Route path="/admindashboard" element={<AdminDashboard />} />
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/adminsignup" element={<AdminSignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/loan-details/:loanId" element={<LoanDetails />} />
        <Route path="/loanform" element={<LoanApplicationForm />} />
        <Route path="/entryform" element={<Entryform />} />
        <Route path="/add-bank" element={<BankForm />} />
        <Route path="/bank-portal" element={<BankPortal />} />      
        <Route path="/bank-loan-details/:loanId" element={<BankLoanDetails />} />     
        <Route path="/broker-signup" element={<BrokerSignup />} />
        <Route path="/broker-portal" element={<BrokerPortal />} />
        <Route path="/prequalification-details/:prequalId" element={<PrequalificationDetails />} />
        <Route path="/bank-portal/prequalification/:prequalId" element={<BankPrequalificationDetails />} />
        <Route path="/loan-calculator" element={
          <>
            <Header />
            <LoanCalculator />
            <Footer />
          </>
        } />
        <Route path="/blog" element={
          <>
            <Header />
            <Blog />
            <Footer />
          </>
        } />
        <Route path="/blog/:id" element={
          <>
            <Header />
            <BlogPost />
            <Footer />
          </>
        } />
        <Route path="/shared-upload-verify" element={<EmailVerificationUpload />} />
        <Route path="/shared-upload/:loanId" element={<SharedUploadPage />} />
        <Route path="/broker-application/:brokerId" element={<WhiteLabelApplicationPage />} />
        <Route path="/eligibility-meeting" element={<EligibilityMeeting />} />
      </Routes>
    </Router>
  );
}

export default App;
