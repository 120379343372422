import React from 'react';
import { Button } from './hero';
import { Container2 } from './Container2';
import backgroundImage from '../images/background-newsletter.jpg';

export function Bottom() {
  return (
    <section id="newsletter" aria-label="Newsletter">
      <Container2>
        <div className="relative overflow-hidden bg-indigo-50 rounded-3xl px-4 py-20 sm:px-6 md:px-16 xl:px-24 xl:py-36 mx-4 sm:mx-6 md:mx-8 lg:mx-10">
          <div className="polygon-container">
            <div className="polygon polygon-1"></div>
            <div className="polygon polygon-2"></div>
            <div className="polygon polygon-3"></div>
          </div>
          <img
            className="absolute left-1/2 top-0 translate-x-\[-10%\] translate-y-\[-45%\] lg:translate-x-\[-32%\]"
            src={backgroundImage}
            alt=""
            width={919}
            height={1351}
          />
          <div className="relative mx-auto max-w-2xl">
            <div className="text-center">
              <p className="font-display text-4xl font-medium tracking-tighter text-blue-900 sm:text-5xl">
                Find out if you qualify for SBA 7(a) Funding in Minutes
              </p>
            </div>
            <div className="mt-8 flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <Button to="/broker-signup">Prequalify My Business</Button>
              <Button to="/searchersignup" variant="secondary">I'm a Searcher</Button>
            </div>
          </div>
        </div>
      </Container2>
    </section>
  );
}