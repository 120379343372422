import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAzgLKlcDIfQE2wHmWuQN1xDfMa6X9awUM",
  authDomain: "sba-loan-5e4a6.firebaseapp.com",
  projectId: "sba-loan-5e4a6",
  storageBucket: "sba-loan-5e4a6.appspot.com",
  messagingSenderId: "136356402281",
  appId: "1:136356402281:web:56f1295d4a2a5840027ef8",
  measurementId: "G-V6EGXYHTL6"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { app, analytics, auth, googleProvider, db, storage, functions };