import React from 'react';

const FinancialInfo = ({ formData, handleChange }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, '');
    handleChange({ target: { name, value: numericValue } });
  };

  return (
    <div className="mb-6">
      <div className="flex items-center mb-4">
        <h3 className="font-semibold text-gray-800">Financial Information</h3>
        <div className="relative ml-2">
          <div className="cursor-pointer group">
            <span className="inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-500 rounded-full">
              i
            </span>
            <div className="absolute z-10 hidden w-64 px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-lg group-hover:block">
              Please enter the SDE and Revenue for the last financial year. If exact figures are unavailable, provide your best estimates.
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="ebitda" className="block mb-2 font-semibold text-gray-700">
          Seller Discretionary Earnings
        </label>
        <div className="relative">
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-blue-900">
            <span className="text-2xl font-bold">$</span>
          </span>
          <input
            type="text"
            name="ebitda"
            id="ebitda"
            value={formatCurrency(formData.ebitda)}
            onChange={handleInputChange}
            className="w-full pl-10 pr-4 py-3 text-2xl font-bold text-blue-900 text-center border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="Enter SDE"
          />
        </div>
      </div>
      <div>
        <label htmlFor="revenue" className="block mb-2 font-semibold text-gray-700">
          Revenue
        </label>
        <div className="relative">
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-blue-900">
            <span className="text-2xl font-bold">$</span>
          </span>
          <input
            type="text"
            name="revenue"
            id="revenue"
            value={formatCurrency(formData.revenue)}
            onChange={handleInputChange}
            className="w-full pl-10 pr-4 py-3 text-2xl font-bold text-blue-900 text-center border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="Enter Revenue"
          />
        </div>
      </div>
    </div>
  );
};

export default FinancialInfo;