import React from 'react';

const stats = [
  { id: 1, name: 'Number of loans approved(2023)', value: '57,000+' },
  { id: 2, name: 'SBA approval amount(2023)', value: '$27.5 billion' },
  { id: 3, name: 'Current WSJ prime rate', value: '8.00%' },
];

export default function StatSection() {
  return (
    <div className="py-0 sm:py-0">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-center mb-12 text-blue-800">
          <span className="inline-block bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
          SBA Loan Market Overview
          </span>
        </h2>
        <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3 bg-gradient-to-r from-blue-700 to-blue-300">
          {stats.map((stat, index) => (
            <div
              key={stat.id}
              className={`flex flex-col p-8 ${
                index === stats.length - 1 && 'sm:col-span-2 lg:col-span-1 sm:mx-auto'
              }`}
            >
              <dt className="text-sm font-semibold leading-6 text-white">{stat.name}</dt>
              <dd className="order-first text-3xl font-semibold tracking-tight text-white">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
}