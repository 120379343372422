import React from 'react';

function SelectQuestion({ name, label, options, value, onChange }) {
  const handleOptionClick = (option) => {
    onChange({ target: { name, value: option } });
  };

  return (
    <div className="mb-6">
      <label htmlFor={name} className="block mb-2 font-semibold text-gray-700">
        {label}
      </label>
      <div className="h-48 overflow-y-auto space-y-2">
        {options.map((option) => (
          <div
            key={option}
            className={`relative flex items-center px-4 py-3 text-lg font-semibold bg-white border border-gray-300 rounded-lg shadow-md cursor-pointer group hover:bg-gray-100 hover:border-blue-900 transition-colors duration-300 ${
              value === option ? 'bg-blue-100 text-blue-900 border-blue-900' : 'text-gray-700'
            }`}
            onClick={() => handleOptionClick(option)}
          >
            <span className="flex-1">{option}</span>
            {value === option && (
              <span className="absolute top-0 right-0 mt-1 mr-1">
                <svg
                  className="w-6 h-6 text-blue-900"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SelectQuestion;