import React, { useState, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const CreditBox = ({ bankId, bankInfo }) => {
  const [formData, setFormData] = useState({
    approvalPercentage: 0,
    creditScore: 0,
    debtServiceRequirement: 0,
    optimalLoanAmountMin: 0,
    optimalLoanAmountMax: 0,
    usualLoanRateMin: 0,
    usualLoanRateMax: 0,
    processTime: 0,
    termSheetTime: 0,
  });

  useEffect(() => {
    if (bankInfo) {
      setFormData({
        approvalPercentage: bankInfo.approvalPercentage || 0,
        creditScore: bankInfo.creditScore || 0,
        debtServiceRequirement: bankInfo.debtServiceRequirement || 0,
        optimalLoanAmountMin: bankInfo.optimalLoanAmountMin || 0,
        optimalLoanAmountMax: bankInfo.optimalLoanAmountMax || 0,
        usualLoanRateMin: bankInfo.usualLoanRateMin || 0,
        usualLoanRateMax: bankInfo.usualLoanRateMax || 0,
        processTime: bankInfo.processTime || 0,
        termSheetTime: bankInfo.termSheetTime || 0,
      });
    }
  }, [bankInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: parseFloat(value) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const bankRef = doc(db, 'banks', bankId);
      await updateDoc(bankRef, formData);
      alert('Credit box updated successfully!');
    } catch (error) {
      console.error('Error updating credit box:', error);
      alert('Error updating credit box. Please try again.');
    }
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-8 mb-8">
      <h2 className="text-3xl font-semibold mb-6 text-gray-800 border-b pb-2">Credit Box</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {Object.entries(formData).map(([key, value]) => (
            <div key={key} className="space-y-2">
              <label htmlFor={key} className="block text-sm font-medium text-gray-700 capitalize">
                {key.replace(/([A-Z])/g, ' $1').trim()}
              </label>
              <input
                id={key}
                name={key}
                type="number"
                value={value}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                min={key === 'creditScore' ? 300 : 0}
                max={key === 'creditScore' ? 850 : key === 'approvalPercentage' ? 100 : undefined}
                step={['approvalPercentage', 'debtServiceRequirement', 'usualLoanRateMin', 'usualLoanRateMax'].includes(key) ? '0.01' : '1'}
              />
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-6">
          <button
            type="submit"
            className="px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update Credit Box
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreditBox;
