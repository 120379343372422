// src/constants/statuses.js

export const LoanStatuses = {
  NEW_APPLICATION: 'New Application',
  SUBMITTED: 'Submitted',
  PREQUALIFIED_FOR: 'Prequalified',
  DOCUMENT_CLARIFICATION: 'Document Clarification',
  TERM_SHEET_EXTENDED: 'Term Sheet Extended',
  UNDERWRITING: 'Underwriting',
  FUNDED: 'Funded',
  REJECTED: 'Rejected',
  // Add other statuses as needed
};

export const StatusNextSteps = {
  [LoanStatuses.NEW_APPLICATION]: 'Submit Application',
  [LoanStatuses.SUBMITTED]: 'Await Prequalification',
  [LoanStatuses.PREQUALIFIED_FOR]: 'Await Document Review',
  [LoanStatuses.DOCUMENT_CLARIFICATION]: 'Answer Questions and Submit Needed Documents',
  [LoanStatuses.TERM_SHEET_EXTENDED]: 'Sign and Move into Underwriting',
  [LoanStatuses.UNDERWRITING]: 'Await Feedback/Further Questions',
  [LoanStatuses.FUNDED]: '',
  [LoanStatuses.REJECTED]: '',
};

