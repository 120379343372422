import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, updateDoc, arrayUnion, getDocs, query, where } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage, auth } from '../../firebase';
import { ArrowLeftIcon, ArrowDownTrayIcon, ArrowUpIcon, ArrowDownIcon, ArrowUpTrayIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { generateSummaryPDF } from '../admin/PdfGenerator';
import { BuildingOfficeIcon, ClockIcon, MapPinIcon, CurrencyDollarIcon, UserIcon } from '@heroicons/react/24/outline';
import LoanApplicationDisplay from './FinancialMetricsDisplayLoan';
import { DocumentIcon } from '@heroicons/react/24/outline';

const BankLoanDetails = () => {
  const { loanId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [loan, setLoan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);
  const bankId = location.state?.bankId;
  const [personalFinancialData, setPersonalFinancialData] = useState([]);
  const [activeGuarantorIndex, setActiveGuarantorIndex] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploadedFilesExpanded, setIsUploadedFilesExpanded] = useState(true);

  const [assumptions, setAssumptions] = useState({
    term: 10, // years
    rate: 10.5, // percentage
  });
  useEffect(() => {
    const fetchOCRData = async () => {
      if (!loanId) return;
  
      try {
        const ocrMetadataRef = collection(db, 'OCRMetadata');
        const q = query(ocrMetadataRef, where('loanApplicationId', '==', loanId));
        const querySnapshot = await getDocs(q);
        
        let personalFinancialOCRs = [];
  
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.documentName === "Personal Financial Statement") {
            personalFinancialOCRs.push(data.ocrResult);
          }
        });
  
        setPersonalFinancialData(personalFinancialOCRs);
      } catch (error) {
        console.error('Error fetching OCR data:', error);
      }
    };
  
    fetchOCRData();
  }, [loanId]);
  useEffect(() => {
    const fetchUploadedFiles = async () => {
      if (!loanId) return;

      try {
        console.log('Fetching files for loanId:', loanId);
        
        const q = query(
          collection(db, 'documentMetadata'),
          where('loanApplicationId', '==', loanId)
        );

        const querySnapshot = await getDocs(q);
        const files = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          fileType: doc.data().documentName || 'Unknown document type',
          fileLabel: doc.data().label || '',
          fileName: doc.data().fileName || 'Unnamed File'
        }));

        console.log('Fetched files:', files);
        setUploadedFiles(files);
      } catch (error) {
        console.error('Error fetching uploaded files:', error);
      }
    };

    fetchUploadedFiles();
  }, [loanId]);

  const toggleUploadedFiles = () => setIsUploadedFilesExpanded(!isUploadedFilesExpanded);

  const formatCurrency = (value) => {
    const numValue = parseFloat(value);
    return isNaN(numValue) ? '$0' : `$${numValue.toLocaleString()}`;
  };

  const renderFinancialItem = (label, value, isTotal = false) => (
    <div className={`py-2 ${isTotal ? 'font-bold' : ''}`}>
      <span className="text-gray-600">{label}:</span>
      <span className="float-right">{value !== undefined ? formatCurrency(value) : 'N/A'}</span>
    </div>
  );

  const calculateNetWorth = (guarantorData) => {
    if (!guarantorData) return 'N/A';
    
    // If Total Assets and Total Liabilities are provided, use them
    if (guarantorData.Assets?.Total !== undefined && guarantorData.Liabilities?.TotalLiabilities !== undefined) {
      return parseFloat(guarantorData.Assets.Total) - parseFloat(guarantorData.Liabilities.TotalLiabilities);
    }
    
    // Otherwise, calculate from individual items
    const assetKeys = ['CashOnHandAndInBanks', 'SavingsAccounts', 'StocksAndBonds', 'RealEstate', 'Automobiles', 'OtherAssets'];
    const liabilityKeys = ['AccountsPayable', 'NotesPayableToBanksAndOthers', 'OtherPersonalProperty','IRAOrOtherRetirementAccoun','InstallmentAccountAuto', 'InstallmentAccountOther', 'LoanAgainstLifeInsurance', 'MortgagesOnRealEstate', 'UnpaidTaxes', 'OtherLiabilities'];
    
    const totalAssets = assetKeys.reduce((sum, key) => sum + parseFloat(guarantorData.Assets?.[key] || 0), 0);
    const totalLiabilities = liabilityKeys.reduce((sum, key) => sum + parseFloat(guarantorData.Liabilities?.[key] || 0), 0);
    
    return totalAssets - totalLiabilities;
  };

  useEffect(() => {
    const fetchLoanDetails = async () => {
      if (!loanId || !bankId) {
        console.error('Loan ID or Bank ID is missing');
        navigate('/bank-portal');
        return;
      }

      try {
        const loanDoc = await getDoc(doc(db, 'loanApplications', loanId));
        if (loanDoc.exists()) {
          const loanData = loanDoc.data();
          console.log("Fetched loan data:", loanData);

          // Fetch saved answers
          const savedAnswersRef = doc(db, 'SavedAnswers', loanId);
          const savedAnswersDoc = await getDoc(savedAnswersRef);
          
          if (savedAnswersDoc.exists()) {
            loanData.savedAnswers = savedAnswersDoc.data().questions;
            console.log("Fetched saved answers:", loanData.savedAnswers);
          } else {
            console.log("No saved answers document found");
          }

          setLoan({ id: loanDoc.id, ...loanData });
        } else {
          console.error('Loan not found');
          navigate('/bank-portal');
        }
      } catch (error) {
        console.error('Error fetching loan details:', error);
      }
    };

    fetchLoanDetails();
  }, [loanId, bankId, navigate]);

  useEffect(() => {
    const generatePDF = async () => {
      if (loan) {
        try {
          const blob = await generateSummaryPDF(loan);
          setPdfBlob(blob);
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      }
    };

    generatePDF();
  }, [loanId, loan]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('User is signed in:', user.uid);
      } else {
        console.log('No user is signed in');
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!loan) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  const handleDownloadAllFiles = async () => {
    if (!loanId || !bankId) {
      console.error('Loan ID or Bank ID is missing');
      return;
    }

    try {
      // Fetch the bank assignment
      const assignmentsRef = collection(db, 'bankAssignments');
      const assignmentQuery = query(assignmentsRef, 
        where('bankId', '==', bankId),
        where('applicationId', '==', loanId)
      );
      const assignmentSnapshot = await getDocs(assignmentQuery);

      if (!assignmentSnapshot.empty) {
        const assignmentDoc = assignmentSnapshot.docs[0].data();
        const dropboxLink = assignmentDoc.dropboxLink;

        if (dropboxLink) {
          window.open(dropboxLink, '_blank');
        } else {
          alert('No Dropbox link available for this loan.');
        }
      } else {
        console.error('No matching bank assignment found');
        alert('No bank assignment found for this loan.');
      }
    } catch (error) {
      console.error('Error fetching Dropbox link:', error);
      alert('An error occurred while trying to fetch the Dropbox link.');
    }
  };

  const getFinancialData = (key) => {
    const years = ['2 Years Ago', 'Last Year', 'Current Year'];
    return years.map(year => ({
      name: year,
      value: loan?.financialAnalysis?.[`Business Tax Return - ${year}`]?.[key] || 0
    }));
  };

  const GRAPH_COLORS = ['#008081', '#6593F5', '#3FE0D0'];

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const FinancialStatCard = ({ item }) => (
    <div className="overflow-hidden rounded-lg bg-white px-6 py-8 shadow sm:p-8">
      <dt className="truncate text-lg font-medium text-gray-500">{item.name}</dt>
      <dd className="mt-2 flex flex-col">
        <div className="flex items-baseline justify-between">
          <div className="flex items-baseline text-3xl font-semibold text-black">
            {item.stat}
            <span className="ml-2 text-base font-medium text-gray-500">from {item.previousStat}</span>
          </div>
          <div
            className={classNames(
              item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
              'inline-flex items-baseline rounded-full px-3 py-1 text-base font-medium'
            )}
          >
            {item.changeType === 'increase' ? (
              <ArrowUpIcon className="-ml-1 mr-0.5 h-6 w-6 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
            ) : (
              <ArrowDownIcon className="-ml-1 mr-0.5 h-6 w-6 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
            )}
            <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
            {item.change}
          </div>
        </div>
        <div className="mt-6 h-48">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={item.data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill={item.color} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </dd>
    </div>
  );

  const financialStats = [
    { 
      name: 'DSCR', 
      stat: loan?.financialAnalysis?.['Business Tax Return - Current Year']?.dscr?.toFixed(2) || 'N/A', 
      previousStat: loan?.financialAnalysis?.['Business Tax Return - Last Year']?.dscr?.toFixed(2) || 'N/A', 
      change: ((loan?.financialAnalysis?.['Business Tax Return - Current Year']?.dscr / loan?.financialAnalysis?.['Business Tax Return - Last Year']?.dscr - 1) * 100).toFixed(2) + '%' || 'N/A',
      changeType: loan?.financialAnalysis?.['Business Tax Return - Current Year']?.dscr > loan?.financialAnalysis?.['Business Tax Return - Last Year']?.dscr ? 'increase' : 'decrease',
      data: getFinancialData('dscr'),
      color: GRAPH_COLORS[0]
    },
    { 
      name: 'Net Operating Income', 
      stat: `$${loan?.financialAnalysis?.['Business Tax Return - Current Year']?.noi?.toLocaleString() || 'N/A'}`, 
      previousStat: `$${loan?.financialAnalysis?.['Business Tax Return - Last Year']?.noi?.toLocaleString() || 'N/A'}`, 
      change: ((loan?.financialAnalysis?.['Business Tax Return - Current Year']?.noi / loan?.financialAnalysis?.['Business Tax Return - Last Year']?.noi - 1) * 100).toFixed(2) + '%' || 'N/A',
      changeType: loan?.financialAnalysis?.['Business Tax Return - Current Year']?.noi > loan?.financialAnalysis?.['Business Tax Return - Last Year']?.noi ? 'increase' : 'decrease',
      data: getFinancialData('noi'),
      color: GRAPH_COLORS[1]
    },
    { 
      name: 'Ordinary Business Income', 
      stat: `$${loan?.financialAnalysis?.['Business Tax Return - Current Year']?.ordinaryBusinessIncome?.toLocaleString() || 'N/A'}`, 
      previousStat: `$${loan?.financialAnalysis?.['Business Tax Return - Last Year']?.ordinaryBusinessIncome?.toLocaleString() || 'N/A'}`, 
      change: ((loan?.financialAnalysis?.['Business Tax Return - Current Year']?.ordinaryBusinessIncome / loan?.financialAnalysis?.['Business Tax Return - Last Year']?.ordinaryBusinessIncome - 1) * 100).toFixed(2) + '%' || 'N/A',
      changeType: loan?.financialAnalysis?.['Business Tax Return - Current Year']?.ordinaryBusinessIncome > loan?.financialAnalysis?.['Business Tax Return - Last Year']?.ordinaryBusinessIncome ? 'increase' : 'decrease',
      data: getFinancialData('ordinaryBusinessIncome'),
      color: GRAPH_COLORS[2]
    }
  ];

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleUploadTermSheet = async () => {
    if (!file || !bankId || !loanId) {
      console.error('Missing required information');
      return;
    }
  
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user is currently signed in');
      }
  
      console.log('Current user:', user.uid);
      const idTokenResult = await user.getIdTokenResult(true);
      console.log('User claims:', idTokenResult.claims);
      console.log('Bank ID:', bankId);
      console.log('Loan ID:', loanId);
  
      // Upload file to Storage
      const storageRef = ref(storage, `termSheets/${bankId}/${loanId}/${file.name}`);
      console.log('Attempting to upload to:', storageRef.fullPath);
      await uploadBytes(storageRef, file);
      console.log('File uploaded successfully');
  
      const downloadURL = await getDownloadURL(storageRef);
      console.log('Download URL:', downloadURL);
  
      // Add document to Firestore
      const termSheetRef = await addDoc(collection(db, 'termSheets'), {
        bankId: bankId,
        loanApplicationId: loanId,
        fileName: file.name,
        fileUrl: downloadURL,
        uploadedAt: new Date(),
        status: 'pending',
        uploadedBy: user.uid,
      });
      console.log('Term sheet document added to Firestore');
  
      // Update loanApplication document with termSheet reference and termSheetReceived flag
      await updateDoc(doc(db, 'loanApplications', loanId), {
        termSheets: arrayUnion(termSheetRef.id),
        termSheetReceived: true
      });
      console.log('Loan application updated with term sheet reference and flag');
  
      console.log('Term sheet upload process completed successfully');
  
      // Fetch loan application details
      const loanRef = doc(db, 'loanApplications', loanId);
      const loanSnap = await getDoc(loanRef);
      if (loanSnap.exists()) {
        const loanData = loanSnap.data();
        await createEmailDocument(loanData, downloadURL);
  
        // Check if the loan application is associated with a broker
        const brokersRef = collection(db, 'brokers');
        const brokerQuery = query(brokersRef, where('userId', '==', loanData.userId));
        const brokerSnapshot = await getDocs(brokerQuery);
  
        if (!brokerSnapshot.empty) {
          const brokerData = brokerSnapshot.docs[0].data();
          await createBrokerEmailDocument(brokerData, loanData);
        }
      } else {
        console.error('Loan application not found');
      }
  
      setIsModalOpen(false);
      setFile(null);
    } catch (error) {
      console.error('Error in term sheet upload process:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      alert(`Error uploading term sheet: ${error.message}`);
    }
  };
  
  const createBrokerEmailDocument = async (brokerData, loanData) => {
    try {
      console.log('Creating email document for broker');
      const emailRef = collection(db, 'email');
  
      const loginUrl = "https://lended.ai/login"; // Replace with your actual login URL
  
      const emailData = {
        to: brokerData.email,
        from: "max@sbacapital.ai",
        replyTo: "max@sbacapital.ai",
        subject: "A term sheet has been uploaded for your client's loan application!",
        templateName: "BrokerTermSheetNotification",
        message: {
          subject: "A term sheet has been uploaded for your client's loan application!",
          firstName: brokerData.firstName,
          loanDetails: `A bank has uploaded a term sheet for your client's (${loanData.firstName} ${loanData.lastName}) loan application. Please log in to review the details.`,
          html: `<p>Hello ${brokerData.firstName},</p>
  
  <p>Great news! A bank has reviewed your client's loan application and uploaded a term sheet for consideration. This is an important step in the loan process.</p>
  
  <p>Client: ${loanData.firstName} ${loanData.lastName}</p>
  
  <p>Please <a href="${loginUrl}">log in to your account</a> to review the term sheet and the details of the offer.</p>
  
  <p>If you have any questions about the term sheet or need assistance understanding the terms, don't hesitate to reach out to us.</p>
  
  <p>We're excited about this progress in your client's loan application!</p>
  
  <p>Best regards,</p>
  <p>Max Krakow</p>
  <p>Lended</p>
  `,
        },
      };
  
      console.log('Broker email data prepared:', JSON.stringify(emailData, null, 2));
  
      const emailDoc = await addDoc(emailRef, emailData);
      console.log('Broker email document created with ID:', emailDoc.id);
      return emailDoc.id;
    } catch (error) {
      console.error('Error creating broker email document:', error);
      console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      throw error;
    }
  };

  const createEmailDocument = async (loanData, termSheetUrl) => {
    try {
      console.log('Creating email document for loan applicant');
      const emailRef = collection(db, 'email');

      const loginUrl = "https://lended.ai/login"; // Replace with your actual login URL

      const emailData = {
        to: loanData.email,
        from: "max@sbacapital.ai",
        replyTo: "max@sbacapital.ai",
        subject: "You've received a term sheet for your loan application!",
        templateName: "TermSheetNotification",
        message: {
          subject: "You've received a term sheet for your loan application!",
          firstName: loanData.firstName,
          loanDetails: "A bank has uploaded a term sheet for your loan application. Please log in to review the details.",
          html: `<p>Hello ${loanData.firstName},</p>

<p>Great news! A bank has reviewed your loan application and uploaded a term sheet for your consideration. This is an important step in the loan process.</p>

<p>Please <a href="${loginUrl}">log in to your account</a> to review the term sheet and the details of the offer.</p>

<p>If you have any questions about the term sheet or need assistance understanding the terms, don't hesitate to reach out to us.</p>

<p>We're excited about this progress in your loan application!</p>

<p>Best regards,</p>
<p>Max Krakow</p>
<p>Lended</p>
`,
        },
      };

      console.log('Email data prepared:', JSON.stringify(emailData, null, 2));

      const emailDoc = await addDoc(emailRef, emailData);
      console.log('Email document created with ID:', emailDoc.id);
      return emailDoc.id;
    } catch (error) {
      console.error('Error creating email document:', error);
      console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      throw error;
    }
  };

  const handleRejectLoan = async () => {
    if (!loanId || !bankId) {
      console.error('Loan ID or Bank ID is missing');
      return;
    }

    try {
      // Update the bank assignment status
      const assignmentsRef = collection(db, 'bankAssignments');
      const assignmentQuery = query(assignmentsRef, 
        where('bankId', '==', bankId),
        where('applicationId', '==', loanId)
      );
      const assignmentSnapshot = await getDocs(assignmentQuery);

      if (!assignmentSnapshot.empty) {
        const assignmentDoc = assignmentSnapshot.docs[0];
        await updateDoc(doc(db, 'bankAssignments', assignmentDoc.id), {
          currentStage: {
            name: 'bankRejected',
            timestamp: new Date()
          }
        });
        console.log('Loan application rejected successfully');
        navigate('/bank-portal'); // Redirect to bank portal
      } else {
        console.error('No matching bank assignment found');
        // You might want to show an error message to the user
      }
    } catch (error) {
      console.error('Error rejecting loan application:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      // You might want to add some user-friendly error handling here
    }
  };

  const getHighestCreditScore = (creditScores) => {
    if (!creditScores || typeof creditScores !== 'object') return 0;
    return Math.max(...Object.values(creditScores).map(score => parseInt(score, 10)));
  };

  const getAverageDSCR = (financialAnalysis) => {
    const dscrValues = Object.values(financialAnalysis).map((data) => parseFloat(data.dscr) || 0);
    const totalDSCR = dscrValues.reduce((sum, value) => sum + value, 0);
    return dscrValues.length > 0 ? totalDSCR / dscrValues.length : 0;
  };

  const getDSCRPoints = (dscr) => {
    if (dscr >= 1.75) return 20;
    if (dscr >= 1.5) return 16;
    if (dscr >= 1.35) return 12;
    if (dscr >= 1.25) return 8;
    if (dscr >= 1.15) return 4;
    return 0;
  };

  const calculateCollateralPoints = (realEstateAssets, loanSize, realEstateAppraisal) => {
    const totalCollateral = realEstateAssets + realEstateAppraisal;
    const collateralPercentage = (totalCollateral / loanSize) * 100;
    if (collateralPercentage >= 100) return 20;
    if (collateralPercentage >= 75) return 16;
    if (collateralPercentage >= 50) return 12;
    if (collateralPercentage >= 25) return 8;
    if (collateralPercentage > 0) return 4;
    return 0;
  };
  const renderWorkingCapitalUse = () => {
    if (loan.loanPurpose === 'workingCapital' && loan.workingCapitalUse) {
      return (
        <div className="mt-8 bg-blue-50 shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-blue-900">Working Capital Use</h3>
            <p className="mt-1 max-w-2xl text-sm text-blue-500">Details on how the working capital will be used</p>
          </div>
          <div className="border-t border-blue-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-blue-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-blue-500">Intended Use</dt>
                <dd className="mt-1 text-sm text-blue-900 sm:mt-0 sm:col-span-2">{loan.workingCapitalUse}</dd>
              </div>
            </dl>
          </div>
        </div>
      );
    }
    return null;
  };
  const calculateEquityPoints = (cashOnHand, savingsAccounts, stocksAndBonds, loanSize, monthlyPayment) => {
    const totalAssets = cashOnHand + savingsAccounts + stocksAndBonds;
    const sixMonthsPayments = monthlyPayment * 6;
    if (totalAssets >= 0.20 * loanSize + sixMonthsPayments) return 20;
    if (totalAssets >= 0.15 * loanSize + sixMonthsPayments) return 16;
    if (totalAssets >= 0.10 * loanSize + sixMonthsPayments) return 12;
    if (totalAssets >= 0.05 * loanSize + sixMonthsPayments) return 8;
    if (totalAssets >= 0.01 * loanSize) return 4;
    return 0;
  };

  const getCreditScorePoints = (creditScore) => {
    creditScore = parseInt(creditScore, 10);
    if (creditScore >= 725) return 20;
    if (creditScore >= 700) return 16;
    if (creditScore >= 650) return 12;
    if (creditScore >= 600) return 8;
    if (creditScore >= 570) return 4;
    return 0;
  };

  const getCriteriaText = (category, points) => {
    const criteria = {
      repayment: {
        20: 'DSCR: 1.75 3 most current years, 1.75 interim, 1.75 pro forma.',
        16: 'DSCR: 1.5 2 most current years, 1.5 interim, 1.5 pro forma.',
        12: 'DSCR: 1.35 most current year, 1.35 interim. 1.35 pro forma.',
        8: 'DSCR: 1.25 current year, 1.25 interim (minimum 6 months), 1.25 pro forma.',
        4: 'Projected DSCR of 1.15, start-up or existing business with no historical cash flow numbers.'
      },
      collateral: {
        20: 'Commercial/Residential real estate equal to 100% of the loan amount.',
        16: 'Commercial/Residential real estate equal to 75-99% of the loan amount.',
        12: 'Commercial/Residential real estate equal to 50-74% of the loan amount.',
        8: 'Commercial/Residential real estate equal to 25-49% of the loan amount.',
        4: 'Commercial/Residential real estate equal to 0-24% of the loan amount.'
      },
      management: {
        20: '3 or more years experience successfully managing the borrowing business or a similar business.',
        16: '1 to 3 years experience successfully managing the borrowing business or a similar business.',
        12: 'At least 3 years successful management experience with a small business.',
        8: 'At least 1 to 3 years of management experience with a small business.',
        4: 'No industry or management experience.'
      },
      credit: {
        20: 'FICO Score of 725 to 850.',
        16: 'FICO Score of 700 to 724.',
        12: 'FICO Score of 650 to 699.',
        8: 'FICO Score of 600 to 649.',
        4: 'FICO Score of 570 to 599.'
      },
      equity: {
        20: 'Enough liquid assets for 20% cash injection and 6 months of SBA payments.',
        16: 'Enough liquid assets for 15-19% cash injection and 6 months of SBA payments.',
        12: 'Enough liquid assets for 10-14% cash injection and 6 months of SBA payments.',
        8: 'Enough liquid assets for 5-9% cash injection and 6 months of SBA Payments',
        4: 'Enough liquid assets for less than 5% and not enough for 6 months of payments.'
      }
    };
    return criteria[category][points] || '';
  };

  const renderCreditEvaluationMatrix = () => {
    const getHighestCreditScore = (creditScores) => {
      if (!creditScores || typeof creditScores !== 'object') return 0;
      return Math.max(...Object.values(creditScores).map(score => parseInt(score, 10)));
    };

    const highestCreditScore = getHighestCreditScore(loan.creditScores);
    const creditScorePoints = getCreditScorePoints(highestCreditScore);

    const financialAnalysis = loan.financialAnalysis || {};
    const averageDSCR = getAverageDSCR(financialAnalysis);
    const dscrPoints = getDSCRPoints(averageDSCR);

    const realEstateAssets = parseFloat(loan.personalFinancialStatement?.Assets?.RealEstate || 0);
    const realEstateAppraisal = parseFloat(loan.realEstateAppraisal || 0);
    const loanSize = parseFloat(loan.loanSize || 0);
    const collateralPoints = calculateCollateralPoints(realEstateAssets, loanSize, realEstateAppraisal);

    const cashOnHand = parseFloat(loan.personalFinancialStatement?.Assets?.CashOnHandAndInBanks || 0);
    const savingsAccounts = parseFloat(loan.personalFinancialStatement?.Assets?.SavingsAccounts || 0);
    const stocksAndBonds = parseFloat(loan.personalFinancialStatement?.Assets?.StocksAndBonds || 0);
    const monthlyPayment = parseFloat(loan.calculatedMonthlyPayment || 0);
    const equityPoints = calculateEquityPoints(cashOnHand, savingsAccounts, stocksAndBonds, loanSize, monthlyPayment);

    const managementPoints = parseInt(loan.ResumeScore || 0, 10);

    const totalPoints = dscrPoints + collateralPoints + managementPoints + creditScorePoints + equityPoints;

    const highlightRow = (category, rowPoints) => {
      let actualPoints;
      switch (category) {
        case 'repayment':
          actualPoints = dscrPoints;
          break;
        case 'collateral':
          actualPoints = collateralPoints;
          break;
        case 'management':
          actualPoints = managementPoints;
          break;
        case 'credit':
          actualPoints = creditScorePoints;
          break;
        case 'equity':
          actualPoints = equityPoints;
          break;
        default:
          actualPoints = 0;
      }
      return actualPoints === rowPoints ? 'bg-yellow-100' : '';
    };
   
    return (
      <div className="mt-8">
        <h3 className="text-xl font-semibold leading-6 text-gray-900 mb-6">Credit Evaluation Matrix</h3>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Credit Evaluation Matrix</h3>
          </div>
          <div className="border-t border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Points Possible</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Repayment Ability</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Collateral</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Management</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Personal Credit</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Equity</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {[20, 16, 12, 8, 4].map(points => (
                  <tr key={points}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{points}</td>
                    <td className={`px-6 py-4 text-sm text-gray-500 ${highlightRow('repayment', points)}`}>{getCriteriaText('repayment', points)}</td>
                    <td className={`px-6 py-4 text-sm text-gray-500 ${highlightRow('collateral', points)}`}>{getCriteriaText('collateral', points)}</td>
                    <td className={`px-6 py-4 text-sm text-gray-500 ${highlightRow('management', points)}`}>{getCriteriaText('management', points)}</td>
                    <td className={`px-6 py-4 text-sm text-gray-500 ${highlightRow('credit', points)}`}>{getCriteriaText('credit', points)}</td>
                    <td className={`px-6 py-4 text-sm text-gray-500 ${highlightRow('equity', points)}`}>{getCriteriaText('equity', points)}</td>
                  </tr>
                ))}
                <tr className="bg-gray-50 font-bold">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">Total: {totalPoints}/100</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{dscrPoints}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{collateralPoints}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{managementPoints}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{creditScorePoints}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{equityPoints}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

const renderPersonalFinancialInfo = () => {
  if (personalFinancialData.length === 0 || !loan || !loan.personalGuarantors) {
    console.log('No personal financial data available:', { personalFinancialData, loan });
    return <div>No personal financial data available.</div>;
  }

  console.log('Active guarantor data:', personalFinancialData[activeGuarantorIndex]);

  const activeGuarantorData = personalFinancialData[activeGuarantorIndex];
  if (!activeGuarantorData) {
    console.log('No data for active guarantor:', { activeGuarantorIndex, personalFinancialData });
    return <div>No data available for the selected guarantor.</div>;
  }

  const calculateTotal = (obj) => {
    return Object.values(obj).reduce((sum, value) => sum + parseFloat(value || 0), 0);
  };

  const totalAssets = calculateTotal(activeGuarantorData.Assets);
  const totalLiabilities = calculateTotal(activeGuarantorData.Liabilities);

  return (
    <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Guarantor's Personal Financial Information</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="sm:divide-y sm:divide-gray-200">
          {/* Tabs for multiple guarantors */}
          <div className="mb-4 border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {loan.personalGuarantors.map((guarantor, index) => (
                <button
                  key={index}
                  onClick={() => setActiveGuarantorIndex(index)}
                  className={`${
                    index === activeGuarantorIndex
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                >
                  {`${guarantor.firstName} ${guarantor.lastName}`}
                </button>
              ))}
            </nav>
          </div>
          {/* Render active guarantor's data */}
          {activeGuarantorData && (
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
              <div>
                <h4 className="text-md font-medium text-gray-900 mb-2">Assets</h4>
                {renderFinancialItem("Cash on Hand", activeGuarantorData.Assets?.CashOnHandAndInBanks)}
                {renderFinancialItem("Savings Accounts", activeGuarantorData.Assets?.SavingsAccounts)}
                {renderFinancialItem("IRA or Other Retirement Account", activeGuarantorData.Assets?.IRAOrOtherRetirementAccount)}
                {renderFinancialItem("Other Personal Property", activeGuarantorData.Assets?.OtherPersonalProperty)}
                {renderFinancialItem("Stocks and Bonds", activeGuarantorData.Assets?.StocksAndBonds)}
                {renderFinancialItem("Real Estate", activeGuarantorData.Assets?.RealEstate)}
                {renderFinancialItem("Automobiles", activeGuarantorData.Assets?.Automobiles)}
                {renderFinancialItem("Other Assets", activeGuarantorData.Assets?.OtherAssets)}
                {renderFinancialItem("Total Assets", totalAssets, true)}
              </div>
              <div>
                <h4 className="text-md font-medium text-gray-900 mb-2">Liabilities</h4>
                {renderFinancialItem("Accounts Payable", activeGuarantorData.Liabilities?.AccountsPayable)}
                {renderFinancialItem("Notes Payable", activeGuarantorData.Liabilities?.NotesPayableToBanksAndOthers)}
                {renderFinancialItem("Auto Installment", activeGuarantorData.Liabilities?.InstallmentAccountAuto)}
                {renderFinancialItem("Other Installment", activeGuarantorData.Liabilities?.InstallmentAccountOther)}
                {renderFinancialItem("Life Insurance Loans", activeGuarantorData.Liabilities?.LoanAgainstLifeInsurance)}
                {renderFinancialItem("Mortgages", activeGuarantorData.Liabilities?.MortgagesOnRealEstate)}
                {renderFinancialItem("Unpaid Taxes", activeGuarantorData.Liabilities?.UnpaidTaxes)}
                {renderFinancialItem("Other Liabilities", activeGuarantorData.Liabilities?.OtherLiabilities)}
                {renderFinancialItem("Total Liabilities", totalLiabilities, true)}
              </div>
            </div>
          )}
          <div className="py-4 sm:py-5 sm:px-6">
            <div className="text-lg font-bold">
              Net Worth: {formatCurrency(calculateNetWorth(activeGuarantorData))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderQuestionsAndAnswers = () => {
  console.log("Loan object:", loan);
  console.log("Saved answers:", loan?.savedAnswers);

  if (!loan || !loan.savedAnswers || loan.savedAnswers.length === 0) {
    return <div>No questions and answers available. (Debug: {JSON.stringify({ loanExists: !!loan, savedAnswersExists: !!loan?.savedAnswers, savedAnswersLength: loan?.savedAnswers?.length })})</div>;
  }

  const personalQuestions = loan.savedAnswers.filter(qa => qa.type === 'personal');
  const businessQuestions = loan.savedAnswers.filter(qa => qa.type === 'business');

  return (
    <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Financial Analysis Questions</h3>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:px-6">
            <h4 className="text-md font-medium text-gray-900 mb-4">Personal Financial Questions</h4>
            <div className="space-y-4">
              {personalQuestions.map((qa, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm font-medium text-gray-700">{qa.question}</p>
                  <p className="mt-2 text-sm text-gray-600">{qa.response || 'No answer provided'}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="py-4 sm:py-5 sm:px-6">
            <h4 className="text-md font-medium text-gray-900 mb-4">Business Financial Questions</h4>
            <div className="space-y-4">
              {businessQuestions.map((qa, index) => (
                <div key={index} className="bg-gray-50 p-4 rounded-lg">
                  <p className="text-sm font-medium text-gray-700">{qa.question}</p>
                  <p className="mt-2 text-sm text-gray-600">{qa.response || 'No answer provided'}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderLoanStructureAndCapTable = () => {
  if (!loan || !loan.loanStructure) {
    return null;
  }

  const loanStructure = loan.loanStructure;
  const totalAmount = parseFloat(loan.loanSize) || 0;
  const capTable = loan.capTable || [];

  const categories = [
    { key: 'sba7aLoan', label: 'SBA 7(a)', color: 'bg-blue-500' },
    { key: 'equityInjection', label: 'Equity Injection', color: 'bg-green-500' },
    { key: 'sellersNote', label: 'Seller Note', color: 'bg-yellow-500' },
  ];

  const renderBar = (category) => {
    let percentage = 0;
    let amount = 0;

    if (category.key === "equityInjection") {
      percentage = parseFloat(loanStructure.equityInjection?.personal) || 0;
    } else {
      percentage = parseFloat(loanStructure[category.key]) || 0;
    }

    amount = (totalAmount * percentage / 100) || 0;
    const barWidth = percentage;

    return (
      <div 
        key={category.key}
        style={{ width: `${barWidth}%` }} 
        className={`h-full ${category.color} relative group`}
      >
        <div className="absolute inset-0 flex items-center justify-center">
          <span className="text-xs font-medium text-white">{percentage.toFixed(1)}%</span>
        </div>
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap">
          {category.label}: {formatCurrency(amount)}
        </div>
      </div>
    );
  };

  return (
    <div className="mt-8 space-y-6">
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Loan Structure</h3>
          <div className="h-10 flex rounded-lg overflow-hidden">
            {categories.map(renderBar)}
          </div>
          <div className="mt-4 flex flex-wrap justify-between text-xs text-gray-500">
            {categories.map(category => {
              let percentage = 0;
              let amount = 0;

              if (category.key === "equityInjection") {
                percentage = parseFloat(loanStructure.equityInjection?.personal) || 0;
              } else {
                percentage = parseFloat(loanStructure[category.key]) || 0;
              }

              amount = (totalAmount * percentage / 100) || 0;

              return (
                <div key={category.key} className="flex items-center mb-2 mr-4">
                  <div className={`w-3 h-3 ${category.color} rounded-full mr-1`}></div>
                  <span>{category.label}: {percentage.toFixed(1)}% ({formatCurrency(amount)})</span>
                </div>
              );
            })}
          </div>
          <div className="mt-4 text-sm font-medium text-gray-700">
            Total Amount: {formatCurrency(totalAmount)}
          </div>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Cap Table</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Percentage</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {capTable.map((item, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{item.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{item.percentage}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

  const calculateAnnualDebtService = (loanAmount) => {
    const monthlyRate = assumptions.rate / 12 / 100;
    const numberOfPayments = assumptions.term * 12;
    const monthlyPayment = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    return monthlyPayment * 12;
  };

  const calculateLoanStructure = () => {
    if (!loan || !loan.loanStructure) return {};

    const storedLoanStructure = loan.loanStructure;
    const projectedSalePrice = parseFloat(storedLoanStructure.projectedSalePrice) || 0;
    const loanPercentage = parseFloat(storedLoanStructure.loanPercentage) || 0;
    const workingCapital = parseFloat(storedLoanStructure.workingCapital) || 0;
    const guaranteeFee = parseFloat(storedLoanStructure.guaranteeFee) || 0;
    const closingCost = parseFloat(storedLoanStructure.closingCost) || 0;
    const packagingCost = parseFloat(storedLoanStructure.packagingCost) || 0;

    const totalProjectCost = projectedSalePrice + workingCapital + guaranteeFee + closingCost + packagingCost;
    const calculatedLoanAmount = totalProjectCost * (loanPercentage / 100);

    const annualDebtService = calculateAnnualDebtService(calculatedLoanAmount);

    return {
      projectedSalePrice,
      loanPercentage,
      workingCapital,
      guaranteeFee,
      closingCost,
      packagingCost,
      totalProjectCost,
      loanAmount: calculatedLoanAmount,
      annualDebtService,
    };
  };

  const renderAssumptions = () => {
    return (
      <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">DSCR Calculation Assumptions</h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Loan Term</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assumptions.term} years</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Interest Rate</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{assumptions.rate}%</dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <nav className="flex" aria-label="Breadcrumb">
                <ol className="flex items-center space-x-4">
                  <li>
                    <div>
                      <button
                        onClick={() => navigate(-1)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <ArrowLeftIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                        <span className="sr-only">Back</span>
                      </button>
                    </div>
                  </li>
                  <li className="flex items-center">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                      {`${loan.firstName || ''} ${loan.lastName || ''}'s Loan Details`}
                    </h2>
                  </li>
                </ol>
              </nav>
            </div>
            <div className="mt-4 flex md:mt-0 md:ml-4">
              <button
                onClick={handleDownloadAllFiles}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
              >
                <ArrowDownTrayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Download All Files
              </button>
              <button
                onClick={() => setIsModalOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mr-2"
              >
                <ArrowUpTrayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Upload Term Sheet
              </button>
              <button
                onClick={handleRejectLoan}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Reject Loan
              </button>
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-4 py-5 sm:px-6 bg-gradient-to-r from-gray-50 to-gray-100 border-b border-gray-200 flex justify-between items-center">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">Loan Files</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Documents provided by the applicant.</p>
              </div>
              <button
                onClick={toggleUploadedFiles}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {isUploadedFilesExpanded ? (
                  <ChevronUpIcon className="h-6 w-6" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6" />
                )}
              </button>
            </div>
            {isUploadedFilesExpanded && (
              <div className="border-t border-gray-200">
                <ul className="divide-y divide-gray-200">
                  {uploadedFiles.map((file) => (
                    <li key={file.id} className="flex items-center justify-between px-4 py-4 sm:px-6">
                      <div className="flex items-center">
                        <DocumentIcon className="h-5 w-5 text-gray-400 mr-2" />
                        <div className="text-sm leading-6 text-gray-900">
                          {file.fileName || 'Unnamed File'} - {file.fileType} ({file.fileLabel})
                        </div>
                      </div>
                      {file.fileUrl ? (
                        <a
                          href={file.fileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm font-medium text-blue-600 hover:text-blue-500"
                        >
                          View
                        </a>
                      ) : (
                        <span className="text-sm text-gray-500">No URL available</span>
                      )}
                    </li>
                  ))}
                  {uploadedFiles.length === 0 && (
                    <li className="px-4 py-4 sm:px-6 text-sm text-gray-500">No files found for this loan application.</li>
                  )}
                </ul>
              </div>
            )}
          </div>
          {/* Applicant Information Card */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-4 py-5 sm:px-6 bg-gradient-to-r from-gray-50 to-gray-100 border-b border-gray-200">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Applicant Information</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal and business details</p>
            </div>
            <div className="px-4 py-5 sm:p-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 lg:grid-cols-4">
                <div className="relative">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <BuildingOfficeIcon className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Business name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 font-semibold">{loan.businessName || 'N/A'}</dd>
                </div>
                <div className="relative">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <ClockIcon className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Business age
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 font-semibold">{loan.businessAge ? `${loan.businessAge} years` : 'N/A'}</dd>
                </div>
                <div className="relative">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <MapPinIcon className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Business state
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 font-semibold">{loan.businessState || 'N/A'}</dd>
                </div>
                <div className="relative">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <CurrencyDollarIcon className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    Loan Size
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 font-semibold">{formatCurrency(loan.loanSize)}</dd>
                </div>
              </dl>
              <div className="mt-6">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                  <UserIcon className="flex-shrink-0 mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  Business description
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{loan.businessSummary || 'N/A'}</dd>
              </div>
            </div>
          </div>
          {renderWorkingCapitalUse()}

          {renderLoanStructureAndCapTable()}

          {/* Detailed Financial Analysis */}
          <div className="mb-8">
            {loan ? (
              <LoanApplicationDisplay 
                financialAnalysis={loan.financialAnalysis || {}}
                loanApplication={loan}
              />
            ) : (
              <p>No financial analysis or loan structure data available.</p>
            )}
          </div>

          {/* DSCR Calculation Assumptions */}
          {renderAssumptions()}

          {/* Personal Financial Info */}
          {renderPersonalFinancialInfo()}

          {/* Questions and Answers Section */}
          {renderQuestionsAndAnswers()}

          {/* Loan Structure and Cap Table */}

          {/* Loan Details */}
          <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
  <div className="px-4 py-5 sm:px-6">
    <h3 className="text-lg leading-6 font-medium text-gray-900">Loan Details</h3>
    <p className="mt-1 max-w-2xl text-sm text-gray-500">Financial information about the loan.</p>
  </div>
  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
    <dl className="sm:divide-y sm:divide-gray-200">
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Loan amount</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatCurrency(loan.loanSize)}</dd>
      </div>
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Loan purpose</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{loan.loanPurpose || 'N/A'}</dd>
      </div>
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Revenue</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatCurrency(loan.revenue)}</dd>
      </div>
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">EBITDA</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatCurrency(loan.ebitda)}</dd>
      </div>
    </dl>
  </div>
</div>
        </div>
      </main>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setIsModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Upload Term Sheet
                      </Dialog.Title>
                      <div className="mt-2">
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="mt-1 block w-full text-sm text-slate-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-violet-50 file:text-violet-700
                            hover:file:bg-violet-100"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={handleUploadTermSheet}
                    >
                      Upload
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default BankLoanDetails;






