import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, addDoc, collection, updateDoc, arrayUnion, serverTimestamp, getDoc } from 'firebase/firestore';
import defaultLogo from '../images/images.png';
import { LoanStatuses } from '../constants/statuses';

const WhiteLabelApplicationPage = () => {
  const { brokerId } = useParams();
  const [businessName, setBusinessName] = useState('');
  const [yearsInBusiness, setYearsInBusiness] = useState('0');
  const [workingCapitalUse, setWorkingCapitalUse] = useState('');
  const [loanAmount, setLoanAmount] = useState('');
  const [numberOfEmployees, setNumberOfEmployees] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [creditScore, setCreditScore] = useState('');
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [brokerLogo, setBrokerLogo] = useState(defaultLogo);
  const [numberOfOwners, setNumberOfOwners] = useState('0');
  const [personalGuarantors, setPersonalGuarantors] = useState([{ firstName: '', lastName: '' }]);
  const [creditScores, setCreditScores] = useState(['']);
  const [emails, setEmails] = useState(['']);
  const [phoneNumbers, setPhoneNumbers] = useState(['']);
  const [hasBankruptcies, setHasBankruptcies] = useState(false);
  const [bankruptcyCount, setBankruptcyCount] = useState('');
  const [bankruptcyTimeframe, setBankruptcyTimeframe] = useState('');
  const [hasOutstandingTaxes, setHasOutstandingTaxes] = useState(false);
  const [outstandingTaxAmount, setOutstandingTaxAmount] = useState('');
  const [hasPendingLawsuits, setHasPendingLawsuits] = useState(false);
  const [hasNegativeIncome, setHasNegativeIncome] = useState(false);
  const [noRiskFactors, setNoRiskFactors] = useState(false);
  const [riskFactorsAnswered, setRiskFactorsAnswered] = useState(false);
  const [lastYearRevenue, setLastYearRevenue] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrokerLogo = async () => {
      const brokerDoc = await getDoc(doc(db, 'brokers', brokerId));
      if (brokerDoc.exists() && brokerDoc.data().logoUrl) {
        setBrokerLogo(brokerDoc.data().logoUrl);
      }
    };
    fetchBrokerLogo();
  }, [brokerId]);

  const createEmailDocument = async (loanData, uploadLink) => {
    try {
      console.log('Creating email document for loan applicant');
      const emailRef = collection(db, 'email');

      const emailData = {
        to: loanData.emails[0],
        from: "max@sbacapital.ai",
        replyTo: "max@sbacapital.ai",
        subject: "Your loan application has been submitted!",
        templateName: "LoanApplicationSubmission",
        message: {
          subject: loanData.businessName + " x Lended - Loan Application Recieved!",
          firstName: loanData.firstName || 'Valued Customer',
          loanDetails: `Your loan application has been submitted successfully. You can upload additional documents using the following link: ${uploadLink}`,
          html: `<p>Hello ${loanData.firstName || 'Valued Customer'},</p>
<p>I saw you got started on your loan application, so I wanted to reach out to see if you had any questions.</p>
<p>Once you get the required files uploaded, we'll be able to start putting together your application and should have some updates for you within 24 hours. In case you lost the upload link, you can find it <a href="lended.ai/${uploadLink}">here!</a></p>
<p>Best,</p>
<p>Max Krakow</p>
<p>CEO, Lended</p>
<p>Lended by SBA Capital AI LLC</p>
`,
        },
      };

      console.log('Email data prepared:', JSON.stringify(emailData, null, 2));

      const emailDoc = await addDoc(emailRef, emailData);
      console.log('Email document created with ID:', emailDoc.id);
      return emailDoc.id;
    } catch (error) {
      console.error('Error creating email document:', error);
      throw error;
    }
  };

  const sanitizeInput = (value, type) => {
    if (!value) return value;
    
    switch (type) {
      case 'number':
        const numericValue = value.toString().replace(/[^0-9]/g, '');
        return numericValue || '0';
      
      case 'email':
        return value.toString().trim().toLowerCase();
      
      case 'text':
        return value.toString().trim();
      
      default:
        return value;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isLoading) return;

    try {
      const now = serverTimestamp();
      
      const sanitizedData = {
        // Basic Information
        businessName: sanitizeInput(businessName, 'text'),
        yearsInBusiness: sanitizeInput(yearsInBusiness, 'number'),
        workingCapitalUse: sanitizeInput(workingCapitalUse, 'text'),
        loanSize: sanitizeInput(loanAmount, 'number'),
        numberOfEmployees: sanitizeInput(numberOfEmployees, 'number'),
        
        // Personal Information
        firstName: sanitizeInput(personalGuarantors[0]?.firstName, 'text'),
        lastName: sanitizeInput(personalGuarantors[0]?.lastName, 'text'),
        
        // Arrays - only sanitize non-empty values
        personalGuarantors: personalGuarantors.map(g => ({
          firstName: sanitizeInput(g.firstName, 'text'),
          lastName: sanitizeInput(g.lastName, 'text')
        })),
        
        creditScores: creditScores
          .filter(score => score !== null && score !== undefined && score !== '')
          .map(score => sanitizeInput(score, 'number')),
        
        emails: emails
          .filter(email => email)
          .map(email => sanitizeInput(email, 'email')),
        
        // Add phone numbers array
        phoneNumbers: phoneNumbers
          .filter(phone => phone)
          .map(phone => sanitizeInput(phone, 'number')),
        
        // Timestamps and Status
        createdAt: now,
        lastUpdated: now,
        lastUserUpdate: now,
        submissionTimestamp: now,
        
        // Fixed Values
        loanPurpose: "workingCapital",
        loanStatus: LoanStatuses.NEW_APPLICATION,
        userId: brokerId,
        
        loanStructure: {
          additionalNotes: "",
          sba7aLoan: "100"
        },
        
        // Add new fields
        riskFactors: {
          hasBankruptcies,
          bankruptcyCount: bankruptcyCount ? parseInt(bankruptcyCount) : 0,
          bankruptcyTimeframe,
          hasOutstandingTaxes,
          outstandingTaxAmount: outstandingTaxAmount ? parseInt(outstandingTaxAmount) : 0,
          hasPendingLawsuits,
          hasNegativeIncome
        },
        lastYearRevenue: sanitizeInput(lastYearRevenue, 'number'),
      };

      console.log('Submitting data:', sanitizedData);

      const docRef = await addDoc(collection(db, 'loanApplications'), sanitizedData);

      await updateDoc(doc(db, 'loanApplications', docRef.id), {
        id: docRef.id
      });

      try {
        const brokerRef = doc(db, 'brokers', brokerId);
        await updateDoc(brokerRef, {
          loanApplications: arrayUnion(docRef.id)
        });
      } catch (brokerError) {
        console.error('Error updating broker:', brokerError);
      }

      navigate(`/shared-upload/${docRef.id}`);
    } catch (error) {
      console.error("Submission error details:", {
        error: error.message,
        code: error.code,
        stack: error.stack,
        data: {
          businessName,
          yearsInBusiness,
          workingCapitalUse,
          loanAmount,
          numberOfEmployees,
          personalGuarantors,
          emails,
          creditScores
        }
      });
      alert('Error: ' + (error.message || 'Please check all fields and try again.'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleNumberInput = (e, setter) => {
    let value = e.target.value;
    
    // Ensure value is never undefined
    if (!value && value !== '') {
      value = '';
    }
    
    // Remove any non-numeric characters except decimal point
    value = value.toString().replace(/[^0-9.]/g, '');
    
    // Handle currency formatting for loan amount
    if (e.target.id === 'loanAmount') {
      // Remove the currency symbol and commas if present
      value = value.replace(/[$,]/g, '');
    } else {
      // For other number inputs, only allow integers
      value = value.replace(/\./g, '');
    }

    // Always set a string value
    setter(value);
  };

  const renderForm = () => {
    switch (step) {
      case 0:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">Business Name</label>
              <input
                type="text"
                id="businessName"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="yearsInBusiness" className="block text-sm font-medium text-gray-700">
                How many years have you been in business? (If startup, enter 0)
              </label>
              <input
                type="number"
                id="yearsInBusiness"
                value={yearsInBusiness}
                onChange={(e) => handleNumberInput(e, setYearsInBusiness)}
                min="0"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="lastYearRevenue" className="block text-sm font-medium text-gray-700">
                What was your revenue on your last tax return?
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  id="lastYearRevenue"
                  value={lastYearRevenue}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, '');
                    setLastYearRevenue(value);
                  }}
                  placeholder="0.00"
                  required
                  className="block w-full pl-7 pr-12 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="workingCapitalUse" className="block text-sm font-medium text-gray-700">Working Capital Use (Equipment, Debt Refinance etc)</label>
              <textarea
                id="workingCapitalUse"
                value={workingCapitalUse}
                onChange={(e) => setWorkingCapitalUse(e.target.value)}
                required
                rows={3}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="loanAmount" className="block text-sm font-medium text-gray-700">Loan Amount</label>
              <input
                type="text"
                id="loanAmount"
                value={loanAmount || ''}
                onChange={(e) => handleNumberInput(e, setLoanAmount)}
                inputMode="numeric"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="numberOfEmployees" className="block text-sm font-medium text-gray-700">Number of Employees</label>
              <input
                type="number"
                id="numberOfEmployees"
                value={numberOfEmployees}
                onChange={(e) => setNumberOfEmployees(e.target.value)}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                id="firstName"
                value={personalGuarantors[0].firstName}
                onChange={(e) => {
                  const newGuarantors = [...personalGuarantors];
                  newGuarantors[0].firstName = e.target.value;
                  setPersonalGuarantors(newGuarantors);
                }}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                id="lastName"
                value={personalGuarantors[0].lastName}
                onChange={(e) => {
                  const newGuarantors = [...personalGuarantors];
                  newGuarantors[0].lastName = e.target.value;
                  setPersonalGuarantors(newGuarantors);
                }}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumbers[0]}
                onChange={(e) => {
                  const newPhoneNumbers = [...phoneNumbers];
                  newPhoneNumbers[0] = e.target.value;
                  setPhoneNumbers(newPhoneNumbers);
                }}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                id="email"
                value={emails[0]}
                onChange={(e) => {
                  const newEmails = [...emails];
                  newEmails[0] = e.target.value;
                  setEmails(newEmails);
                }}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label htmlFor="creditScore" className="block text-sm font-medium text-gray-700">Credit Score</label>
              <input
                type="number"
                id="creditScore"
                value={creditScores[0]}
                onChange={(e) => {
                  const newCreditScores = [...creditScores];
                  newCreditScores[0] = e.target.value;
                  setCreditScores(newCreditScores);
                }}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-6">
            <div>
              <label htmlFor="numberOfOwners" className="block text-sm font-medium text-gray-700">
                How many additional owners have more than 20% ownership? (Do not include the owner's information you just entered)
              </label>
              <input
                type="number"
                id="numberOfOwners"
                value={numberOfOwners}
                onChange={(e) => {
                  const num = parseInt(e.target.value) || 0;
                  setNumberOfOwners(num);
                }}
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4">Risk Factors</h3>
              <p className="text-sm text-red-600 mb-4">* Please select at least one option or confirm none apply</p>
              
              {/* None Apply Option */}
              <div className="mb-6">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={noRiskFactors}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      setNoRiskFactors(checked);
                      setRiskFactorsAnswered(true);
                      if (checked) {
                        // Reset all other risk factors
                        setHasBankruptcies(false);
                        setHasOutstandingTaxes(false);
                        setHasPendingLawsuits(false);
                        setHasNegativeIncome(false);
                        setBankruptcyCount('');
                        setBankruptcyTimeframe('');
                        setOutstandingTaxAmount('');
                      }
                    }}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <span className="ml-2 text-sm font-medium text-gray-700">
                    None of these risk factors apply
                  </span>
                </label>
              </div>

              {/* Only show risk factors if "None Apply" is not checked */}
              {!noRiskFactors && (
                <div className="space-y-6">
                  <div className="mb-6">
                    <label className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={hasBankruptcies}
                        onChange={(e) => setHasBankruptcies(e.target.checked)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <span className="ml-2 text-sm text-gray-700">
                        Has the business or any owners filed for bankruptcy?
                      </span>
                    </label>
                    
                    {hasBankruptcies && (
                      <div className="ml-6 space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            How many bankruptcies?
                          </label>
                          <input
                            type="number"
                            value={bankruptcyCount}
                            onChange={(e) => setBankruptcyCount(e.target.value)}
                            min="0"
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            How recent was the last bankruptcy?
                          </label>
                          <select
                            value={bankruptcyTimeframe}
                            onChange={(e) => setBankruptcyTimeframe(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                          >
                            <option value="">Select timeframe</option>
                            <option value="0-3">0-3 years ago</option>
                            <option value="3-10">3-10 years ago</option>
                            <option value="10+">10+ years ago</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mb-6">
                    <label className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        checked={hasOutstandingTaxes}
                        onChange={(e) => setHasOutstandingTaxes(e.target.checked)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <span className="ml-2 text-sm text-gray-700">
                        Are there any outstanding taxes?
                      </span>
                    </label>
                    
                    {hasOutstandingTaxes && (
                      <div className="ml-6">
                        <label className="block text-sm font-medium text-gray-700">
                          Outstanding tax amount
                        </label>
                        <input
                          type="text"
                          value={outstandingTaxAmount}
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, '');
                            setOutstandingTaxAmount(value);
                          }}
                          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                          placeholder="Enter amount"
                        />
                      </div>
                    )}
                  </div>

                  <div className="mb-6">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={hasPendingLawsuits}
                        onChange={(e) => setHasPendingLawsuits(e.target.checked)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <span className="ml-2 text-sm text-gray-700">
                        Are there any pending lawsuits?
                      </span>
                    </label>
                  </div>

                  <div className="mb-6">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        checked={hasNegativeIncome}
                        onChange={(e) => setHasNegativeIncome(e.target.checked)}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      />
                      <span className="ml-2 text-sm text-gray-700">
                        Did the business had negative net income on its most recent tax return?
                      </span>
                    </label>
                  </div>
                </div>
              )}
            </div>

            {/* Add submit button directly in the form */}
            <div className="mt-8">
              <button
                type="submit"
                disabled={isLoading || !validateStep()}
                className="w-full inline-flex justify-center py-3 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
              >
                {isLoading ? 'Submitting...' : 'Submit Application'}
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const validateStep = () => {
    console.log('Validating step:', step, {
      businessName,
      yearsInBusiness,
      workingCapitalUse,
      loanAmount,
      numberOfEmployees,
      personalGuarantors,
      emails,
      creditScores
    });

    switch (step) {
      case 0:
        if (!businessName?.trim() || 
            yearsInBusiness === '' || 
            !lastYearRevenue) {
          console.log('Step 0 validation failed');
          return false;
        }
        return true;

      case 1:
        if (!workingCapitalUse?.trim() || 
            !loanAmount || 
            !numberOfEmployees) {
          console.log('Step 1 validation failed');
          return false;
        }
        return true;

      case 2:
        if (!personalGuarantors?.[0]?.firstName?.trim() || 
            !personalGuarantors?.[0]?.lastName?.trim() || 
            !emails?.[0]?.trim() || 
            !creditScores?.[0]) {
          console.log('Step 2 validation failed');
          return false;
        }
        return true;

      case 3:
        if (numberOfOwners === '' || numberOfOwners === undefined) {
          console.log('Step 3 validation failed');
          return false;
        }
        return true;

      case 4:
        // Check if either "None Apply" is checked or at least one risk factor is selected
        const hasAnyRiskFactorSelected = hasBankruptcies || 
          hasOutstandingTaxes || 
          hasPendingLawsuits || 
          hasNegativeIncome;
        if (!riskFactorsAnswered && !noRiskFactors) {
          console.log('Step 4 validation failed');
          return false;
        }
        return true;

      default:
        return true;
    }
  };

  const handleNext = () => {
    if (!validateStep()) {
      const errorMessages = {
        0: 'Please enter both Business Name and Years in Business',
        1: 'Please enter Working Capital Use, Loan Amount, and Number of Employees',
        2: 'Please enter Owner Name, Email, and Credit Score',
        3: 'Please specify the number of additional owners',
        4: 'Please complete all additional owner information'
      };
      
      alert(errorMessages[step] || 'Please fill out all required fields before proceeding.');
      return;
    }

    if (step === 3) {
      const numAdditionalOwners = parseInt(numberOfOwners) || 0;
      setPersonalGuarantors(prev => [
        prev[0],  // Keep the first owner
        ...Array(numAdditionalOwners).fill().map(() => ({ firstName: '', lastName: '' }))
      ]);
      setCreditScores(prev => [
        prev[0],  // Keep the first credit score
        ...Array(numAdditionalOwners).fill('')
      ]);
      setEmails(prev => [
        prev[0],  // Keep the first email
        ...Array(numAdditionalOwners).fill('')
      ]);
    }
    
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const formatCurrency = (value) => {
    if (!value) return '';
    return `$${Number(value).toLocaleString()}`;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="w-full max-w-6xl bg-white rounded-2xl shadow-xl overflow-hidden">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 bg-blue-600 p-12 text-white flex flex-col justify-center">
            <h2 className="text-4xl font-bold mb-4">Loan Application</h2>
            <p className="text-xl mb-8">Complete your application for working capital</p>
            <div className="bg-white bg-opacity-20 rounded-lg p-6">
              <div className="flex items-center">
                <p className="text-2xl font-semibold mb-2">Loan Amount ($25k- $5 million)</p>
              </div>
              <input
                type="text"
                value={formatCurrency(loanAmount)}
                onChange={(e) => handleNumberInput(e, setLoanAmount)}
                className="text-4xl font-bold bg-transparent border-none focus:outline-none"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 p-12">
            <div className="mb-8">
              <img
                className="h-12 w-auto mb-6"
                src={brokerLogo}
                alt="Broker Logo"
              />
              <h2 className="text-3xl font-bold text-gray-900 mb-2">
                Complete Your Application
              </h2>
              <p className="text-lg text-gray-600">
                Please provide some information about your business.
              </p>
            </div>
            <form onSubmit={handleSubmit} className="space-y-6">
              {renderForm()}
              <div className="flex justify-between">
                {step > 0 && (
                  <button
                    type="button"
                    onClick={handlePrevious}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Previous
                  </button>
                )}
                {step < 4 && (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Next
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteLabelApplicationPage;
