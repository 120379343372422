// CapTable.js

import React, { useState, useEffect } from 'react';

function CapTable({ name, label, value, onChange }) {
  const [owners, setOwners] = useState(value && value.length > 0 ? value : [{ name: '', percentage: '' }]);

  const handleAddOwner = () => {
    const updatedOwners = [...owners, { name: '', percentage: '' }];
    setOwners(updatedOwners);
  };

  const handleRemoveOwner = (index) => {
    if (owners.length > 1) {
      const updatedOwners = owners.filter((_, i) => i !== index);
      setOwners(updatedOwners);
    }
  };

  const handleOwnerChange = (e, index) => {
    const { name, value } = e.target;
    const updatedOwners = owners.map((owner, i) => (i === index ? { ...owner, [name]: value } : owner));
    setOwners(updatedOwners);
  };

  const calculatePG = (owners) => {
    return owners.filter((owner) => parseFloat(owner.percentage) > 20).length;
  };

  const totalPercentage = owners.reduce((sum, owner) => sum + (parseFloat(owner.percentage) || 0), 0);

  useEffect(() => {
    onChange(owners, calculatePG(owners));
  }, [owners]);

  return (
    <div>
      <div className="flex items-center mb-2">
        <label htmlFor={name} className="block font-semibold text-gray-700">
          {label}
        </label>
        <div className="relative ml-2">
          <div className="cursor-pointer group">
            <span className="inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-blue-500 rounded-full">
              i
            </span>
            <div className="absolute z-10 hidden w-64 px-4 py-2 mt-2 text-sm text-gray-700 bg-white border border-gray-300 rounded-lg shadow-lg group-hover:block">
              If you are acquiring a compant please provide the ownership structure of the new entity post-acquisition. You may group all owners below 20% into one owner.
            </div>
          </div>
        </div>
      </div>
      {owners.map((owner, index) => (
        <div key={index} className="mb-2">
          <input
            type="text"
            name="name"
            value={owner.name}
            onChange={(e) => handleOwnerChange(e, index)}
            className="w-1/2 px-4 py-2 mr-2 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="Owner Name"
            required
          />
          <input
            type="number"
            name="percentage"
            value={owner.percentage}
            onChange={(e) => handleOwnerChange(e, index)}
            className="w-1/4 px-4 py-2 mr-2 text-gray-700 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            placeholder="Ownership %"
            min="0"
            max="100"
            step="0.01"
            required
          />
          {owners.length > 1 && (
            <button
              type="button"
              onClick={() => handleRemoveOwner(index)}
              className="px-2 py-1 text-sm font-semibold text-white bg-red-500 rounded-lg hover:bg-red-600"
            >
              Remove
            </button>
          )}
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddOwner}
        className="px-2 py-1 text-sm font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600"
      >
        Add Owner
      </button>
      <p className="text-sm text-gray-500">Total Ownership: {totalPercentage.toFixed(2)}%</p>
      {totalPercentage !== 100 && <p className="text-sm text-red-500">Total ownership must equal 100%</p>}
    </div>
  );
}

export default CapTable;