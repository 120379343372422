import React, { useState, useEffect } from 'react';

function RealEstateAppraisalInput({ name, label, value, onChange }) {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(formatCurrency(value || 0));
  }, [value]);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const handleChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, '');
    const formattedValue = formatCurrency(rawValue);
    setInputValue(formattedValue);
    onChange({ target: { name, value: rawValue || 0 } });
  };

  const handleBlur = () => {
    if (inputValue === '') {
      setInputValue(formatCurrency(0));
      onChange({ target: { name, value: 0 } });
    }
  };

  return (
    <div className="flex flex-col items-center">
      <label htmlFor={name} className="block mb-2 font-semibold text-gray-700">
        {label}
      </label>
      <div className="relative">
        <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-blue-900">
          <span className="text-4xl font-bold">$</span>
        </span>
        <input
          type="text"
          name={name}
          id={name}
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          className="w-full pl-12 pr-4 py-4 text-4xl font-bold text-blue-900 text-center border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          placeholder="Enter appraisal value"
        />
      </div>
    </div>
  );
}

export default RealEstateAppraisalInput;
