import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, where, orderBy, limit, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import LightModeLoanCard from './LightModeLoanCard';
import { BarsArrowUpIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { BankManagement } from './BankManagement';
import { PreLenderCard, InTalksCard, ClosedDealCard, PrequalificationCard } from './LoanCards';
import { LoanStatuses } from '../constants/statuses';
import { format } from 'date-fns';


// Define separate tabs for each loan type
const acquisitionTabs = [
  { name: 'Recent Activity', section: 'recentActivity' },
  { name: 'Pre-Lender', section: 'preLender' },
  { name: 'In Talks', section: 'inTalks' },
  { name: 'Closed Deals', section: 'closedDeals' },
  { name: 'Bank Management', section: 'bankManagement' },
];

const workingCapitalTabs = [
  { name: 'Recent Activity', section: 'recentActivity' },
  { name: 'New Applications', section: LoanStatuses.NEW_APPLICATION },
  { name: 'Submitted', section: LoanStatuses.SUBMITTED },
  { name: 'Prequalified', section: LoanStatuses.PREQUALIFIED_FOR },
  { name: 'Document Review', section: LoanStatuses.DOCUMENT_CLARIFICATION },
  { name: 'Underwriting', section: LoanStatuses.UNDERWRITING },
  { name: 'Funded', section: LoanStatuses.FUNDED },
  { name: 'Bank Management', section: 'bankManagement' },
];

const loanPurposeFilters = [
  { value: 'all', label: 'All Purposes' },
  { value: 'Acquisition', label: 'Acquisition' },
  { value: 'workingCapital', label: 'Working Capital' },
  { value: 'workingCapital-startup', label: 'Working Capital (Startup)' },
  { value: 'workingCapital-nonsba', label: 'Working Capital (Non-SBA)' },
];

const LOAN_TYPE_FILTERS = [
  { value: 'all', label: 'All Types' },
  { value: 'Acquisition', label: 'Acquisition' },
  { value: 'workingCapital', label: 'Working Capital' },
  { value: 'calls', label: 'Calls' },
];

const callsTabs = [
  { name: 'Upcoming Calls', section: 'upcomingCalls' },
  { name: 'Scheduled', section: 'scheduledCalls' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [recentUsers, setRecentUsers] = useState([]);
  const [preLenderLoans, setPreLenderLoans] = useState([]);
  const [inTalksLoans, setInTalksLoans] = useState([]);
  const [closedDeals, setClosedDeals] = useState([]);
  const [prequalifications, setPrequalifications] = useState([]);
  const [activeSection, setActiveSection] = useState('recentActivity');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLoanType, setSelectedLoanType] = useState('Acquisition');
  const [loans, setLoans] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  // Update the isToday function to handle UTC dates
  const isToday = (date1, date2) => {
    if (!date1 || !date2) return false;
    
    // Ensure we're working with Date objects
    const d1 = date1 instanceof Date ? date1 : date1.toDate();
    const d2 = date2 instanceof Date ? date2 : date2.toDate();
    
    // Compare year, month, and day
    return d1.getFullYear() === d2.getFullYear() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getDate() === d2.getDate();
  };

  // Get current tabs based on loan type
  const getCurrentTabs = () => {
    switch (selectedLoanType) {
      case 'Acquisition':
        return acquisitionTabs;
      case 'workingCapital':
        return workingCapitalTabs;
      case 'calls':
        return callsTabs;
      default:
        return acquisitionTabs;
    }
  };

  // Handle loan type change
  const handleLoanTypeChange = (newType) => {
    setSelectedLoanType(newType);
    // Reset to first tab of the new type
    setActiveSection(newType === 'Acquisition' ? 'recentActivity' : 'recentActivity');
  };

  // Modify the fetch functions to filter by loan type
  const fetchLoans = async (status) => {
    try {
      const loansRef = collection(db, 'loanApplications');
      let q;

      if (selectedLoanType === 'workingCapital') {
        q = query(
          loansRef,
          where('loanPurpose', '==', 'workingCapital'),
          where('loanStatus', '==', status)
        );
      } else {
        q = query(
          loansRef,
          where('loanPurpose', '==', 'Acquisition'),
          where('status', '==', status)
        );
      }

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error('Error fetching loans:', error);
      return [];
    }
  };

  useEffect(() => {
    const fetchRecentUsers = async () => {
      try {
        const usersRef = collection(db, 'loanApplications');
        const q = query(usersRef, orderBy('createdAt', 'desc'), limit(5));
        const querySnapshot = await getDocs(q);
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRecentUsers(usersData);
      } catch (error) {
        console.error('Error fetching recent users:', error);
      }
    };

    const fetchPreLenderLoans = async () => {
      try {
        const loansRef = collection(db, 'loanApplications');
        const q = query(loansRef, where('status', '==', 'Pre-lender'));
        const querySnapshot = await getDocs(q);
        const loansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPreLenderLoans(loansData);
      } catch (error) {
        console.error('Error fetching pre-lender loans:', error);
      }
    };

    const fetchInTalksLoans = async () => {
      try {
        const loansRef = collection(db, 'loanApplications');
        const q = query(loansRef, where('status', 'in', ['in-talks', 'under_review']));
        const querySnapshot = await getDocs(q);
        const loansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInTalksLoans(loansData);
      } catch (error) {
        console.error('Error fetching in-talks loans:', error);
      }
    };

    const fetchClosedDeals = async () => {
      try {
        const loansRef = collection(db, 'loanApplications');
        const q = query(loansRef, where('status', '==', 'closed'));
        const querySnapshot = await getDocs(q);
        const loansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClosedDeals(loansData);
      } catch (error) {
        console.error('Error fetching closed deals:', error);
      }
    };

    const fetchPrequalifications = async () => {
      console.log('Fetching prequalifications...');
      try {
        const prequalRef = collection(db, 'prequalificationApplications');
        const querySnapshot = await getDocs(prequalRef);
        console.log('Prequalifications query snapshot:', querySnapshot);
        const prequalData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Parsed prequalification data:', prequalData);
        setPrequalifications(prequalData);
      } catch (error) {
        console.error('Error fetching prequalifications:', error);
      }
    };

    fetchRecentUsers();
    fetchPreLenderLoans();
    fetchInTalksLoans();
    fetchClosedDeals();
    fetchPrequalifications();
  }, [selectedLoanType]);

  // Define fetchAllLoans as a function in the component
  const fetchAllLoans = async () => {
    try {
      const loansRef = collection(db, 'loanApplications');
      let q;
      
      if (selectedLoanType === 'calls') {
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        
        const endOfDay = new Date(selectedDate);
        endOfDay.setHours(23, 59, 59, 999);
        
        q = query(loansRef);
        
        const querySnapshot = await getDocs(q);
        const loansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const filteredLoans = loansData.filter(loan => {
          const loanDate = loan.displayDate?.toDate?.() || loan.createdAt?.toDate?.() || new Date(loan.createdAt);
          const loanLocalDate = new Date(loanDate).toLocaleDateString();
          const selectedLocalDate = startOfDay.toLocaleDateString();
          
          return loanLocalDate === selectedLocalDate;
        });

        setLoans(filteredLoans);
      } else {
        // Original query for non-calls views
        q = query(
          loansRef,
          where('loanPurpose', '==', selectedLoanType === 'workingCapital' ? 'workingCapital' : 'Acquisition')
        );
        const querySnapshot = await getDocs(q);
        const loansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLoans(loansData);
      }
    } catch (error) {
      console.error('Error fetching loans:', error);
      setLoans([]);
    }
  };

  // Use it in the useEffect
  useEffect(() => {
    fetchAllLoans();
  }, [selectedDate, selectedLoanType]);

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };

  const handleCardClick = (loan) => {
    console.log('Card clicked:', loan);
    if (activeSection === 'prequalifications') {
      console.log('Navigating to prequalification details:', `/prequalification-details/${loan.id}`);
      navigate(`/prequalification-details/${loan.id}`);
    } else {
      navigate(`/loan-details/${loan.id}`);
    }
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  const changeDate = (direction) => {
    const newDate = new Date(selectedDate);
    if (direction === 'prev') {
      newDate.setDate(newDate.getDate() - 1);
    } else if (direction === 'next') {
      newDate.setDate(newDate.getDate() + 1);
    }
    setSelectedDate(newDate);
  };

  const navigateToLoanDate = (loan) => {
    let displayDate;
    
    try {
      if (loan.called) {
        // For called loans, try followUpDate first, then displayDate
        if (loan.followUpDate?.toDate) {
          displayDate = loan.followUpDate.toDate();
        } else if (loan.displayDate?.toDate) {
          displayDate = loan.displayDate.toDate();
        } else if (loan.followUpDate) {
          displayDate = new Date(loan.followUpDate);
        } else if (loan.displayDate) {
          displayDate = new Date(loan.displayDate);
        }
      } else {
        // For uncalled loans, try createdAt
        if (loan.createdAt?.toDate) {
          displayDate = loan.createdAt.toDate();
        } else if (loan.createdAt) {
          displayDate = new Date(loan.createdAt);
        }
      }
      
      // If we couldn't get a valid date, use today's date
      if (!displayDate || isNaN(displayDate.getTime())) {
        displayDate = new Date();
        console.warn('Using current date as fallback for loan:', loan.id);
      }
      
      setSelectedDate(displayDate);
    } catch (error) {
      console.error('Error processing date for loan:', loan.id, error);
      // Fallback to current date if there's an error
      setSelectedDate(new Date());
    }
  };

  useEffect(() => {
    const searchAcrossLoans = async () => {
      if (!searchTerm) {
        setSearchResults([]);
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      const searchTermLower = searchTerm.toLowerCase();

      try {
        const loansRef = collection(db, 'loanApplications');
        const querySnapshot = await getDocs(loansRef);
        const allLoans = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter based on search term
        const filteredLoans = allLoans.filter(loan => {
          // Check business name
          const businessNameMatch = loan.businessName?.toLowerCase().includes(searchTermLower);
          
          // Check first and last name
          const fullNameMatch = `${loan.firstName} ${loan.lastName}`.toLowerCase().includes(searchTermLower);
          
          // Check primary email
          const emailMatch = loan.email?.toLowerCase().includes(searchTermLower);
          
          // Check emails array
          const emailsArrayMatch = Array.isArray(loan.emails) && 
            loan.emails.some(email => email.toLowerCase().includes(searchTermLower));

          return businessNameMatch || fullNameMatch || emailMatch || emailsArrayMatch;
        });

        setSearchResults(filteredLoans);
      } catch (error) {
        console.error('Error searching loans:', error);
        setSearchResults([]);
      } finally {
        setIsSearching(false);
      }
    };

    // Debounce the search to avoid too many requests
    const timeoutId = setTimeout(() => {
      searchAcrossLoans();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const renderSection = () => {
    let loansToRender;
    let sectionTitle;

    // If there's a search term, use search results instead of section-based data
    if (searchTerm) {
      loansToRender = searchResults;
      sectionTitle = "Search Results";
    } else {
      // Original section-based logic
      if (selectedLoanType === 'calls') {
        const compareDate = new Date(selectedDate);
        
        if (activeSection === 'upcomingCalls') {
          loansToRender = loans.filter(loan => {
            const relevantDate = loan.createdAt?.toDate() || new Date(loan.createdAt);
            return !loan.called && 
                   !loan.allDocumentsUploaded && 
                   isToday(relevantDate, compareDate);
          });
          sectionTitle = "Upcoming Calls";
        } else {
          loansToRender = loans.filter(loan => !loan.allDocumentsUploaded);
          sectionTitle = selectedDate.toLocaleDateString() === new Date().toLocaleDateString()
            ? "Today's Applications"
            : `Applications - ${selectedDate.toLocaleDateString()}`;
        }
      } else if (selectedLoanType === 'workingCapital') {
        switch (activeSection) {
          case LoanStatuses.NEW_APPLICATION:
            loansToRender = loans.filter(loan => loan.loanStatus === LoanStatuses.NEW_APPLICATION);
            sectionTitle = "New Applications";
            break;
          case LoanStatuses.PREQUALIFIED_FOR:
            loansToRender = loans.filter(loan => loan.loanStatus === LoanStatuses.PREQUALIFIED_FOR);
            sectionTitle = "Prequalified";
            break;
          case LoanStatuses.DOCUMENT_CLARIFICATION:
            loansToRender = loans.filter(loan => loan.loanStatus === LoanStatuses.DOCUMENT_CLARIFICATION);
            sectionTitle = "Document Review";
            break;
          case LoanStatuses.UNDERWRITING:
            loansToRender = loans.filter(loan => loan.loanStatus === LoanStatuses.UNDERWRITING);
            sectionTitle = "Underwriting";
            break;
          case LoanStatuses.FUNDED:
            loansToRender = loans.filter(loan => loan.status === LoanStatuses.FUNDED);
            sectionTitle = "Funded";
            break;
          case LoanStatuses.SUBMITTED:
            loansToRender = loans.filter(loan => loan.loanStatus === LoanStatuses.SUBMITTED);
            sectionTitle = "Submitted Applications";
            break;
          case 'bankManagement':
            return <BankManagement />;
          default:
            loansToRender = recentUsers;
            sectionTitle = "Recent Activity";
        }
      } else {
        switch (activeSection) {
          case 'recentActivity':
            loansToRender = recentUsers;
            sectionTitle = "Recent Activity";
            break;
          case 'preLender':
            loansToRender = preLenderLoans;
            sectionTitle = "Pre-Lender Loans";
            break;
          case 'inTalks':
            loansToRender = inTalksLoans;
            sectionTitle = "In Talks with Lender";
            break;
          case 'closedDeals':
            loansToRender = closedDeals;
            sectionTitle = "Closed Deals";
            break;
          case 'prequalifications':
            loansToRender = prequalifications;
            sectionTitle = "Prequalifications";
            break;
          case 'bankManagement':
            return <BankManagement />;
          default:
            loansToRender = [];
            sectionTitle = "";
        }
      }
    }

    console.log('Loans to render:', loansToRender);

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold">
            {searchTerm ? `Search Results for "${searchTerm}"` : sectionTitle}
          </h2>
          
          {/* Date Navigation */}
          <div className="flex items-center justify-center space-x-4 bg-white rounded-lg shadow p-2">
            <button
              onClick={() => changeDate('prev')}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronLeftIcon className="h-5 w-5 text-gray-600" />
            </button>
            
            <span className="text-lg font-medium px-2">{format(selectedDate, 'MMM d, yyyy')}</span>
            
            <button
              onClick={() => changeDate('next')}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronRightIcon className="h-5 w-5 text-gray-600" />
            </button>
          </div>

          <div className="flex gap-4">
            <select
              value={selectedLoanType}
              onChange={(e) => setSelectedLoanType(e.target.value)}
              className="block rounded-md border-gray-300 py-1.5 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            >
              {LOAN_TYPE_FILTERS.map((filter) => (
                <option key={filter.value} value={filter.value}>
                  {filter.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {isSearching ? (
          <div className="flex justify-center items-center py-8">
            <p>Searching...</p>
          </div>
        ) : loansToRender?.length === 0 ? (
          <p>{searchTerm ? 'No results found.' : 'No loans found.'}</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {loansToRender.map((loan) => {
              console.log('Rendering loan card:', loan);
              const displayName = loan.businessName || `${loan.firstName} ${loan.lastName}`.trim();
              return (
                <LightModeLoanCard
                  key={loan.id}
                  id={loan.id}
                  name={displayName}
                  loanSize={loan.projectedSalePrice || loan.loanSize}
                  loanPurpose={loan.businessSummary || loan.loanPurpose}
                  businessState={loan.businessState}
                  businessAge={loan.businessAge}
                  submittedAt={loan.lastUpdated ? new Date(loan.lastUpdated).toLocaleDateString() : (loan.createdAt && loan.createdAt.toDate ? loan.createdAt.toDate().toLocaleDateString() : 'N/A')}
                  ebitda={loan.ebitda}
                  onClick={() => {
                    if (selectedLoanType === 'calls') {
                      navigateToLoanDate(loan);
                      handleCardClick(loan);
                    } else {
                      handleCardClick(loan);
                    }
                  }}
                  isBlue={activeSection === 'prequalifications'}
                  action={loan.action}
                  firstName={loan.firstName}
                  called={loan.followUpDate ? loan.followupCalled : loan.called}
                  allDocumentsUploaded={loan.allDocumentsUploaded}
                  personalGuarantors={loan.personalGuarantors}
                  isFollowUp={!!loan.followUpDate}
                  onCallStatusChange={fetchAllLoans}
                  isCallsTab={selectedLoanType === 'calls'}
                  notes={loan.notes}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const handleSaveFinancials = async (loanId, year, financials) => {
    const loanRef = doc(db, 'loanApplications', loanId);
    
    try {
      await updateDoc(loanRef, {
        [`financialAnalysis.${year}`]: financials
      });
      console.log('Financials saved successfully');
    } catch (error) {
      console.error('Error saving financials:', error);
      throw error;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {/* Keep only the loan type toggle */}
          <div className="mb-4">
            <div className="flex justify-end">
              <div className="inline-flex rounded-md shadow-sm" role="group">
                <button
                  type="button"
                  onClick={() => handleLoanTypeChange('Acquisition')}
                  className={`px-4 py-2 text-sm font-medium ${
                    selectedLoanType === 'Acquisition'
                      ? 'bg-indigo-600 text-white'
                      : 'bg-white text-gray-700 hover:bg-gray-50'
                  } border border-gray-200 ${
                    selectedLoanType === 'Acquisition' ? '' : 'hover:bg-gray-50'
                  } ${selectedLoanType === 'Acquisition' ? 'rounded-l-lg' : 'border-l'}`}
                >
                  Acquisition
                </button>
                <button
                  type="button"
                  onClick={() => handleLoanTypeChange('workingCapital')}
                  className={`px-4 py-2 text-sm font-medium ${
                    selectedLoanType === 'workingCapital'
                      ? 'bg-indigo-600 text-white'
                      : 'bg-white text-gray-700 hover:bg-gray-50'
                  } border-t border-b border-gray-200`}
                >
                  Working Capital
                </button>
                <button
                  type="button"
                  onClick={() => handleLoanTypeChange('calls')}
                  className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
                    selectedLoanType === 'calls'
                      ? 'bg-indigo-600 text-white'
                      : 'bg-white text-gray-700 hover:bg-gray-50'
                  } border border-gray-200`}
                >
                  Calls
                </button>
              </div>
            </div>
          </div>

          {/* Update the tabs section */}
          <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              {selectedLoanType === 'Acquisition' ? 'Acquisition Loans' : 'Working Capital Loans'}
            </h3>
            <div className="mt-3 sm:mt-0 sm:ml-4">
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8">
                  {getCurrentTabs().map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setActiveSection(tab.section)}
                      className={classNames(
                        activeSection === tab.section
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          
          {/* Remove the filter section and keep only search and logout */}
          <div className="mt-3 sm:mt-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <div className="mt-2 flex rounded-md shadow-sm">
                  <div className="relative flex-grow focus-within:z-10">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Search applicants"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  onClick={handleLogout}
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-offset"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-7xl mx-auto py-6">
          <div className="sm:px-0">
            {renderSection()}
          </div>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboard;

