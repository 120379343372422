import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    doc, 
    getDoc, 
    updateDoc, 
    addDoc, 
    serverTimestamp, 
    getFirestore,
    collection,
    query,
    where,
    getDocs
} from 'firebase/firestore';
import { db } from '../../firebase';
import { ChevronDownIcon, ChevronUpIcon, ArrowLeftIcon, DocumentIcon, BanknotesIcon } from '@heroicons/react/20/solid';
import PrequalFinancialMetricsDisplay from './PrequalFinancialMetricsDisplay';
import LoanOfficerAssignment from './PrequalLoanOfficerAssignment';
import PrequalificationOcrReview from './PrequalificationOcrReview';
import PrequalDebtServiceCoverageCalculator from './prequalDebtServiceCoverageCalc';
import { generateSummaryPDF } from './PdfGenerator';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';

const PrequalificationDetails = () => {
  const { prequalId } = useParams();
  const navigate = useNavigate();
  const [prequalData, setPrequalData] = useState(null);
  const [ocrData, setOcrData] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isApplicantInfoExpanded, setIsApplicantInfoExpanded] = useState(true);
  const [isFinancialOverviewExpanded, setIsFinancialOverviewExpanded] = useState(true);
  const [isBusinessDetailsExpanded, setIsBusinessDetailsExpanded] = useState(true);
  const [isUploadedFilesExpanded, setIsUploadedFilesExpanded] = useState(true);

  useEffect(() => {
    const fetchPrequalData = async () => {
      const prequalDocRef = doc(db, 'prequalificationApplications', prequalId);
      const prequalDocSnap = await getDoc(prequalDocRef);
      
      if (prequalDocSnap.exists()) {
        const data = { id: prequalDocSnap.id, ...prequalDocSnap.data() };
        setPrequalData(data);
        console.log("Fetched prequalData:", data);
      } else {
        console.log("No such prequalification application!");
      }
    };

    const fetchOCRData = async () => {
      const ocrMetadataRef = collection(db, 'OCRMetadata');
      const q = query(ocrMetadataRef, where('applicationId', '==', prequalId));
      const querySnapshot = await getDocs(q);
      
      const processedData = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.documentName === "Business Tax Returns") {
          const year = data.label.match(/\d{4}/) ? data.label.match(/\d{4}/)[0] : data.label;
          processedData[year] = data.ocrResult;
        }
      });
      setOcrData(processedData);
      console.log("Fetched OCR Data:", processedData);
    };

    const fetchUploadedFiles = async () => {
      const q = query(
        collection(db, 'prequalificationMetadata'),
        where('applicationId', '==', prequalId)
      );

      const querySnapshot = await getDocs(q);
      const files = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUploadedFiles(files);
    };

    fetchPrequalData();
    fetchOCRData();
    fetchUploadedFiles();
  }, [prequalId]);

  const toggleApplicantInfo = () => setIsApplicantInfoExpanded(!isApplicantInfoExpanded);
  const toggleFinancialOverview = () => setIsFinancialOverviewExpanded(!isFinancialOverviewExpanded);
  const toggleBusinessDetails = () => setIsBusinessDetailsExpanded(!isBusinessDetailsExpanded);
  const toggleUploadedFiles = () => setIsUploadedFilesExpanded(!isUploadedFilesExpanded);

  const handleBack = () => {
    navigate('/admindashboard');
  };

  const handleAssignBank = async (bankId, dropboxLink) => {
    try {
      console.log('Starting bank assignment process...');
      console.log('Prequalification ID:', prequalId);
      console.log('Bank ID:', bankId);
      console.log('Dropbox Link:', dropboxLink);

      const now = new Date().toISOString();

      // Update the prequalification application
      const prequalDocRef = doc(db, 'prequalificationApplications', prequalId);
      const updateData = {
        assignedBankId: bankId,
        status: 'Assigned to Bank',
        lastUpdated: now,
        dropboxLink: dropboxLink
      };

      await updateDoc(prequalDocRef, updateData);
      console.log('Prequalification application updated successfully');

      // Create a new bank assignment
      const bankAssignmentRef = collection(db, 'bankAssignments');
      const newAssignment = {
        prequalificationId: prequalId,
        bankId: bankId,
        assignedAt: now,
        currentStage: {
          name: 'prequalification',
          enteredAt: now
        },
        stageHistory: [
          {
            name: 'prequalification',
            enteredAt: now,
            exitedAt: null
          }
        ],
        dropboxLink: dropboxLink
      };

      const docRef = await addDoc(bankAssignmentRef, newAssignment);
      console.log('Bank assignment created successfully with ID:', docRef.id);

      // Fetch bank data and create email
      const bankRef = doc(db, 'banks', bankId);
      const bankSnap = await getDoc(bankRef);
      if (bankSnap.exists()) {
        const bankData = bankSnap.data();
        console.log('Bank data retrieved:', bankData);
        
        await createEmailDocument(bankData);
      } else {
        console.error('Bank not found:', bankId);
      }

      // Update local state
      setPrequalData(prevData => ({
        ...prevData,
        assignedBankId: bankId,
        status: 'Assigned to Bank',
        lastUpdated: now,
        dropboxLink: dropboxLink
      }));

      console.log('Bank assignment process completed successfully');
    } catch (error) {
      console.error('Error assigning bank:', error);
      throw error;
    }
  };

  const createEmailDocument = async (bankData) => {
    try {
      console.log('Creating email document for bank:', bankData.bankName);
      const emailRef = collection(db, 'email');

      const loginUrl = "https://lended.ai/login"; // Replace with your actual login URL

      const emailData = {
        to: bankData.email,
        from: "max@sbacapital.ai",
        replyTo: "max@sbacapital.ai",
        subject: "You've been matched with a prequalification!",
        templateName: "PrequalMatch",
        message: {
          subject: "You've been matched with a prequalification!",
          firstName: bankData.loanOfficerName,
          prequalDetails: "A new prequalification application has been matched with your bank in the loan portal. Please log in to review the details.",
          html: `<p>Hey ${bankData.loanOfficerName},</p>

<p>A new prequalification application has been matched with your bank in the loan portal. Please <a href="${loginUrl}">log in</a> to review the details.</p>

<p>Looking forward to hearing back from you!</p>

<p>Best,</p>
<p>Max Krakow</p>
<p>Lended</p>
`,
        },
      };
      console.log('Email data prepared:', JSON.stringify(emailData, null, 2));

      const emailDoc = await addDoc(emailRef, emailData);
      console.log('Email document created with ID:', emailDoc.id);
    } catch (error) {
      console.error('Error creating email document:', error);
      console.error('Error details:', JSON.stringify(error, Object.getOwnPropertyNames(error)));
      throw error;
    }
  };

  const formatDate = (dateValue) => {
    if (dateValue instanceof Date) {
      return dateValue.toLocaleString();
    } else if (typeof dateValue === 'string') {
      return new Date(dateValue).toLocaleString();
    } else if (dateValue && typeof dateValue.toDate === 'function') {
      return dateValue.toDate().toLocaleString();
    }
    return 'N/A';
  };

  const calculateRevenue = (yearData) => {
    return yearData?.gross_receipts_or_sales || 0;
  };

  const calculateEBITDA = (yearData) => {
    const baseIncome = Number(yearData?.ordinary_business_income || 0);
    const interest = Number(yearData?.interest || 0);
    const depreciation = Number(yearData?.depreciation || 0);
    const amortization = Number(yearData?.amortization || 0);
    return baseIncome + interest + depreciation + amortization;
  };

  const formatCurrency = (amount) => {
    const num = parseFloat(amount);
    if (isNaN(num)) return 'N/A';
    const millions = num / 1000000;
    return `$${millions.toFixed(1)}mm`;
  };

  const renderExpectedPrequalAmount = () => {
    const expectedAmount = prequalData?.expectedPrequalAmount;
    return (
      <div className="mb-8 bg-green-50 border border-green-200 rounded-md p-4">
        <div className="flex items-center">
          <BanknotesIcon className="h-6 w-6 text-green-500 mr-2" />
          <div>
            <p className="text-sm font-medium text-green-800">Expected Prequalification Amount</p>
            <p className="text-lg font-semibold text-green-900">
              {typeof expectedAmount === 'number' 
                ? formatCurrency(expectedAmount) 
                : expectedAmount || 'Not calculated yet'}
            </p>
          </div>
        </div>
      </div>
    );
  };

  if (!prequalData || Object.keys(ocrData).length === 0) {
    return <div>Loading...</div>;
  }

  const currentYear = Object.keys(ocrData).sort().pop();
  const currentYearData = ocrData[currentYear];

  const financialStats = [
    { name: 'Revenue', value: calculateRevenue(currentYearData), prefix: '$' },
    { name: 'EBITDA', value: calculateEBITDA(currentYearData), prefix: '$' },
    { name: 'Projected Sale Price', value: prequalData.projectedSalePrice, prefix: '$' },
    { name: 'Real Estate Appraisal', value: prequalData.realEstateAppraisal, prefix: '$' },
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="py-10">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="mb-4 flex items-center">
            <button
              onClick={handleBack}
              className="flex items-center text-gray-600 hover:text-gray-900"
            >
              <ArrowLeftIcon className="h-5 w-5 mr-2" />
              Back to Admin Dashboard
            </button>
          </div>

          <h1 className="text-3xl font-bold leading-tight text-gray-900 mb-4">Prequalification Details</h1>

          {renderExpectedPrequalAmount()}

          {/* Business Details */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-4 py-6 sm:px-6 flex justify-between items-center">
              <div>
                <h3 className="text-base font-semibold leading-7 text-gray-900">Business Details</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Information about the business.</p>
              </div>
              <button
                onClick={toggleBusinessDetails}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {isBusinessDetailsExpanded ? (
                  <ChevronUpIcon className="h-6 w-6" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6" />
                )}
              </button>
            </div>
            {isBusinessDetailsExpanded && (
              <div className="border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Business Name</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{prequalData.businessName}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Business Age</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{prequalData.businessAge} years</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Business State</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{prequalData.businessState}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Business Description</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{prequalData.businessDescription}</dd>
                  </div>
                  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-900">Business Summary</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{prequalData.businessSummary}</dd>
                  </div>
                </dl>
              </div>
            )}
          </div>

          {/* Financial Overview */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-4 py-6 sm:px-6 flex justify-between items-center">
              <div>
                <h3 className="text-base font-semibold leading-7 text-gray-900">Financial Overview</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Key financial metrics.</p>
              </div>
              <button
                onClick={toggleFinancialOverview}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {isFinancialOverviewExpanded ? (
                  <ChevronUpIcon className="h-6 w-6" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6" />
                )}
              </button>
            </div>
            {isFinancialOverviewExpanded && (
              <div className="px-4 py-6 sm:px-6">
                <PrequalFinancialMetricsDisplay ocrData={ocrData} loanData={prequalData} />
              </div>
            )}
          </div>

          {/* Uploaded Files */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="px-4 py-6 sm:px-6 flex justify-between items-center">
              <div>
                <h3 className="text-base font-semibold leading-7 text-gray-900">Uploaded Files</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Documents provided by the applicant.</p>
              </div>
              <button
                onClick={toggleUploadedFiles}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                {isUploadedFilesExpanded ? (
                  <ChevronUpIcon className="h-6 w-6" />
                ) : (
                  <ChevronDownIcon className="h-6 w-6" />
                )}
              </button>
            </div>
            {isUploadedFilesExpanded && (
              <div className="border-t border-gray-100">
                <ul className="divide-y divide-gray-100">
                  {uploadedFiles.map((file) => (
                    <li key={file.id} className="flex items-center justify-between px-4 py-4 sm:px-6">
                      <div className="flex items-center">
                        <DocumentIcon className="h-5 w-5 text-gray-400 mr-2" />
                        <div className="text-sm leading-6 text-gray-900">{file.fileName}</div>
                      </div>
                      <a
                        href={file.fileUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-sm font-medium text-blue-600 hover:text-blue-500"
                      >
                        View
                      </a>
                    </li>
                  ))}
                  {uploadedFiles.length === 0 && (
                    <li className="px-4 py-4 sm:px-6 text-sm text-gray-500">No files uploaded yet.</li>
                  )}
                </ul>
              </div>
            )}
          </div>

          {/* Loan Officer Assignment */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-8">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Assign Bank</h3>
              <LoanOfficerAssignment 
                loanId={prequalId} 
                handleAssignBank={handleAssignBank}
                isPrequalification={true}
              />
            </div>
          </div>

          {/* Debt Service Coverage Calculator */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mt-8">
            <div className="px-4 py-6 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Debt Service Coverage Calculator</h3>
              {prequalData && (
                <>
                  <p>Debug: Projected Sale Price: ${prequalData.projectedSalePrice}</p>
                  <PrequalDebtServiceCoverageCalculator 
                    prequalId={prequalId}
                    projectedSalePrice={prequalData.projectedSalePrice}
                  />
                </>
              )}
            </div>
          </div>

          {/* Prequalification OCR Review */}
          {prequalId && (
            <PrequalificationOcrReview prequalId={prequalId} />
          )}

        </div>
      </main>
    </div>
  );
};

export default PrequalificationDetails;