import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const FinancialMetricsDisplayAdmin = ({ ocrData, loanApplication }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  };

  const calculateNewOfficerCompensation = () => {
    const baseCompensation = 80000; // $80,000 per guarantor
    const numberOfGuarantors = (loanApplication.personalGuarantors || []).length;
    return baseCompensation * numberOfGuarantors;
  };

  const calculateNOI = (yearData) => {
    if (!yearData) return 0;
    const sde = calculateSDE(yearData);
    const newOfficerCompensation = calculateNewOfficerCompensation();
    return sde - newOfficerCompensation;
  };

  const calculateSDE = (yearData) => {
    if (!yearData) return 0;
    return yearData.ordinaryBusinessIncome +
           yearData.officerCompensation +
           yearData.interest +
           yearData.depreciation;
  };

  const calculateAnnualDebtService = () => {
    const totalLoanAmount = Number(loanApplication.loanSize || 0);
    const sba7aPercentage = Number(loanApplication.loanStructure?.sba7aLoan || 0) / 100;
    const sba7aLoanAmount = totalLoanAmount * sba7aPercentage;
    const interestRate = 0.105; // 10.5% interest rate
    const loanTerm = 10; // 10-year term

    if (sba7aLoanAmount <= 0) {
      return 0;
    }

    const monthlyRate = interestRate / 12;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment = (sba7aLoanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    
    return monthlyPayment * 12;
  };

  const calculateDSCR = (noi) => {
    const annualDebtService = calculateAnnualDebtService();
    return annualDebtService > 0 ? noi / annualDebtService : 0;
  };

  const getFinancialData = (key) => {
    return Object.keys(ocrData).map(year => ({
      name: year,
      value: key === 'dscr' ? calculateDSCR(calculateNOI(ocrData[year])) :
             key === 'noi' ? calculateNOI(ocrData[year]) :
             ocrData[year][key] || 0
    })).sort((a, b) => b.name.localeCompare(a.name));
  };

  const GRAPH_COLORS = ['#008081', '#6593F5', '#3FE0D0'];

  const FinancialStatCard = ({ item }) => (
    <div className="overflow-hidden rounded-lg bg-white px-6 py-8 shadow sm:p-8">
      <dt className="truncate text-lg font-medium text-gray-500">{item.name}</dt>
      <dd className="mt-2 flex flex-col">
        <div className="flex items-baseline justify-between">
          <div className="flex items-baseline text-3xl font-semibold text-black">
            {item.stat}
            <span className="ml-2 text-base font-medium text-gray-500">from {item.previousStat}</span>
          </div>
          <div
            className={`inline-flex items-baseline rounded-full px-3 py-1 text-base font-medium ${
              item.changeType === 'increase' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
            }`}
          >
            {item.changeType === 'increase' ? (
              <svg className="-ml-1 mr-0.5 h-6 w-6 flex-shrink-0 self-center text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
              </svg>
            ) : (
              <svg className="-ml-1 mr-0.5 h-6 w-6 flex-shrink-0 self-center text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            )}
            <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
            {item.change}
          </div>
        </div>
        <div className="mt-6 h-48">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={item.data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill={item.color} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </dd>
    </div>
  );

  const years = Object.keys(ocrData).sort((a, b) => b.localeCompare(a));
  const currentYear = years[0];
  const lastYear = years[1];

  const financialStats = [
    { 
      name: 'DSCR', 
      stat: formatNumber(calculateDSCR(calculateNOI(ocrData[currentYear]))),
      previousStat: formatNumber(calculateDSCR(calculateNOI(ocrData[lastYear]))),
      change: ((calculateDSCR(calculateNOI(ocrData[currentYear])) / calculateDSCR(calculateNOI(ocrData[lastYear])) - 1) * 100).toFixed(2) + '%',
      changeType: calculateDSCR(calculateNOI(ocrData[currentYear])) > calculateDSCR(calculateNOI(ocrData[lastYear])) ? 'increase' : 'decrease',
      data: getFinancialData('dscr'),
      color: GRAPH_COLORS[0]
    },
    { 
      name: 'Net Operating Income', 
      stat: formatCurrency(calculateNOI(ocrData[currentYear])),
      previousStat: formatCurrency(calculateNOI(ocrData[lastYear])),
      change: ((calculateNOI(ocrData[currentYear]) / calculateNOI(ocrData[lastYear]) - 1) * 100).toFixed(2) + '%',
      changeType: calculateNOI(ocrData[currentYear]) > calculateNOI(ocrData[lastYear]) ? 'increase' : 'decrease',
      data: getFinancialData('noi'),
      color: GRAPH_COLORS[1]
    },
    { 
      name: 'Ordinary Business Income', 
      stat: formatCurrency(ocrData[currentYear]?.ordinaryBusinessIncome || 0),
      previousStat: formatCurrency(ocrData[lastYear]?.ordinaryBusinessIncome || 0),
      change: ((ocrData[currentYear]?.ordinaryBusinessIncome / ocrData[lastYear]?.ordinaryBusinessIncome - 1) * 100).toFixed(2) + '%',
      changeType: ocrData[currentYear]?.ordinaryBusinessIncome > ocrData[lastYear]?.ordinaryBusinessIncome ? 'increase' : 'decrease',
      data: getFinancialData('ordinaryBusinessIncome'),
      color: GRAPH_COLORS[2]
    }
  ];

  return (
    <div className="mb-8">
      <h3 className="text-xl font-semibold leading-6 text-gray-900 mb-6">Financial Overview</h3>
      <dl className="mt-5 grid grid-cols-1 gap-8 sm:grid-cols-3">
        {financialStats.map((item) => (
          <FinancialStatCard key={item.name} item={item} />
        ))}
      </dl>

      <h3 className="text-xl font-bold mt-8 mb-4">Financial Analysis</h3>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 border-b text-left">Metric</th>
            {years.map(year => (
              <th key={year} className="px-4 py-2 border-b text-right">{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[
            { key: 'grossRevenue', label: 'Gross Receipts or Sales' },
            { key: 'ordinaryBusinessIncome', label: 'Ordinary Business Income', isBold: true },
            { key: 'officerCompensation', label: '+ Original Officer Compensation' },
            { key: 'interest', label: '+ Interest' },
            { key: 'depreciation', label: '+ Depreciation' },
          ].map(({ key, label, isBold }) => (
            <tr key={key}>
              <td className={`px-4 py-2 border-b ${isBold ? 'font-bold' : ''}`}>{label}</td>
              {years.map(year => (
                <td key={year} className={`px-4 py-2 border-b text-right ${isBold ? 'font-bold' : ''}`}>
                  {formatCurrency(ocrData[year]?.[key] || 0)}
                </td>
              ))}
            </tr>
          ))}
          <tr className="bg-gray-100">
            <td className="px-4 py-2 border-b font-bold">Seller's Discretionary Earnings (SDE)</td>
            {years.map(year => (
              <td key={year} className="px-4 py-2 border-b text-right font-bold">
                {formatCurrency(calculateSDE(ocrData[year]))}
              </td>
            ))}
          </tr>
          <tr>
            <td className="px-4 py-2 border-b">- New Officer Compensation</td>
            {years.map(year => (
              <td key={year} className="px-4 py-2 border-b text-right">
                {formatCurrency(-calculateNewOfficerCompensation())}
              </td>
            ))}
          </tr>
          <tr className="bg-gray-100">
            <td className="px-4 py-2 border-b font-bold">Net Operating Income (NOI)</td>
            {years.map(year => (
              <td key={year} className="px-4 py-2 border-b text-right font-bold">
                {formatCurrency(calculateNOI(ocrData[year]))}
              </td>
            ))}
          </tr>
          <tr>
            <td className="px-4 py-2 border-b">Debt Service Coverage Ratio (DSCR)</td>
            {years.map(year => (
              <td key={year} className="px-4 py-2 border-b text-right">
                {formatNumber(calculateDSCR(calculateNOI(ocrData[year])))}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FinancialMetricsDisplayAdmin;