import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../../firebase';
import LightModeLoanCard from '../admin/LightModeLoanCard';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import CreditBox from '../admin/CreditBox';
import PrequalificationTab from './PrequalificationTab';

const tabs = [
  { name: 'Recent Activity', section: 'recentActivity' },
  { name: 'Prequalification', section: 'prequalification' },
  { name: 'Working Capital', section: 'workingCapital' },
  { name: 'Under Review', section: 'underReview' },
  { name: 'Term Sheet Sent', section: 'termSheetSent' },
  { name: 'Term Sheet Signed', section: 'termSheetSigned' },
  { name: 'Rejected', section: 'rejected' },
  { name: 'Credit Box', section: 'creditBox' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const BankPortal = () => {
  const [assignedLoans, setAssignedLoans] = useState([]);
  const [bankInfo, setBankInfo] = useState(null);
  const [bankId, setBankId] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeSection, setActiveSection] = useState('underReview');
  const [isLoading, setIsLoading] = useState(true);
  const [currentUserUID, setCurrentUserUID] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBankInfoAndLoans = async () => {
      setIsLoading(true);
      try {
        const user = auth.currentUser;
        if (user) {
          setCurrentUserUID(user.uid);
          
          // Fetch bank info
          const banksRef = collection(db, 'banks');
          const bankQuery = query(banksRef, where('userId', '==', user.uid));
          const bankSnapshot = await getDocs(bankQuery);
          
          if (!bankSnapshot.empty) {
            const bankDoc = bankSnapshot.docs[0];
            const bankData = { id: bankDoc.id, ...bankDoc.data() };
            setBankInfo(bankData);
            setBankId(bankDoc.id);

            // Fetch bank assignments
            const assignmentsRef = collection(db, 'bankAssignments');
            const assignmentsQuery = query(assignmentsRef, where('bankId', '==', bankDoc.id));
            const assignmentsSnapshot = await getDocs(assignmentsQuery);
            
            const loanPromises = assignmentsSnapshot.docs.map(async (assignmentDoc) => {
              const assignmentData = assignmentDoc.data();
              console.log("Assignment data:", JSON.stringify(assignmentData, null, 2));

              try {
                if (!assignmentData.applicationId) {
                  console.warn("Missing applicationId for assignment:", assignmentDoc.id);
                  return null;
                }
                console.log("Fetching loan application with ID:", assignmentData.applicationId);
                const loanRef = doc(db, 'loanApplications', assignmentData.applicationId);
                const loanDoc = await getDoc(loanRef);
                
                if (loanDoc.exists()) {
                  const loanData = loanDoc.data();
                  console.log("Loan application data:", JSON.stringify(loanData, null, 2));
                  return { 
                    id: loanDoc.id, 
                    ...loanData, 
                    assignedAt: assignmentData.assignedAt,
                    currentStage: assignmentData.currentStage,
                    isPrequalification: false
                  };
                } else {
                  console.warn("Loan application document does not exist:", assignmentData.applicationId);
                  return null;
                }
              } catch (error) {
                console.error("Error processing assignment:", assignmentDoc.id, error);
                return null;
              }
            });

            const loans = (await Promise.all(loanPromises)).filter(loan => loan !== null);
            console.log("Fetched loans:", JSON.stringify(loans, null, 2));
            setAssignedLoans(loans);
          } else {
            setError('No bank found for this user. Please contact support.');
          }
        } else {
          setError('No authenticated user found. Please log in.');
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError(`Error fetching data: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBankInfoAndLoans();
  }, [auth]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Sign out error:', error);
      });
  };

  const handleCardClick = (loanId, isPrequalification) => {
    if (isPrequalification) {
      navigate(`/bank-portal/prequalification/${loanId}`, { state: { bankId: bankId } });
    } else {
      navigate(`/bank-loan-details/${loanId}`, { state: { bankId: bankId } });
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLoans = assignedLoans.filter(loan => {
    const fullName = `${loan.firstName || ''} ${loan.lastName || ''}`.toLowerCase();
    const businessState = (loan.businessState || '').toLowerCase();
    const loanPurpose = (loan.loanPurpose || '').toLowerCase();
    const searchTermLower = searchTerm.toLowerCase();

    return fullName.includes(searchTermLower) ||
           businessState.includes(searchTermLower) ||
           loanPurpose.includes(searchTermLower);
  });

  const renderSection = () => {
    let loansToRender = filteredLoans;
    let sectionTitle = "All Loans";

    switch (activeSection) {
      case 'recentActivity':
        loansToRender = filteredLoans.sort((a, b) => b.assignedAt - a.assignedAt).slice(0, 10);
        sectionTitle = "Recent Activity";
        break;
      case 'prequalification':
        return <PrequalificationTab bankId={bankId} onCardClick={handleCardClick} />;
      case 'workingCapital':
        loansToRender = filteredLoans.filter(loan => loan.loanPurpose === 'workingCapital');
        sectionTitle = "Working Capital Loans";
        break;
      case 'underReview':
        loansToRender = filteredLoans.filter(loan => !loan.isPrequalification && loan.currentStage.name === 'underReview');
        sectionTitle = "Under Review";
        break;
      case 'termSheetSent':
        loansToRender = filteredLoans.filter(loan => !loan.isPrequalification && loan.currentStage.name === 'termSheetSent');
        sectionTitle = "Term Sheet Sent";
        break;
      case 'termSheetSigned':
        loansToRender = filteredLoans.filter(loan => !loan.isPrequalification && loan.currentStage.name === 'termSheetSigned');
        sectionTitle = "Term Sheet Signed";
        break;
      case 'rejected':
        loansToRender = filteredLoans.filter(loan => 
          !loan.isPrequalification && (loan.currentStage.name === 'bankRejected' || loan.currentStage.name === 'userTurnedDown')
        );
        sectionTitle = "Rejected";
        break;
      case 'creditBox':
        return <CreditBox bankId={bankId} bankInfo={bankInfo} />;
      default:
        sectionTitle = "All Loans";
    }

    console.log("Rendering section:", sectionTitle);
    console.log("Loans to render:", loansToRender);

    return (
      <div>
        <h2 className="text-2xl font-semibold mb-4">{sectionTitle}</h2>
        {loansToRender.length === 0 ? (
          <p>No loans found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {loansToRender.map((loan) => {
              const submittedDate = loan.createdAt?.toDate?.() || loan.lastUpdated?.toDate?.() || loan.createdAt || loan.lastUpdated;
              const formattedDate = submittedDate instanceof Date ? submittedDate.toLocaleDateString() : 'N/A';

              return (
                <LightModeLoanCard
                  key={`${loan.id}-${loan.currentStage?.name}-${Math.random()}`}
                  name={loan.isPrequalification ? loan.businessName : `${loan.firstName} ${loan.lastName}`}
                  loanSize={loan.loanSize}
                  projectedSalePrice={loan.projectedSalePrice}
                  loanPurpose={loan.isPrequalification ? loan.businessDescription : loan.loanPurpose}
                  businessState={loan.businessState}
                  businessAge={loan.businessAge}
                  submittedAt={formattedDate}
                  ebitda={loan.ebitda}
                  onClick={() => handleCardClick(loan.id, loan.isPrequalification)}
                  isPrequalification={loan.isPrequalification}
                  workingCapitalUse={loan.workingCapitalUse}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!bankInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    
          {bankId && (
            <p className="text-sm text-gray-500">{bankInfo?.bankName}</p>
          )}
          <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
            <h3 className="text-base font-semibold leading-6 text-gray-900">{bankInfo?.loanOfficerName}'s Portal</h3>
            <div className="mt-3 sm:mt-0 sm:ml-4">
              <div className="hidden sm:block">
                <nav className="-mb-px flex space-x-8">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      onClick={() => setActiveSection(tab.section)}
                      className={classNames(
                        activeSection === tab.section
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium'
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
              <div className="sm:hidden">
                <label htmlFor="current-tab" className="sr-only">
                  Select a tab
                </label>
                <select
                  id="current-tab"
                  name="current-tab"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={activeSection}
                  onChange={(e) => setActiveSection(e.target.value)}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name} value={tab.section}>
                      {tab.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="mt-3 sm:mt-4">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              <div className="flex-grow">
                <div className="mt-0 flex rounded-md shadow-sm">
                  <div className="relative flex-grow focus-within:z-10">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Search applicants"
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-4">
                <button
                  onClick={handleLogout}
                  className="w-full sm:w-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-offset"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        {renderSection()}
      </main>
    </div>
  );
};

export default BankPortal;