import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const formFields = [
  { name: 'bankName', type: 'text', label: 'Bank Name', component: 'input', required: true },
  { name: 'number', type: 'tel', label: 'Phone Number', component: 'input', required: true },
  { name: 'email', type: 'email', label: 'Email address', component: 'input', required: true },
  { name: 'loanOfficerName', type: 'text', label: 'Loan Officer Name', component: 'input', required: true },
  { name: 'notes', type: 'textarea', label: 'Notes', component: 'textarea', required: false },
  { name: 'referralPercentage', type: 'select', label: 'Referral Percentage', component: 'select', options: [
    { value: 0.5, label: '0.5%' },
    { value: 1, label: '1%' },
    { value: 1.5, label: '1.5%' },
    { value: 2, label: '2%' },
    { value: 2.5, label: '2.5%' },
    { value: 3, label: '3%' }
  ], required: true },
  { name: 'usualLoanRate', type: 'select', label: 'Usual Loan Rate', component: 'select', options: [
    { value: 1.5, label: 'Prime + 1.5' },
    { value: 2, label: 'Prime + 2' },
    { value: 2.5, label: 'Prime + 2.5' },
    { value: 2.75, label: 'Prime + 2.75' },
    { value: 3, label: 'Prime + 3' }
  ], required: true },
  { name: 'state', type: 'multiselect', label: 'States Served', component: 'multiselect', options: [
    { value: 'All', label: 'All States' },
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
  ], required: true },
  { name: 'industriesNotServed', type: 'text', label: 'Industries Not Served (comma-separated)', component: 'input', required: false },
  { name: 'specializedIndustries', type: 'text', label: 'Specialized Industries (comma-separated)', component: 'input', required: false },
  { name: 'optimalLoanAmount.min', type: 'select', label: 'Optimal Loan Amount (Min)', component: 'select', options: [
    { value: 100000, label: '$100,000' },
    { value: 250000, label: '$250,000' },
    { value: 500000, label: '$500,000' },
    { value: 1000000, label: '$1,000,000' },
    { value: 2000000, label: '$2,000,000' }
  ], required: true },
  { name: 'optimalLoanAmount.max', type: 'select', label: 'Optimal Loan Amount (Max)', component: 'select', options: [
    { value: 1000000, label: '$1,000,000' },
    { value: 2000000, label: '$2,000,000' },
    { value: 3000000, label: '$3,000,000' },
    { value: 5000000, label: '$5,000,000' },
    { value: 9000000, label: '$9,000,000' }
  ], required: true },
  { name: 'debtServiceRequirement', type: 'select', label: 'Debt Service Requirement', component: 'select', options: [
    { value: 1.1, label: '1.1' },
    { value: 1.15, label: '1.15' },
    { value: 1.2, label: '1.2' },
    { value: 1.25, label: '1.25' },
    { value: 1.3, label: '1.3' }
  ], required: true },
  { name: 'processTime', type: 'select', label: 'Process Time (days)', component: 'select', options: [
    { value: 30, label: '30 days' },
    { value: 45, label: '45 days' },
    { value: 60, label: '60 days' },
    { value: 90, label: '90 days' },
    { value: 120, label: '120 days' }
  ], required: true },
  { name: 'approvalPercentage', type: 'select', label: 'Approval Percentage', component: 'select', options: [
    { value: 50, label: '50%' },
    { value: 60, label: '60%' },
    { value: 70, label: '70%' },
    { value: 80, label: '80%' },
    { value: 90, label: '90%' }
  ], required: true },
  { name: 'creditScore', type: 'select', label: 'Credit Score', component: 'select', options: [
    { value: 600, label: '600+' },
    { value: 650, label: '650+' },
    { value: 700, label: '700+' },
    { value: 750, label: '750+' }
  ], required: true },
  { name: 'assetCashflowBased', type: 'select', label: 'Asset/Cashflow Based', component: 'select', options: [
    { value: 'Asset', label: 'Asset' },
    { value: 'Cashflow', label: 'Cashflow' },
    { value: 'Both', label: 'Both' }
  ], required: true },
  { name: 'industryExperienceNeeded', type: 'select', label: 'Industry Experience Needed', component: 'select', options: [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'Transferable', label: 'Transferable' }
  ], required: true },
  { name: 'termSheetTime', type: 'select', label: 'Term Sheet Time', component: 'select', options: [
    { value: 1, label: '1 day' },
    { value: 2, label: '2 days' },
    { value: 3, label: '3 days' },
    { value: 5, label: '5 days' },
    { value: 7, label: '7 days' }
  ], required: true },
  { name: 'neededItemsForTermSheet', type: 'multiselect', label: 'Needed Items for Term Sheet', component: 'multiselect', options: [
    { value: 'Tax Returns', label: 'Tax Returns' },
    { value: 'Financial Statements', label: 'Financial Statements' },
    { value: 'Business Plan', label: 'Business Plan' },
    { value: 'Collateral Information', label: 'Collateral Information' },
    { value: 'Personal Financial Statement', label: 'Personal Financial Statement' }
  ], required: true },
];

// Update the formatRange function to handle nested properties
const formatRange = (obj, prefix = '') => {
  if (!obj || typeof obj !== 'object') return 'N/A';
  const min = obj.min?.toLocaleString() || 'N/A';
  const max = obj.max?.toLocaleString() || 'N/A';
  return `${prefix}${min} to ${prefix}${max}`;
};

const formatCurrency = (value) => {
  return value ? `$${value.toLocaleString()}` : 'N/A';
};

export const BankManagement = () => {
  const [banks, setBanks] = useState([]);
  const [newBank, setNewBank] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchBanks();
    initializeNewBank();
  }, []);

  const initializeNewBank = () => {
    setNewBank({
      bankName: '',
      number: '',
      email: '',
      loanOfficerName: '',
      notes: '',
      referralPercentage: '',
      usualLoanRate: '',
      state: [],
      industriesNotServed: '',
      specializedIndustries: '',
      optimalLoanAmount: {
        min: '',
        max: ''
      },
      debtServiceRequirement: '',
      processTime: '',
      approvalPercentage: '',
      creditScore: '',
      assetCashflowBased: '',
      industryExperienceNeeded: '',
      termSheetTime: '',
      neededItemsForTermSheet: []
    });
  };

  const fetchBanks = async () => {
    const banksCollection = collection(db, 'banks');
    const bankSnapshot = await getDocs(banksCollection);
    const bankList = bankSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setBanks(bankList);
  };

  const handleInputChange = (value, name = null) => {
    if (name) {
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        setNewBank(prevBank => ({
          ...prevBank,
          [parent]: { ...prevBank[parent], [child]: value }
        }));
      } else {
        setNewBank(prevBank => ({
          ...prevBank,
          [name]: value
        }));
      }
    } else if (value && value.target) {
      const { name, value: inputValue } = value.target;
      if (name.includes('.')) {
        const [parent, child] = name.split('.');
        setNewBank(prevBank => ({
          ...prevBank,
          [parent]: { ...prevBank[parent], [child]: inputValue }
        }));
      } else {
        setNewBank(prevBank => ({ ...prevBank, [name]: inputValue }));
      }
    }
    console.log('Updated newBank:', newBank); // Add this line for debugging
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const bankToSave = { ...newBank };

      // Handle comma-separated strings for industries
      ['industriesNotServed', 'specializedIndustries'].forEach(field => {
        if (typeof bankToSave[field] === 'string') {
          bankToSave[field] = bankToSave[field].split(',').map(item => item.trim());
        }
      });

      // Ensure numeric fields are saved as numbers
      ['referralPercentage', 'usualLoanRate', 'debtServiceRequirement', 'approvalPercentage', 'creditScore', 'processTime', 'termSheetTime'].forEach(field => {
        if (bankToSave[field]) {
          bankToSave[field] = Number(bankToSave[field]);
        }
      });

      // Handle nested optimalLoanAmount field
      if (bankToSave.optimalLoanAmount) {
        bankToSave.optimalLoanAmount = {
          min: Number(bankToSave.optimalLoanAmount.min),
          max: Number(bankToSave.optimalLoanAmount.max)
        };
      }

      console.log('Bank to save:', bankToSave); // Add this line for debugging

      await addDoc(collection(db, 'banks'), bankToSave);
      fetchBanks();
      initializeNewBank();
    } catch (error) {
      console.error('Error adding bank: ', error);
    }
  };

  const handleDelete = async (id, bankName) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${bankName}?`);
    
    if (isConfirmed) {
      try {
        await deleteDoc(doc(db, 'banks', id));
        fetchBanks(); // Refresh the bank list after deletion
      } catch (error) {
        console.error('Error deleting bank: ', error);
      }
    }
  };



  const renderFormField = (field) => {
    const { name, type, label, component, required, options } = field;
    if (component === 'input' || component === 'textarea') {
      return (
        <div className="sm:col-span-3">
          <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </label>
          <div className="mt-2">
            {component === 'input' ? (
              <input
                type={type}
                name={name}
                id={name}
                value={name.includes('.') ? newBank[name.split('.')[0]]?.[name.split('.')[1]] || '' : newBank[name] || ''}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            ) : (
              <textarea
                id={name}
                name={name}
                rows={3}
                value={newBank[name] || ''}
                onChange={handleInputChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            )}
          </div>
        </div>
      );
    } else if (component === 'select' || component === 'multiselect') {
      return (
        <div className="sm:col-span-3">
          <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </label>
          <div className="mt-2">
            <Select
              isMulti={component === 'multiselect'}
              name={name}
              options={options}
              value={
                component === 'multiselect'
                  ? options.filter(option => {
                      const fieldValue = name.includes('.') 
                        ? newBank[name.split('.')[0]]?.[name.split('.')[1]] 
                        : newBank[name];
                      return fieldValue?.includes(option.value);
                    })
                  : options.find(option => {
                      const fieldValue = name.includes('.') 
                        ? newBank[name.split('.')[0]]?.[name.split('.')[1]] 
                        : newBank[name];
                      return option.value === fieldValue;
                    }) || null
              }
              onChange={(selectedOption) => {
                if (component === 'multiselect') {
                  handleInputChange(selectedOption.map(option => option.value), name);
                } else {
                  handleInputChange(selectedOption ? selectedOption.value : null, name);
                }
              }}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="space-y-10 divide-y divide-gray-900/10">
      {/* Bank List */}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Bank Name</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Loan Officer</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Contact</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Loan Details</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Requirements</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Industries</th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {banks.map((bank) => (
                  <tr key={bank.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{bank.bankName || 'N/A'}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{bank.loanOfficerName || 'N/A'}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div>Email: {bank.email || 'N/A'}</div>
                      <div>Phone: {bank.number || 'N/A'}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div>Referral %: {bank.referralPercentage !== undefined ? `${bank.referralPercentage}%` : 'N/A'}</div>
                      <div>Usual Rate: Prime + {formatRange({ min: bank.usualLoanRateMin, max: bank.usualLoanRateMax })}</div>
                      <div>Loan Amount: {formatRange({ min: bank.optimalLoanAmountMin, max: bank.optimalLoanAmountMax }, '$')}</div>
                      <div>Process Time: {bank.processTime !== undefined ? `${bank.processTime} days` : 'N/A'}</div>
                      <div>Approval %: {bank.approvalPercentage !== undefined ? `${bank.approvalPercentage}%` : 'N/A'}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div>Credit Score: {bank.creditScore !== undefined ? `${bank.creditScore}+` : 'N/A'}</div>
                      <div>Debt Service: {bank.debtServiceRequirement || 'N/A'}</div>
                      <div>Asset/Cashflow: {bank.assetCashflowBased || 'N/A'}</div>
                      <div>Experience: {bank.industryExperienceNeeded || 'N/A'}</div>
                      <div>Term Sheet Time: {bank.termSheetTime !== undefined ? `${bank.termSheetTime} day(s)` : 'N/A'}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <div>States Served: {Array.isArray(bank.state) ? bank.state.join(', ') : 'N/A'}</div>
                      <div>Needed for Term Sheet: {Array.isArray(bank.neededItemsForTermSheet) ? bank.neededItemsForTermSheet.join(', ') : 'N/A'}</div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <button
                        onClick={() => handleDelete(bank.id, bank.bankName)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Add Bank Form */}
      <div className="space-y-10 divide-y divide-gray-900/10">
        <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
          <div className="px-4 sm:px-0">
            <h2 className="text-base font-semibold leading-7 text-gray-900">Add New Bank</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Please fill in the details for the new bank.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="px-4 py-6 sm:p-8">
              <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {formFields.map(field => renderFormField(field))}
              </div>
            </div>
            <div className="mt-8 flex items-center justify-end gap-x-6 px-4 py-4 sm:px-8">
              <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
