import React from 'react';
import logo from '../images/logo.png';
import { NavLink } from './NavLink';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-slate-50 mt-12">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="py-8 flex flex-col items-center">
          <img className="h-16 w-auto mb-4" src={logo} alt="SBA Capital Logo" />
          <nav className="mt-4 text-sm" aria-label="quick links">
            <Link
            to="/add-bank"
            className="text-sm text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out"
          >
            Bank Signup
          </Link>
          </nav>
        </div>
        <div className="flex flex-col items-center justify-center border-t border-slate-400/10 py-6 space-y-4">
         
          <p className="text-sm text-slate-500">
            Copyright &copy; {new Date().getFullYear()} SBA Capital LLC. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;