import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DollarSign, Calendar, Clock, MapPin, TrendingUp, Edit3, Phone } from 'lucide-react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import logo from '../images/images.png';

const LightModeLoanCard = ({ 
  id,
  name, 
  loanSize, 
  projectedSalePrice,
  businessState, 
  businessAge,
  submittedAt,
  ebitda,
  lastUserUpdate,
  onClick,
  isPrequalification,
  action,
  called = false,
  allDocumentsUploaded = false,
  personalGuarantors = [],
  isFollowUp = false,
  onCallStatusChange,
  loanPurpose = 'Acquisition',
  notes = '',
  isCallsTab = false,
}) => {
  const navigate = useNavigate();
  const [isCalled, setIsCalled] = useState(called);
  const [currentAction, setCurrentAction] = useState(action);
  const isWorkingCapital = loanPurpose === 'workingCapital';
  const isNonSBA = loanPurpose === 'workingCapital-nonsba';
  const [currentNotes, setCurrentNotes] = useState(notes);

  const handleCardClick = () => {
    navigate(`/loan-details/${id}`);
  };

  const handleCallToggle = async (e) => {
    e.stopPropagation();
    try {
      const firstName = personalGuarantors[0]?.firstName || 'Customer';
      const loanDocRef = doc(db, 'loanApplications', id);
      
      if (isFollowUp) {
        // Handle follow-up call toggle
        const newFollowupCalledStatus = !isCalled;
        
        if (!isCalled) { // If currently "No" and changing to "Yes"
          // Get current displayDate and add 3 days to it
          const currentDisplayDate = await getDoc(loanDocRef);
          const currentDate = currentDisplayDate.data().displayDate.toDate();
          const nextFollowUpDate = new Date(currentDate);
          nextFollowUpDate.setDate(nextFollowUpDate.getDate() + 3);
          
          console.log('Setting next follow-up:', {
            followupCalled: false,
            displayDate: nextFollowUpDate,
            followUpDate: nextFollowUpDate,
            action: `Call ${firstName} to check on documents by ${nextFollowUpDate.toLocaleDateString()}`
          });

          await updateDoc(loanDocRef, {
            followupCalled: false,
            displayDate: nextFollowUpDate,
            followUpDate: nextFollowUpDate,
            action: `Call ${firstName} to check on documents by ${nextFollowUpDate.toLocaleDateString()}`
          });
          
          setIsCalled(false);
          setCurrentAction(`Call ${firstName} to check on documents by ${nextFollowUpDate.toLocaleDateString()}`);
          
          if (onCallStatusChange) {
            onCallStatusChange();
          }
        } else {
          // If marking as not called
          await updateDoc(loanDocRef, {
            followupCalled: false,
            action: `Follow-up needed with ${firstName}`
          });
          setIsCalled(false);
          setCurrentAction(`Follow-up needed with ${firstName}`);
        }
      } else {
        // Handle initial call toggle (existing logic)
        const newCalledStatus = !isCalled;
        const loanSnapshot = await getDoc(loanDocRef);
        
        if (!loanSnapshot.exists()) {
          console.error('Loan document not found');
          return;
        }

        const createdAt = loanSnapshot.data().createdAt?.toDate() || new Date();
        const followUpDate = new Date(createdAt);
        followUpDate.setDate(followUpDate.getDate() + 3);
        
        let newAction = newCalledStatus
          ? (allDocumentsUploaded 
              ? `${firstName} has uploaded all documents. No action required.`
              : `Call ${firstName} to upload documents by ${followUpDate.toLocaleDateString()}`)
          : `${firstName} to see what they are working on (new signup)`;

        await updateDoc(loanDocRef, {
          called: newCalledStatus,
          action: newAction,
          followUpDate: followUpDate,
          displayDate: followUpDate,
          followupCalled: false
        });

        setIsCalled(newCalledStatus);
        setCurrentAction(newAction);
      }
    } catch (error) {
      console.error('Error updating call status:', error);
    }
  };

  const handleNotesChange = async (e) => {
    e.stopPropagation();
    const newNotes = e.target.value;
    setCurrentNotes(newNotes);
    
    try {
      const loanDocRef = doc(db, 'loanApplications', id);
      await updateDoc(loanDocRef, {
        notes: newNotes
      });
    } catch (error) {
      console.error('Error updating notes:', error);
    }
  };

  return (
    <div 
      className={`w-96 rounded-xl overflow-hidden shadow-lg border border-gray-200 cursor-pointer ${isWorkingCapital ? 'bg-blue-50' : isNonSBA ? 'bg-green-50' : 'bg-white'}`}
      onClick={onClick}
    >
      <div className="p-6 border-b border-gray-100">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-semibold text-gray-800">{name}</h3>
          <img src={logo} alt="Company Logo" className="w-5 h-5" />
        </div>
        <div className="flex items-center text-gray-600 text-sm mb-3">
          <MapPin className="w-4 h-4 mr-2 text-blue-500" />
          <p>{businessState}</p>
        </div>
        <div className="border-2 border-dotted border-gray-300 rounded-md p-2 text-sm mb-3">
          <span className="text-gray-600">Action: </span>
          <span className="font-medium text-blue-600">{currentAction || 'No action recorded'}</span>
        </div>
        {isCallsTab && (
          <div className="border-2 border-dotted border-gray-300 rounded-md p-2 text-sm mb-3">
            <span className="text-gray-600 block mb-1">Notes: </span>
            <textarea
              className="w-full p-2 text-sm text-gray-700 border border-gray-200 rounded resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
              placeholder="Enter call notes here..."
              value={currentNotes}
              onChange={handleNotesChange}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        )}
        <div className="flex items-center justify-between mt-2 p-2 bg-gray-50 rounded-md">
          <span className="text-sm text-gray-600 flex items-center">
            <Phone className="w-4 h-4 mr-2 text-gray-500" />
            Called Status
          </span>
          <button
            onClick={handleCallToggle}
            className={`px-3 py-1 rounded-md text-sm font-medium transition-colors duration-200 ${
              isCalled 
                ? 'bg-green-100 text-green-800 hover:bg-green-200' 
                : 'bg-red-100 text-red-800 hover:bg-red-200'
            }`}
          >
            {isCalled ? 'Yes' : 'No'}
          </button>
        </div>
      </div>
      <div className="p-6 space-y-4">
        <div className="flex items-center justify-between">
          <span className="text-gray-600">
            {isPrequalification ? "Projected Sale Price" : "Loan Amount"}
          </span>
          <div className="flex items-center">
            <DollarSign className="w-5 h-5 text-green-600 mr-1" />
            <span className="text-2xl font-bold text-gray-800">
              ${Number(isPrequalification ? projectedSalePrice : loanSize).toLocaleString()}
            </span>
          </div>
        </div>
        <div className={`rounded-lg p-4 space-y-2 ${isWorkingCapital ? 'bg-blue-100' : isNonSBA ? 'bg-green-100' : 'bg-blue-50'}`}>
          <div className="flex items-center justify-between">
            <span className="text-blue-700 font-medium">Business Age</span>
            <div className="flex items-center text-gray-800">
              <Calendar className="w-4 h-4 mr-2 text-blue-500" />
              {businessAge}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-blue-700 font-medium">EBITDA</span>
            <div className="flex items-center text-gray-800">
              <TrendingUp className="w-4 h-4 mr-2 text-blue-500" />
              ${Number(ebitda).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
      <div className={`px-6 py-4 flex items-center justify-between ${isWorkingCapital ? 'bg-blue-100' : isNonSBA ? 'bg-green-100' : 'bg-gray-50'}`}>
        <span className="text-gray-600 text-sm">Submitted</span>
        <div className="flex items-center text-gray-700 text-sm">
          <Clock className="w-4 h-4 mr-2 text-blue-500" />
          {submittedAt}
        </div>
      </div>
      <div className={`px-6 py-4 flex items-center justify-between ${isWorkingCapital ? 'bg-blue-200' : isNonSBA ? 'bg-green-200' : 'bg-gray-100'}`}>
        <span className="text-gray-600 text-sm">Last User Update</span>
        <div className="flex items-center text-gray-700 text-sm">
          <Edit3 className="w-4 h-4 mr-2 text-red-500" />
          {lastUserUpdate ? lastUserUpdate : 'N/A'}
        </div>
      </div>
    </div>
  );
};

export default LightModeLoanCard;