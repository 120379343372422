import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';

const PrequalFinancialMetricsDisplay = ({ ocrData = {}, loanData = {} }) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  };

  const years = Object.keys(ocrData).sort((a, b) => {
    const order = {
      'Business Tax Return - 2 Years Ago': 0,
      'Business Tax Return - Last Year': 1,
      'Business Tax Return - Current Year': 2
    };
    return order[a] - order[b];
  });

  const calculateEBITDAOwnersComp = (yearData) => {
    const baseIncome = Number(yearData.ordinary_business_income || 0);
    const originalOfficerCompensation = Number(yearData.compensation_of_officers || 0);
    const interest = Number(yearData.interest || 0);
    const depreciation = Number(yearData.depreciation || 0);
    const amortization = Number(yearData.amortization || 0);
    
    return baseIncome + originalOfficerCompensation + interest + depreciation + amortization;
  };

  const calculateAddBacks = (otherDeductions) => {
    const allowedAddBacks = ['MEALS', 'TRAVEL', 'DONATIONS'];
    return Object.entries(otherDeductions).reduce((total, [category, amount]) => {
      if (allowedAddBacks.some(allowed => category.toUpperCase().includes(allowed))) {
        return total + Number(amount || 0);
      }
      return total;
    }, 0);
  };

  const calculateSDE = (yearData) => {
    if (!yearData) return 0;
    const ebitdaOwnersComp = calculateEBITDAOwnersComp(yearData);
    const addbacksTotal = calculateAddBacks(yearData.other_deductions || {});
    
    return ebitdaOwnersComp + addbacksTotal;
  };

  const calculateNOI = (yearData) => {
    if (!yearData) return 0;
    const sde = calculateSDE(yearData);
    const newOfficerCompensation = 75000; // Fixed at $75,000
    return sde - newOfficerCompensation;
  };

  const calculateAnnualDebtService = () => {
    const totalLoanAmount = Number(loanData.projectedSalePrice || 0);
    const sba7aPercentage = 0.90; // Assuming 90% SBA 7(a) loan
    const sba7aLoanAmount = totalLoanAmount * sba7aPercentage;
    const interestRate = 0.105; // 10.5% interest rate
    const loanTerm = 10; // 10-year term

    if (sba7aLoanAmount <= 0) {
      return 0;
    }

    const monthlyRate = interestRate / 12;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment = (sba7aLoanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    
    return monthlyPayment * 12;
  };

  const calculateDSCR = (noi) => {
    const annualDebtService = calculateAnnualDebtService();
    return annualDebtService > 0 ? noi / annualDebtService : 0;
  };

  const getFinancialData = (key) => {
    return years.map(year => {
      const yearData = ocrData[year] || {};
      let value;
      switch (key) {
        case 'dscr':
          value = calculateDSCR(calculateNOI(yearData));
          break;
        case 'noi':
          value = calculateNOI(yearData);
          break;
        case 'ordinaryBusinessIncome':
          value = yearData.ordinary_business_income || 0;
          break;
        default:
          value = 0;
      }
      return { name: year.replace('Business Tax Return - ', ''), value };
    });
  };

  const financialStats = [
    { 
      name: 'DSCR', 
      stat: formatNumber(calculateDSCR(calculateNOI(ocrData[years[2]] || {}))),
      previousStat: formatNumber(calculateDSCR(calculateNOI(ocrData[years[1]] || {}))),
      change: ((calculateDSCR(calculateNOI(ocrData[years[2]] || {})) / calculateDSCR(calculateNOI(ocrData[years[1]] || {})) - 1) * 100).toFixed(2) + '%',
      changeType: calculateDSCR(calculateNOI(ocrData[years[2]] || {})) > calculateDSCR(calculateNOI(ocrData[years[1]] || {})) ? 'increase' : 'decrease',
      data: getFinancialData('dscr'),
      color: '#008081'
    },
    { 
      name: 'Net Operating Income', 
      stat: formatCurrency(calculateNOI(ocrData[years[2]] || {})),
      previousStat: formatCurrency(calculateNOI(ocrData[years[1]] || {})),
      change: ((calculateNOI(ocrData[years[2]] || {}) / calculateNOI(ocrData[years[1]] || {}) - 1) * 100).toFixed(2) + '%',
      changeType: calculateNOI(ocrData[years[2]] || {}) > calculateNOI(ocrData[years[1]] || {}) ? 'increase' : 'decrease',
      data: getFinancialData('noi'),
      color: '#6593F5'
    },
    { 
      name: 'Ordinary Business Income', 
      stat: formatCurrency(ocrData[years[2]]?.ordinary_business_income || 0),
      previousStat: formatCurrency(ocrData[years[1]]?.ordinary_business_income || 0),
      change: ((ocrData[years[2]]?.ordinary_business_income / ocrData[years[1]]?.ordinary_business_income - 1) * 100).toFixed(2) + '%',
      changeType: ocrData[years[2]]?.ordinary_business_income > ocrData[years[1]]?.ordinary_business_income ? 'increase' : 'decrease',
      data: getFinancialData('ordinaryBusinessIncome'),
      color: '#3FE0D0'
    }
  ];

  return (
    <div className="overflow-x-auto">
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-8">
        {financialStats.map((item) => (
          <FinancialStatCard key={item.name} item={item} />
        ))}
      </div>

      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-2 border-b text-left">Metric</th>
            {years.map(year => (
              <th key={year} className="px-4 py-2 border-b text-right">{year.replace('Business Tax Return - ', '')}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[
            { key: 'gross_receipts_or_sales', label: 'Gross Receipts or Sales' },
            { key: 'cost_of_goods_sold', label: 'Cost of Goods Sold', isNegative: true },
            { key: 'ordinary_business_income', label: 'Ordinary Business Income', isBold: true },
            { key: 'compensation_of_officers', label: '+ Original Officer Compensation' },
            { key: 'interest', label: '+ Interest' },
            { key: 'depreciation', label: '+ Depreciation' },
            { key: 'amortization', label: '+ Amortization' },
            { key: 'ebitdaOwnersComp', label: 'EBITDA + Owners Comp', calculate: calculateEBITDAOwnersComp, isBold: true, hasBorder: true },
            { key: 'ebitdaOwnersCompPercentage', label: 'EBITDA + Owners Comp %', calculate: (yearData) => (calculateEBITDAOwnersComp(yearData) / Number(yearData.gross_receipts_or_sales || 1)) * 100, isPercentage: true },
          ].map(({ key, label, calculate, isBold, hasBorder, isNegative, isPercentage }) => (
            <tr key={key} className={`hover:bg-gray-50 ${hasBorder ? 'border-t-2 border-gray-300' : ''}`}>
              <td className={`px-4 py-2 border-b ${isBold ? 'font-bold' : 'font-medium'} ${isNegative ? 'text-red-600' : ''}`}>{label}</td>
              {years.map(year => (
                <td key={year} className={`px-4 py-2 border-b text-right ${isBold ? 'font-bold' : ''} ${isNegative ? 'text-red-600' : ''}`}>
                  {calculate 
                    ? (isPercentage
                        ? `${formatNumber(calculate(ocrData[year]))}%`
                        : formatCurrency(calculate(ocrData[year])))
                    : (isPercentage
                        ? `${formatNumber((Number(ocrData[year]?.[key]) / Number(ocrData[year]?.gross_receipts_or_sales || 1)) * 100)}%`
                        : formatCurrency(Number(ocrData[year]?.[key] || 0)))}
                </td>
              ))}
            </tr>
          ))}
          <tr className="bg-gray-100">
            <td colSpan={years.length + 1} className="px-4 py-2 border-b font-bold">Add Backs</td>
          </tr>
          {['MEALS', 'TRAVEL', 'DONATIONS'].map(addBack => (
            <tr key={addBack} className="hover:bg-gray-50">
              <td className="px-4 py-2 border-b pl-8 font-medium text-green-600">+ {addBack}</td>
              {years.map(year => (
                <td key={year} className="px-4 py-2 border-b text-right text-green-600">
                  {formatCurrency(
                    Object.entries(ocrData[year]?.other_deductions || {})
                      .filter(([category]) => category.toUpperCase().includes(addBack))
                      .reduce((sum, [, value]) => sum + Number(value || 0), 0)
                  )}
                </td>
              ))}
            </tr>
          ))}
          {[
            { key: 'sde', label: 'Seller\'s Discretionary Earnings (SDE)', calculate: calculateSDE, isBold: true, hasBorder: true },
            { key: 'newOfficerCompensation', label: '- New Officer Compensation', calculate: () => -75000, isNegative: true },
            { key: 'noi', label: 'Net Operating Income (NOI)', calculate: calculateNOI, isBold: true, hasBorder: true },
            { key: 'dscr', label: 'Debt Service Coverage Ratio (DSCR)', calculate: (yearData) => calculateDSCR(calculateNOI(yearData)), isBold: true, hasBorder: true, isRatio: true },
          ].map(({ key, label, calculate, isBold, hasBorder, isNegative, isRatio }) => (
            <tr key={key} className={`hover:bg-gray-50 ${hasBorder ? 'border-t-2 border-gray-300' : ''}`}>
              <td className={`px-4 py-2 border-b ${isBold ? 'font-bold' : 'font-medium'} ${isNegative ? 'text-red-600' : ''}`}>{label}</td>
              {years.map(year => (
                <td key={year} className={`px-4 py-2 border-b text-right ${isBold ? 'font-bold' : ''} ${isNegative ? 'text-red-600' : ''}`}>
                  {isRatio
                    ? formatNumber(calculate(ocrData[year]))
                    : formatCurrency(calculate(ocrData[year]))}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const FinancialStatCard = ({ item }) => (
  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
    <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
    <dd className="mt-2 flex items-baseline justify-between md:block lg:flex">
      <div className="flex items-baseline text-sm font-semibold text-green-600">
        {item.changeType === 'increase' ? (
          <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
        ) : (
          <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
        )}
        <span className="sr-only"> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
        {item.change}
      </div>
      <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
        from {item.previousStat}
      </div>
    </dd>
    <div className="mt-3">
      <ResponsiveContainer width="100%" height={100}>
        <BarChart data={item.data}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" fill={item.color} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  </div>
);

export default PrequalFinancialMetricsDisplay;