import React from 'react';
import { Link } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import precheckLanding from '../images/prechecklanding.png';
import commandLanding from '../images/commandlanding.png';

export function Button({ to, children, variant = 'primary' }) {
  const baseClasses = 'inline-block px-8 py-4 text-lg font-medium text-white rounded-lg';
  const primaryClasses = 'bg-blue-600 hover:bg-blue-700';
  const secondaryClasses = 'bg-blue-400 hover:bg-blue-500';

  return (
    <Link
      to={to}
      className={`${baseClasses} ${variant === 'primary' ? primaryClasses : secondaryClasses}`}
    >
      {children}
    </Link>
  );
}

export function Hero() {
  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pb-24 pt-16 sm:px-6 lg:px-8 lg:pt-24">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-8 lg:gap-20">
          <div className="hero-content w-full lg:w-1/2">
            <h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-5xl mb-6">
              <div className="text-center">Get term sheets for your next SBA 7(a) loan in</div>
              <div className="flex justify-center items-center mt-2">
                <span className="text-blue-600 line-through mr-2">weeks</span>
                <span className="text-blue-600">24 hours</span>
              </div>
            </h1>
            <p className="text-lg text-slate-700 mb-8 text-center">
              Get started by answering a few questions below.
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-8">
              <Link
                to="/searchersignup"
                className="w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-center"
              >
                Acquisition
              </Link>
              <Link
                to="/workingcapitalsignup"
                className="w-full sm:w-auto bg-blue-400 hover:bg-blue-500 text-white font-bold py-3 px-6 rounded text-center"
              >
                Working Capital
              </Link>
             
            </div>
            <div className="text-sm text-gray-500 mb-8 text-center">
              Applying is free and won't impact your credit.
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm max-w-md mx-auto">
              <div className="flex items-center justify-center">
                <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                One application
              </div>
              <div className="flex items-center justify-center">
                <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                40+ lenders
              </div>
              <div className="flex items-center justify-center">
                <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Multiple term sheets
              </div>
              <div className="flex items-center justify-center">
                <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
                Expert guidance
              </div>
            </div>
          </div>
          <div className="relative hero-images" style={{ top: '-50px' }}>
            <img src={precheckLanding} alt="Precheck Landing" className="w-full max-w-2xl mx-auto" />
            <img src={commandLanding} alt="Command Landing" className="absolute bottom-[-10%] -left-8 w-1/3 max-w-xs" />
          </div>
        </div>
        
        {/* Partnered lenders section */}
        
      </div>
      
      {/* Spacer to prevent content from being hidden behind the banner */}
      <div className="h-20 sm:h-24"></div>
    </>
  );
}

export default Hero;
