import React from 'react';
import { Document, Page, Text, Image, View, StyleSheet, pdf } from '@react-pdf/renderer';
import logoImage from '../images/images.png'; // Adjust this import path as needed

const styles = StyleSheet.create({
  page: {
    padding: 50,
    fontSize: 11,
    fontFamily: 'Helvetica',
    lineHeight: 1.5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20, // Reduced from 30 to 20
  },
  logo: {
    width: 50,
    height: 50,
  },
  companyInfo: {
    alignItems: 'flex-end',
    fontSize: 10,
  },
  date: {
    marginBottom: 20,
  },
  recipient: {
    marginBottom: 30,
  },
  subject: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 30,
    textDecoration: 'underline',
  },
  content: {
    textAlign: 'justify',
  },
  paragraph: {
    marginBottom: 15,
  },
  bulletPoint: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  bullet: {
    width: 10,
    marginRight: 5,
  },
  bulletText: {
    flex: 1,
  },
  signature: {
    marginTop: 30,
  },
  signatureName: {
    fontWeight: 'bold',
  },
  disclaimer: {
    marginTop: 30,
    fontSize: 9,
    fontStyle: 'italic',
  },
});

const PrequalificationLetterPDF = ({ customerName, prequalifiedAmount, date }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image style={styles.logo} src={logoImage} />
        <View style={styles.companyInfo}>
          <Text>Lended</Text>
          <Text>17 Alta Lane</Text>
          <Text>Chappaqua, NY 10514</Text>
          <Text>Phone: (914) 319-7160</Text>
          <Text>Email: max@lended.ai</Text>
        </View>
      </View>
      <Text style={styles.date}>{date}</Text>
      <Text style={styles.subject}>Pre-Qualification for SBA Loan</Text>

      <View style={styles.content}>
        <Text style={styles.paragraph}>Dear {customerName},</Text>

        <Text style={styles.paragraph}>
          I am pleased to inform you that Lended has reviewed your financial background and qualifications as of {date}. Based on the information you provided, including your resume, credit score, liquidity, and assets, it is our opinion that you appear qualified to obtain an SBA loan to purchase a business for an amount up to ${prequalifiedAmount.toLocaleString()}.
        </Text>

        <Text style={styles.paragraph}>
          While this is a positive indication of your potential eligibility, please note the following important points:
        </Text>

        <View style={styles.bulletPoint}>
          <Text style={styles.bullet}>•</Text>
          <Text style={styles.bulletText}>Lended is not a bank or financial institution and will not be directly funding the SBA loan. This letter does not represent a commitment to lend.</Text>
        </View>

        <View style={styles.bulletPoint}>
          <Text style={styles.bullet}>•</Text>
          <Text style={styles.bulletText}>This pre-qualification is based solely on our review of the financial information and supporting documentation you provided as of the date of this letter. It is not a guarantee that an SBA loan will be approved. The actual terms and approval of any loan are subject to a formal application, underwriting review, and credit approval by a bank or SBA lender.</Text>
        </View>

        <View style={styles.bulletPoint}>
          <Text style={styles.bullet}>•</Text>
          <Text style={styles.bulletText}>Your financial situation and creditworthiness may change over time. This pre-qualification only pertains to your qualifications as of the date of our review.</Text>
        </View>

        <Text style={styles.paragraph}>
          If you have any questions about this pre-qualification letter, please don't hesitate to contact us. We appreciate your interest in Lended and look forward to potentially assisting you with your business financing needs.
        </Text>

        <View style={styles.signature}>
          <Text>Sincerely,</Text>
          <Text style={styles.signatureName}>Max Krakow</Text>
          <Text>Chief Executive Officer</Text>
          <Text>Lended</Text>
        </View>
      </View>

      <Text style={styles.disclaimer}>
        This pre-qualification letter is for informational purposes only and does not constitute a loan commitment or guarantee of approval.
      </Text>
    </Page>
  </Document>
);

export const generatePrequalificationLetterPDF = async (customerName, prequalifiedAmount) => {
  const date = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  
  try {
    const blob = await pdf(
      <PrequalificationLetterPDF 
        customerName={customerName} 
        prequalifiedAmount={prequalifiedAmount} 
        date={date} 
      />
    ).toBlob();
    return blob;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};