import React, { useState, useEffect, useRef, useCallback } from 'react';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const formatNumber = (num) => {
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
};

const CurrencyInput = ({ value, onChange, label }) => {
  const handleChange = (e) => {
    let inputValue = e.target.value.replace(/[^0-9.]/g, '');
    
    // Remove leading zeros
    inputValue = inputValue.replace(/^0+/, '');
    
    // Ensure only one decimal point
    const parts = inputValue.split('.');
    if (parts.length > 2) {
      inputValue = parts[0] + '.' + parts.slice(1).join('');
    }
    
    // Limit to two decimal places
    if (parts[1] && parts[1].length > 2) {
      inputValue = parts[0] + '.' + parts[1].slice(0, 2);
    }
    
    // Convert to number and update state
    const numValue = parseFloat(inputValue) || 0;
    onChange(numValue);
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          type="text"
          value={value === 0 ? '' : formatNumber(value)}
          onChange={handleChange}
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
          placeholder="0.00"
        />
      </div>
    </div>
  );
};

const PrequalDebtServiceCoverageCalculator = ({ prequalId, projectedSalePrice }) => {
  const [confirmedReturns, setConfirmedReturns] = useState([]);
  const [selectedAddbacks, setSelectedAddbacks] = useState({});
  const [normalizedOfficerCompensation, setNormalizedOfficerCompensation] = useState(0);
  const [interestRate, setInterestRate] = useState(10.5);
  const [loanTerm, setLoanTerm] = useState(10);
  const [activeTab, setActiveTab] = useState(0);
  const [financialAnalysis, setFinancialAnalysis] = useState({});
  const [savedYears, setSavedYears] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loanPercentage, setLoanPercentage] = useState(90);
  const [workingCapital, setWorkingCapital] = useState(0);
  const [guaranteeFee, setGuaranteeFee] = useState(0);
  const [closingCost, setClosingCost] = useState(0);
  const [packagingCost, setPackagingCost] = useState(0);
  const calculatorRef = useRef(null);

  const calculateTotalProjectCost = useCallback(() => {
    return Number(projectedSalePrice) + workingCapital + guaranteeFee + closingCost + packagingCost;
  }, [projectedSalePrice, workingCapital, guaranteeFee, closingCost, packagingCost]);

  const calculateLoanAmount = useCallback(() => {
    const totalProjectCost = calculateTotalProjectCost();
    return totalProjectCost * (loanPercentage / 100);
  }, [calculateTotalProjectCost, loanPercentage]);

  const calculateGuaranteeFee = useCallback(() => {
    const loanAmount = calculateLoanAmount();
    const guaranteedAmount = loanAmount * 0.75; // 75% of the loan amount

    let fee = 0;

    console.log('Calculating Guarantee Fee:');
    console.log('Total Project Cost:', calculateTotalProjectCost());
    console.log('Loan Amount:', loanAmount);
    console.log('Guaranteed Amount:', guaranteedAmount);

    if (loanAmount < 1000000) {
      fee = 0; // 0% for loans less than $1,000,000
      console.log('Fee (0% for loans less than $1,000,000):', fee);
    } else if (loanAmount <= 2000000) {
      fee = 0.0145 * guaranteedAmount;
      console.log('Fee (1.45% of guaranteed amount):', fee);
    } else {
      fee = 0.0350 * Math.min(guaranteedAmount, 1000000) +
            0.0375 * Math.max(guaranteedAmount - 1000000, 0);
      console.log('Fee (3.50% up to $1M + 3.75% over $1M):', fee);
    }

    return fee;
  }, [calculateLoanAmount, calculateTotalProjectCost]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch OCR metadata
        const ocrMetadataRef = collection(db, 'OCRMetadata');
        const q = query(ocrMetadataRef, where('applicationId', '==', prequalId));
        const querySnapshot = await getDocs(q);
        
        const confirmedDocs = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(doc => doc.documentName === "Business Tax Returns" && isDocumentFullyConfirmed(doc));

        // Fetch saved financial analysis and loan structure
        const prequalDocRef = doc(db, 'prequalificationApplications', prequalId);
        const prequalDocSnap = await getDoc(prequalDocRef);
        const savedData = prequalDocSnap.data();
        const savedFinancialAnalysis = savedData?.financialAnalysis || {};
        const savedLoanStructure = savedData?.loanStructure || {};

        // Merge OCR data with saved financial analysis
        const mergedData = confirmedDocs.map(ocrDoc => {
          const year = ocrDoc.label || ocrDoc.documentName;
          const savedData = savedFinancialAnalysis[year] || {};
          return {
            ...ocrDoc,
            financialAnalysis: savedData
          };
        });

        setConfirmedReturns(mergedData);
        setFinancialAnalysis(savedFinancialAnalysis);

        // Initialize selectedAddbacks and normalizedOfficerCompensation
        const initialAddbacks = {};
        let initialCompensation = 0;
        const savedYears = [];

        mergedData.forEach(doc => {
          const year = doc.label || doc.documentName;
          if (savedFinancialAnalysis[year]) {
            savedYears.push(year);
            Object.keys(savedFinancialAnalysis[year].addBacks || {}).forEach(category => {
              initialAddbacks[category] = true;
            });
            if (savedFinancialAnalysis[year].newOfficerCompensation) {
              initialCompensation = savedFinancialAnalysis[year].newOfficerCompensation;
            }
          }
        });

        setSelectedAddbacks(initialAddbacks);
        setNormalizedOfficerCompensation(initialCompensation);
        setSavedYears(savedYears);

        // Set loan structure state
        setWorkingCapital(savedLoanStructure.workingCapital || 0);
        setGuaranteeFee(savedLoanStructure.guaranteeFee || 0);
        setClosingCost(savedLoanStructure.closingCost || 0);
        setPackagingCost(savedLoanStructure.packagingCost || 0);
        setLoanPercentage(savedLoanStructure.loanPercentage || 90);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [prequalId]);

  const isDocumentFullyConfirmed = (document) => {
    if (!document || !document.confirmedFields) return false;
    const requiredFields = ['compensation_of_officers', 'gross_receipts_or_sales', 'ordinary_business_income', 'other_deductions'];
    return requiredFields.every(field => document.confirmedFields[field] === 'changed' || document.confirmedFields[field] === 'unchanged');
  };

  const calculateAnnualDebtService = () => {
    if (loanAmount <= 0 || interestRate <= 0 || loanTerm <= 0) {
      console.error('Invalid loan parameters');
      return 0;
    }

    const monthlyRate = interestRate / 12 / 100;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    const annualDebtService = monthlyPayment * 12;

    return annualDebtService;
  };

  const calculateNOI = useCallback((taxReturn) => {
    if (!taxReturn || !taxReturn.ocrResult) {
      console.error('Tax return or OCR result is missing');
      return 0;
    }

    const baseIncome = Number(taxReturn.ocrResult?.ordinary_business_income || 0);
    const originalOfficerCompensation = Number(taxReturn.ocrResult?.compensation_of_officers || 0);
    const interest = Number(taxReturn.ocrResult?.interest || 0);
    const depreciation = Number(taxReturn.ocrResult?.depreciation || 0);
    
    const year = taxReturn.label || taxReturn.documentName;
    const addbacks = Object.entries(selectedAddbacks)
      .filter(([_, isSelected]) => isSelected)
      .reduce((sum, [category]) => {
        const amount = taxReturn.ocrResult?.other_deductions?.find(d => d.category === category)?.amount || 0;
        return sum + Number(amount);
      }, 0);
    
    const normalizedCompensation = typeof normalizedOfficerCompensation === 'number' 
      ? normalizedOfficerCompensation 
      : 0;

    const noi = baseIncome + addbacks + originalOfficerCompensation - normalizedCompensation + interest + depreciation;
    return isNaN(noi) ? 0 : noi;
  }, [selectedAddbacks, normalizedOfficerCompensation]);

  const calculateDSCR = useCallback((noi) => {
    const annualDebtService = calculateAnnualDebtService();
    return annualDebtService > 0 ? noi / annualDebtService : 0;
  }, [calculateAnnualDebtService]);

  const handleAddbackChange = (category, isChecked) => {
    setSelectedAddbacks(prev => {
      const updated = { ...prev, [category]: isChecked };
      return updated;
    });
    // Remove the year from savedYears when a change is made
    const currentYear = confirmedReturns[activeTab].label || confirmedReturns[activeTab].documentName;
    setSavedYears(prev => prev.filter(year => year !== currentYear));
  };

  const saveChanges = async () => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      const prequalDocRef = doc(db, 'prequalificationApplications', prequalId);
      const currentTaxReturn = confirmedReturns[activeTab];
      const year = currentTaxReturn.label || currentTaxReturn.documentName;
      
      const addBacks = {};

      currentTaxReturn.ocrResult?.other_deductions?.forEach((deduction) => {
        if (selectedAddbacks[deduction.category]) {
          addBacks[deduction.category] = Number(deduction.amount);
        }
      });

      const financialAnalysis = {
        [year]: {
          addBacks,
          grossRevenue: Number(currentTaxReturn.ocrResult?.gross_receipts_or_sales || 0),
          newOfficerCompensation: normalizedOfficerCompensation,
          officerCompensation: Number(currentTaxReturn.ocrResult?.compensation_of_officers || 0),
          ordinaryBusinessIncome: Number(currentTaxReturn.ocrResult?.ordinary_business_income || 0),
          interest: Number(currentTaxReturn.ocrResult?.interest || 0),
          depreciation: Number(currentTaxReturn.ocrResult?.depreciation || 0),
          costOfGoodsSold: Number(currentTaxReturn.ocrResult?.cost_of_goods_sold || 0),
          year
        }
      };

      const updateData = {
        [`financialAnalysis.${year}`]: financialAnalysis[year],
        loanStructure: {
          projectedSalePrice: Number(projectedSalePrice),
          workingCapital: Number(workingCapital),
          guaranteeFee: Number(guaranteeFee),
          closingCost: Number(closingCost),
          packagingCost: Number(packagingCost),
          loanPercentage: Number(loanPercentage)
        }
      };

      await updateDoc(prequalDocRef, updateData);

      console.log(`Changes saved successfully for ${year}`);
      setSavedYears(prev => [...new Set([...prev, year])]);
    } catch (error) {
      console.error('Error saving changes:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (calculatorRef.current.requestFullscreen) {
        calculatorRef.current.requestFullscreen();
      } else if (calculatorRef.current.mozRequestFullScreen) { // Firefox
        calculatorRef.current.mozRequestFullScreen();
      } else if (calculatorRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
        calculatorRef.current.webkitRequestFullscreen();
      } else if (calculatorRef.current.msRequestFullscreen) { // IE/Edge
        calculatorRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, []);

  const calculateSDE = (taxReturn) => {
    if (!taxReturn || !taxReturn.ocrResult) {
      console.error('Tax return or OCR result is missing');
      return 0;
    }

    const baseIncome = Number(taxReturn.ocrResult?.ordinary_business_income || 0);
    const originalOfficerCompensation = Number(taxReturn.ocrResult?.compensation_of_officers || 0);
    const interest = Number(taxReturn.ocrResult?.interest || 0);
    const depreciation = Number(taxReturn.ocrResult?.depreciation || 0);
    
    const addbacks = Object.values(financialAnalysis[taxReturn.label || taxReturn.documentName]?.addBacks || {})
      .reduce((sum, amount) => sum + Number(amount), 0);

    const sde = baseIncome + originalOfficerCompensation + interest + depreciation + addbacks;
    return isNaN(sde) ? 0 : sde;
  };

  const calculateEBITDAOwnerCompPercentage = (taxReturn) => {
    const grossReceipts = Number(taxReturn.ocrResult?.gross_receipts_or_sales || 0);
    const ebitdaOwnerComp = Number(taxReturn.ocrResult?.ordinary_business_income || 0) +
      Number(taxReturn.ocrResult?.compensation_of_officers || 0) +
      Number(taxReturn.ocrResult?.interest || 0) +
      Number(taxReturn.ocrResult?.depreciation || 0);

    if (grossReceipts === 0) return 0;
    return (ebitdaOwnerComp / grossReceipts) * 100;
  };

  const totalProjectCost = calculateTotalProjectCost();
  const loanAmount = calculateLoanAmount();

  useEffect(() => {
    const newGuaranteeFee = calculateGuaranteeFee();
    setGuaranteeFee(newGuaranteeFee);
  }, [projectedSalePrice, workingCapital, closingCost, packagingCost, loanPercentage, calculateGuaranteeFee]);

  const currentNOI = calculateNOI(confirmedReturns[activeTab]);

  return (
    <div ref={calculatorRef} className="bg-white shadow-2xl rounded-2xl p-8 max-w-6xl mx-auto flex flex-col min-h-screen">
      <div className="flex-grow overflow-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-3xl font-light text-gray-800">Debt Service Coverage Calculator</h2>
          <button
            onClick={toggleFullScreen}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition-colors duration-200 ease-in-out"
          >
            {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
          </button>
        </div>
        
        {/* New section for loan structure */}
        <div className="mb-8">
          <h3 className="text-xl font-light mb-4 text-gray-800">Loan Structure</h3>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <CurrencyInput
              label="Working Capital"
              value={workingCapital}
              onChange={(value) => setWorkingCapital(Number(value))}
            />
            <CurrencyInput
              label="Guarantee Fee (Auto-calculated)"
              value={guaranteeFee}
              onChange={() => {}} // This input is now read-only
              disabled={true}
            />
            <CurrencyInput
              label="Closing Cost"
              value={closingCost}
              onChange={(value) => setClosingCost(Number(value))}
            />
            <CurrencyInput
              label="Packaging Cost"
              value={packagingCost}
              onChange={(value) => setPackagingCost(Number(value))}
            />
          </div>
          <div className="flex items-center mb-4">
            <label className="mr-4">
              Loan Percentage:
              <input
                type="number"
                value={loanPercentage}
                onChange={(e) => setLoanPercentage(Math.min(100, Math.max(0, Number(e.target.value))))}
                className="ml-2 w-20 px-2 py-1 border rounded"
              />
            </label>
            <span>Equity Injection: {100 - loanPercentage}%</span>
          </div>
          <div className="grid grid-cols-2 gap-2 text-sm">
            <div className="font-medium text-gray-600">Projected Sale Price:</div>
            <div className="text-right">${formatNumber(projectedSalePrice)}</div>
            
            <div className="font-medium text-gray-600">Working Capital:</div>
            <div className="text-right">${formatNumber(workingCapital)}</div>
            
            <div className="font-medium text-gray-600">Guarantee Fee:</div>
            <div className="text-right">${formatNumber(guaranteeFee)}</div>
            
            <div className="font-medium text-gray-600">Closing Cost:</div>
            <div className="text-right">${formatNumber(closingCost)}</div>
            
            <div className="font-medium text-gray-600">Packaging Cost:</div>
            <div className="text-right">${formatNumber(packagingCost)}</div>
            
            <div className="font-bold text-gray-800 border-t border-gray-300 pt-2 mt-2">Total Project Cost:</div>
            <div className="font-bold text-gray-800 border-t border-gray-300 pt-2 mt-2 text-right">${formatNumber(totalProjectCost)}</div>
            
            <div className="font-bold text-gray-800">Calculated Loan Amount:</div>
            <div className="font-bold text-gray-800 text-right">${formatNumber(loanAmount)}</div>
          </div>
        </div>

        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {confirmedReturns.map((taxReturn, index) => {
                const year = taxReturn.label || taxReturn.documentName;
                const isSaved = savedYears.includes(year);
                return (
                  <button
                    key={taxReturn.id}
                    className={`${
                      index === activeTab
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200 ease-in-out ${
                      isSaved ? 'bg-green-50' : ''
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {year}
                  </button>
                );
              })}
            </nav>
          </div>
        </div>

        {confirmedReturns[activeTab] && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
            <div>
              <h3 className="text-2xl font-light mb-4 text-gray-800">
                {confirmedReturns[activeTab].label || confirmedReturns[activeTab].documentName}
              </h3>
              <p className="mb-4 text-gray-600">Ordinary Business Income: <span className="font-semibold">${formatNumber(confirmedReturns[activeTab].ocrResult?.ordinary_business_income || 0)}</span></p>

              <div className="mb-6">
                <h4 className="text-xl font-light mb-3 text-gray-800">Addbacks</h4>
                {confirmedReturns[activeTab].ocrResult?.other_deductions?.map((deduction) => (
                  <label key={deduction.category} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={selectedAddbacks[deduction.category] || false}
                      onChange={(e) => handleAddbackChange(deduction.category, e.target.checked)}
                      className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-gray-700">{deduction.category}: ${formatNumber(Number(deduction.amount))}</span>
                  </label>
                ))}
              </div>

              <div className="mb-6">
                <h4 className="text-xl font-light mb-3 text-gray-800">Normalized Officer Compensation</h4>
                <CurrencyInput
                  label="Owner Compensation"
                  value={normalizedOfficerCompensation}
                  onChange={(value) => setNormalizedOfficerCompensation(Number(value))}
                />
              </div>
            </div>

            <div className="bg-gray-50 p-6 rounded-xl shadow-inner">
              <h4 className="text-xl font-light mb-4 text-gray-800">Calculation Breakdown</h4>
              <div className="grid grid-cols-2 gap-2 text-sm">
                <div className="font-medium text-gray-600">Gross Receipts or Sales</div>
                <div className="text-right">${formatNumber(confirmedReturns[activeTab].ocrResult?.gross_receipts_or_sales || 0)}</div>
                
                <div className="font-medium text-gray-600">Cost of Goods Sold</div>
                <div className="text-right">${formatNumber(confirmedReturns[activeTab].ocrResult?.cost_of_goods_sold || 0)}</div>
                
                <div className="font-medium text-gray-600">Ordinary Business Income</div>
                <div className="text-right">${formatNumber(confirmedReturns[activeTab].ocrResult?.ordinary_business_income || 0)}</div>
                
                <div className="font-medium text-gray-600">+ Original Officer Compensation</div>
                <div className="text-right">${formatNumber(Number(confirmedReturns[activeTab].ocrResult?.compensation_of_officers || 0))}</div>
                
                <div className="font-medium text-gray-600">+ Interest</div>
                <div className="text-right">${formatNumber(Number(confirmedReturns[activeTab].ocrResult?.interest || 0))}</div>
                
                <div className="font-medium text-gray-600">+ Depreciation</div>
                <div className="text-right">${formatNumber(Number(confirmedReturns[activeTab].ocrResult?.depreciation || 0))}</div>
                
                <div className="font-bold text-gray-800 border-t border-gray-300 pt-2 mt-2">EBITDA + Owners Comp</div>
                <div className="font-bold text-gray-800 border-t border-gray-300 pt-2 mt-2 text-right">
                  ${formatNumber(
                    Number(confirmedReturns[activeTab].ocrResult?.ordinary_business_income || 0) +
                    Number(confirmedReturns[activeTab].ocrResult?.compensation_of_officers || 0) +
                    Number(confirmedReturns[activeTab].ocrResult?.interest || 0) +
                    Number(confirmedReturns[activeTab].ocrResult?.depreciation || 0)
                  )}
                </div>
                
                <div className="font-medium text-gray-600">EBITDA + Owners Comp %</div>
                <div className="text-right">{formatNumber(calculateEBITDAOwnerCompPercentage(confirmedReturns[activeTab]))}%</div>
                
                {confirmedReturns[activeTab].ocrResult?.other_deductions?.map((deduction, index) => (
                  selectedAddbacks[deduction.category] && (
                    <React.Fragment key={deduction.category}>
                      <div className={`font-medium text-gray-600 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} p-2`}>+ {deduction.category}</div>
                      <div className={`text-right ${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} p-2`}>${formatNumber(Number(deduction.amount))}</div>
                    </React.Fragment>
                  )
                ))}
                
                <div className="font-bold text-gray-800 border-t border-gray-300 pt-2 mt-2">Seller's Discretionary Earnings (SDE)</div>
                <div className="font-bold text-gray-800 border-t border-gray-300 pt-2 mt-2 text-right">
                  ${formatNumber(calculateSDE(confirmedReturns[activeTab]))}
                </div>
                
                <div className="font-medium text-gray-600">- New Officer Compensation</div>
                <div className="text-right">${formatNumber(normalizedOfficerCompensation)}</div>
                
                <div className="font-bold text-gray-800 border-t border-gray-300 pt-2 mt-2">Net Operating Income (NOI)</div>
                <div className="font-bold text-gray-800 border-t border-gray-300 pt-2 mt-2 text-right">
                  ${formatNumber(currentNOI)}
                </div>
              </div>

              <div className="mt-6">
                <h4 className="text-xl font-light mb-4 text-gray-800">Results</h4>
                <div className="bg-white rounded-lg p-4 shadow-md">
                  <div className="text-center mb-4">
                    <h5 className="text-lg font-medium text-gray-700">Debt Service Coverage Ratio (DSCR)</h5>
                    <div className="text-4xl font-bold text-indigo-600 mt-2">
                      {formatNumber(calculateDSCR(currentNOI))}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2 text-sm">
                    <div className="font-medium text-gray-600">Net Operating Income (NOI)</div>
                    <div className="text-right">${formatNumber(currentNOI)}</div>
                    
                    <div className="font-medium text-gray-600">Annual Debt Service</div>
                    <div className="text-right">${formatNumber(calculateAnnualDebtService())}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-auto pt-4">
        <button
          onClick={saveChanges}
          disabled={isSaving}
          className={`w-full px-6 py-3 ${
            isSaving ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
          } text-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition-colors duration-200 ease-in-out`}
        >
          {isSaving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>
    </div>
  );
};

export default PrequalDebtServiceCoverageCalculator;
