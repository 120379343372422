import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, updateDoc, collection, query, where, getDocs, addDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../../firebase';
import NaicsSearch from '../loanForm/NaicsSearch';
import CurrencyInput from '../loanForm/CurrencyInput';
import CapTable from '../loanForm/CapTable';
import StateSelect from '../loanForm/StateSelect';
import SelectQuestion from '../loanForm/SelectQuestion';
import FinancialInfo from '../loanForm/financialinfo';
import LoanStructure from '../loanForm/LoanStructure';
import RealEstateAppraisalInput from '../loanForm/RealEstateAppraisalInput';
import BusinessSummary from '../loanForm/BusinessSummary'; // Import the new component

const LoanForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [currentUser, setCurrentUser] = useState(null);
  const [applicationData, setApplicationData] = useState(null);
  const navigate = useNavigate();

  const formFields = [
    { name: 'loanSize', type: 'currency', label: 'What is the total purchase price for the business?', component: 'currency', required: true },
    { name: 'purchaseType', type: 'select', label: 'What type of purchase is it?', component: 'select', options: ['Stock', 'Asset', 'Unsure'], required: true },
    { name: 'businessState', type: 'select', label: 'What state is the business in?', component: 'stateSelect', required: true },
    { name: 'businessAge', type: 'select', label: 'How long has the company been in business?', component: 'select', options: ['Startup', '0-2 Years', '2+ Years'], required: true },
    { name: 'capTable', type: 'capTable', label: 'Cap Table', component: 'capTable', required: true },
    { name: 'realEstateAppraisal', type: 'currency', label: 'If there is real estate in the deal, what is its appraisal?', component: 'realEstateAppraisal', required: false },
    { name: 'loanStructure', type: 'loanStructure', label: 'Expected Loan Structure', component: 'loanStructure', required: true },
    { name: 'businessSummary', type: 'businessSummary', label: 'Can you provide a short summary of the business you are buying?', component: 'BusinessSummary', required: true }, // New question for business summary
    { name: 'additionalNotes', type: 'textarea', label: 'Additional Notes', component: 'textarea', required: false },
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchFormData = async () => {
      if (currentUser) {
        try {
          const formQuery = query(collection(db, 'loanApplications'), where('userId', '==', currentUser.uid));
          const querySnapshot = await getDocs(formQuery);
          if (!querySnapshot.empty) {
            const formDoc = querySnapshot.docs[0];
            setFormData(formDoc.data());
            setApplicationData(formDoc);
            setCurrentStep(formDoc.data().currentStep || 0); // Set the step to where the user left off
          } else {
            setFormData({});
            setApplicationData(null);
          }
        } catch (error) {
          console.error('Error fetching form data:', error);
        }
      }
    };

    fetchFormData();
  }, [currentUser]);

  const handleNext = async () => {
    const currentQuestion = formFields[currentStep];

    // Validate if the required field is filled out
    if (currentQuestion.required) {
      if (currentQuestion.component === 'capTable') {
        const totalPercentage = formData.capTable?.reduce((sum, owner) => sum + (parseFloat(owner.percentage) || 0), 0);
        if (!formData.capTable || formData.capTable.length === 0 || totalPercentage !== 100) {
          console.log('Cap Table validation failed:', formData.capTable, totalPercentage);
          alert('Please fill in the Cap Table and ensure the total ownership equals 100%');
          return;
        }
      } else if (!formData[currentQuestion.name]) {
        console.log(`Required field "${currentQuestion.name}" is missing:`, formData[currentQuestion.name]);
        alert(`Please fill in the required field: ${currentQuestion.label}`);
        return;
      }
    }

    try {
      if (currentUser) {
        const updatedFormData = {
          ...formData,
          currentStep: currentStep + 1,
        };

        console.log('Updated form data:', updatedFormData);

        if (applicationData) {
          console.log('Updating existing application:', applicationData.ref.path);
          await updateDoc(applicationData.ref, updatedFormData);
        } else {
          console.log('Creating new application');
          const newDocRef = await addDoc(collection(db, 'loanApplications'), {
            ...updatedFormData,
            userId: currentUser.uid,
            createdAt: new Date(),
            loanapplicationstatus: 'in progress',
          });
          console.log('New application created:', newDocRef.path);
          setApplicationData(newDocRef);
        }
      }

      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error('Error saving form data to Firestore:', error);
      console.log('Form data that caused the error:', formData);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleExit = async () => {
    if (currentUser && applicationData) {
      try {
        const updatedFormData = {
          ...formData,
          currentStep, // Save the current step
        };
        await updateDoc(applicationData.ref, updatedFormData);
      } catch (error) {
        console.error('Error saving form data to Firestore:', error);
      }
    }
    navigate('/portal');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const validFormData = {};

      // Filter out undefined values from formData
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== undefined) {
          validFormData[key] = value;
        }
      });
      

      // Update the existing document with the submitted status
      if (applicationData) {
        const applicationRef = doc(db, 'loanApplications', applicationData.id);
        await updateDoc(applicationRef, {
          ...validFormData,
           lastUserUpdate: new Date(),
          loanapplicationstatus: 'submitted',
        });
      }

      navigate('/portal');
    } catch (error) {
      console.error('Error submitting form data to Firestore:', error);
    }
  };

  const handleChange = (event) => {
    let name, value;
    if (event.target && event.target.name) {
      // Standard DOM event
      ({ name, value } = event.target);
    } else if (event.name !== undefined) {
      // Custom event object
      ({ name, value } = event);
    } else {
      console.error('Unexpected event structure:', event);
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCapTableChange = (value, pg) => {
    setFormData((prevData) => ({
      ...prevData,
      capTable: value,
      pg,
    }));
  };

  const renderQuestion = () => {
    switch (currentField.component) {
      case 'input':
        return (
          <>
            <label htmlFor={currentField.name} className="block mb-2 font-semibold text-gray-700">
              {currentField.label}
            </label>
            <input
              type="text"
              id={currentField.name}
              name={currentField.name}
              value={formData[currentField.name] || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 text-gray-700 border border-blue-900 rounded-lg focus:outline-none focus:border-blue-500"
              required={currentField.required}
            />
          </>
        );
      case 'select':
        return (
          <SelectQuestion
            name={currentField.name}
            label={currentField.label}
            options={currentField.options}
            value={formData[currentField.name] || ''}
            onChange={handleChange}
          />
        );
      case 'currency':
        return (
          <CurrencyInput
            name={currentField.name}
            label={currentField.label}
            value={formData[currentField.name] || ''}
            onChange={handleChange}
          />
        );
      case 'stateSelect':
        return (
          <StateSelect
            name={currentField.name}
            label={currentField.label}
            value={formData[currentField.name] || ''}
            onChange={handleChange}
          />
        );
      case 'naicsSearch':
        return (
          <NaicsSearch
            name={currentField.name}
            label={currentField.label}
            value={formData[currentField.name] || ''}
            onChange={handleChange}
          />
        );
      case 'capTable':
        return (
          <CapTable
            name={currentField.name}
            label={currentField.label}
            value={formData[currentField.name] || []}
            onChange={handleCapTableChange}
          />
        );
      case 'realEstateAppraisal':
        return (
          <RealEstateAppraisalInput
            name={currentField.name}
            label={currentField.label}
            value={formData[currentField.name] || ''}
            onChange={handleChange}
          />
        );
      case 'loanStructure':
        return (
          <LoanStructure
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'financialInfo':
        return (
          <FinancialInfo
            formData={formData}
            handleChange={handleChange}
          />
        );
      case 'BusinessSummary':
        return (
          <BusinessSummary
            name={currentField.name}
            label={currentField.label}
            value={formData[currentField.name] || ''}
            onChange={(value) => handleChange({ name: currentField.name, value })}
          />
        );
      case 'textarea':
        return (
          <>
            <label htmlFor={currentField.name} className="block mb-2 font-semibold text-gray-700">
              {currentField.label}
            </label>
            <textarea
              id={currentField.name}
              name={currentField.name}
              value={formData[currentField.name] || ''}
              onChange={handleChange}
              className="w-full px-4 py-2 text-gray-700 border border-blue-900 rounded-lg focus:outline-none focus:border-blue-500"
              required={currentField.required}
            ></textarea>
          </>
        );
      default:
        return null;
    }
  };

  const currentField = formFields[currentStep];

  return (
    <div className="gradient-background flex items-center justify-center">
      <button
        type="button"
        onClick={handleExit}
        className="absolute top-4 left-4 px-4 py-2 font-semibold text-white bg-red-500 rounded-lg hover:bg-red-700"
      >
        Exit
      </button>
      <div className="polygon polygon-1"></div>
      <div className="polygon polygon-2"></div>
      <div className="polygon polygon-3"></div>
      <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-lg relative z-10 form-container mx-4 sm:mx-auto">
        <h2 className="text-3xl font-bold mt-8 mb-8 text-center text-blue-900">Loan Application</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            {renderQuestion()}
          </div>
          <div className="flex justify-between mt-8">
            <button
              type="button"
              onClick={handleBack}
              className={`px-6 py-2 font-semibold text-white rounded-lg ${currentStep === 0 ? 'bg-gray-400' : 'bg-blue-900 hover:bg-blue-600'}`}
              disabled={currentStep === 0}
            >
              Back
            </button>
            {currentStep < formFields.length - 1 ? (
              <button
                type="button"
                onClick={handleNext}
                className="px-6 py-2 font-semibold text-white bg-blue-900 rounded-lg hover:bg-blue-600"
              >
                Next
              </button>
            ) : (
              <button
                type="submit"
                className="px-6 py-2 font-semibold text-white bg-green-600 rounded-lg hover:bg-green-700"
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoanForm;
