import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, ClockIcon, CurrencyDollarIcon } from '@heroicons/react/24/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const TermSheetViewer = ({ loanId }) => {
  const [termSheets, setTermSheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTermSheet, setSelectedTermSheet] = useState(null);
  const [bankDetails, setBankDetails] = useState({});

  const fetchBankDetails = async (bankId) => {
    try {
      const db = getFirestore();
      const bankDoc = await getDoc(doc(db, 'banks', bankId));
      if (bankDoc.exists()) {
        setBankDetails(prevDetails => ({
          ...prevDetails,
          [bankId]: bankDoc.data()
        }));
      }
    } catch (err) {
      console.error('Error fetching bank details:', err);
    }
  };

  const getLoansInIndustry = () => {
    // Placeholder function for loans in industry
    return 'N/A';
  };

  const getApprovalRateColor = (rate) => {
    const percentage = parseFloat(rate);
    if (isNaN(percentage)) return 'text-gray-400';
    if (percentage < 75) return 'text-red-500';
    if (percentage < 90) return 'text-yellow-500';
    return 'text-green-500';
  };

  const getProcessTimeColor = (time) => {
    const days = parseFloat(time);
    if (isNaN(days)) return 'text-gray-400';
    if (days <= 45) return 'text-green-500';
    if (days <= 75) return 'text-yellow-500';
    return 'text-red-500';
  };

  const getTotalAmountColor = (amount) => {
    const totalAmount = parseFloat(amount);
    if (isNaN(totalAmount)) return 'text-gray-400';
    if (totalAmount < 100000000) return 'text-red-500';
    if (totalAmount < 500000000) return 'text-yellow-500';
    return 'text-green-500';
  };

  const formatCurrency = (amount) => {
    const num = parseFloat(amount);
    if (isNaN(num)) return 'N/A';
    const millions = num / 1000000;
    return `$${millions.toFixed(1)}mm`;
  };

  const renderBankStats = () => {
    if (!selectedTermSheet) return null;

    const bankId = selectedTermSheet.bankId;
    const bank = bankDetails[bankId] || {};

    const approvalRate = bank.approvalPercentage || 'N/A';
    const approvalRateColor = getApprovalRateColor(approvalRate);

    const processTime = bank.processTime || 'N/A';
    const processTimeColor = getProcessTimeColor(processTime);

    const totalAmount = bank.totalAmount || 'N/A';
    const totalAmountColor = getTotalAmountColor(totalAmount);

    const stats = [
      { 
        name: 'Approval Rate', 
        stat: `${approvalRate}%`,
        icon: CheckCircleIcon,
        iconColor: approvalRateColor
      },
      { 
        name: 'Process Time', 
        stat: `${processTime} days`,
        icon: ClockIcon,
        iconColor: processTimeColor
      },
      { 
        name: 'Total Loan Amount', 
        stat: formatCurrency(totalAmount),
        subStat: '(4 years)',
        icon: CurrencyDollarIcon,
        iconColor: totalAmountColor
      },
    ];

    return (
      <dl className="grid grid-cols-1 gap-4 sm:grid-cols-3 mb-6">
        {stats.map((item) => (
          <div key={item.name} className="bg-white overflow-hidden shadow rounded-lg p-4 flex items-center">
            <item.icon className={`h-12 w-12 ${item.iconColor} mr-4`} />
            <div className="flex-grow text-right">
              <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
              <dd className="mt-1 text-2xl font-semibold text-gray-900">{item.stat}</dd>
              {item.subStat && (
                <dd className="mt-1 text-sm text-gray-500">{item.subStat}</dd>
              )}
            </div>
          </div>
        ))}
      </dl>
    );
  };

  useEffect(() => {
    const fetchTermSheets = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        const termSheetsQuery = query(collection(db, 'termSheets'), where('loanApplicationId', '==', loanId));
        const termSheetsSnapshot = await getDocs(termSheetsQuery);
        
        const sheets = termSheetsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setTermSheets(sheets);
        if (sheets.length > 0) {
          setSelectedTermSheet(sheets[0]);
        }

        const bankIds = [...new Set(sheets.map(sheet => sheet.bankId))];
        await Promise.all(bankIds.map(fetchBankDetails));
      } catch (err) {
        console.error('Error fetching term sheets:', err);
        setError('Failed to load term sheets. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (loanId) {
      fetchTermSheets();
    }
  }, [loanId]);

  const handleSetupMeeting = (bankId) => {
    // Implement the logic to set up a meeting here
    console.log(`Setting up meeting with bank ID: ${bankId}`);
    // You might want to navigate to a meeting scheduler page or open a modal
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Term Sheets</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Review and compare term sheets from different lenders.
          </p>
        </div>
        <a
          href="https://calendly.com/lended"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Setup Consultation
        </a>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-md">
            <p className="font-bold">Error</p>
            <p>{error}</p>
          </div>
        ) : termSheets.length === 0 ? (
          <div className="text-center py-10 bg-gray-50 rounded-xl">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <h3 className="mt-2 text-xl font-semibold text-gray-900">No term sheets</h3>
            <p className="mt-1 text-sm text-gray-500">No term sheets are available for this loan application.</p>
          </div>
        ) : (
          <div className="flex flex-col p-6">
            <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
              <div className="w-full lg:w-1/4 bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="px-4 py-5 sm:px-6 bg-white border-b border-gray-200">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">Available Term Sheets</h3>
                </div>
                <div className="border-t border-gray-200">
                  <div className="h-[32rem] overflow-y-auto">
                    {termSheets.map((sheet) => (
                      <div
                        key={sheet.id}
                        className={`p-4 cursor-pointer transition-all duration-200 ${
                          selectedTermSheet && selectedTermSheet.id === sheet.id
                            ? 'bg-blue-50 border-l-4 border-blue-500'
                            : 'hover:bg-gray-50'
                        }`}
                        onClick={() => setSelectedTermSheet(sheet)}
                      >
                        <div className="flex items-center justify-between">
                          <h4 className="text-lg font-semibold text-gray-900">{bankDetails[sheet.bankId]?.bankName || 'Bank Name'}</h4>
                          <span className="px-2 py-1 text-xs font-semibold text-blue-600 bg-blue-100 rounded-full">
                            {sheet.status || 'Pending'}
                          </span>
                        </div>
                        <div className="mt-2 flex justify-between items-center">
                          <div className="text-sm text-gray-500">
                            <p>Process Time: <span className="font-medium text-gray-700">{bankDetails[sheet.bankId]?.processTime || 'N/A'} days</span></p>
                            <p>Approval Rate: <span className="font-medium text-gray-700">{bankDetails[sheet.bankId]?.approvalPercentage || 'N/A'}%</span></p>
                          </div>
                          <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                          </svg>
                        </div>
                        <p className="text-xs text-gray-400 mt-2">Uploaded: {sheet.uploadedAt?.toDate().toLocaleString()}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-3/4 bg-white rounded-xl shadow-md overflow-hidden">
                {selectedTermSheet ? (
                  <div className="flex flex-col h-[48rem]">
                    <div className="p-6">
                      {renderBankStats()}
                    </div>
                    <div className="flex-grow border rounded-md overflow-hidden mx-6 mb-6">
                      <iframe
                        src={selectedTermSheet.fileUrl}
                        className="w-full h-full border-0"
                        title={`Preview of ${selectedTermSheet.fileName}`}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-[48rem] bg-gray-50">
                    <div className="text-center">
                      <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                      <h3 className="mt-2 text-lg font-medium text-gray-900">No term sheet selected</h3>
                      <p className="mt-1 text-sm text-gray-500">Select a term sheet to view details</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TermSheetViewer;