import React, { useEffect, useState, useCallback } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { PlusIcon, TrashIcon, PencilIcon, CheckIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';

const FinancialMetricsDisplayCustomer = ({ loanId, onFinancialDataCalculated }) => {
  const [financialData, setFinancialData] = useState({});
  const [loanData, setLoanData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addBacks, setAddBacks] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [mostRecentOcrDocId, setMostRecentOcrDocId] = useState(null);
  const [years, setYears] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!loanId) {
        setError("No loan ID provided");
        setLoading(false);
        return;
      }

      try {
        // Fetch loan application data
        const loanDocRef = doc(db, 'loanApplications', loanId);
        const loanDocSnap = await getDoc(loanDocRef);
        
        if (loanDocSnap.exists()) {
          setLoanData(loanDocSnap.data());
        } else {
          setError("Loan application not found");
          setLoading(false);
          return;
        }

        // Fetch OCR data
        const ocrMetadataRef = collection(db, 'OCRMetadata');
        const q = query(ocrMetadataRef, where('loanApplicationId', '==', loanId));
        const querySnapshot = await getDocs(q);
        
        const processedData = {};
        let mostRecentYear = '';
        let mostRecentDocId = '';
        let fetchedAddBacks = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.documentName === "Business Tax Returns") {
            const year = getYearFromLabel(data.label);
            processedData[year] = {
              ordinaryBusinessIncome: parseFloat(data.ocrResult?.ordinary_business_income) || 0,
              grossRevenue: parseFloat(data.ocrResult?.gross_receipts_or_sales) || 0,
              officerCompensation: parseFloat(data.ocrResult?.compensation_of_officers) || 0,
              interest: parseFloat(data.ocrResult?.interest) || 0,
              depreciation: parseFloat(data.ocrResult?.depreciation) || 0,
              amortization: parseFloat(data.ocrResult?.amortization) || 0,
              costOfGoodsSold: parseFloat(data.ocrResult?.cost_of_goods_sold) || 0,
              otherDeductions: data.ocrResult?.other_deductions || {},
              year: year,
            };
            
            if (year > mostRecentYear) {
              mostRecentYear = year;
              mostRecentDocId = doc.id;
              fetchedAddBacks = data.addBacks || [];
            }
          }
        });

        setFinancialData(processedData);
        setYears(Object.keys(processedData).sort((a, b) => a.localeCompare(b)));
        setMostRecentOcrDocId(mostRecentDocId);
        setAddBacks(fetchedAddBacks);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [loanId]);

  const calculateFinancialSummary = useCallback(() => {
    if (years.length > 0 && !loading) {
      return {
        dscr: years.map(year => ({
          year,
          value: calculateDSCR(calculateNOIWithAddBacks(financialData[year] || {}))
        })),
        sde: years.map(year => ({
          year,
          value: calculateSDEWithAddBacks(financialData[year] || {})
        })),
        noi: years.map(year => ({
          year,
          value: calculateNOIWithAddBacks(financialData[year] || {})
        })),
        addBacks: addBacks.map(addBack => ({
          name: addBack.name,
          values: years.map(year => ({
            year,
            value: addBack.values[year] || 0
          }))
        }))
      };
    }
    return null;
  }, [years, financialData, addBacks, loading]);

  const debouncedUpdateFinancialData = useCallback(
    debounce((data) => {
      if (data) onFinancialDataCalculated(data);
    }, 1000),
    [onFinancialDataCalculated]
  );

  useEffect(() => {
    const summary = calculateFinancialSummary();
    if (summary) debouncedUpdateFinancialData(summary);
  }, [calculateFinancialSummary, debouncedUpdateFinancialData]);

  const getYearFromLabel = (label) => {
    const yearLabels = {
      'Business Tax Return - Current Year': '2023',
      'Business Tax Return - Last Year': '2022',
      'Business Tax Return - 2 Years Ago': '2021'
    };
    return yearLabels[label] || label;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  };

  const calculateSDE = (yearData) => {
    if (!yearData) return 0;

    const baseIncome = yearData.ordinaryBusinessIncome || 0;
    const originalOfficerCompensation = yearData.officerCompensation || 0;
    const interest = yearData.interest || 0;
    const depreciation = yearData.depreciation || 0;
    const amortization = yearData.amortization || 0;
    const addbacks = yearData.otherDeductions 
      ? Object.values(yearData.otherDeductions).reduce((sum, amount) => sum + (parseFloat(amount) || 0), 0)
      : 0;
    
    return baseIncome + originalOfficerCompensation + interest + depreciation + amortization + addbacks;
  };

  const calculateTotalAddBacks = (year) => {
    return addBacks.reduce((total, addBack) => total + (addBack.values[year] || 0), 0);
  };

  const calculateSDEWithAddBacks = (yearData) => {
    const baseSDEValue = calculateSDE(yearData);
    const yearAddBack = calculateTotalAddBacks(yearData.year);
    return baseSDEValue + yearAddBack;
  };

  const calculateNOIWithAddBacks = (yearData) => {
    const sdeWithAddBacks = calculateSDEWithAddBacks(yearData);
    const newOfficerCompensation = calculateNewOfficerCompensation();
    return sdeWithAddBacks - newOfficerCompensation;
  };

  const calculateNewOfficerCompensation = () => {
    const numberOfGuarantors = loanData?.personalGuarantors?.length || 0;
    return 80000 * numberOfGuarantors;
  };

  const calculateDSCR = (noi) => {
    const annualDebtService = calculateAnnualDebtService();
    return annualDebtService > 0 ? noi / annualDebtService : 0;
  };

  const calculateAnnualDebtService = () => {
    const loanAmount = calculateLoanAmount();
    if (loanAmount <= 0 || interestRate <= 0 || loanTerm <= 0) {
      console.error('Invalid loan parameters');
      return 0;
    }

    const monthlyRate = interestRate / 12 / 100;
    const numberOfPayments = loanTerm * 12;
    const monthlyPayment = (loanAmount * monthlyRate * Math.pow(1 + monthlyRate, numberOfPayments)) / (Math.pow(1 + monthlyRate, numberOfPayments) - 1);
    const annualDebtService = monthlyPayment * 12;

    return annualDebtService;
  };

  const calculateLoanAmount = () => {
    if (!loanData) return 0;
    const totalProjectCost = calculateTotalProjectCost();
    const loanPercentage = loanData.loanStructure?.loanPercentage || 90;
    return totalProjectCost * (loanPercentage / 100);
  };

  const calculateTotalProjectCost = () => {
    if (!loanData) return 0;
    const loanSize = parseFloat(loanData.loanSize) || 0;
    const workingCapital = parseFloat(loanData.loanStructure?.workingCapital) || 0;
    const guaranteeFee = parseFloat(loanData.loanStructure?.guaranteeFee) || 0;
    const closingCost = parseFloat(loanData.loanStructure?.closingCost) || 0;
    const packagingCost = parseFloat(loanData.loanStructure?.packagingCost) || 0;
    return loanSize + workingCapital + guaranteeFee + closingCost + packagingCost;
  };

  // You'll need to define these variables or fetch them from your loan data
  const interestRate = loanData?.interestRate || 10.5; // Default to 10.5% if not provided
  const loanTerm = loanData?.loanTerm || 10; // Default to 10 years if not provided

  const getFinancialData = (key) => {
    return years.map(year => {
      const yearData = financialData[year] || {};
      let value;
      switch (key) {
        case 'dscr':
          value = calculateDSCR(calculateNOIWithAddBacks(yearData));
          break;
        case 'noi':
          value = calculateNOIWithAddBacks(yearData);
          break;
        case 'ordinaryBusinessIncome':
          value = yearData.ordinaryBusinessIncome || 0;
          break;
        default:
          value = 0;
      }
      return { name: year, value };
    });
  };

  const financialStats = [
    { 
      name: 'DSCR', 
      stat: formatNumber(calculateDSCR(calculateNOIWithAddBacks(financialData[years[years.length - 1]] || {}))),
      previousStat: formatNumber(calculateDSCR(calculateNOIWithAddBacks(financialData[years[years.length - 2]] || {}))),
      change: ((calculateDSCR(calculateNOIWithAddBacks(financialData[years[years.length - 1]] || {})) / calculateDSCR(calculateNOIWithAddBacks(financialData[years[years.length - 2]] || {})) - 1) * 100).toFixed(2) + '%',
      changeType: calculateDSCR(calculateNOIWithAddBacks(financialData[years[years.length - 1]] || {})) > calculateDSCR(calculateNOIWithAddBacks(financialData[years[years.length - 2]] || {})) ? 'increase' : 'decrease',
      data: getFinancialData('dscr'),
      color: '#008081'
    },
    { 
      name: 'Net Operating Income', 
      stat: formatCurrency(calculateNOIWithAddBacks(financialData[years[years.length - 1]] || {})),
      previousStat: formatCurrency(calculateNOIWithAddBacks(financialData[years[years.length - 2]] || {})),
      change: ((calculateNOIWithAddBacks(financialData[years[years.length - 1]] || {}) / calculateNOIWithAddBacks(financialData[years[years.length - 2]] || {}) - 1) * 100).toFixed(2) + '%',
      changeType: calculateNOIWithAddBacks(financialData[years[years.length - 1]] || {}) > calculateNOIWithAddBacks(financialData[years[years.length - 2]] || {}) ? 'increase' : 'decrease',
      data: getFinancialData('noi'),
      color: '#6593F5'
    },
    { 
      name: 'Ordinary Business Income', 
      stat: formatCurrency(financialData[years[years.length - 1]]?.ordinaryBusinessIncome || 0),
      previousStat: formatCurrency(financialData[years[years.length - 2]]?.ordinaryBusinessIncome || 0),
      change: ((financialData[years[years.length - 1]]?.ordinaryBusinessIncome / financialData[years[years.length - 2]]?.ordinaryBusinessIncome - 1) * 100).toFixed(2) + '%',
      changeType: financialData[years[years.length - 1]]?.ordinaryBusinessIncome > financialData[years[years.length - 2]]?.ordinaryBusinessIncome ? 'increase' : 'decrease',
      data: getFinancialData('ordinaryBusinessIncome'),
      color: '#3FE0D0'
    }
  ];

  const FinancialStatCard = ({ item }) => (
    <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
      <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
      <dd className="mt-1 flex flex-col">
        <div className="flex items-baseline justify-between">
          <div className="flex items-baseline text-2xl font-semibold text-blue-600">
            {item.stat}
            <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
          </div>
        </div>
        <div className="mt-4 h-36">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={item.data}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill={item.color} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </dd>
    </div>
  );

  const saveAddBacks = useCallback(debounce(async (addBacksToSave) => {
    if (!mostRecentOcrDocId) {
      console.error('No OCR document ID found');
      return;
    }

    try {
      const docRef = doc(db, 'OCRMetadata', mostRecentOcrDocId);
      await updateDoc(docRef, {
        addBacks: addBacksToSave
      });
      console.log('Add-backs saved successfully');
      setEditingIndex(null);  // Exit edit mode after saving
    } catch (error) {
      console.error('Error saving add-backs:', error);
    }
  }, 1000), [mostRecentOcrDocId]);

  const handleAddNewAddBack = () => {
    const newAddBacks = [...addBacks, { name: '', values: {} }];
    setAddBacks(newAddBacks);
    setEditingIndex(newAddBacks.length - 1);  // Set new add-back to edit mode
  };

  const handleRemoveAddBack = (index) => {
    const newAddBacks = addBacks.filter((_, i) => i !== index);
    setAddBacks(newAddBacks);
    saveAddBacks(newAddBacks);
  };

  const handleAddBackChange = (index, field, value, year) => {
    const newAddBacks = [...addBacks];
    if (field === 'name') {
      newAddBacks[index].name = value;
    } else {
      newAddBacks[index].values[year] = parseFloat(value) || 0;
    }
    setAddBacks(newAddBacks);
    saveAddBacks(newAddBacks);
  };

  const handleSaveAddBack = (index) => {
    setEditingIndex(null);
    saveAddBacks(addBacks);
  };

  const handleEditAddBack = (index) => {
    setEditingIndex(index);
  };

  const calculateEBITDAOwnersComp = (yearData) => {
    const baseIncome = Number(yearData.ordinaryBusinessIncome || 0);
    const originalOfficerCompensation = Number(yearData.officerCompensation || 0);
    const interest = Number(yearData.interest || 0);
    const depreciation = Number(yearData.depreciation || 0);
    const amortization = Number(yearData.amortization || 0);
    
    return baseIncome + originalOfficerCompensation + interest + depreciation + amortization;
  };

  if (loading) {
    return <div>Loading financial data...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (Object.keys(financialData).length === 0) {
    return <div>No financial data available.</div>;
  }

  return (
    <div className="space-y-8">
      <div>
        <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {financialStats.map((item) => (
            <FinancialStatCard key={item.name} item={item} />
          ))}
        </dl>
      </div>

      <h3 className="text-xl font-bold mt-8 mb-4">Financial Analysis</h3>
      {years.length > 0 ? (
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 border-b text-left">Metric</th>
              {years.map(year => (
                <th key={year} className="px-4 py-2 border-b text-right">{year}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[
              { key: 'grossRevenue', label: 'Gross Receipts or Sales' },
              { key: 'costOfGoodsSold', label: '- Cost of Goods Sold', isRed: true },
              { key: 'ordinaryBusinessIncome', label: 'Ordinary Business Income', isBold: true },
              { key: 'officerCompensation', label: '+ Original Officer Compensation' },
              { key: 'interest', label: '+ Interest' },
              { key: 'depreciation', label: '+ Depreciation' },
              { key: 'amortization', label: '+ Amortization' },
              { key: 'ebitdaOwnersComp', label: 'EBITDA + Owners Comp', calculate: calculateEBITDAOwnersComp, isBold: true, hasBorder: true },
              { key: 'ebitdaOwnersCompPercentage', label: 'EBITDA + Owners Comp %', calculate: (yearData) => (calculateEBITDAOwnersComp(yearData) / Number(yearData.grossRevenue || 1)) * 100, isPercentage: true },
            ].map(({ key, label, calculate, isBold, hasBorder, isRed, isPercentage }) => (
              <tr key={key} className={hasBorder ? 'border-t-2 border-gray-300' : ''}>
                <td className={`px-4 py-2 border-b ${isBold ? 'font-bold' : ''} ${isRed ? 'text-red-600' : ''}`}>{label}</td>
                {years.map(year => (
                  <td key={year} className={`px-4 py-2 border-b text-right ${isBold ? 'font-bold' : ''} ${isRed ? 'text-red-600' : ''}`}>
                    {calculate 
                      ? (isPercentage 
                          ? `${formatNumber(calculate(financialData[year]))}%`
                          : formatCurrency(calculate(financialData[year])))
                      : (isPercentage
                          ? `${formatNumber(financialData[year]?.[key] || 0)}%`
                          : formatCurrency(financialData[year]?.[key] || 0))}
                  </td>
                ))}
              </tr>
            ))}
            <tr className="bg-gray-100">
              <td colSpan={years.length + 1} className="px-4 py-2 border-b font-bold">Add Backs</td>
            </tr>
            {addBacks.length > 0 ? (
              addBacks.map((addBack, index) => (
                <tr key={`addback-${index}`}>
                  <td className="px-4 py-2 border-b text-green-600">+ {addBack.name}</td>
                  {years.map(year => (
                    <td key={year} className="px-4 py-2 border-b text-right text-green-600">
                      {formatCurrency(addBack.values[year] || 0)}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={years.length + 1} className="px-4 py-2 border-b text-center text-gray-500 italic">
                  No add backs available. Add backs will appear here when added.
                </td>
              </tr>
            )}
            <tr className="bg-gray-100">
              <td className="px-4 py-2 border-b font-bold">Seller's Discretionary Earnings (SDE)</td>
              {years.map(year => (
                <td key={year} className="px-4 py-2 border-b text-right font-bold">
                  {formatCurrency(calculateSDEWithAddBacks(financialData[year] || {}))}
                </td>
              ))}
            </tr>
            <tr>
              <td className="px-4 py-2 border-b text-red-600">- New Officer Compensation</td>
              {years.map(year => (
                <td key={year} className="px-4 py-2 border-b text-right text-red-600">
                  {formatCurrency(calculateNewOfficerCompensation())}
                </td>
              ))}
            </tr>
            <tr className="bg-gray-100">
              <td className="px-4 py-2 border-b font-bold">Net Operating Income (NOI)</td>
              {years.map(year => (
                <td key={year} className="px-4 py-2 border-b text-right font-bold">
                  {formatCurrency(calculateNOIWithAddBacks(financialData[year] || {}))}
                </td>
              ))}
            </tr>
            <tr>
              <td className="px-4 py-2 border-b">Debt Service Coverage Ratio (DSCR)</td>
              {years.map(year => (
                <td key={year} className="px-4 py-2 border-b text-right">
                  {formatNumber(calculateDSCR(calculateNOIWithAddBacks(financialData[year] || {})))}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      ) : (
        <p>No financial data available.</p>
      )}

      {years.length < 3 && (
        <p className="text-yellow-600">Please upload all three years of financial data for a complete analysis.</p>
      )}

      {/* Add-backs editing section */}
      <div className="mt-8 bg-white shadow rounded-lg overflow-hidden">
        <div className="px-4 py-5 sm:px-6 bg-gray-50 border-b border-gray-200">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Add-backs</h3>
          <p className="mt-1 text-sm text-gray-500">Financial add-backs for each year.</p>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <div className="overflow-x-auto">
            {addBacks.length > 0 ? (
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                    {years.map(year => (
                      <th key={year} className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{year}</th>
                    ))}
                    <th className="px-3 py-2 w-20"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {addBacks.map((addBack, index) => (
                    <tr key={index}>
                      <td className="px-3 py-2 whitespace-nowrap">
                        {editingIndex === index ? (
                          <input
                            type="text"
                            value={addBack.name}
                            onChange={(e) => handleAddBackChange(index, 'name', e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Add-back name"
                          />
                        ) : (
                          <span className="text-sm text-gray-900">{addBack.name}</span>
                        )}
                      </td>
                      {years.map(year => (
                        <td key={year} className="px-3 py-2 whitespace-nowrap">
                          {editingIndex === index ? (
                            <input
                              type="number"
                              value={addBack.values[year] || ''}
                              onChange={(e) => handleAddBackChange(index, 'value', e.target.value, year)}
                              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              placeholder="0"
                            />
                          ) : (
                            <span className="text-sm text-gray-900">{formatCurrency(addBack.values[year] || 0)}</span>
                          )}
                        </td>
                      ))}
                      <td className="px-3 py-2 whitespace-nowrap w-20">
                        <div className="flex items-center justify-end space-x-2">
                          {editingIndex === index ? (
                            <button
                              onClick={() => handleSaveAddBack(index)}
                              className="text-green-600 hover:text-green-800 rounded-full hover:bg-green-100 transition-colors duration-200 p-1"
                            >
                              <CheckIcon className="h-5 w-5" />
                            </button>
                          ) : (
                            <button
                              onClick={() => handleEditAddBack(index)}
                              className="text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-100 transition-colors duration-200 p-1"
                            >
                              <PencilIcon className="h-5 w-5" />
                            </button>
                          )}
                          <button
                            onClick={() => handleRemoveAddBack(index)}
                            className="text-red-600 hover:text-red-800 rounded-full hover:bg-red-100 transition-colors duration-200 p-1"
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className="text-gray-500 text-center py-4">No add-backs yet. Click the button below to add one.</p>
            )}
          </div>
          <button
            onClick={handleAddNewAddBack}
            className="mt-6 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Add new add-back
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinancialMetricsDisplayCustomer;


