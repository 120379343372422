import React, { useState, useEffect } from 'react';
import { getFirestore, addDoc, collection, query, where, getDocs, deleteDoc, doc, updateDoc, arrayUnion, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage, db } from '../../firebase';
import { getAuth, getIdToken } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { PlusIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { LoanStatuses, StatusNextSteps } from '../constants/statuses';

const NewWorkingCapitalForm = ({ initialData, onSave, onCancel, onUpdate }) => {
  const [formData, setFormData] = useState(initialData || {});
  const [creditScores, setCreditScores] = useState(initialData?.creditScores || []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [processingStatus, setProcessingStatus] = useState({});
  const [personalGuarantors, setPersonalGuarantors] = useState(initialData?.personalGuarantors || []);
  const [isAddOwnerModalOpen, setIsAddOwnerModalOpen] = useState(false);
  const [newOwnerFirstName, setNewOwnerFirstName] = useState('');
  const [newOwnerLastName, setNewOwnerLastName] = useState('');
  const [newOwnerCreditScore, setNewOwnerCreditScore] = useState('');
  const [newOwnerEmail, setNewOwnerEmail] = useState('');
  const [owners, setOwners] = useState(initialData?.owners || []);
  const auth = getAuth();

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
      setPersonalGuarantors(initialData.personalGuarantors || []);
      setCreditScores(initialData.creditScores || []);
      setOwners(initialData.owners || []);
    }
  }, [initialData]);

  useEffect(() => {
    const fetchUploadedDocuments = async () => {
      if (formData.id) {
        const uploadedDocsQuery = query(
          collection(db, 'documentMetadata'),
          where('loanApplicationId', '==', formData.id)
        );
        const uploadedDocsSnapshot = await getDocs(uploadedDocsQuery);
        const uploadedDocsData = {};
        uploadedDocsSnapshot.forEach((doc) => {
          const data = doc.data();
          const key = `${data.documentName}-${data.label}${data.guarantorId ? `-${data.guarantorId}` : ''}`;
          uploadedDocsData[key] = {
            url: data.fileUrl,
            name: data.fileName,
            uploadedAt: data.uploadedAt,
            metadataId: doc.id
          };
        });
        setUploadedFiles(uploadedDocsData);
      }
    };
    fetchUploadedDocuments();
  }, [formData.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const personalDocuments = [
    {
      name: 'Personal Financial Statement',
      description: 'Upload your Personal Financial Statement.',
      required: true,
      labels: ['Personal Financial Statement']
    },
    {
      name: 'Personal Tax Returns',
      description: "Upload the current year's personal tax return.",
      required: true,
      labels: ['Personal Tax Return - Current Year']
    }
  ];

  const businessDocuments = [
    {
      name: 'Business Tax Returns',
      description: 'Upload the last 3 years of business tax returns.',
      required: true,
      labels: [
        'Business Tax Return - Current Year',
        'Business Tax Return - Last Year',
        'Business Tax Return - 2 Years Ago'
      ]
    },
    {
      name: 'Interim P&L',
      description: 'Upload the interim Profit and Loss statement (PDF or Excel).',
      required: true,
      labels: ['Interim P&L'],
      acceptedFileTypes: '.pdf,.xlsx,.xls'
    },
    {
      name: 'Bank Statements',
      description: 'Upload last 3 months of bank statements.',
      required: true,
      labels: [
        'Bank Statement - Most Recent Month',
        'Bank Statement - Previous Month',
        'Bank Statement - 2 Months Ago'
      ],
      acceptedFileTypes: '.pdf,.xlsx,.xls'
    },
    {
      name: 'Debt Schedule',
      description: "Upload your company's debt schedule.",
      required: true,
      labels: ['Debt Schedule'],
      acceptedFileTypes: '.pdf,.xlsx,.xls'
    }
  ];

  const handleFileUpload = async (event, documentName, documentLabel, guarantorId = null) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Attempting file upload...');
      console.log('Loan Application ID:', formData.id);
      console.log('Broker ID:', formData.userId);

      if (!formData.id || !formData.userId) {
        console.error('Missing loan application ID or user ID');
        alert('Unable to upload file. Please ensure the form has been saved.');
        return;
      }

      const fileKey = `${documentName}-${documentLabel}${guarantorId ? `-${guarantorId}` : ''}`;
      setProcessingStatus(prevStatus => ({
        ...prevStatus,
        [fileKey]: 'processing'
      }));

      try {
        const storage = getStorage();
        const storageRef = ref(storage, `documents/${formData.userId}/${formData.id}/${documentName}/${documentLabel}${guarantorId ? `/${guarantorId}` : ''}/${file.name}`);
        
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        const fileMetadata = {
          documentName,
          label: documentLabel,
          fileName: file.name,
          fileUrl: downloadURL,
          uploadedAt: new Date().toISOString(),
          loanApplicationId: formData.id,
          userId: formData.userId,
          businessName: formData.businessName,
          uploadedBy: 'broker',
          guarantorId: guarantorId,
        };

        const metadataRef = collection(db, 'documentMetadata');
        const docRef = await addDoc(metadataRef, fileMetadata);
        fileMetadata.documentId = docRef.id;

        // Process the document
        const auth = getAuth();
        const idToken = await getIdToken(auth.currentUser);

        const requestBody = {
          fileUrl: downloadURL,
          metadata: fileMetadata,
        };

        const response = await fetch('https://sba-30d9855b23ef.herokuapp.com/process-document', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
          throw new Error('Failed to process document');
        }

        const processingResult = await response.json();
        console.log('Document processing result:', processingResult);

        // Update the application document with the new uploaded file
        const applicationRef = doc(db, 'loanApplications', formData.id);
        await updateDoc(applicationRef, {
          [`uploadedDocuments.${documentName}`]: true,
          lastUpdated: new Date().toISOString()
        });

        setUploadedFiles(prevFiles => ({
          ...prevFiles,
          [fileKey]: { name: file.name, url: downloadURL }
        }));

        setFormData(prevData => ({
          ...prevData,
          uploadedDocuments: {
            ...prevData.uploadedDocuments,
            [documentName]: true
          }
        }));

        setProcessingStatus(prevStatus => ({
          ...prevStatus,
          [fileKey]: 'complete'
        }));

        console.log('File uploaded successfully');
      } catch (error) {
        console.error('Error uploading or processing file:', error);
        setProcessingStatus(prevStatus => ({
          ...prevStatus,
          [fileKey]: 'error'
        }));
        alert('Error uploading or processing file. Please try again.');
      }
    }
  };

  const getUploadedFileName = (documentName, label, guarantorId = null) => {
    const key = `${documentName}-${label}${guarantorId ? `-${guarantorId}` : ''}`;
    return uploadedFiles[key]?.name || null;
  };

  const handleAddOwner = async () => {
    if (newOwnerFirstName && newOwnerLastName && newOwnerCreditScore && newOwnerEmail) {
      const newOwner = {
        firstName: newOwnerFirstName,
        lastName: newOwnerLastName,
        email: newOwnerEmail,
        creditScore: newOwnerCreditScore,
      };

      const updatedPersonalGuarantors = [...personalGuarantors, { firstName: newOwnerFirstName, lastName: newOwnerLastName }];
      const updatedCreditScores = [...creditScores, newOwnerCreditScore];
      const updatedOwners = [...owners, newOwner];

      const updatedFormData = {
        ...formData,
        personalGuarantors: updatedPersonalGuarantors,
        creditScores: updatedCreditScores,
        owners: updatedOwners,
      };

      try {
        // Update the loan application in Firestore
        const loanApplicationRef = doc(db, 'loanApplications', formData.id);
        await updateDoc(loanApplicationRef, {
          personalGuarantors: updatedPersonalGuarantors,
          creditScores: updatedCreditScores,
          owners: updatedOwners,
          lastUpdated: new Date().toISOString(),
        });

        // Update local state
        setPersonalGuarantors(updatedPersonalGuarantors);
        setCreditScores(updatedCreditScores);
        setOwners(updatedOwners);
        setFormData(updatedFormData);

        // Inform the parent component of the changes
        onUpdate(updatedFormData);

        // Reset the modal fields
        setNewOwnerFirstName('');
        setNewOwnerLastName('');
        setNewOwnerCreditScore('');
        setNewOwnerEmail('');
        setIsAddOwnerModalOpen(false);

        console.log('New owner added and loan application updated:', newOwner);
        console.log('Updated form data:', updatedFormData);
      } catch (error) {
        console.error('Error updating loan application:', error);
        alert('Failed to add new owner. Please try again.');
      }
    } else {
      alert('Please fill in all fields for the new owner.');
    }
  };

  const renderPersonalDocuments = () => (
    <div className="space-y-8 bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-gray-900 border-b pb-2">
        Personal Documents - Add all owners over 20%
      </h3>
      {owners.map((owner, index) => (
        <div key={index} className="space-y-4 mt-6">
          <h4 className="text-lg font-medium text-gray-800">
            {owner.firstName} {owner.lastName} ({owner.email})
          </h4>
          {personalDocuments.map((document) => (
            <div key={document.name} className="space-y-4">
              <div>
                <h5 className="text-md font-medium text-gray-700">{document.name}</h5>
                <p className="text-sm text-gray-600 mt-1">{document.description}</p>
              </div>
              {document.labels.map((label) => (
                <div key={label} className="bg-gray-50 p-4 rounded-md">
                  <label
                    htmlFor={`${owner.firstName}-${owner.lastName}-${label}`}
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    {label}
                  </label>
                  <div className="relative">
                    <input
                      id={`${owner.firstName}-${owner.lastName}-${label}`}
                      name={`${owner.firstName}-${owner.lastName}-${label}`}
                      type="file"
                      accept="application/pdf"
                      onChange={(e) =>
                        handleFileUpload(e, document.name, label, `${owner.firstName} ${owner.lastName}`)
                      }
                      className="block w-full text-sm text-gray-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-blue-50 file:text-blue-700
                            hover:file:bg-blue-100
                            focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    />
                    {getUploadedFileName(
                      document.name,
                      label,
                      `${owner.firstName} ${owner.lastName}`
                    ) && (
                      <div className="mt-2 flex items-center text-sm text-green-600">
                        <CheckCircleIcon className="mr-2 h-5 w-5" />
                        {getUploadedFileName(document.name, label, `${owner.firstName} ${owner.lastName}`)}
                      </div>
                    )}
                    {processingStatus[
                      `${document.name}-${label}-${owner.firstName} ${owner.lastName}`
                    ] === 'processing' && (
                      <div className="mt-2 flex items-center text-sm text-blue-600">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Uploading...
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
      <div className="mt-4">
        <button
          type="button"
          onClick={() => setIsAddOwnerModalOpen(true)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add Owner
        </button>
      </div>
    </div>
  );

  const renderBusinessDocuments = () => (
    <div className="space-y-8 bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-gray-900 border-b pb-2">Required Business Documents</h3>
      {businessDocuments.map((document) => (
        <div key={document.name} className="space-y-4">
          <div>
            <h4 className="text-lg font-medium text-gray-800">{document.name}</h4>
            <p className="text-sm text-gray-600 mt-1">{document.description}</p>
          </div>
          {document.labels.map((label) => (
            <div key={label} className="bg-gray-50 p-4 rounded-md">
              <label htmlFor={label} className="block text-sm font-medium text-gray-700 mb-2">
                {label}
              </label>
              <div className="relative">
                <input
                  id={label}
                  name={label}
                  type="file"
                  accept={document.acceptedFileTypes || "application/pdf"}
                  onChange={(e) => handleFileUpload(e, document.name, label)}
                  className="block w-full text-sm text-gray-500
                    file:mr-4 file:py-2 file:px-4
                    file:rounded-full file:border-0
                    file:text-sm file:font-semibold
                    file:bg-blue-50 file:text-blue-700
                    hover:file:bg-blue-100
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                />
                {getUploadedFileName(document.name, label) && (
                  <div className="mt-2 flex items-center text-sm text-green-600">
                    <CheckCircleIcon className="mr-2 h-5 w-5" />
                    {getUploadedFileName(document.name, label)}
                  </div>
                )}
                {processingStatus[`${document.name}-${label}`] === 'processing' && (
                  <div className="mt-2 flex items-center text-sm text-blue-600">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Uploading...
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    console.log('Submitting form...');

    try {
      // Ensure there's always at least one personal guarantor (the main applicant)
      let updatedPersonalGuarantors = formData.personalGuarantors || [];
      let updatedCreditScores = formData.creditScores || [];

      if (updatedPersonalGuarantors.length === 0) {
        const mainGuarantor = {
          firstName: formData.firstName,
          lastName: formData.lastName,
        };
        updatedPersonalGuarantors = [mainGuarantor];
      }

      if (updatedCreditScores.length === 0 && formData.creditScore) {
        updatedCreditScores = [formData.creditScore];
      }

      const updatedFormData = {
        ...formData,
        personalGuarantors: updatedPersonalGuarantors,
        creditScores: updatedCreditScores,
        lastUpdated: new Date().toISOString(),
        lastUserUpdate: new Date().toISOString(),
        loanStatus: LoanStatuses.SUBMITTED,
      };

      console.log('Saving updatedFormData:', updatedFormData);
      
      await onSave(updatedFormData);
      
      console.log('Form submitted successfully');
      alert('Form saved successfully. Next step: ' + StatusNextSteps[LoanStatuses.SUBMITTED]);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error saving form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="space-y-8">
        <form onSubmit={handleSubmit} className="space-y-8">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={formData.firstName || ''}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={formData.lastName || ''}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="businessName" className="block text-sm font-medium text-gray-700">Business Name</label>
              <input
                type="text"
                name="businessName"
                id="businessName"
                value={formData.businessName || ''}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="loanSize" className="block text-sm font-medium text-gray-700">Loan Amount</label>
              <input
                type="number"
                name="loanSize"
                id="loanSize"
                value={formData.loanSize || ''}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label htmlFor="numberOfEmployees" className="block text-sm font-medium text-gray-700">Number of Employees</label>
              <input
                type="number"
                name="numberOfEmployees"
                id="numberOfEmployees"
                value={formData.numberOfEmployees || ''}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                required
              />
            </div>
          </div>
          <div className="col-span-full">
            <label htmlFor="businessSummary" className="block text-sm font-medium leading-6 text-gray-900">
              Business Summary
            </label>
            <div className="mt-2">
              <textarea
                id="businessSummary"
                name="businessSummary"
                rows={3}
                value={formData.businessSummary || ''}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about your business.</p>
          </div>
          <div className="col-span-full">
            <label htmlFor="workingCapitalUse" className="block text-sm font-medium leading-6 text-gray-900">
              Working Capital Use
            </label>
            <div className="mt-2">
              <textarea
                id="workingCapitalUse"
                name="workingCapitalUse"
                rows={3}
                value={formData.workingCapitalUse || ''}
                onChange={handleChange}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <p className="mt-3 text-sm leading-6 text-gray-600">Describe how you plan to use the working capital.</p>
          </div>
          {renderPersonalDocuments()}
          {renderBusinessDocuments()}
          <div className="flex justify-end space-x-4 mt-6">
            <button
              type="button"
              onClick={onCancel}
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Saving...' : 'Submit Application'}
            </button>
          </div>
          {isAddOwnerModalOpen && (
            <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <PlusIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                          Add Owner
                        </h3>
                        <div className="mt-4 space-y-4">
                          <div>
                            <label htmlFor="newOwnerFirstName" className="block text-sm font-medium text-gray-700">
                              First Name
                            </label>
                            <input
                              type="text"
                              id="newOwnerFirstName"
                              value={newOwnerFirstName}
                              onChange={(e) => setNewOwnerFirstName(e.target.value)}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Enter first name"
                            />
                          </div>
                          <div>
                            <label htmlFor="newOwnerLastName" className="block text-sm font-medium text-gray-700">
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="newOwnerLastName"
                              value={newOwnerLastName}
                              onChange={(e) => setNewOwnerLastName(e.target.value)}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Enter last name"
                            />
                          </div>
                          <div>
                            <label htmlFor="newOwnerEmail" className="block text-sm font-medium text-gray-700">
                              Email
                            </label>
                            <input
                              type="email"
                              id="newOwnerEmail"
                              value={newOwnerEmail}
                              onChange={(e) => setNewOwnerEmail(e.target.value)}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Enter email"
                            />
                          </div>
                          <div>
                            <label htmlFor="newOwnerCreditScore" className="block text-sm font-medium text-gray-700">
                              Credit Score
                            </label>
                            <input
                              type="number"
                              id="newOwnerCreditScore"
                              value={newOwnerCreditScore}
                              onChange={(e) => setNewOwnerCreditScore(e.target.value)}
                              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                              placeholder="Enter credit score"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      onClick={handleAddOwner}
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Add
                    </button>
                    <button
                      type="button"
                      onClick={() => setIsAddOwnerModalOpen(false)}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default NewWorkingCapitalForm;
