import React, { useState } from 'react';
import { Button } from './button';
import { Header } from './header';
import { db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const generateTimeSlots = () => {
  const slots = [];
  const currentDate = new Date();
  
  // Find next Wednesday and Friday
  const wednesday = new Date(currentDate);
  wednesday.setDate(currentDate.getDate() + ((3 + 7 - currentDate.getDay()) % 7));
  
  const friday = new Date(currentDate);
  friday.setDate(currentDate.getDate() + ((5 + 7 - currentDate.getDay()) % 7));

  // Wednesday slots
  const wednesdaySlots = [
    { time: '3:00 PM', available: true },
    { time: '4:00 PM', available: true },
  ];

  // Friday slots
  const fridaySlots = [
    { time: '2:00 PM', available: true },
    { time: '2:30 PM', available: true },
    { time: '3:00 PM', available: true },
    { time: '3:30 PM', available: true },
  ];

  return { wednesdaySlots, fridaySlots };
};

export function EligibilityMeeting() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    selectedTime: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [bookingSuccess, setBookingSuccess] = useState(false);

  const { wednesdaySlots, fridaySlots } = generateTimeSlots();
  const currentDate = new Date();
  const wednesday = new Date(currentDate);
  wednesday.setDate(currentDate.getDate() + ((3 + 7 - currentDate.getDay()) % 7));
  const friday = new Date(currentDate);
  friday.setDate(currentDate.getDate() + ((5 + 7 - currentDate.getDay()) % 7));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      await addDoc(collection(db, 'AdMeeting'), {
        ...formData,
        status: 'scheduled',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      setBookingSuccess(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        selectedTime: '',
      });

    } catch (error) {
      console.error('Error saving meeting:', error);
      setSubmitError('There was an error scheduling your meeting. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  if (bookingSuccess) {
    return (
      <>
        <Header />
        <div className="min-h-screen bg-gray-50 py-12">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-3xl">
              <div className="bg-white rounded-2xl shadow-xl p-8 text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                  <svg
                    className="h-6 w-6 text-green-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">
                  Meeting Successfully Scheduled!
                </h2>
                <div className="bg-green-50 rounded-xl p-6 mb-6">
                  <p className="text-green-800 text-lg mb-4">
                    Your consultation has been confirmed for:
                  </p>
                  <p className="text-green-900 font-semibold text-xl">
                    {formData.selectedTime}
                  </p>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-600">
                    We've sent a confirmation email with meeting details and calendar invite.
                  </p>
                  <p className="text-gray-600">
                    Please check your email and add the meeting to your calendar.
                  </p>
                </div>
                <div className="mt-8 space-y-4">
                  <Button
                    onClick={() => setBookingSuccess(false)}
                    color="mainBlue"
                    className="w-full justify-center py-3"
                  >
                    Book Another Meeting
                  </Button>
                  <Button
                    href="/"
                    color="white"
                    className="w-full justify-center py-3"
                  >
                    Return to Homepage
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-50 py-12">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-3xl">
            <div className="bg-white rounded-2xl shadow-xl p-8">
              <div className="text-center mb-8">
                <div className="inline-block bg-yellow-100 text-yellow-800 px-4 py-2 rounded-full text-sm font-semibold mb-4">
                  Limited Availability: Only 6 Spots Left This Week
                </div>
                <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Book Your Free SBA Evaluation
                </h1>
                <p className="mt-4 text-lg text-gray-600">
                  Schedule a 15-minute consultation to discuss your SBA loan eligibility with our expert team
                </p>
              </div>

              <div className="bg-blue-50 rounded-xl p-6 mb-8">
                <h2 className="text-xl font-semibold text-blue-900 mb-4">What to Expect:</h2>
                <ul className="space-y-3 text-blue-800">
                  <li className="flex items-center">
                    <svg className="h-5 w-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Evaluate your business's SBA loan eligibility
                  </li>
                  <li className="flex items-center">
                    <svg className="h-5 w-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Review loan amount options and terms
                  </li>
                  <li className="flex items-center">
                    <svg className="h-5 w-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Discuss required documentation
                  </li>
                  <li className="flex items-center">
                    <svg className="h-5 w-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Access to our network of 40+ preferred SBA lenders
                  </li>
                  <li className="flex items-center">
                    <svg className="h-5 w-5 mr-2 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Guaranteed best available terms if you qualify
                  </li>
                </ul>
              </div>

              <div className="bg-green-50 rounded-xl p-6 mb-8">
                <h2 className="text-xl font-semibold text-green-900 mb-4">Our Commitment to You:</h2>
                <p className="text-green-800">
                  With our network of over 40 preferred SBA lenders, we'll ensure you get the best possible terms if you qualify. 
                  If you don't currently qualify, we'll provide a clear roadmap for improvement and present alternative funding options 
                  tailored to your business needs.
                </p>
              </div>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    required
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    required
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                    onChange={handleInputChange}
                  />
                </div>

                <div>
                  <label htmlFor="selectedTime" className="block text-sm font-medium text-gray-700">
                    Select Available Time Slot (EST)
                  </label>
                  <div className="mt-4 space-y-4">
                    {/* Taken slots */}
                    <div className="mb-4">
                      <h3 className="text-sm font-medium text-gray-500 mb-2">Already Reserved:</h3>
                      <div className="space-y-2">
                        <div className="text-gray-400 line-through text-sm">Wednesday {wednesday.toLocaleDateString()} - 1:00 PM EST</div>
                        <div className="text-gray-400 line-through text-sm">Wednesday {wednesday.toLocaleDateString()} - 2:00 PM EST</div>
                        <div className="text-gray-400 line-through text-sm">Friday {friday.toLocaleDateString()} - 1:00 PM EST</div>
                        <div className="text-gray-400 line-through text-sm">Friday {friday.toLocaleDateString()} - 1:30 PM EST</div>
                      </div>
                    </div>

                    {/* Available slots */}
                    <select
                      name="selectedTime"
                      id="selectedTime"
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                      onChange={handleInputChange}
                    >
                      <option value="">Choose an available time</option>
                      <optgroup label={`Wednesday ${wednesday.toLocaleDateString()}`}>
                        {wednesdaySlots.map((slot, index) => (
                          <option key={`wed-${index}`} value={`Wednesday ${wednesday.toLocaleDateString()} ${slot.time}`}>
                            {slot.time} EST
                          </option>
                        ))}
                      </optgroup>
                      <optgroup label={`Friday ${friday.toLocaleDateString()}`}>
                        {fridaySlots.map((slot, index) => (
                          <option key={`fri-${index}`} value={`Friday ${friday.toLocaleDateString()} ${slot.time}`}>
                            {slot.time} EST
                          </option>
                        ))}
                      </optgroup>
                    </select>
                  </div>
                </div>

                {submitError && (
                  <div className="text-red-600 text-sm mt-2">
                    {submitError}
                  </div>
                )}

                <div className="mt-6">
                  <Button
                    type="submit"
                    color="mainBlue"
                    className="w-full justify-center py-3"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Reserving...' : 'Reserve Your Spot'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EligibilityMeeting;